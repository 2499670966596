import React, { createContext, useState, FC, useEffect } from "react";
import CookieCrypto from "../Extended-tools/CookieCrypto";

import { IOnlineClient, IUser, UserRole } from "../Models";

export type AppContextState = {
  user: IUser;
  setUser: (user: IUser) => void;
  updateImageUrl: (imageUrl: string) => void;
  setOnlineClientList: (data: IOnlineClient[]) => void;
  onlineList: IOnlineClient[] | null;
  setSidebarHideStatus: () => void;
  sidebarHideStatus: boolean;

};

const contextDefaultValues: AppContextState = {
  user: {
    userMapId: 0,
    userId: 0,
    staffId: 0,
    username: "",
    fullName: "",
    dbName: "",
    imageUrl: "",
    token: "",
    schoolFullName: "",
    role: UserRole.NEVER,
    firstLoginInfo: null
  },
  setUser: (user) => {},  
  updateImageUrl: (imageUrl) => {},
  onlineList: null,
  setOnlineClientList: (data) => {},
  setSidebarHideStatus: () => {},
  sidebarHideStatus: false,

};

export const AppContext = createContext<AppContextState>(contextDefaultValues);

const TodosProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, _setUser] = useState(contextDefaultValues.user);
  const [onlineList, _setOnlineList] = useState<any>(contextDefaultValues.onlineList);
  const [sidebarHideStatus, sethideSidebar] = useState<boolean>(false);

  const setUser = (user: IUser) => {
    CookieCrypto().SetCookie(JSON.stringify(user));
    _setUser(user);
  };


  const setSidebarHideStatus = () => {

    sethideSidebar((prev) => !prev);
  };

  const updateImageUrl = (imageUrl: string) => {
    setUser({ ...user, imageUrl: imageUrl });
  };

  const setOnlineClientList = (data: any) => {
    // console.log('onlineList JSOn => ', JSON.stringify(data));
    _setOnlineList(data);
  };

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        updateImageUrl,
        onlineList,
        setOnlineClientList,
        sidebarHideStatus,
        setSidebarHideStatus,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default TodosProvider;
