import { useEffect, useState } from "react";
import { IPaymentPlan, IPaymentPlanWithStudentsView } from "../../Models";
import { PaymentPlanService } from "../../Services";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import toast from "react-hot-toast";
import SkeletonList from "../../Components/Loading/Skeleton_List";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";

const PaymentPlanWithStudents = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IPaymentPlanWithStudentsView[] | null>();

  const paymentPlanService = PaymentPlanService();

  useEffect(() => {
    GetList();
  }, []);

  const GetList = async () => {
    setIsLoading(true);
    const { data, error } = await paymentPlanService.PaymentPlanWithStudents();
    setIsLoading(false);
    if (data) {
      if (data.success) {
        setData(data.returnData as IPaymentPlanWithStudentsView[]);
      } else {
        toast.error(data?.errorMessage);
      }
    } else {
      toast.error(error);
    }
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div>
        <Button
          style={{ height: 30, width: 30, marginRight: 10 }}
          icon="pi pi-eye"
          className="p-button-rounded p-button-warning"
          onClick={() => navigate(`/students/${rowData.studentId}`)}
        />
      </div>
    );
  };

  const formatCurrency = (value: number) => {
    return value.toLocaleString("tr-TR", { style: "currency", currency: "try" });
  };

  const ToplamOdenecek = () => {
    let total = 0;
    if (data) {
      for (let sale of data) {
        total += sale.totalAmountToBePaid;
      }
    }
    return 'Ödenecek: '+ formatCurrency(total);
  };

  const ToplamOdenen = () => {
    let total = 0;
    if (data) {
      for (let sale of data) {
        total += sale.totalAmountPaid;
      }
    }
    return 'Ödenen: '+formatCurrency(total);
  };

  const ToplamIndirim = () => {
    let total = 0;
    if (data) {
      for (let sale of data) {
        total += sale.totalDiscount;
      }
    }
    return 'İndirim: '+formatCurrency(total);
  };
  const ToplamKalan = () => {
    let total = 0;
    if (data) {
      for (let sale of data) {
        total += sale.totalDiscount;
      }
    }
    return 'Kalan: '+formatCurrency(total);
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Toplam:" colSpan={4} footerStyle={{ textAlign: "right" }} />
        <Column footer={ToplamOdenecek} />
        <Column footer={ToplamOdenen} />
        <Column footer={ToplamIndirim} />
        <Column footer={ToplamKalan} />
   
      </Row>
    </ColumnGroup>
  );

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">Ödeme Planı Tanımlı Öğrenciler</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div className="">
            <ConfirmDialog />
            <div className=" ">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className=" bg-white space-y-6 ">
                  {data && (
                    <DataTable
                      value={data}
                      className="table-auto w-full"
                      columnResizeMode="fit"
                      stripedRows
                      scrollable
                      scrollHeight="800px"
                      scrollDirection="vertical"
                      responsiveLayout="stack"
                      size="small"
                    
                      //  virtualScrollerOptions={{ itemSize: 46 }} 
                      loading={isLoading}
                      emptyMessage="Ekli ödeme planı bulunmamaktadır."
                      sortMode="multiple"
                      footer={`Toplam Kayıt Sayısı: ${data.length}`}
                      footerColumnGroup={footerGroup}
                    >
                      <Column field="studentFullName" className=" min-w-[200px]" header="Öğrenci adı soyadı" sortable></Column>
                      <Column field="classroomName" className="" header="Sınıfı" sortable></Column>
                      <Column field="planName" className="" header="Ödeme Planı" sortable></Column>
                      <Column field="installmentCount" className="" header="Taksit sayısı" sortable></Column>

                      <Column
                        sortable
                        field="totalAmountToBePaid"
                        className=""
                        header="Toplam Ödenecek"
                        body={(rowData: any) => `${rowData.totalAmountToBePaid.toLocaleString("tr-TR")} ₺`}
                      ></Column>

                      <Column field="totalAmountPaid" className="" header="Toplam Ödenen" body={(rowData: any) => `${rowData.totalAmountPaid.toLocaleString("tr-TR")} ₺`}></Column>
                      <Column field="totalDiscount" className="" header="Toplam İndirim" body={(rowData: any) => `${rowData.totalDiscount.toLocaleString("tr-TR")} ₺`}></Column>
                      <Column field="remainingAmount" className="" header="Kalan ödeme" body={(rowData: any) => `${rowData.remainingAmount.toLocaleString("tr-TR")} ₺`}></Column>

                      <Column className=" min-w-[110px]" header="İşlemler" body={(data) => actionBodyTemplate(data)} exportable={false}></Column>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentPlanWithStudents;
