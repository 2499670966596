import React, { useContext, useEffect, useState } from "react";
import { ParentService, PersonnelService, StudentService } from "../../Services";
import { useNavigate, useParams } from "react-router-dom";
import { EnPersonnelType, IClassroom, IPersonnel, IResultDto, UserRole } from "../../Models";
import InputComp from "../../Components/Input/InputComp";
import { ProgressSpinner } from "primereact/progressspinner";
import ProfileImage from "../../Components/Image/ProfileImage";
import { InputMask } from "primereact/inputmask";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { AppContext } from "../../Context/Store";
import toast from "react-hot-toast";
import SkeletonCard from "../../Components/Loading/Skeleton_Card";

const PersonnelCard = () => {
  const { user, updateImageUrl } = useContext(AppContext);
  const navigate = useNavigate();
  const { AddPersonnel, UpdatePersonnel, FindPersonnelById } = PersonnelService();
  const { GetClassroomList } = StudentService();
  const params = useParams();
  let classroomListGlo: IClassroom[] = [];
  const [classroomList, setClassroomList] = useState<IClassroom[]>([]);
  const [selectedClassroom, setSelectedClassroom] = useState<IClassroom>();

  const [selectedParentType, setSelectedParentType] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const [isUpdateData, setIsUpdateData] = useState(false);

  const [personnelData, setPersonnelData] = useState<IPersonnel>({
    id: 0,
    name: "",
    surname: "",
    mobilePhone: "",
    imageUrl: "",
    password: "",
    personnelType: EnPersonnelType.PERSONEL,
    isActive: true,
    createdDate: new Date(),
    updatedDate: null,
    description: "",
    isMapped: false,
    accessNumber: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      if (params.id && Number.parseInt(params.id) > 0) {
        await GetClassroomListData();
        await GetParentById(Number.parseInt(params.id));
      } else {
        await GetClassroomListData();
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

 
  const GetClassroomListData = async (): Promise<void> => {
    const { data, error } = await GetClassroomList();
    if (error) {
      console.log("error => ", error);
    } else {
      var _data = data?.returnData as IClassroom[];
      classroomListGlo = _data;
      setClassroomList(_data);
    }
  };

  const GetParentById = async (parentId: number): Promise<void> => {
    setIsLoading(true);
    const { data, error } = await FindPersonnelById(parentId);
    setIsLoading(false);

    if (error) {
      console.log("error => ", error);
    } else {
      const resData = data?.returnData as IPersonnel;

      setSelectedParentType(resData.personTypeName ? resData.personTypeName : "");
      setPersonnelData(resData);
      setIsUpdateData(true);

      //@ts-ignore
      if (resData?.classroomTeacher && resData?.classroomTeacher?.id > 0) {
        //@ts-ignore
        const _classroom = classroomListGlo.find((x) => x.id === resData?.classroomTeacher?.classroomId);
        if (_classroom) {
          setSelectedClassroom(_classroom);
        }
      }
    }
  };

  const ogrenimDurumuOptionTemplate = (option: any) => {
    return <span>{option}</span>;
  };

  const selectedOgrenimDurumuTemplate = (option: string, props: any) => {
    if (option) {
      return (
        <div className="flex justify-between " style={{ marginTop: -4 }}>
          <span>{option}</span>
        </div>
      );
    }

    return (
      <div className="flex " style={{ marginTop: -4 }}>
        {props.placeholder}
      </div>
    );
  };

  const btnSaveHandle = async () => {
    if (personnelData) {
      if (!personnelData.mobilePhone && !personnelData.name && !personnelData.surname) {
        toast.error("Adı, soyadı ve telefon numarası boş olamaz");
        return;
      }
      setIsProgress(true);

      let _data = { ...personnelData };


      _data.personnelType = getEnumKeyName(selectedParentType) as EnPersonnelType;

      _data.classroomId = selectedClassroom?.id;

      const { data, error } = isUpdateData ? await UpdatePersonnel(_data) : await AddPersonnel(_data);
      setIsProgress(false);

      if (data) {
        if (data.success) {
          updateImageUrl(personnelData.imageUrl);
          toast.success("İşlem başarılı");
          return navigate("/personnels");
        } else {
          const err = data as IResultDto;
          toast.error(`${err.errorMessage}`);
        }
      }
    } else {
      toast.error("Bilgileri eksiksiz girmeniz gerekmektedir");
    }
  };

  const kisiTipi = () => Object.keys(EnPersonnelType).map((key) => (EnPersonnelType as any)[key]);

  // const kisiTipi = () => {
  //   const _role = UserRole[user.role.toUpperCase() as keyof typeof UserRole];
  //   return Object.keys(EnPersonnelType).map((key) => {
  //     if (key == "ADMIN") {
  //       if (user.role == UserRole.ADMIN || user.role == UserRole.SUPERADMIN) {
  //         return (EnPersonnelType as any)[key];
  //       }
  //     } else {
  //       return (EnPersonnelType as any)[key];
  //     }
  //   });
  // };

  function getEnumKeyName(value: string) {
    return Object.entries(EnPersonnelType).find(([key, val]) => val === value)?.[0];
  }

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">{isUpdateData ? "Personel Güncelle" : "Yeni Personel ekle"}</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      {isLoading ? (
        <SkeletonCard />
      ) : (
        <>
          <div className="ml-2">
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  {/* Burası ilk satır başlangıç */}
                  <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-5 lg:col-span-5">
                      <label className="col-sm-2col-form-label">Resim</label>
                      <div className="w-44 h-44 border-2 rounded-md flex justify-center">
                        <ProfileImage
                          uploadImageUrl={async (e) => {}}
                          modalOpen={true}
                          folder="Personel"
                          value={personnelData.imageUrl}
                          selectedImage={async (e) => {
                            setPersonnelData({ ...personnelData, imageUrl: e });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-span-4 lg:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">Adı</label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <InputComp
                          type="text"
                          value={personnelData.name}
                          placeholder="Personel adı"
                          onChange={(e: any) => {
                            setPersonnelData({
                              ...personnelData,
                              name: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-span-4 lg:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">Soyadı</label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <InputComp
                          type="text"
                          value={personnelData.surname}
                          placeholder="Personel Soyadı"
                          onChange={(e: any) => {
                            setPersonnelData({
                              ...personnelData,
                              surname: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-span-4 lg:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">Telefon</label>
                      <div className="mt-1 rounded-md shadow-sm">
                        <InputComp
                          tooltip="Başında 0 olmadan giriniz (xxx)xxxxxxx"
                          tooltipOptions={{
                            position: "top",
                            autoHide: true,
                          }}
                          type="text"
                          value={personnelData.mobilePhone}
                          placeholder="Telefon"
                          onChange={(e: any) => {
                            if (e.target.value.length > 0) {
                              if (e.target.value.toString().startsWith("0")) {
                                return;
                              } else if (e.target.value.length > 10) {
                                return;
                              }
                            }
                            setPersonnelData({
                              ...personnelData,
                              mobilePhone: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-span-4 lg:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">Şifre :</label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        {personnelData.personTypeName == EnPersonnelType.ADMIN ? (
                          user.role != UserRole.ADMIN && user.role != UserRole.SUPERADMIN ? (
                            <label>yetkisiz</label>
                          ) : (
                            <Password
                              className="min-w-full h-[38px]"
                              feedback={false}
                              value={personnelData.password ? personnelData.password : ""}
                              toggleMask
                              onChange={(e) =>
                                setPersonnelData({
                                  ...personnelData,
                                  password: e.target.value,
                                })
                              }
                            />
                          )
                        ) : (
                          <Password
                            className="min-w-full h-[38px]"
                            feedback={false}
                            value={personnelData.password ? personnelData.password : ""}
                            toggleMask
                            onChange={(e) =>
                              setPersonnelData({
                                ...personnelData,
                                password: e.target.value,
                              })
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-span-4 lg:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">Görevi :</label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <Dropdown
                          value={selectedParentType}
                          options={kisiTipi()}
                          onChange={(e) => {
                            setSelectedParentType(e.value);
                          }}
                          placeholder="Yetki Türü Seçiniz"
                          className="w-full h-10 "
                          valueTemplate={selectedOgrenimDurumuTemplate}
                          itemTemplate={ogrenimDurumuOptionTemplate}
                        />
                      </div>
                    </div>

                    <div className="col-span-4 lg:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">Geçiş - Access Numarası</label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <InputComp
                          type="text"
                          value={personnelData.accessNumber}
                          placeholder="Geçiş numarası varsa doldurunuz"
                          onChange={(e: any) => {
                            setPersonnelData({
                              ...personnelData,
                              accessNumber: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-span-4 lg:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">Personel Aktifliği</label>
                      <div className="flex items-center h-11 ">
                        <InputSwitch
                          inputId="smsServiceApiActive"
                          onChange={(e) =>
                            setPersonnelData({
                              ...personnelData,
                              isActive: e.value,
                            })
                          }
                          checked={personnelData.isActive}
                        ></InputSwitch>
                        <label htmlFor="userActive" className="ml-3">
                          {personnelData.isActive ? "Kişi aktif" : "Kişi pasif"}
                        </label>
                      </div>
                    </div>
                    {selectedParentType === EnPersonnelType.OGRETMEN && (
                      <div className="col-span-4 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Sınıfı : (Sadece Öğretmenler için)</label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Dropdown
                            value={selectedClassroom}
                            options={classroomList}
                            onChange={(e) => setSelectedClassroom(e.value)}
                            optionLabel="name"
                            placeholder="Sınıf Seçiniz"
                            className="w-full h-10"
                            valueTemplate={(option) => option && <span>{option.name}</span>}
                            itemTemplate={(option) => <span>{option.name}</span>}
                            showClear
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <Button
                    onClick={() => btnSaveHandle()}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    label="Kaydet"
                    loading={isProgress}
                  ></Button>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PersonnelCard;
