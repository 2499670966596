import React, { useEffect, useRef, useState } from "react";
import InputComp from "../../Components/Input/InputComp";
import { IClassroom, IStudent } from "../../Models";
import { StudentService } from "../../Services";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import SkeletonList from "../../Components/Loading/Skeleton_List";

const ClassroomList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [classroomList, setClassroomList] = useState<IClassroom[] | null>();
  const classroomService = StudentService();
  const toast: any = useRef(null);
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    ClassroomList();
  }, []);

  const ClassroomList = async () => {
    setIsLoading(true);
    const { data, error } = await classroomService.GetClassroomList();
    setIsLoading(false);
    if (data) {
      if (data.success) {
        setClassroomList(data.returnData as IClassroom[]);
      } else {
        setClassroomList(null);
        toast.error(data?.errorMessage);
      }
    }
  };

  const btnDeleteHandle = async (itemId: any) => {
    const { data, error } = await classroomService.DeleteClasroom(itemId);
    setIsLoading(false);

    if (error) {
      console.log("Error DeleteSchoolHandle => ", error);
    } else {
      if (data?.success) {
        toast.current.show({
          severity: "success",
          summary: "Bilgi",
          detail: "Silme işlemi başarılı",
          life: 3000,
        });
        ClassroomList();
      } else {
        reject(data?.errorMessage);
      }
    }
  };

  const confirm2 = (data: any) => {
    confirmDialog({
      message: `${data.name} isimli sınıfı silmek istediğinize emin misiniz ?`,
      header: "Silme işlemi",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => {
        btnDeleteHandle(data.id);
      },
      reject,
    });
  };

  const reject = (text: string = "") => {
    toast.current.show({
      severity: "warn",
      summary: "Bİlgi",
      detail: text ? text : "Silme işlemi iptal edildi",
      life: 3000,
    });
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div>
        <Button
          style={{ height: 30, width: 30, marginRight: 10 }}
          icon="pi pi-eye"
          className="p-button-rounded p-button-warning"
          onClick={() => {
            navigate(`/classroom/${rowData.id}`);
          }}
        />
        <Button style={{ height: 30, width: 30 }} icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirm2(rowData)} />
      </div>
    );
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">Sınıf Listesi</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div className="">
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className=" ">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className=" bg-white space-y-6 ">
                  {classroomList && (
                    <DataTable
                      value={classroomList}
                      stripedRows
                      scrollable
                      responsiveLayout="scroll"
                      loading={isLoading}
                      emptyMessage="Ekli Sınıf Bulunmamaktadır."
                      footer={`Toplam Kayıt Sayısı: ${classroomList.length} - Toplam Öğrenci Sayısı : ${classroomList.reduce((partialSum, a) => partialSum + a.studentCount, 0)}`}
                      scrollHeight="650px"
                      size="small"
                      scrollDirection="vertical"
                      columnResizeMode="fit"
                    >
                      <Column sortable field="name" className="h-11" header="Adı"></Column>
                      <Column sortable field="studentCount" className="h-11" header="Öğrenci Sayısı"></Column>
                      <Column className="h-11" header="İşlemler" body={(data) => actionBodyTemplate(data)} exportable={false}></Column>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ClassroomList;
