import React, { useEffect, useRef, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import InputComp from "../../Components/Input/InputComp";
import { Button } from "primereact/button";
import { StudentService } from "../../Services";
import { IClassroom } from "../../Models";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

const ClassroomCard = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const btnRef: any = useRef<HTMLButtonElement>();

  const [classroomData, setClassroomData] = useState<IClassroom>({
    id: 0,
    name: "",
    studentCount: 0,
  });

  const classroomService = StudentService();
  // Değişken tanımlama ayarlaması

  useEffect(() => {
    if (params.id && Number.parseInt(params.id) > 0) {
      FindClassroom(Number.parseInt(params.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FindClassroom = async (itemId: number) => {
    setIsLoading(true);
    const { data, error } = await classroomService.FindClassroomById(itemId);
    setIsLoading(false);

    if (error) {
      console.log("error => ", error);
    } else {
      setClassroomData(data?.returnData as IClassroom);
      setIsUpdateData(true);
    }
  };

  const btnSaveHandle = async (stayOnPage: boolean = false) => {
    console.log("classroomData=> ", classroomData);
    if (classroomData.name === "") {
      toast.error("Sınıf adı boş olamaz !");
      return;
    }
    btnRef.current.disabled = true;

    const classroomId =
      params.id && Number.parseInt(params.id) > 0
        ? Number.parseInt(params.id)
        : 0;

    const { data, error } =
      classroomId > 0
        ? await classroomService.UpdateClassroom(classroomData)
        : await classroomService.AddClassroom(classroomData);
    console.log("data => ", data);

    btnRef.current.disabled = false;

    if (data) {
      if (data.success) {
        if (!stayOnPage) {
          navigate("/classrooms");
        } else {
          setClassroomData({ id: 0, name: "", studentCount: 0 });
        }
      } else {
        toast.error(data?.errorMessage);
      }
    }

    btnRef.current.disabled = false;
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      {isLoading && (
        <ProgressSpinner style={{ width: "50px", height: "50px" }} />
      )}

      <div className="m-2 mb-5">
        <label className="text-2xl">
          {isUpdateData ? "Sınıf Güncelle" : "Yeni Sınıf ekle"}
        </label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>
      <div className="ml-2">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div className="grid grid-cols-4 gap-4">
                <div className="col-span-4 lg:col-span-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Sınıf Adı
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={classroomData.name}
                      placeholder="Sınıf adı"
                      onChange={(e: any) => {
                        setClassroomData({
                          ...classroomData,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center px-4 py-3 bg-gray-50 text-right sm:px-6">
              <div></div>
              <div>
                <Button
                  className="h-10"
                  loading={btnRef.current?.disabled}
                  ref={btnRef}
                  onClick={() => btnSaveHandle()}
                  label="Kaydet"
                ></Button>
                <Button
                  className="h-10"
                  loading={btnRef.current?.disabled}
                  ref={btnRef}
                  style={{ marginLeft: "10px" }}
                  onClick={() => btnSaveHandle(true)}
                  label="Kaydet ve Sayfada Kal"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default ClassroomCard;
