import moment from "moment";
import React from "react";
import AxiosUtility from "../Extended-tools/AxiosUtility";
import { IClassroom, ICompanyStudentPayment, IResultDto, IStudent, IStudentAnnouncementClass, IStudentParent, IStudentParentWiew_Card } from "../Models";

const StudentService = () => {
  const CreateStudentAnnouncementClass = async (data: IStudentAnnouncementClass) => {
    try {
      console.log("start :" + moment().format("YYYY-MM-DD HH:mm:ss"));
      const response = await AxiosUtility().post("/student/AddStudentAnnouncementClass", data);
      console.log("end :" + moment().format("YYYY-MM-DD HH:mm:ss"));

      // console.log("AddStudent service response ", response);
      if (response.status === 200) {
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const AddStudent = async (data: IStudent) => {
    try {
      const response = await AxiosUtility().post("/student/add", data);
      // console.log("AddStudent service response ", response);
      if (response.status === 200) {
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const DeleteStudent = async (id: number) => {
    try {
      const response = await AxiosUtility().delete(`/student/${id}`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GetAllStudents = async (desc: boolean) => {
    try {
      // console.log("Start Students:" + moment().format("YYYY-MM-DD HH:mm:ss"));

      const response = await AxiosUtility().get(`/student/all?desc=${desc}`);
      // console.log("End Students:" + moment().format("YYYY-MM-DD HH:mm:ss"));

      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const GetAllStudentsOrderName = async (desc: boolean) => {
    try {
      // console.log("Start Students:" + moment().format("YYYY-MM-DD HH:mm:ss"));

      const response = await AxiosUtility().get(`/student/all/order?desc=${desc}`);
      // console.log("End Students:" + moment().format("YYYY-MM-DD HH:mm:ss"));

      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const GetAllStudentsWithPaymentPlan = async () => {
    try {
      console.log("Start :" + moment().format("YYYY-MM-DD HH:mm:ss"));
      const response = await AxiosUtility().get(`/student/AllWithPaymentPlan`);
      console.log("End :" + moment().format("YYYY-MM-DD HH:mm:ss"));
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const CompanyStudentPayment = async () => {
    try {
      console.log("Start CompanyStudentPayment :" + moment().format("YYYY-MM-DD HH:mm:ss"));
      const response = await AxiosUtility().get(`/student/CompanyStudentPayment`);
      console.log("End CompanyStudentPayment :" + moment().format("YYYY-MM-DD HH:mm:ss"));
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const UpdateStudent = async (data: IStudent) => {
    try {
      const response = await AxiosUtility().put("/student/Update", data);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const StudentPaymentAddForCompany = async (data: ICompanyStudentPayment) => {
    try {
      const response = await AxiosUtility().post("/paymentPlan/StudentPaymentAddForCompany", {
        studentId: data.studentId,
        noteAdmin: data.adminNote,
        discount: data.discount,
        paidAmount: data.paidAmount,
      });
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const FindStudentById = async (studentId: string) => {
    try {
      // console.log("Start FindStudent: " + moment().format("YYYY-MM-DD HH:mm:ss"));
      const response = await AxiosUtility().get(`/student/${studentId}`);
      // console.log("End FindStudent: " + moment().format("YYYY-MM-DD HH:mm:ss"));
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const FindStudentByNameSurname = async (nameSurname: string) => {
    try {
      // console.log("Start FindStudent: " + moment().format("YYYY-MM-DD HH:mm:ss"));
      const response = await AxiosUtility().get(`/student/find?nameSurname=${nameSurname}`);
      // console.log("End FindStudent: " + moment().format("YYYY-MM-DD HH:mm:ss"));
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const AddClassroom = async (data: IClassroom) => {
    // console.log("running AddClassroom");
    try {
      const response = await AxiosUtility().post("/student/classroom/add", data);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const UpdateClassroom = async (classroom: IClassroom) => {
    // console.log("running UpdateClassroom");
    try {
      const response = await AxiosUtility().put("/student/classroom/Update", classroom);

      if (response.status === 200) {
        // console.log("UpdateClassroom service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('UpdateClassroom service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const DeleteClasroom = async (classroomId: number) => {
    try {
      if (classroomId && classroomId > 0) {
        const response = await AxiosUtility().delete(`/student/classroom/delete/${classroomId}`);

        if (response.status === 200) {
          // console.log('AddCategory service response 200 ', response)
          const data = response.data as IResultDto;
          // console.log('data=> ', data)
          return { data };
        } else {
          // console.log('AddCategory service response !200 ', response)
          const error = response;
          return { error };
        }
      } else {
        const error = "Geçerli bir kategori seçimi yapılmadı !";
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const FindClassroomById = async (categoryId: number) => {
    try {
      if (categoryId && categoryId > 0) {
        const response = await AxiosUtility().post(`/student/classroom/${categoryId}`);

        if (response.status === 200) {
          // console.log('AddCategory service response 200 ', response)
          const data = response.data as IResultDto;
          // console.log('data=> ', data)
          return { data };
        } else {
          // console.log('AddCategory service response !200 ', response)
          const error = response;
          return { error };
        }
      } else {
        const error = "Geçerli bir kategori seçimi yapılmadı !";
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GetClassroomList = async () => {
    try {
      const response = await AxiosUtility().get(`/student/classroom/all`);

      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const CreateSoundsWithAll = async () => {
    try {
      const response = await AxiosUtility().get(`/student/CreateSoundsWithAll`);

      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const CreateSoundsWithAllEmpty = async () => {
    try {
      const response = await AxiosUtility().get(`/student/CreateSoundsWithAllEmpty`);

      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const CreateSoundById = async (studentId: number) => {
    try {
      const response = await AxiosUtility().post(`/student/createSounds/${studentId}`);

      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const AddStudentParent = async (studentId: number, parentId: number, parentType: string, sendSms: boolean, isParent: boolean, isUseMobile: boolean, isUseCard: boolean) => {
    try {
      const data = {
        id: 0,
        parentId,
        parentType,
        studentId,
        sendSms,
        isParent,
        isUseMobile,
        isUseCard,
      };

      const response = await AxiosUtility().post("/parent/AddStudentparent", data);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const StudentParentMatchList = async (desc: boolean) => {
    try {
      const response = await AxiosUtility().get(`/student/StudentParentMatchList${desc && "?desc=true"}`);

      if (response.status === 200) {
        const data = response.data as IResultDto;
        // console.log('GetAllParents service response 200 ', data)

        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const StudentParentCardUseList = async () => {
    try {
      const response = await AxiosUtility().get(`/student/StudentParentCardUseList`);

      if (response.status === 200) {
        const data = response.data as IResultDto;
        // console.log('GetAllParents service response 200 ', data)

        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const DeleteStudentParentRelation = async (id: number) => {
    try {
      const response = await AxiosUtility().delete(`/parent/studentParent/delete/${id}`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const AddStudentCardReuest = async (data: IStudentParentWiew_Card[]) => {
    try {
      const response = await AxiosUtility().post("/parent/AddCartNumberList", data);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const UpdateStudentParent = async (studentParent: IStudentParent) => {
    try {
      const response = await AxiosUtility().put("/parent/UpdateStudentParent", studentParent);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const StudentListWithParent = async () => {
    try {
      const response = await AxiosUtility().get(`/student/StudentListWithParent`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const GetParentList = async (studentId: number) => {
    try {
      const response = await AxiosUtility().get(`/student/${studentId}/parents`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const DownloadEOkulImage = async (studentIds: number[]) => {
    try {
      const response = await AxiosUtility().post("/student/downloadResizedImageAllStudent", studentIds, {
        responseType: "blob",
      });

      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)

        // let blob = new Blob([response.data], { type: "application/zip" }),
        //   url = window.URL.createObjectURL(blob);

        // window.open(url); // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions

        const data = response.data as IResultDto;

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "E-Okul Öğrenci Resimleri.zip"); //or any other extension
        document.body.appendChild(link);
        link.click();

        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GetAllStudentParents = async (desc: boolean) => {
    try {
      const response = await AxiosUtility().get(`/parent/AllStudentParent${desc && "?desc=true"}`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const AddFastStudentWithParent = async (data: any) => {
    try {
      const response = await AxiosUtility().post("/student/AddFastStudentWithParent", data);
      // console.log("AddStudent service response ", response);
      if (response.status === 200) {
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  // studentParent işlemleri

  const StudentParentCardUpdateSingle = async (id: number, cardNumber: string) => {
    try {
      const response = await AxiosUtility().put("/student/StudentParentCardUpdateSingle", { id, cardNumber });
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const TopluKartnumarasiGirisi = async (model: any) => {
    try {
      const response = await AxiosUtility().post("/parent/AddBulkCardNumber", model);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GenerateRandomCardNumber = async (ids: any) => {
    try {
      const response = await AxiosUtility().post("/parent/GenerateRandomCardNumber", ids);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GetStudentAnnouncementClassByStudent = async (studentId: number) => {
    try {
      const response = await AxiosUtility().get(`/student/${studentId}/StudentAnnouncementClassList`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const DeleteStudentAnnouncementClass = async (id: number) => {
    try {
      if (id && id > 0) {
        const response = await AxiosUtility().delete(`/student/DeleteStudentAnnouncementClass/${id}`);

        if (response.status === 200) {
          // console.log('AddCategory service response 200 ', response)
          const data = response.data as IResultDto;
          // console.log('data=> ', data)
          return { data };
        } else {
          // console.log('AddCategory service response !200 ', response)
          const error = response;
          return { error };
        }
      } else {
        const error = "Geçerli bir kategori seçimi yapılmadı !";
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  //StudentMovement?page=1&pageSize=3
  const GetStudentMovementList = async (studentId: number) => {
    try {
      const response = await AxiosUtility().get(`/student/${studentId}/StudentMovementAll`);

      if (response.status === 200) {
        // console.log('LoginUser service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        console.log("LoginUser service response !200 ", response);
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      return { error };
    }
  };
  const StudentMovementResponse = async (movementId: number) => {
    try {
      const response = await AxiosUtility().get(`/student/StudentMovementRespons/${movementId}`);

      if (response.status === 200) {
        // console.log('LoginUser service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        console.log("StudentMovementResponse service response !200 ", response);
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      return { error };
    }
  };

  const ChangeStudentClassroom = async (studentId: number, classroomId: number) => {
    try {
      const response = await AxiosUtility().post(`/student/ChangeStudentClassroom/?studentId=${studentId}&classroomId=${classroomId}`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        return { data };
      } else {
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      return { error };
    }
  }




  return {
    AddStudent,
    GetAllStudentsOrderName,
    GetAllStudents,
    FindStudentById,
    UpdateStudent,
    AddClassroom,
    GetClassroomList,
    FindClassroomById,
    UpdateClassroom,
    DeleteClasroom,
    StudentParentMatchList,
    AddStudentParent,
    GetAllStudentParents,
    DeleteStudentParentRelation,
    GetAllStudentsWithPaymentPlan,
    AddFastStudentWithParent,
    DeleteStudent,
    StudentListWithParent,
    GetParentList,
    CreateSoundsWithAll,
    CreateSoundsWithAllEmpty,
    CreateSoundById,
    UpdateStudentParent,
    StudentParentCardUseList,
    StudentParentCardUpdateSingle,
    AddStudentCardReuest,
    DownloadEOkulImage,
    GetStudentAnnouncementClassByStudent,
    DeleteStudentAnnouncementClass,
    CreateStudentAnnouncementClass,
    TopluKartnumarasiGirisi,
    CompanyStudentPayment,
    StudentPaymentAddForCompany,
    FindStudentByNameSurname,
    GenerateRandomCardNumber,
    GetStudentMovementList,
    StudentMovementResponse,
    ChangeStudentClassroom
  };
};

export { StudentService };
