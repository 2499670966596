import React, { useEffect, useRef, useState } from "react";
import InputComp from "../../Components/Input/InputComp";
import { IPaymentPlan, IStudent, IStudentPayment } from "../../Models";
import { PaymentPlanService, StudentService } from "../../Services";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import toast from "react-hot-toast";
import { BlockUI } from "primereact/blockui";
import { FilterMatchMode } from "primereact/api";

const StudentBulkInstallment = () => {
  const navigate = useNavigate();
  const studentService = StudentService();
  const btnRef: any = useRef<HTMLButtonElement>();
  const [isLoading, setIsLoading] = useState(false);
  const [loadinPaymenList, setLoadingPaymentList] = useState(false);
  const [paymentPlanList, setPaymentPlanList] = useState<
    IPaymentPlan[] | null
  >();
  const [studentList, setStudentList] = useState<IStudent[] | null>();
  const [selectedStudent, setSelectedStudent] = useState<IStudent[] | null>();
  const paymentPlanService = PaymentPlanService();
  const [selectedPaymentPlan, setSelectedPaymentPlan] =
    useState<IPaymentPlan>();
    const [filters1, setFilters1] = useState<any>(null);

    
  useEffect(() => {
    GetPaymentPlanList();
    GetStudentList();
  }, []);

  const GetStudentList = async () => {
    setIsLoading(true);
    const { data, error } =
      await studentService.GetAllStudentsWithPaymentPlan();
    setIsLoading(false);
    if (data) {
      if (data.success) {
        setStudentList(data.returnData as IStudent[]);
        initFilters1();
      } else {
        toast.error(data?.errorMessage);
      }
    }
  };
  const GetPaymentPlanList = async () => {
    setLoadingPaymentList(true);
    const { data, error } = await paymentPlanService.PaymentPlanListAll();
    setLoadingPaymentList(false);
    if (data) {
      if (data.success) {
        setPaymentPlanList(data.returnData as IPaymentPlan[]);
      } else {
        toast.error(data?.errorMessage);
      }
    } else {
      toast.error(error);
    }
  };

  const addPaymentAdd = async () => {
    // console.log("selectedStudent => ", selectedStudent);
    // console.log("selectedPaymentPlan => ", selectedPaymentPlan);
    btnRef.current.disabled = true;
    if (selectedStudent && selectedPaymentPlan) {
      setIsLoading(true);
      let success = false;
      for (let i = 0; i < selectedStudent.length; i++) {
        const { data } = await paymentPlanService.AddPaymentPlanForStudent(
          selectedStudent[i].id,
          selectedPaymentPlan.planName
        );
        console.log("data => ", data);
        if (data) {
          if (data.success === true) {
            const student = data.returnData as IStudentPayment[];

            console.log("student=> ", student[0].student.name);
            if (student) {
              toast.success(
                `${student[0].student.name} ${student[0].student.surname} için ödeme planı eklendi.`
              );
            }

            success = true;
          } else {
            toast.error("Hata !" + data?.errorMessage);
            success = false;
          }
        }
      }

      setSelectedStudent(null);
      setSelectedPaymentPlan(undefined);
      await GetStudentList();

      btnRef.current.disabled = false;
      if (success) {
        // navigate("/payment/student-bulk-installment");
      }

      setIsLoading(false);
    } else {
      toast.error("Ödeme planı ve öğrenci seçimi yapılmadı !");
    }
  };

  const isSelectable = (student: IStudent) => {
    let isSelectable = true;
    let planName = "";

    if (student.studentPayments && student.studentPayments.length > 0) {
      var a = student.studentPayments.map((t) => t.paymentPlan.planName);
      var unique = a.filter(onlyUnique);
      planName = unique.join(", ");
      if (selectedPaymentPlan) {
        if (planName ===selectedPaymentPlan?.planName) {
          isSelectable = false;
        }
      }
    }

    return isSelectable;
  };

  const isRowSelectable = (event: any) => {
    const data = event.data as IStudent;
    return isSelectable(data);
  };

  function onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  const nameWithPaymentPlanName = (student: IStudent) => {
    let planName = "";

    if (student.studentPayments && student.studentPayments.length > 0) {
      var a = student.studentPayments.map((t) => t.paymentPlan.planName);
      var unique = a.filter(onlyUnique);
      planName = unique.join(", ");
    }

    if (
      selectedPaymentPlan &&
      student.studentPayments &&
      student.studentPayments.length > 0
    ) {
      if (planName ===selectedPaymentPlan?.planName) {
        return (
          <div className="p-disabled ">
            <label>{student.name}</label>
            <label className="block text-sm text-orange-400">
              {planName.length > 0 ? planName : ""}
            </label>
          </div>
        );
      }
    }

    return (
      <div>
        <label>{student.fullName}</label>
        <label className="block text-sm text-orange-400">
          {planName.length > 0 ? planName : ""}
        </label>
      </div>
    );
  };


  const initFilters1 = () => {
    setFilters1({
      fullName: { value: null, matchMode: FilterMatchMode.CONTAINS,lng : "tr"  },
      "classroom.name": { value: null, matchMode: FilterMatchMode.CONTAINS ,lng : "tr" },
    });
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      {/* {isLoading && (
        <ProgressSpinner style={{ width: "50px", height: "50px" }} />
      )} */}

      <div className="m-2 mb-5">
        <label className="text-2xl">Öğrenciye taksit tanımla</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>
      <div className="">
        <ConfirmDialog />
        <div>
          <label className="text-xl">Ödeme planı listesi</label>
          <hr className="my-auto flex-grow-1 mt-2" />
        </div>
        <div className="grid gap-1 ">
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className=" bg-white space-y-6 ">
              {paymentPlanList && (
                <DataTable
                  columnResizeMode="fit"
                  loadingIcon="fa fa-spinner fa-spin"
                  loading={loadinPaymenList}
                  value={paymentPlanList}
                  selectionMode="single"
                  onSelectionChange={(e) => setSelectedPaymentPlan(e.value)}
                  selection={selectedPaymentPlan}
                  stripedRows
                  scrollable
                  responsiveLayout="scroll"
                  emptyMessage="Ekli ödeme planı bulunmamaktadır."
                >
                  <Column
                    selectionMode="single"
                    className="h-10 max-w-[40px]"
                  ></Column>
                  <Column
                    field="planName"
                    className="h-10"
                    header="Ödeme Planı Adı"
                  ></Column>
                  <Column
                    field="installment"
                    className="h-10"
                    header="Taksit Sayısı"
                  ></Column>
                </DataTable>
              )}
            </div>
          </div>
        </div>

        <div className="mt-8">
          <label className="text-xl">Öğrenci listesi</label>
          <hr className="my-auto flex-grow-1 mt-2" />
          <div className="grid gap-1 ">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className=" bg-white space-y-6 ">
                <BlockUI
                  blocked={selectedPaymentPlan ? false : true}
                  template={
                    <div className="flex flex-col justify-center items-center">
                      <i
                        className="pi pi-lock text-gray-800"
                        style={{ fontSize: "3rem" }}
                      />

                      <label className="block text-white">
                        Ödeme planı seçiniz
                      </label>
                    </div>
                  }
                >
                  {studentList && (
                    <DataTable
                      filters={filters1}
                      disabled={true}
                      value={studentList}
                      dataKey="id"
                      stripedRows
                      emptyMessage="Kayıtlı öğrenci yok"
                      responsiveLayout="scroll"
                      scrollHeight="650px"
                      loading={isLoading}
                      selection={selectedStudent}
                      onSelectionChange={(e) => setSelectedStudent(e.value)}
                      isDataSelectable={(e: any) => isRowSelectable(e)}
                      scrollable
                      size="small"
                      filterDisplay="row"
                    >
                      <Column
                        className="max-w-[40px]"
                        headerClassName="w-4"
                        selectionMode="multiple"
                        headerStyle={{ width: "3em" }}
                      ></Column>
                      <Column
                        filter
                        filterMatchMode="contains"
                        body={nameWithPaymentPlanName}
                        filterField="fullName"
                        header="Adı"
                      ></Column>

                      <Column
                        filter
                        filterMatchMode="contains"
                        field="classroom.name"
                        header="Sınıf"
                      ></Column>
                    </DataTable>
                  )}
                </BlockUI>
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center px-4 py-3 bg-gray-50 text-right sm:px-6">
            <div>
              <Button
                ref={btnRef}
                loading={btnRef.current?.disabled}
                disabled={btnRef.current?.disabled}
                className="h-10"
                onClick={() => {
                  addPaymentAdd();
                }}
                label="Kaydet"
              ></Button>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default StudentBulkInstallment;
