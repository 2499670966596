import { useEffect, useRef, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import InputComp from "../../Components/Input/InputComp";
import { Button } from "primereact/button";
import { IAppSetting, IResultDto } from "../../Models";
import { AppSettingService, SmsService } from "../../Services";
import toast from "react-hot-toast";
import { Dropdown } from "primereact/dropdown";
import { PrimeIcons } from "primereact/api";
import { classNames } from "primereact/utils";
import { InputSwitch } from "primereact/inputswitch";
const SettingPage = () => {
  const appSettingService = AppSettingService();
  const smsService = SmsService();
  const btnRef: any = useRef<HTMLButtonElement>();
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [senderList, setSenderList] = useState<any[]>([]);
  const [selectedSender, setSelectedSender] = useState("");
  const [senderListLoading, setSenderListLoading] = useState(false);
  const [credit, setCredit] = useState("--");
  const [settingData, setSettingData] = useState<IAppSetting[]>([
    {
      id: 0,
      setKey: "SmsApiUserName",
      setValue: "",
      isActive: false,
    },
    {
      id: 0,
      setKey: "SmsApiPassword",
      setValue: "",
      isActive: false,
    },
    {
      id: 0,
      setKey: "SmsDefaultSender",
      setValue: "",
      isActive: false,
    },
    {
      id: 0,
      setKey: "SendSms",
      setValue: "False",
      isActive: false,
    },
    {
      id: 0,
      setKey: "StudentEntryExistSendSms",
      setValue: "False",
      isActive: false,
    },
    {
      id: 0,
      setKey: "SendSmsLatePayment",
      setValue: "False",
      isActive: false,
    },
    {
      id: 0,
      setKey: "IsUseMobileApp",
      setValue: "False",
      isActive: false,
    },
  ]);

  useEffect(() => {
    GetSettings();
  }, []);

  const GetSettings = async () => {
    setIsLoading(true);
    const { data } = await appSettingService.GetAllSetting();
    setIsLoading(false);
    if (data) {
      if (data.success) {
        const _data = data.returnData as IAppSetting[];
        if (_data.length > 0) {
          setSettingData(_data);
          if (_data) {
            const username = _data.find((t) => t.setKey == "SmsApiUserName")?.setValue;
            const password = _data.find((t) => t.setKey == "SmsApiPassword")?.setValue;
            const sender = _data.find((t) => t.setKey == "SmsDefaultSender")?.setValue;
            if (username && password) {
              GetSenderList(username, password, sender);
            }
          }
        }
      } else {
        toast.error("Yüklme işleminde hata oluştu.");
        console.log(data.errorMessage);
      }
    }
  };

  const GetSenderList = async (username: string = "", password: string = "", sender: string = "") => {
    let _username = "";
    let _password = "";
    if (username && password) {
      _username = username;
      _password = password;
    } else {
      const _SmsApiUserName = settingData.find((t) => t.setKey == "SmsApiUserName")?.setValue;
      const _SmsApiPassword = settingData.find((t) => t.setKey == "SmsApiPassword")?.setValue;
      _username = _SmsApiUserName ? _SmsApiUserName : "";
      _password = _SmsApiPassword ? _SmsApiPassword : "";
    }
    if (_username && _password) {
      setSenderListLoading(true);
      const { data } = await smsService.GetSenders(_username, _password);
      setSenderListLoading(false);
      if (data) {
        const _senderList = data.data.senders;
        setSenderList(_senderList);
        GetCredit(_username, _password);
        if (sender) {
          const _sender = _senderList.find((x: any) => x.name === sender);
          if (_sender) {
            setSelectedSender(_sender);
          }
        }
      }
    } else {
      toast.error("Sms api bilgileri girilmelidir !");
    }
  };
  const GetCredit = async (username: string = "", password: string = "") => {
    if (username && password) {
      setSenderListLoading(true);
      const { data } = await smsService.GetCredit(username, password);
      setSenderListLoading(false);
      if (data) {
        const _data = data as IResultDto;
        if (_data) {
          if (_data.success) {
            setCredit(_data.returnData);
          } else {
            toast.error(_data.errorMessage);
          }
        }
      }
    } else {
      toast.error("Sms api bilgileri girilmelidir !");
    }
  };
  
  const btnSaveHandle = async () => {
    btnRef.current.disabled = true;
    // console.log("settingData=> ", settingData);
    const { data } = await appSettingService.UpdateSettingBulk(settingData);
    if (data) {
      if (data.success) {
        toast.success("Güncelleme işlemi başarılı.");
        // GetSettings();
      } else {
        toast.error("Güncelleme işleminde hata oluştu.");
        console.log(data.errorMessage);
      }
    }
    btnRef.current.disabled = false;
  };

  const GetValue = (appKey: string): string => {
    const _data = settingData.find((t) => t.setKey === appKey);
    if (_data) {
      return _data.setValue;
    }
    return "";
  };

  const SetValue = (appKey: string, appValue: string, isActive: boolean = false) => {
    const _data = settingData.find((t) => t.setKey === appKey);
    console.log("appkey=> ", _data);
    if (_data) {
      _data.setValue = appValue;
      _data.isActive = isActive;
      setSettingData([...settingData]);
    }
  };

  // Dropdown template start
  const dropdownOptionTemplate = (option: any) => {
    return <span>{option.name}</span>;
  };

  const dropdownValueTemplate = (option: { name: string; code: string }, props: any) => {
    if (option) {
      return (
        <div style={{ marginTop: -4 }}>
          <span>{option.name}</span>
        </div>
      );
    }
    return (
      <div className="flex " style={{ marginTop: -4 }}>
        {props.placeholder}
      </div>
    );
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      {isLoading && <ProgressSpinner style={{ width: "50px", height: "50px" }} />}
      <div className="m-2 mb-5">
        <label className="text-2xl">{isUpdateData ? "_NAME_ Güncelle" : "Ayarları ekle / Güncelle"}</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>
      <div className="ml-2">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className=" bg-white space-y-6 sm:p-6">
              {/* Sms Ayarı */}
              <div className="border p-3 -mx-2 relative">
                <label className="absolute -top-3  z-30 px-4 bg-white">Sms Ayarı</label>
                <div className="grid grid-cols-12 gap-4 mt-2">
                  <div className="col-span-12 lg:col-span-4">
                    <label className="block text-sm font-medium text-gray-700">Api Kullanıcı Adı:</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={GetValue("SmsApiUserName")}
                        onChange={(e: any) => {
                          SetValue("SmsApiUserName", e.target.value, true);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-4">
                    <label className="block text-sm font-medium text-gray-700">Api Kullanıcı şifre:</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={GetValue("SmsApiPassword")}
                        onChange={(e: any) => {
                          SetValue("SmsApiPassword", e.target.value, true);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-4 flex  items-end ">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Varsayılan Gönderici Başlık</label>
                      <div className="mt-1 rounded-md shadow-sm">
                        <Dropdown
                          disabled={senderListLoading}
                          className="min-w-[220px] h-10"
                          value={selectedSender}
                          options={senderList}
                          dataKey="id"
                          onChange={(e) => {
                            setSelectedSender(e.value);
                            SetValue("SmsDefaultSender", e.value.name, true);
                          }}
                          optionLabel="name"
                          placeholder="Başlık seçiniz"
                          valueTemplate={dropdownValueTemplate}
                          itemTemplate={dropdownOptionTemplate}
                        />
                      </div>
                    </div>
                    <Button
                      loading={senderListLoading}
                      onClick={() => {
                        GetSenderList();
                      }}
                      icon={PrimeIcons.REFRESH}
                      style={{ height: 42, width: 42 }}
                      className={classNames("p-button-rounded p-button-info ml-4", senderListLoading && "p-button-loading p-button-warning")}
                    />
                    <label className="mb-2 ml-3">{credit}</label>
                  </div>
                </div>
              </div>
              {/* Sms Gönderim Ayarı */}
              <div className="border p-3 -mx-2 relative">
                <label className="absolute -top-3  z-30 px-4 bg-white">Sms Gönderim Ayarı</label>
                <div className="grid grid-cols-12 gap-4 mt-2">
                  <div className="col-span-12 lg:col-span-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Sms Gönderimi</label>
                      <div className="flex items-center h-11 ">
                        <InputSwitch
                          inputId="SendSms"
                          onChange={(e) => SetValue("SendSms", e.value ? "True" : "False", true)}
                          checked={GetValue("SendSms") == "True" ? true : false}
                        ></InputSwitch>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Velisi Dışında Öğrenci Giriş-Çıkışlarında Sms Gönder</label>
                      <div className="flex items-center h-11 ">
                        <InputSwitch
                          inputId="StudentEntryExistSendSms"
                          disabled={GetValue("SendSms") == "True" ? false : true}
                          onChange={(e) => SetValue("StudentEntryExistSendSms", e.value ? "True" : "False", true)}
                          checked={GetValue("StudentEntryExistSendSms") == "True" ? true : false}
                        ></InputSwitch>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Ödemesi Gecikenlere Sms Gönder</label>
                      <div className="flex items-center h-11 ">
                        <InputSwitch
                          disabled={GetValue("SendSms") == "True" ? false : true}
                          inputId="SendSmsLatePayment"
                          onChange={(e) => SetValue("SendSmsLatePayment", e.value ? "True" : "False", true)}
                          checked={GetValue("SendSmsLatePayment") == "True" ? true : false}
                        ></InputSwitch>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-12 lg:col-span-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Tüm Öğrenci Giriş-Çıkışlarında Veliye Sms Gönder</label>
                      <div className="flex items-center h-11 ">
                        <InputSwitch
                          inputId="AllStudentEntryExistSendSms"
                          disabled={GetValue("SendSms") == "True" ? false : true}
                          onChange={(e) => SetValue("AllStudentEntryExistSendSms", e.value ? "True" : "False", true)}
                          checked={GetValue("AllStudentEntryExistSendSms") == "True" ? true : false}
                        ></InputSwitch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Kapı  Ayarı */}
              <div className="border p-3 -mx-2 relative">
                <label className="absolute -top-3  z-30 px-4 bg-white">Kapı Ayarı</label>
                <div className="grid grid-cols-12 gap-4 mt-2">
                  <div className="col-span-12 lg:col-span-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Mobilden kapı açabilme</label>
                      <div className="flex items-center h-11 ">
                        <InputSwitch
                          inputId="remoteDoorOpen"
                          onChange={(e) => SetValue("RemoteDoorControl", e.value ? "True" : "False", true)}
                          checked={GetValue("RemoteDoorControl") == "True" ? true : false}
                        ></InputSwitch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Bildirim Ayarları */}
              <div className="border p-3 -mx-2 relative">
                <label className="absolute -top-3  z-30 px-4 bg-white">Bildirim Ayarları</label>
                <div className="grid grid-cols-12 gap-4 mt-2">
                  <div className="col-span-12 lg:col-span-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Anons bildirimini öğretmenin telefonuna gönder</label>
                      <div className="flex items-center h-11 ">
                        <InputSwitch
                          inputId="sendNotificationToTeacherForAnnouncement"
                          onChange={(e) => SetValue("SendNotificationToTeacherForAnnouncement", e.value ? "True" : "False", true)}
                          checked={GetValue("SendNotificationToTeacherForAnnouncement") == "True" ? true : false}
                        ></InputSwitch>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Sınıf modülü kapalıysa anons bildirimini öğretmenin telefonuna gönder</label>
                      <div className="flex items-center h-11 ">
                        <InputSwitch
                          inputId="SendNotificationToTeacherForAnnouncementIsCloseWinApp"
                          onChange={(e) => SetValue("SendNotificationToTeacherForAnnouncementIsCloseWinApp", e.value ? "True" : "False", true)}
                          checked={GetValue("SendNotificationToTeacherForAnnouncementIsCloseWinApp") == "True" ? true : false}
                        ></InputSwitch>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Velisi Dışında Öğrenci Giriş-Çıkışlarında Bildirim Gönder</label>
                      <div className="flex items-center h-11 ">
                        <InputSwitch
                          // disabled={true}
                          inputId="StudentEntryExistSendNotification"
                          onChange={(e) => SetValue("StudentEntryExistSendNotification", e.value ? "True" : "False", true)}
                          checked={GetValue("StudentEntryExistSendNotification") == "True" ? true : false}
                        ></InputSwitch>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Ödemesi Gecikenlere Bildirim Gönder</label>
                      <div className="flex items-center h-11 ">
                        <InputSwitch
                          // disabled={true}
                          inputId="SendSmsLatePaymentNotification"
                          onChange={(e) => SetValue("SendSmsLatePaymentNotification", e.value ? "True" : "False", true)}
                          checked={GetValue("SendSmsLatePaymentNotification") == "True" ? true : false}
                        ></InputSwitch>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-12 lg:col-span-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Tüm Öğrenci Giriş-Çıkışlarında Veliye Bildirim Gönder</label>
                      <div className="flex items-center h-11 ">
                        <InputSwitch
                          inputId="AllStudentEntryExistSendNotification"
                          onChange={(e) => SetValue("AllStudentEntryExistSendNotification", e.value ? "True" : "False", true)}
                          checked={GetValue("AllStudentEntryExistSendNotification") == "True" ? true : false}
                        ></InputSwitch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Genel Ayarlar */}
              <div className="border p-3 -mx-2 relative">
                <label className="absolute -top-3  z-30 px-4 bg-white">Genel Ayarlar</label>
                <div className="grid grid-cols-12 gap-4 mt-2">
                  <div className="col-span-12 lg:col-span-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Mobil Kullanımı</label>
                      <div className="flex items-center h-11 ">
                        <InputSwitch
                          inputId="remoteDoorOpen"
                          onChange={(e) => SetValue("IsUseMobileApp", e.value ? "True" : "False", true)}
                          checked={GetValue("IsUseMobileApp") == "True" ? true : false}
                        ></InputSwitch>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-12 lg:col-span-8">
                    <label className={"block text-sm font-medium text-gray-700 "}>Aktif değilse gösterilecek mesaj   (250 karakter)</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        disabled={GetValue("IsUseMobileApp") != "True" ? false : true}
                        type="text"
                        value={GetValue("IsUseMobileAppText")}
                        onChange={(e: any) => {
                          SetValue("IsUseMobileAppText", e.target.value, true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <Button
                loading={btnRef?.current?.disabled}
                ref={btnRef}
                onClick={() => btnSaveHandle()}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Kaydet
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </div>
  );
};
export default SettingPage;
