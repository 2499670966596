import { DataTable } from 'primereact/datatable'
import React, { useState } from 'react'
import SkeletonList from '../../../Components/Loading/Skeleton_List';

const UpdateModule = () => {
    const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="m-3 p-6 rounded-lg bg-white">
    <div className="m-2 mb-5">
      <label className="text-2xl">Güncelleme Listesi</label>
      <hr className="my-auto flex-grow-1 mt-2" />
    </div>

    {isLoading ? (
      <SkeletonList />
    ) : (
      <>
        <div className="ml-2">
          <div className="mt-5 md:mt-0 ">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
          
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>
      </>
    )}
  </div>
  )
}

export default UpdateModule