import AxiosUtily from "../Extended-tools/AxiosUtility";
import IResultDto from "../Models/Abstract/IResultDto";

const SchoolService = () => {
    const GetReport = async () => {

        try {
            const response = await AxiosUtily().get(`/school/ReportDetail`);

            // console.log('login',response);
            if (response.status === 200) {
                // console.log('LoginUser service response 200 ', response)
                const data = response.data as IResultDto;
                // console.log('data=> ', data)
                return { data };
            }
            else {
                // console.log('AddCategory service response !200 ', response)
                const error = response;
                return { error }
            }
        } catch (err: any) {
            const error = err.response ? err.response : err;
            // console.log(error);
            return { error };
        }
    }
    const GetClientList = async (schoolCode:string) => {

        try {
            const response = await AxiosUtily().get(`/school/?schoolCode=${schoolCode}`);

            // console.log('login',response);
            if (response.status === 200) {
                // console.log('LoginUser service response 200 ', response)
                const data = response.data as IResultDto;
                // console.log('data=> ', data)
                return { data };
            }
            else {
                // console.log('AddCategory service response !200 ', response)
                const error = response;
                return { error }
            }
        } catch (err: any) {
            const error = err.response ? err.response : err;
            // console.log(error);
            return { error };
        }
    }
    return {
        GetReport,
        GetClientList
    }
}

export { SchoolService };