import React from "react";
import AxiosUtility from "../Extended-tools/AxiosUtility";
import { IClient, IResultDto } from "../Models";

const ClientService = () => {
  const Add = async (data: IClient) => {
    try {
      const response = await AxiosUtility().post("/client/desktop/add", data);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const FindClientById = async (parentId: number) => {
    try {
      const response = await AxiosUtility().get(`/client/desktop/${parentId}`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const Update = async (client: IClient) => {
    // console.log("running UpdateClassroom");
    try {
      const response = await AxiosUtility().put(
        "/client/desktop/Update",
        client
      );

      if (response.status === 200) {
        // console.log("UpdateClassroom service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('UpdateClassroom service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GetList = async () => {
    try {
      const response = await AxiosUtility().get(`/client/desktop/all`);

      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  return { Add, FindClientById, Update, GetList };
};

export { ClientService };
