import React, { useEffect, useRef, useState } from "react";
import { PaymentPlanService } from "../../Services";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import toast from "react-hot-toast";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Tooltip } from "primereact/tooltip";
import { IPaymentPlan } from "../../Models";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode } from "primereact/api";
import { classNames } from "primereact/utils";
import SkeletonList from "../../Components/Loading/Skeleton_List";
import { Button } from "primereact/button";

const PaymentEntryForStudent = () => {
  let _paymentPlanList: any = [];
  const navigate = useNavigate();
  const [loadingPaymentPlanList, setLoadingPaymentPlanList] = useState(false);
  const [loadingPaymentList, setLoadingPaymentList] = useState(false);

  const [paymentPlanList, setPaymentPlanList] = useState<any[]>();
  const [header, setHeader] = useState<any>();
  const [paymentPlans, setPaymentPlans] = useState();
  const [selectPaymentPlan, setSelectPaymentPlan] = useState<any>();
  const [filters1, setFilters1] = useState<any>(null);

  const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];

  const paymentPlanService = PaymentPlanService();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (_paymentPlanList.length === 0) {
      GetPaymentPlanList();
    }
  }, []);

  // useEffect(() => {
  //   console.log("paymentPlans Loaded ==> " + Date.now());
  // }, [paymentPlans]);

  useEffect(() => {
    if (selectPaymentPlan) {
      if (selectPaymentPlan.name) {
        GetList(selectPaymentPlan.name);
      }
    }
  }, [selectPaymentPlan]);

  const GetPaymentPlanList = async () => {
    setLoadingPaymentPlanList(true);
    const { data, error } = await paymentPlanService.PaymentPlanListAll();
    setLoadingPaymentPlanList(false);
    if (data) {
      if (data.success) {
        const _data = data.returnData as IPaymentPlan[];
        const newData = _data.map((item: IPaymentPlan) => {
          return { name: item.planName, code: item.planName };
        });

        // console.log("GetPaymentPlanList data => ", newData);
        _paymentPlanList = newData;
        setPaymentPlans(_paymentPlanList);

        if (newData.length == 1) {
          setSelectPaymentPlan(_paymentPlanList[0]);
        }
        initFilters1();
      } else {
        toast.error(data?.errorMessage);
      }
    } else {
      toast.error(error);
    }
  };

  const GetList = async (planName: string) => {
    setLoadingPaymentList(true);
    const { data, error } = await paymentPlanService.PaymentEntryForStudentListByPlanName(planName);
    setLoadingPaymentList(false);
    if (data) {
      // console.log("**** Data => ", data);
      if (data.success) {
        // console.log("data => ", data);
        var _header = Object.keys(data.returnData[0]);
        if (_header) {
          const newHEaderList = _header.map((item) => {
            return { field: item, header: item };
          });
          setHeader(newHEaderList);
        }
        setPaymentPlanList(data.returnData);
      } else {
        toast.error(data?.errorMessage);
      }
    } else {
      toast.error(error);
    }
  };

  const AddPayment = async (rowId: number, PaidAmount: number) => {
    const { data, error } = await paymentPlanService.AddPaymentPaid(rowId, PaidAmount);

    if (data) {
      if (data.success) {
        // console.log("AddPayment data => ", data);
        toast.success(data.successMessage);

        // paymentPlanList.
      } else {
        toast.error(data.errorMessage);
      }
    } else {
      toast.error(error);
    }
  };

  const AddPaidDate = async (rowId: number, paidDate: Date | null) => {
    // setLoadingPaymentList(true);
    const { data, error } = await paymentPlanService.AddPaidDate(rowId, paidDate);
    // setLoadingPaymentList(false);

    if (data) {
      if (data.success) {
        toast.success(data.successMessage);
      } else {
        toast.error(data.errorMessage);
      }
    } else {
      toast.error(error);
    }
  };

  const priceEditor = (options: any) => {
    // console.log("priceEditor options.value", options.value);

    if (typeof options.value !== "number") {
      options.value =0;
    }

    const price = parseFloat(options.value);
    if (price == -1) {
      return <label>Silinmiş Taksit</label>;
    }

    return (
      <div className="">
        <InputNumber
          size={6}
          className="h-3 w-2"
          inputMode="numeric"
    
          value={(isNaN(options.value) || options.value) === 0.00 ? null : parseFloat(options.value)}
          onValueChange={(e: any) => {
            options.editorCallback(e.value);
          }}
          mode="currency"
          currency="TRY"
          locale="tr-TR"

          
        />
      </div>
    );
  };

  const dateEditor = (options: any) => {
    return (
      <Calendar
        dateFormat="dd.mm.yy"
        showButtonBar
        yearNavigator
        yearRange="2023:2024"
        locale="tr"
        className=" focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md h-5"
        value={options.value}
        onChange={(e: any) => {
          options.editorCallback(e.target.value);
        }}
      ></Calendar>
    );
  };

  const cellEditor = (options: any) => {
    const month = monthNames.some((item) => item === options.field);

    if (month) {
      return priceEditor(options);
    } else if (options.field.includes("PaymentDate")) {
      return dateEditor(options);
    } else {
      return options.value;
    }
  };

  const priceBodyTemplate = (rowData: any, field: string) => {
    const dueDate = rowData[field + "-DueDate"];

    const overdue = rowData[field + "-Overdue"];
    const remainingPayment = rowData[field + "-RemainingPayment"];

    const discount = parseFloat(rowData[field + "-Discount"]);

    const price = parseFloat(rowData[field]);

    const dueDateFormatted = moment(dueDate, "YYYY.MM.DD");

    var dueDateDay = moment().diff(dueDateFormatted, "days");

    const _value = new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
    })
      .format(price ? price : 0)
      .toString();

    const _discount = new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
    })
      .format(discount)
      .toString();

    //<Tooltip target=".tooltip" />

    // console.log('"tooltip" + field + rowData.StudentId)=> ', ".tooltip" + field + rowData.StudentId);
    return overdue == "true" ? (
      <>
        <Tooltip target={classNames(".tooltip" + field + rowData.StudentId)} />
        <label
          data-pr-tooltip={
            "Son ödeme tarihi : " +
            dueDateFormatted.format("DD.MM.YYYY") +
            " - " +
            dueDateDay +
            " gün geçti \r\n" +
            "Ödenecek tutar : " +
            remainingPayment +
            " \r\n" +
            (discount > 0 ? "İndirim : " + _discount : "")
          }
          className={classNames("tooltip" + field + rowData.StudentId, " bg-red-200 p-[2px] ml-2 text-white rounded-sm")}
        >
          {_value}
        </label>
      </>
    ) : (
      <>
        {discount > 0 ? (
          <>
            <Tooltip target={classNames(".tooltip" + field + rowData.StudentId)} />
            <label
              data-pr-tooltip={discount > 0 ? "indirim : " + _discount : ""}
              className={classNames("tooltip" + field + rowData.StudentId, " bg-amber-200 p-[2px] ml-2 text-white rounded-sm")}
            >
              {_value}
            </label>
          </>
        ) : (
          <>
            {price.toString() == "-1" ? (
              <label className={classNames("tooltip" + field + rowData.StudentId, "bg-purple-300")}>Silinmiş Taksit</label>
            ) : (
              <label className={classNames("tooltip" + field + rowData.StudentId)}>{_value}</label>
            )}
          </>
        )}
      </>
    );
  };

  const textBodyTemplate = (rowData: any, field: string) => {
    return <label>{rowData[field]}</label>;
  };

  const dateBodyTemplate = (rowData: any, field: string) => {
    return <label>{rowData[field] ? moment(rowData[field]).format("DD.MM.yyyy") : ""}</label>;
  };

  const isPositiveInteger = (val: any) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  const convertToDateFormat = (val: any) => {
    if (val) {
      return moment(val).format("DD.MM.yyyy");
    }
    return "";
  };

  const onCellEditComplete = async (e: any) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    const monthId = `${field}-Id`;
    const rowId = rowData[monthId];
    const oldValue = rowData[field];

    const month = monthNames.some((item) => item === field);
    if (month) {

      if (isNaN(newValue) ) {
        return;
      }
      // console.log('onCellEditComplete newValue',newValue);
   
      if (typeof newValue !== "number") {
        return
      }
      const paidAmount = Number(newValue.toFixed(2));
      
      // if (isPositiveInteger(paidAmount)) {
      if (true) {
        const oldValue = Number(rowData[field]);

        if (oldValue !== paidAmount) {
          rowData[field] = paidAmount;
          await AddPayment(rowId, paidAmount);

          // eklecek değerler
          const installmentAmount = Number(rowData[field + "-InstallmentAmount"]);
          const discount = Number(rowData[field + "-Discount"]);

          const dueDate = moment(rowData[field + "-DueDate"], "DD.MM.YYYY");
          var now = moment();
          var dueDateDay = now.diff(dueDate, "days");

          // Odenen - taksit tutarı - indirim tutarı
          const newObject = {
            [field]: paidAmount,
            [field + "-Overdue"]: installmentAmount - paidAmount - discount > 0 && dueDateDay > 0 ? "true" : "false",
            [field + "-PaidAmount"]: paidAmount,
            [field + "-RemainingPayment"]: installmentAmount - paidAmount - discount,
          };

          const newState = paymentPlanList?.map((obj) => (obj[field + "-Id"] === rowId ? { ...obj, ...newObject } : obj));
          setPaymentPlanList(newState);
        }
      } else {
        event.preventDefault();
        toast.error("Lütfen sıfırdan büyük bir sayı giriniz");
      }
    } else if (field.includes("PaymentDate")) {
      const _oldValue = moment(oldValue);
      const _newValue = moment(newValue);
      const _field = field.replace(" PaymentDate", "-Id");

      const _oldValueFormat = _oldValue.format("DD.MM.YYYY");
      const _newValueFormat = _newValue.format("DD.MM.YYYY");

      if (_oldValueFormat != _newValueFormat) {
        rowData[field] = newValue;

        const _rowId = rowData[_field];

        const _dateData = _newValue ? new Date(_newValue.add(1, "day").format("YYYY-MM-DD HH:mm:ss")) : null;

        await AddPaidDate(_rowId, _dateData);
      }
    } else {
      rowData[field] = newValue;
    }
  };

  const ChangeHeaderNameToTr = (header: string) => {
    const _header = header;

    if (_header.includes("PaymentDate")) {
      return header.replace("PaymentDate", "Ödeme Tarihi");
    }

    switch (header) {
      case "PlanName":
        return "Plan Adı";
      case "StudentNameSurname":
        return "Öğrenci Adı Soyadı";
      case "StudentId":
        return "Id";
      case _header.includes("PaymentDate") ? "PaymentDat2" : "PaymentDate1":
        return " Ödeme Tarihi";

      default:
        return header;
    }
  };

  const renderBody = (data: any, field: any, header: any) => {
    // console.log("field=> ", field);

    if (field == "PlanName") {
      return textBodyTemplate(data, field);
    } else if (field == "StudentNameSurname") {
      return textBodyTemplate(data, field);
    } else if (field.includes("PaymentDate")) {
      return dateBodyTemplate(data, field);
    } else if (monthNames.some((item) => item === field)) {
      return priceBodyTemplate(data, field);
    }
  };

  const initFilters1 = () => {
    setFilters1({
      StudentNameSurname: { value: null, matchMode: FilterMatchMode.CONTAINS, lng: "tr" },
    });
  };

  const dt: any = useRef(null);

  const exportCSV = (selectionOnly: any) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const header2 = (
    <div className="flex items-end justify-end export-buttons">
      <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" />
    </div>
  );

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">Ödeme Girişi</label>

        <hr className="my-auto flex-grow-1 mt-2" />
      </div>
      <div className="">
        <div className=" ">
          {paymentPlans && (
            <Dropdown
              className="min-w-[220px]"
              value={selectPaymentPlan}
              options={paymentPlans}
              onChange={(e) => setSelectPaymentPlan(e.value)}
              optionLabel="name"
              placeholder={loadingPaymentPlanList ? "Yükleniyor..." : "Ödeme planı seçiniz"}
            />
          )}

          <div className="shadow sm:rounded-md sm:overflow-hidden mt-4">
            <div className=" bg-white space-y-6 ">
              {loadingPaymentList ? (
                <SkeletonList />
              ) : (
                <>
                  {paymentPlanList && (
                    <DataTable
                      ref={dt}
                      rowHover
                      filters={filters1}
                      className="w-full"
                      showGridlines
                      editMode="cell"
                      size="small"
                      scrollHeight="63vh"
                      loading={loadingPaymentList}
                      emptyMessage="Öğrenciye tanımlı ödeme planı bulunmamaktadır."
                      value={paymentPlanList}
                      filterDisplay="row"
                     // header={header2}
                      footer={`Toplam Kayıt Sayısı: ${paymentPlanList.length}`}
                      scrollable
                    >
                      {header?.map(({ field, header }: { field: string; header: any }) => {
                        let say = 1;
                        say = say + 1;
                        return (
                          <Column
                            showFilterMatchModes={false}
                            showFilterMenuOptions={false}
                            showFilterOperator={false}
                            showFilterMenu={false}
                            showClearButton={false}
                            frozen={field == "StudentNameSurname"}
                            style={field == "StudentNameSurname" ? { minWidth: "200px" } : { minWidth: "120px" }}
                            sortable
                            filter={field == "StudentNameSurname"}
                            filterMatchMode={field == "StudentNameSurname" ? "contains" : "startsWith"}
                            key={field}
                            field={field}
                            hidden={field.includes("-") || field == "PlanName" || field.includes("Id") ? true : false}
                            header={ChangeHeaderNameToTr(header)}
                            body={(data) => renderBody(data, field, header)}
                            editor={(options) => cellEditor(options)}
                            onCellEditComplete={onCellEditComplete}
                          />
                        );
                      })}
                    </DataTable>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default PaymentEntryForStudent;
