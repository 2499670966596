import AxiosUtily from "../Extended-tools/AxiosUtility";
import { IAppSetting, ISchoolDetail } from "../Models";
import IResultDto from "../Models/Abstract/IResultDto";

const AppSettingService = () => {
  const GetAllSetting = async () => {
    try {
      const response = await AxiosUtily().get(`/setting/All`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log('LoginUser service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GetSchoolDetail = async () => {
    try {
      const response = await AxiosUtily().get(`/setting/school`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log('LoginUser service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const UpdateSettingBulk = async (data: IAppSetting[]) => {
    try {
      const response = await AxiosUtily().put(`/setting/updateBulk`, data);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log('LoginUser service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const UpdateSchoolDetail = async (data: ISchoolDetail) => {
    try {
      const response = await AxiosUtily().put(`/setting/UpdateSchoolDetail`, data);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log('LoginUser service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  return {
    GetAllSetting,
    UpdateSettingBulk,
    GetSchoolDetail,
    UpdateSchoolDetail
  };
};

export { AppSettingService };
