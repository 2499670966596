import { useEffect, useRef, useState } from "react";
import { IOverduePayments, ISendSmsOverDueByStudent } from "../../Models";
import { PaymentPlanService } from "../../Services";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import toast from "react-hot-toast";
import SkeletonList from "../../Components/Loading/Skeleton_List";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { InputTextarea } from "primereact/inputtextarea";
import CompSmsSenderList from "../../Components/Sms/SmsSenderList";
import * as XLSX from "xlsx";
import moment from "moment";
import { Mention } from "primereact/mention";
import { AutoComplete } from "primereact/autocomplete";

const OverduePayment = () => {
  const navigate = useNavigate();
  const paymentPlanService = PaymentPlanService();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IOverduePayments[] | null>();
  const [selectedRow, setSelectedRow] = useState<IOverduePayments[] | null>();

  const btnRef: any = useRef<HTMLButtonElement>();
  const [btnSaveProgress, setBtnSaveProgress] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [messageContant, setMessageContant] = useState("");
  const [ekstraNumber, setEkstraNumber] = useState("");
  const [selectedSenderNme, setSelectedSenderNme] = useState<any>();

  useEffect(() => {
    GetList();
  }, []);

  const GetList = async () => {
    setIsLoading(true);
    const { data, error } = await paymentPlanService.PaymentPlanWithStudentsOverdue();
    setIsLoading(false);
    if (data) {
      if (data.success) {
        console.log("data GetList=> ", data.returnData as IOverduePayments[]);
        setData(data.returnData as IOverduePayments[]);
      } else {
        toast.error(data?.errorMessage);
      }
    } else {
      toast.error(error);
    }
  };
  const groupBy = (items: any, key: any) =>
    items.reduce(
      (result: any, item: any) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {}
    );

  let smsData = {} as ISendSmsOverDueByStudent;
  const btnSendSmsHandle = async () => {
    if (selectedRow && selectedRow.length > 0 && messageContant.trim().length > 0 && selectedSenderNme) {
      // Önce smsData nesnesini oluşturun
      smsData = {} as ISendSmsOverDueByStudent;

      smsData.sender = selectedSenderNme?.name;
      smsData.message = messageContant.trim();
      smsData.isMulti = false;

      const messageData: any = [];

      const groupedData: any = [];

      selectedRow.forEach((item) => {
        const studentId = item.studentId;

        if (!groupedData[studentId]) {
          groupedData[studentId] = [];
        }

        groupedData[studentId].push(item);
      });

      groupedData.forEach((item: any) => {
        if (item.length == 1) {
 
          const _message = messageContant.trim();
          let messageContent = _message;
          if (messageContant.includes("@{")) {
            smsData.isMulti = true;
            if (messageContant.includes("@{ad}")) {

              messageContent = messageContent.replace("@{ad}", item[0].studentFullName);
            }
            if (messageContant.includes("@{kalan}")) {
              messageContent = messageContent.replace("@{kalan}", item[0].totalRemainingAmount.toLocaleString("tr-TR"));
            }

            if (messageContant.includes("@{ay}")) {
              messageContent = messageContent.replace("@{ay}", item[0].months);
            }

            if (messageContant.includes("@{plan}")) {
              messageContent = messageContent.replace("@{plan}", item[0].planName);
            }
          }

          messageData.push({ studentId: item[0].studentId, messageContent });
        } else if (item.length > 1) {
          let messageContent = "";

          for (let i = 0; i < item.length; i++) {
            let item2 = item[i];
            let _message = messageContant.trim();
         
            if (messageContant.includes("@{")) {
              smsData.isMulti = true;

              if (messageContant.includes("@{ad}")) {
                _message = _message.replace("@{ad}", item2.studentFullName);
              }

              if (messageContant.includes("@{kalan}")) {
                _message = _message.replace("@{kalan}", item2.totalRemainingAmount.toLocaleString("tr-TR"));
              }

              if (messageContant.includes("@{ay}")) {
                _message = _message.replace("@{ay}", item2.months);
              }

              if (messageContant.includes("@{plan}")) {
                _message = _message.replace("@{plan}", item2.planName);
              }

              messageContent += _message + " ";
            }
          }

          messageContent = messageContent.substring(0, messageContent.length - 1);
          messageData.push({ studentId: item[0].studentId, messageContent });
        }
      });

      smsData.messageContentAndNumber = messageData;

      if (ekstraNumber && ekstraNumber?.length > 0) {
        const _number = ekstraNumber.split("\n");
        smsData.extraPhoneNumbers = _number;
      }

      confirmDialog({
        message: `${smsData.messageContentAndNumber.length} öğrenci için velilerine sms gönderimini onaylıyor musunuz ?`,
        header: "Onay",
        icon: "pi pi-exclamation-triangle",
        accept,
        reject: () => {
          toast.error("Sms gönderimi iptal edildi");
        },
      });
    } else {
      toast.error("Lütfen gerekli alanları doldurunuz");
    }
  };

  const accept = async () => {
    const { data } = await paymentPlanService.SendSmsOverdueByStudentId(smsData);
    if (data) {
      if (data.success) {
        setMessageContant("");
        setEkstraNumber("");
        setSelectedRow(null);
        toast.success("Sms gönderimi başarılı");
      } else {
        console.log("error => ", data);
        toast.error(data.errorMessage);
      }
    }
  };

  const longestLength = (data3: any) => {
    return data3.reduce(function (maxLength: any, row: any) {
      const length = row ? row.length : 0;
      return length > maxLength ? length : maxLength;
    }, 0);
  };

  const exportExcel = () => {
    if (data) {
      const data3 = data.map(function (item) {
        return {
          Öğrenci: item.studentFullName,
          Sınıf: item.classroomName,
          Plan: item.planName,
          Ay: item.months,
          "Ay Sayısı": item.monthsNumbers.length > 1 ? item.months.split(",").length + " Ay" : "1 Ay",
          "Vade Tarihi": moment(item.dueDate).format("DD") + ". Günü",
          "Aylık Tutar": item.installmentAmount,
          "Toplam Ödenen": item.totalAmountPaid,
          "Toplam İskonto": item.totalDiscount,
          "Toplam Kalan": item.totalRemainingAmount,
        };
      });

      var ws = XLSX.utils.json_to_sheet(data3);
      const ogrenciList = data3.map((item) => item.Öğrenci);
      const Sınıf = data3.map((item) => item.Sınıf);
      const Plan = data3.map((item) => item.Plan);
      const Ay = data3.map((item) => item.Ay);

      var wscols = [{ wch: longestLength(ogrenciList) }, { wch: longestLength(Sınıf) }, { wch: longestLength(Plan) }, { wch: longestLength(Ay) }];

      ws["!cols"] = wscols;

      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sayfa1");
      XLSX.writeFile(wb, "Ödemesi gecikenle.xlsx");
    }
  };

  const [suggestions, setSuggestions] = useState([]);
  const [customers, setCustomers] = useState<any>([
    { key: "{ad}", value: "Öğrenci" },
    { key: "{kalan}", value: "Kalan Bakiye" },
    { key: "{ay}", value: "Ay(lar)" },
    { key: "{plan}", value: "Ödeme Planı" },
  ]);

  const itemTemplate = (suggestion: any) => {
    return (
      <div className="flex align-items-center">
        <span className="flex flex-column ml-2">
          {suggestion.value}
          <small style={{ fontSize: ".75rem", color: "var(--text-color-secondary)" }}>@{suggestion.key}</small>
        </span>
      </div>
    );
  };
  const onSearch = (event: any) => {
    //in a real application, make a request to a remote url with the query and return suggestions, for demo we filter at client side
    setTimeout(() => {
      const query = event.query;
      let suggestions;

      if (!query.trim().length) {
        suggestions = [...customers];
      } else {
        suggestions = customers.filter((customer: any) => {
          return customer.value.toLowerCase().startsWith(query.toLowerCase());
        });
      }

      setSuggestions(suggestions);
    }, 250);
  };

  const header = (
    <div className="flex items-end flex-col">
      <Button className="bg-green-700" type="button" icon="pi pi-file-excel" onClick={exportExcel} data-pr-tooltip="XLS" />
    </div>
  );
  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">Ödemesi Geciken Öğrenciler</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div className="">
            <ConfirmDialog />
            <div className=" ">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className=" grid grid-cols-12  grid-gap-4 m-3">
                  <div className="col-span-4 m-2 ">
                    <label className="block text-xl font-medium text-gray-700 mb-2">Mesaj içeriği</label>

                    <Mention
                      value={messageContant}
                      onChange={(e: any) => setMessageContant(e.target.value)}
                      suggestions={suggestions}
                      onSearch={onSearch}
                      field="key"
                      placeholder="@ ile mesajları özelleştirebilirsiniz"
                      rows={5}
                      cols={40}
                      style={{ height: 185, width: "100%" }}
                      itemTemplate={itemTemplate}
                    />
                  </div>
                  <div className="col-span-4 m-2 ">
                    <label className=" text-xl font-medium text-gray-700 mb-2">İlave numara</label>
                    <InputTextarea
                      value={ekstraNumber}
                      onChange={(e) => {
                        setEkstraNumber(e.target.value);
                      }}
                      rows={8}
                      cols={30}
                      style={{ height: 185, width: "100%", marginTop: 8 }}
                    />
                  </div>
                  <div className="col-span-4 mt-12 ml-6 ">
                    <CompSmsSenderList
                      username=""
                      password=""
                      defaultSenderName=""
                      showCredit={false}
                      setSelectedSenderName={(senderName) => {
                        setSelectedSenderNme(senderName);
                      }}
                    />
                    <div className="mt-6">
                      <Button
                        ref={btnRef}
                        loading={btnSaveProgress}
                        disabled={btnDisable}
                        className="h-10"
                        onClick={() => {
                          btnSendSmsHandle();
                        }}
                        label="Sms Gönder"
                      ></Button>
                    </div>
                  </div>
                </div>

                <div className=" bg-white space-y-6 ">
                  {data && (
                    <DataTable
                      header={header}
                      value={data}
                      className="table-auto w-full"
                      columnResizeMode="fit"
                      stripedRows
                      scrollable
                      scrollHeight="800px"
                      scrollDirection="vertical"
                      responsiveLayout="stack"
                      selectionMode="multiple"
                      size="small"
                      loading={isLoading}
                      emptyMessage="Ekli ödeme planı bulunmamaktadır."
                      sortMode="multiple"
                      footer={`Toplam Kayıt Sayısı: ${data.length}`}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selection={selectedRow}
                    >
                      <Column className="max-w-[40px]" headerClassName="w-4" selectionMode="multiple" headerStyle={{ width: "3em" }}></Column>
                      <Column field="studentFullName" className=" min-w-[200px]" header="Öğrenci adı soyadı" sortable></Column>
                      <Column field="classroomName" className="" header="Sınıfı" sortable></Column>
                      <Column field="planName" className="" header="Ödeme Planı" sortable></Column>
                      <Column field="months" className="" header="Ay" sortable></Column>
                      <Column
                        field="dueDate"
                        className=""
                        header="Son Ödeme"
                        body={(rowData: any) => {
                          const date = new Date(rowData.dueDate);
                          const day = date.getDate(); // Günü al
                          const month = date.toLocaleDateString("tr-TR", { month: "long" }); // Ayı al (uzun isim)
                          const formattedDate = `${day} ${month}`;
                          return formattedDate;
                        }}
                        sortable
                      ></Column>

                      <Column
                        field="installmentAmount"
                        className=""
                        header="Aylık Tutar"
                        body={(rowData: any) => `${rowData.installmentAmount.toLocaleString("tr-TR")} ₺`}
                      ></Column>
                      <Column field="totalAmountPaid" className="" header="Toplam Ödenen" body={(rowData: any) => `${rowData.totalAmountPaid.toLocaleString("tr-TR")} ₺`}></Column>
                      <Column field="totalDiscount" className="" header="Toplam İskonto" body={(rowData: any) => `${rowData.totalDiscount.toLocaleString("tr-TR")} ₺`}></Column>
                      <Column
                        field="totalRemainingAmount"
                        className=""
                        header="Toplam Kalan"
                        body={(rowData: any) => `${rowData.totalRemainingAmount.toLocaleString("tr-TR")} ₺`}
                      ></Column>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OverduePayment;
