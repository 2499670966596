import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { RadioButton } from "primereact/radiobutton";
import { TabPanel, TabView } from "primereact/tabview";
import { Tooltip } from "primereact/tooltip";
import { classNames } from "primereact/utils";
import { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sound from "react-sound";
import DropdownComponent from "../../Components/Dropdown/DropdownComponent";
import ProfileImage from "../../Components/Image/ProfileImage";
import InputComp from "../../Components/Input/InputComp";
import SkeletonCard from "../../Components/Loading/Skeleton_Card";
import { AppContext } from "../../Context/Store";
import { IClassroom, IPaymentPlanDetailWithStudentsView, IStudent, IStudentAnnouncementClass, IStudentMovement, IStudentParent, IStudentPayment, UserRole } from "../../Models";
import { PaymentPlanService, StudentService } from "../../Services";
import LabelComp from "../../Components/LabelComp";
import { act } from "react-dom/test-utils";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";

let selectedStudentParent: any;

const StudentCard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams<"id">();
  const studentService = StudentService();
  const paymentPlanService = PaymentPlanService();
  const { user } = useContext(AppContext);
  const btnRef: any = useRef<HTMLButtonElement>();

  let studentId: number = id ? Number.parseInt(id) : 0;
  const [activeIndex, setActiveIndex] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [dataProgress, setDataProgress] = useState(false);
  const [classroomList, setClassroomList] = useState<any[] | null>();
  const [classroomDataLoading, setClassroomDataLoading] = useState(false);
  const [selectedClassroom, setSelectedClassroom] = useState<any>();

  const [deleteBtnProgress, setDeleteBtnProgress] = useState(false);

  const [paymentPlanDetail, setPaymentPlanDetail] = useState<IPaymentPlanDetailWithStudentsView[]>([]);

  const [selectedInstallment, setSelectedInstallment] = useState<IPaymentPlanDetailWithStudentsView>();

  const [paymentPlanDetailLoading, setPaymentPlanDetailLoading] = useState(false);
  const [downloadSoundProgress, setDownloadSoundProgress] = useState(false);

  const [parentListLoading, setParentListLoading] = useState(false);
  const [studentAnnouncementClassLoading, setStudentAnnouncementClassLoading] = useState(false);
  const [parentList, setParentList] = useState<IStudentParent[]>();
  const [cardNumber, setCardNumber] = useState("");

  const [discount, setDiscount] = useState(0);

  const [saveStudentAnnouncemetClassLoading, setSaveStudentAnnouncemetClassLoading] = useState(false);
  const [selectedStudentAnnouncementClass, setSelectedStudentAnnouncementClass] = useState<IStudentAnnouncementClass>({
    id: 0,
    startTime: "",
    endTime: "",
    studentId: 0,
    classroomId: 0,
  });
  const [studentAnnouncementClassList, setStudentAnnouncementClassList] = useState<IStudentAnnouncementClass[]>([]);
  const [studentAnnounemetClassDialog, setStudentAnnouncementClassDialog] = useState(false);

  const [mobilUseStateChangeUser, setMobilUseStateChangeUser] = useState(false);
  const [soundPlayStatus, setSoundPlayStatus] = useState<"STOPPED" | "PLAYING" | "PAUSED">("STOPPED");

  const [submitted, setSubmitted] = useState(false);
  const [installmentDialog, setInstallmentDialog] = useState(false);
  const [cardNumbertDialog, setCardNumberDialog] = useState(false);

  const [studentData, setStudentData] = useState<IStudent>({
    name: "",
    surname: "",
    classroomId: 1,
    id: 0,
    imageUrl: "",
    soundUrl: "",
    isActive: true,
    status: "Devam Ediyor",
    formUrl: "",
    isUseMobile: true,
    fullName: "",
    gender: "",
    tcNumber: "",
    announcementClass: false,
    note: "",
  });

  const devamDurumu = ["Devam Ediyor", "Ayrıldı", "Dondurdu", "Tamamladı"];

  //  Değişkenlerin bitişi
  useEffect(() => {
    setIsLoading(true);
    GetClassroomList().then(() => {
      if (!location.pathname.includes("/new") && id && Number.parseInt(id) > 0) {
        findUser_ById(id);

        setIsLoading(false);
      }
      setIsLoading(false);
    });
  }, [id]); // eslint-disable-next-line react-hooks/exhaustive-deps

  const [movementListData, setMovementListData] = useState<IStudentMovement[]>([]);
  const [movementListDataLoading, setMovementListDataLoading] = useState(false);

  const StudentMovementList = async () => {
    setMovementListDataLoading(true);
    const { data, error } = await studentService.GetStudentMovementList(studentId);
    try {
      if (data) {
        if (data.success) {
          // console.log("data=> ", data);
          setMovementListData(data.returnData);
        } else {
          toast.error(data.errorMessage);
        }
      }
      setMovementListDataLoading(false);
    } catch (error) {
      console.log("error=> ", error);
    }
    setMovementListDataLoading(false);
  };
  useEffect(() => {
    // console.log("activeIndex => ", activeIndex);
    if (activeIndex === 4) {
      StudentMovementList();
    }
  }, [activeIndex]);

  const GetClassroomList = async () => {
    setClassroomDataLoading(true);
    const { data, error } = await studentService.GetClassroomList();
    setClassroomDataLoading(false);
    if (error) {
      toast.error("Sınıf listesi yüklenirken hata oluştu");
    } else {
      if (data && data.success) {
        // console.log('GetClassroomList data=> ', data.returnData)
        setClassroomList(data?.returnData as IClassroom[]);
      } else {
        toast.error(`Sınıf listesi yüklenirken hata oluştu ${data?.errorMessage}`);
      }
    }
  };

  const GetPaymentDetail = async (_studentId: number) => {
    setPaymentPlanDetailLoading(true);
    const { data, error } = await paymentPlanService.GetPaymentDetailListForStudent(_studentId);
    setPaymentPlanDetailLoading(false);
    if (error) {
      toast.error("Ödeme detayları yüklenirken hata oluştu");
    } else {
      if (data) {
        if (data.success) {
          // console.log("GetPaymentDetail data=> ", data.returnData);
          setPaymentPlanDetail(data.returnData as IPaymentPlanDetailWithStudentsView[]);
        } else {
          toast.error(data.errorMessage);
        }
      }
    }
  };

  const GetParentList = async (studentId: number) => {
    setParentListLoading(true);
    const { data, error } = await studentService.GetParentList(studentId);
    setParentListLoading(false);
    if (error) {
      toast.error("Veli listesi yüklenirken hata oluştu err");
    } else {
      if (data) {
        if (data.success) {
          setParentList(data?.returnData as IStudentParent[]);
        } else {
          toast.error(`Veli listesi yüklenirken hata oluştu ${data.errorMessage || ""}`);
        }
      }
    }
  };

  const GetStudentAnnouncementClassList = async (studentId: number) => {
    setStudentAnnouncementClassLoading(true);
    const { data, error } = await studentService.GetStudentAnnouncementClassByStudent(studentId);
    setStudentAnnouncementClassLoading(false);
    if (error) {
      toast.error("Sınıf anons saati yüklenirken hata oluştu err");
    } else {
      if (data) {
        if (data.success) {
          const _data = data.returnData as IStudentAnnouncementClass[];
          console.log("_data AnnouncementClass => ", _data);
          setStudentAnnouncementClassList(_data);
        } else {
          toast.error(`Sınıf anons saati yüklenirken hata oluştu ${data.errorMessage || ""}`);
        }
      }
    }
  };

  const findUser_ById = async (userId: string) => {
    // console.log("userId =>  ", userId);
    if (userId) {
      const { data, error } = await studentService.FindStudentById(userId);
      setIsLoading(false);
      if (data) {
        if (data.success) {
          const _data = data.returnData as IStudent;
          setSelectedClassroom(_data.classroom);
          setMobilUseStateChangeUser(_data.isUseMobile);
          setStudentData(_data);

          setSelectedStudentAnnouncementClass({ ...selectedStudentAnnouncementClass, studentId: _data.id });
          GetParentList(_data.id);
          GetPaymentDetail(_data.id);
          if (_data.announcementClass) {
            // sınıflara göre anons saatlerini getir
            GetStudentAnnouncementClassList(_data.id);
          }

          // console.log("findUser_ById data=> ", data.returnData);
        } else {
          toast.error(data?.errorMessage ? data.errorMessage : "Hata oluştu");
          console.log("error Message => ", data?.errorMessage);
        }
      }
    } else {
      toast.error("Yıldızlı alanları doldurunuz !");
    }
  };

  const btnSaveHandle = async () => {
    //  console.log("studentData=> ", JSON.stringify(studentData));
    btnRef.current.disabled = true;
    setDataProgress(true);

    if (studentData.name.trim().length < 3 && studentData.surname.trim().length < 3) {
      setDataProgress(false);
      btnRef.current.disabled = false;
      toast.error("Öğrenci adı ve soyadı girilmelidir");
      return;
    }

    if (!studentData.gender) {
      setDataProgress(false);
      btnRef.current.disabled = false;
      toast.error("Öğrenci cinsiyeti seçilmelidir ");
      return;
    }

    if (id) {
      // Güncelle

      const { data, error } = await studentService.UpdateStudent(studentData);
      btnRef.current.disabled = false;
      setDataProgress(false);
      if (error) {
        toast.error(error);
      } else {
        if (data && data.success) {
          toast.success(data.successMessage);
          navigate("/students");
        } else {
          toast.error(data?.errorMessage ? data.errorMessage : "Hata oluştu");
        }
      }
    } else {
      // Yeni kayıt ekle

      const { data, error } = await studentService.AddStudent(studentData);
      btnRef.current.disabled = false;
      setDataProgress(false);
      if (error) {
        toast.error(error);
      } else {
        if (data && data.success) {
          toast.success(data.successMessage);
          navigate("/students");
        } else {
          toast.error(data?.errorMessage ? data.errorMessage : "Hata oluştu");
        }
      }
    }
  };

  const ogrenimDurumuOptionTemplate = (option: any) => {
    return <span>{option}</span>;
  };

  const selectedOgrenimDurumuTemplate = (option: string, props: any) => {
    if (option) {
      return (
        <div className="flex justify-between " style={{ marginTop: -4 }}>
          <span>{option}</span>
        </div>
      );
    }

    return (
      <div className="flex " style={{ marginTop: -4 }}>
        {props.placeholder}
      </div>
    );
  };

  const onClassroomChange = (e: { value: any }) => {
    if (e.value) {
      setSelectedClassroom(e.value);
      setStudentData({ ...studentData, classroomId: e.value.id });
      setSelectedStudentAnnouncementClass({ ...selectedStudentAnnouncementClass, classroomId: e.value.id });
    }
  };

  const classroomOptionTemplate = (option: any) => {
    return <span>{option.name}</span>;
  };

  const selectedClassroomTemplate = (option: { name: string; code: string }, props: any) => {
    if (option) {
      return (
        <div style={{ marginTop: -4 }}>
          <span>{option.name}</span>
        </div>
      );
    }

    return (
      <div className="flex " style={{ marginTop: -4 }}>
        {props.placeholder}
      </div>
    );
  };

  const isSendSmsBodyTemplate = (item: IStudentParent) => {
    return (
      <div className="flex justify-center items-center min-w-[140px] max-w-[140px] -ml-3">
        <Button
          className="p-button-rounded p-button-text"
          onClick={() => {
            let _item = { ...item };
            _item.sendSms = !item.sendSms;

            selectedStudentParent = _item;
            updateStudentParent();
          }}
        >
          <i
            className={classNames("pi", {
              "true-icon pi-check-circle text-green-600 text-xl": item.sendSms,
              "false-icon pi-times-circle text-red-400 text-xl": !item.sendSms,
            })}
          ></i>
        </Button>
      </div>
    );
  };

  const parentFullNameBodyTemplate = (item: IStudentParent) => {
    return (
      <div className="flex justify-center min-w-[50px]">
        {item.parent.imageUrl.length > 0 && (
          <Tooltip target={classNames(".custom-tooltip-btn" + item.id)} autoHide>
            <img alt="logo" src={item.parent.imageUrl} data-pr-tooltip="PrimeReact-Logo" className="w-60 h-60" />
          </Tooltip>
        )}

        <label className={classNames(item.parent.imageUrl ? "custom-tooltip-btn" + item.id : "")}>{item.parent.fullName}</label>
      </div>
    );
  };

  const isSParentBodyTemplate = (item: IStudentParent) => {
    return (
      <div className="flex justify-center items-center min-w-[140px] max-w-[140px] -ml-3">
        <Button
          className="p-button-rounded p-button-text"
          onClick={() => {
            let _item = { ...item };
            _item.isParent = !item.isParent;
            selectedStudentParent = _item;
            updateStudentParent();
          }}
        >
          <i
            className={classNames("pi", {
              "true-icon pi-check-circle text-green-600 text-xl": item.isParent,
              "false-icon pi-times-circle text-red-400 text-xl": !item.isParent,
            })}
          ></i>
        </Button>
      </div>
    );
  };

  const [studentParentBusy, setStudentParentBusy] = useState(false);

  const updateStudentParent = async () => {
    console.log("item.isUseCard> ", selectedStudentParent);
    if (selectedStudentParent) {
      setStudentParentBusy(true);
      const { data } = await studentService.UpdateStudentParent(selectedStudentParent);
      setStudentParentBusy(false);

      if (data) {
        if (data.success) {
          toast.success(data.successMessage);
          setStudentParentBusy(false);

          if (parentList) {
            // update parentList for studentParentRow
            const newParentList = parentList.map((item: IStudentParent) => {
              if (item.id === selectedStudentParent?.id) {
                item = selectedStudentParent;
              }
              return item;
            });

            setParentList(newParentList);
          }
        } else {
          toast.error(data.errorMessage);
          setStudentParentBusy(false);
        }
      }
    }
  };

  const isSUseCardBodyTemplate = (item: IStudentParent) => {
    return (
      <div className="flex justify-center items-center min-w-[140px] max-w-[140px] -ml-3">
        <Button
          className="p-button-rounded p-button-text"
          onClick={() => {
            let _item = { ...item };
            _item.isUseCard = !item.isUseCard;

            selectedStudentParent = _item;

            if (!item.isUseCard) {
              setCardNumber(item.cardNumber);
              setCardNumberDialog(true);
            } else {
              updateStudentParent();
            }
          }}
        >
          <i
            className={classNames("pi", {
              "true-icon pi-check-circle text-green-600 text-xl": item.isUseCard,
              "false-icon pi-times-circle text-red-400 text-xl": !item.isUseCard,
            })}
          ></i>
        </Button>
      </div>
    );
  };

  const actionInstallmentBodyTemplate = (rowData: IPaymentPlanDetailWithStudentsView) => {
    return (
      <div>
        <Button style={{ height: 30, width: 30, marginRight: 5 }} icon="pi pi-pencil" className="p-button-rounded p-button-warning" onClick={() => editProduct(rowData)} />
        <Button
          disabled={deleteBtnProgress}
          style={{ height: 30, width: 30, marginRight: 5 }}
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger "
          onClick={(e) => DeleteHandle(e, rowData)}
        ></Button>
      </div>
    );
  };
  const actionStudentAnnouncementClassBodyTemplate = (rowData: IStudentAnnouncementClass) => {
    return (
      <div>
        {/* <Button
          style={{ height: 30, width: 30, marginRight: 5 }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-warning"
          // onClick={() => editProduct(rowData)}
        /> */}

        <Button
          disabled={deleteBtnProgress}
          style={{ height: 30, width: 30, marginRight: 5 }}
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger "
          onClick={(e) => DeleteStudentAnnouncementClassHandle(e, rowData)}
        ></Button>
      </div>
    );
  };

  const acceptDeleteHandle = async (e: any, item: IPaymentPlanDetailWithStudentsView) => {
    setDeleteBtnProgress(true);
    console.log("item=> ", item);
    if (item) {
      const { data, error } = await paymentPlanService.InstallmentDelete(item?.id);
      setDeleteBtnProgress(false);
      if (data) {
        // console.log("data=> ", data);
        if (data.success) {
          toast.success(`${item.monthName} - ${item.planName} kaydı silindi`);
          GetPaymentDetail(item.studentId);
        } else {
          toast.error(data.errorMessage);
        }
      }
    }
  };

  const acceptStudentAnnouncementClassDeleteHandle = async (e: any, item: IStudentAnnouncementClass) => {
    setDeleteBtnProgress(true);

    console.log("delete start");
    const { data } = await studentService.DeleteStudentAnnouncementClass(item.id);
    setDeleteBtnProgress(false);
    if (data) {
      // console.log("data=> ", data);
      if (data.success) {
        toast.success(`$Kayıt silindi`);
        GetStudentAnnouncementClassList(item.studentId);
      } else {
        toast.error(data.errorMessage);
      }
    }
  };

  const DeleteHandle = async (e: any, item: IPaymentPlanDetailWithStudentsView) => {
    console.log("DeleteHandle");
    setSelectedInstallment(item);

    confirmPopup({
      target: e.currentTarget,
      message: `${item.planName} ${item.monthName} taksit, silmek istiyor musunuz ?`,
      icon: "pi pi-times-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => acceptDeleteHandle(e, item),
    });
  };
  const DeleteStudentAnnouncementClassHandle = async (e: any, item: IStudentAnnouncementClass) => {
    confirmPopup({
      target: e.currentTarget,
      message: `${item.classroom?.name} sınıf anons saatini silmek istiyor musunuz ?`,
      icon: "pi pi-times-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => acceptStudentAnnouncementClassDeleteHandle(e, item),
    });
  };

  /////////////////

  const DeleteRelationHandle = async (e: any, item: any) => {
    questionRelationDelete(e, item.id);
  };

  const acceptRelationDelete = async (e: any, id: any) => {
    const { data, error } = await studentService.DeleteStudentParentRelation(id);

    if (data) {
      if (data.success) {
        toast.success("Silme işlemi başarılı");
        await GetParentList(studentId);
      } else {
        toast.error(`Silme işlemi başarısız ! ${data?.errorMessage}`);
      }
    }
  };

  const questionRelationDelete = (event: any, id: any) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Bu kaydı silmek istiyor musunuz ?",
      icon: "pi pi-times-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => acceptRelationDelete(event, id),
    });
  };

  const actionBodyTemplate = (rowData: IStudentParent) => {
    return (
      <div>
        <Button
          style={{ height: 30, width: 30, marginRight: 8 }}
          icon="pi pi-eye"
          className="p-button-rounded p-button-warning"
          onClick={() => navigate(`/parent/${rowData.parentId}`)}
        />

        <Button
          // disabled={deleteBtnProgress}
          onClick={(e) => DeleteRelationHandle(e, rowData)}
          style={{ height: 30, width: 30, marginRight: 5 }}
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger "
        ></Button>
      </div>
    );
  };

  const DownloadSound = async () => {
    if (studentData) {
      setDownloadSoundProgress(true);
      const { data } = await studentService.CreateSoundById(studentData.id);
      setDownloadSoundProgress(false);
      if (data) {
        if (data.success) {
          toast.success(data.successMessage);
          setStudentData({ ...studentData, soundUrl: data.returnData });
        } else {
          toast.error(data.errorMessage);
        }
      }
    }
  };

  const createDateBody = (data: IPaymentPlanDetailWithStudentsView) => {
    if (data.dueDate) {
      return <label>{moment(data.dueDate).format("DD.MM.YYYY")}</label>;
    }
  };

  const createPaidDateBody = (data: IPaymentPlanDetailWithStudentsView) => {
    if (data.paymentDate != null) {
      return <label>{moment(data.paymentDate).format("DD.MM.YYYY")}</label>;
    }
  };

  const hideInstallmentDialog = () => {
    setSubmitted(false);
    setInstallmentDialog(false);
  };

  const hideCardNumberDialog = () => {
    setCardNumberDialog(false);
  };
  const hideStudentAnnouncementClassDialog = () => {
    setStudentAnnouncementClassDialog(false);
  };

  const editProduct = (installment: IPaymentPlanDetailWithStudentsView) => {
    console.log("editProduct");
    setSelectedInstallment(installment);
    setInstallmentDialog(true);
  };

  const saveInstallment = async () => {
    if (selectedInstallment) {
      const { data } = await paymentPlanService.InstallmentUpdate(selectedInstallment);
      if (data) {
        if (data.success) {
          toast.success(data.successMessage);
          setInstallmentDialog(false);
          GetPaymentDetail(studentId);
        } else {
          toast.error(data.errorMessage);
        }
      }
    } else {
      toast.error("Lütfen taksit seçiniz");
    }
  };
  const saveStudentAnnouncemetClass = async () => {
    setSaveStudentAnnouncemetClassLoading(true);
    console.log("save handle => ", selectedStudentAnnouncementClass);

    if (selectedStudentAnnouncementClass.startTime.length != 5 && selectedStudentAnnouncementClass.endTime.length != 5) {
      toast.error("Lütfen saat bilgisi giriniz");
      setSaveStudentAnnouncemetClassLoading(false);
      return;
    }

    const { data } = await studentService.CreateStudentAnnouncementClass(selectedStudentAnnouncementClass);
    console.log("save data => ", data);
    setSaveStudentAnnouncemetClassLoading(false);

    if (data) {
      if (data.success) {
        setSelectedStudentAnnouncementClass({
          id: 0,
          startTime: "",
          endTime: "",
          studentId: 0,
          classroomId: 0,
        });
        toast.success(data.successMessage);
        setStudentAnnouncementClassDialog(false);
        await GetStudentAnnouncementClassList(studentId);
      } else {
        toast.error(data.errorMessage);
      }
    }
  };

  const installmentDialogFooter = (
    <>
      <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideInstallmentDialog} />
      <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={saveInstallment} />
    </>
  );
  const StudentAnnouncementClassDialogFooter = (
    <>
      <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideStudentAnnouncementClassDialog} />
      <Button label="Kaydet" loading={studentAnnouncementClassLoading} icon="pi pi-check" className="p-button-text" onClick={saveStudentAnnouncemetClass} />
    </>
  );
  const [val1, setVal1] = useState();

  const cardNumbertDialogFooter = (
    <>
      <Button
        label="Numara ile kaydet"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          selectedStudentParent.cardNumber = cardNumber;
          setCardNumberDialog(false);
          updateStudentParent();
        }}
      />
      <Button
        label="Hayır"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          selectedStudentParent.cardNumber = "";
          setCardNumberDialog(false);
          updateStudentParent();
        }}
      />
    </>
  );

  const [expandedRows, setExpandedRows] = useState<any>(null);
  const onRowExpand = (event: any) => {
    // console.log("onRowExpand => ", event.data);
    GetMovementResponse(event.data.id);
  };

  const onRowCollapse = (event: any) => {
    // console.log("onRowCollapse => ", event);
  };

  const [childExpandedRows, setChildExpandedRows] = useState<any>(null);

  const GetMovementResponse = async (studentId: number) => {
    {
      setChildExpandedRows(null);
      const { data, error } = await studentService.StudentMovementResponse(studentId);
      if (data) {
        if (data.success) {
          // console.log("data=> ", data);
          setChildExpandedRows(data.returnData);
        } else {
          toast.error(data.errorMessage);
        }
      }
    }
  };
  const rowExpansionTemplate = (rowData: any) => {
    // console.log("rowExpansionTemplate => ", rowData);

    return (
      <div className="ml-1 w-full p-2 bg-blue-200 rounded-md">
        <label
          // style={{
          //   fontSize: 20,
          //   fontWeight: 600,
          //   margin: 20,
          // }}
          className="text-lg font-bold text-gray-800"
        >
          Anons Edilme Detayı - İşlem Id: {rowData.id}
        </label>
        <DataTable value={childExpandedRows} className="mt-3">
          <Column field="id" header="Id" className="max-w-[100px]"></Column>
          <Column field="client.id" header="clientId" className="max-w-[100px]"></Column>
          <Column field="client.displayName" header="displayName"></Column>
          <Column field="processDate" header="İşlem" body={(data) => moment(data.processDate).format("DD.MM.YYYY HH:mm")}></Column>
          <Column field="forverdedDate" header="İletilme" body={(data) => (data.forverdedDate ? moment(data.forverdedDate).format("DD.MM.YYYY HH:mm") : "-")}></Column>
          <Column field="announcementDate" header="Anons" body={(data) => (data.announcementDate ? moment(data.announcementDate).format("DD.MM.YYYY HH:mm") : "-")}></Column>
        </DataTable>
      </div>
    );
  };

  const allowExpansion = (rowData: any) => {
    console.log("rowData => ", rowData);
    return rowData != null;
  };

  const footerGroup = () => {
    const remainingTotal = studentData.id > 0 ? paymentPlanDetail.reduce((acc, val) => acc + val.remainingAmount, 0) : 0;
    const totalInstallmentAmoutn = studentData.id > 0 ? paymentPlanDetail.reduce((acc, val) => acc + val.installmentAmount, 0) : 0;
    return (
      <ColumnGroup>
        <Row>
          <Column footer="Ödenen :" colSpan={3} footerStyle={{ textAlign: "right" }} />
          <Column footer={totalInstallmentAmoutn} colSpan={3} footerStyle={{ textAlign: "left" }} />

          <Column footer="Kalan :" colSpan={3} footerStyle={{ textAlign: "right" }} />
          <Column footer={remainingTotal} colSpan={3} footerStyle={{ textAlign: "left" }} />
        </Row>
      </ColumnGroup>
    );
  };

  return (
    <div className="m-3 p-4 rounded-lg bg-white">
      <ConfirmPopup />

      {isLoading ? (
        <SkeletonCard />
      ) : (
        <>
          <div className="m-2 mb-5">
            <label className="text-2xl">{studentData.id > 0 ? "Öğrenci Güncelle" : "Yeni Öğrenci ekle"}</label>
            <hr className="my-auto flex-grow-1 mt-2" />
          </div>

          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => {
              setActiveIndex(e.index);
            }}
          >
            <TabPanel header="Genel Bilgiler">
              <div className="">
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className="px-4">
                      {/* Burası ilk satır başlangıç */}
                      <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-2 lg:col-span-2">
                          <label className="col-sm-2col-form-label">Resim </label>
                          <label className="text-gray-200"> _{studentData.id}</label>
                          <div className="w-44 h-44 border-2 rounded-md flex justify-center">
                            <ProfileImage
                              uploadImageUrl={async (e) => {}}
                              modalOpen={true}
                              folder="Ogrenci"
                              value={studentData.imageUrl}
                              selectedImage={async (e) => {
                                setStudentData({ ...studentData, imageUrl: e });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-span-2 lg:col-span-2">
                          <label className="col-sm-2col-form-label">Öğrenci Formu (* Zorunlu değil)</label>
                          <div className="w-44 h-44 border-2 rounded-md flex justify-center">
                            <ProfileImage
                              uploadImageUrl={async (e) => {}}
                              modalOpen={false}
                              folder="Form"
                              value={studentData.formUrl}
                              selectedImage={async (e) => {
                                setStudentData({ ...studentData, formUrl: e });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-span-4 lg:col-span-2">
                          <label className="block text-sm font-medium text-gray-700">Adı</label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <InputComp
                              type="text"
                              value={studentData.name}
                              placeholder="Öğrenci adı"
                              onChange={(e: any) => {
                                setStudentData({
                                  ...studentData,
                                  name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-span-4 lg:col-span-2">
                          <label className="block text-sm font-medium text-gray-700">Soyadı</label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <InputComp
                              type="text"
                              value={studentData.surname}
                              placeholder="Öğrenci Soyadı"
                              onChange={(e: any) => {
                                setStudentData({
                                  ...studentData,
                                  surname: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-4 gap-4 mt-3">
                        <div className="col-span-4 lg:col-span-2">
                          <label className="block text-sm font-medium text-gray-700">Devam Durumu</label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <Dropdown
                              value={studentData.status}
                              options={devamDurumu}
                              onChange={(e) => {
                                setStudentData({
                                  ...studentData,
                                  status: e.value,
                                });
                              }}
                              optionLabel="name"
                              placeholder="Okul Durumu Seçiniz"
                              className="min-w-full h-10"
                              valueTemplate={selectedOgrenimDurumuTemplate}
                              itemTemplate={ogrenimDurumuOptionTemplate}
                            />
                          </div>
                        </div>

                        <div className="col-span-4 lg:col-span-2">
                          <label className="block text-sm font-medium text-gray-700">Sınıfı</label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <DropdownComponent
                              disabled={classroomDataLoading}
                              id="classroomId"
                              value={selectedClassroom}
                              options={classroomList as any[]}
                              onChange={onClassroomChange}
                              optionLabel="name"
                              dataKey="id"
                              filter
                              showClear
                              filterBy="name"
                              placeholder={classroomDataLoading ? "Yükleniyor.." : "Sınıf Seçiniz"}
                              className="min-w-full"
                              panelStyle={{ marginTop: -30 }}
                              valueTemplate={selectedClassroomTemplate}
                              itemTemplate={classroomOptionTemplate}
                            />
                          </div>
                        </div>

                        <div className="col-span-4 lg:col-span-2">
                          <label className="block text-sm font-medium text-gray-700">Açıklama - Not</label>
                          <div className="mt-1">
                            <textarea
                              value={studentData.note}
                              onChange={(e) => {
                                setStudentData({
                                  ...studentData,
                                  note: e.target.value,
                                });
                              }}
                              rows={3}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                              placeholder="Öğrenci için not alanı"
                              defaultValue={""}
                            />
                          </div>
                        </div>

                        <div className="col-span-4 lg:col-span-2">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">Mobil Kullanımı</label>
                            <div className="flex items-center h-11 ">
                              <InputSwitch
                                inputId="smsServiceApiActive"
                                disabled={studentData.id != 0 && UserRole.ADMIN !== user.role && UserRole.SUPERADMIN !== user.role && mobilUseStateChangeUser}
                                onChange={(e) =>
                                  setStudentData({
                                    ...studentData,
                                    isUseMobile: e.value,
                                  })
                                }
                                checked={studentData.isUseMobile}
                              ></InputSwitch>
                              <label htmlFor="userActive" className="ml-3">
                                {studentData.isUseMobile ? "aktif" : "pasif"}
                              </label>
                            </div>
                          </div>
                          {studentData.isUseMobile && (
                            <>
                              <label className="text-red-400  block">
                                - Bu öğrenci mobil kullanımı için {studentData.id > 0 && mobilUseStateChangeUser ? "ücretlendirildi" : "ücretlendirilecek"}
                              </label>
                              {studentData.id == 0 && <label>Mobil uygulama kullanmayacak ise lütfen pasif yapınız. Daha sonra değiştiremezsiniz.</label>}
                            </>
                          )}
                        </div>

                        <div className="col-span-4 lg:col-span-2">
                          <div className="grid grid-cols-4 gap-4">
                            <div className="col-span-4 lg:col-span-2">
                              <label className="block text-sm font-medium text-gray-700">Tc Numarası</label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <InputComp
                                  type="text"
                                  value={studentData.tcNumber}
                                  placeholder="Tc Numarası"
                                  onChange={(e: any) => {
                                    if (e.target.value.length > 11) {
                                      return;
                                    }
                                    setStudentData({
                                      ...studentData,
                                      tcNumber: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-span-2 lg:col-span-2">
                              <label className="block text-sm font-medium text-gray-700">Cinsiyeti :</label>
                              <div className="mt-1 flex gap-2">
                                <RadioButton
                                  inputId="chKız"
                                  name="gender"
                                  value="Kız"
                                  onChange={(e) => {
                                    setStudentData({
                                      ...studentData,
                                      gender: e.value,
                                    });
                                  }}
                                  checked={studentData.gender === "Kız"}
                                />
                                <label htmlFor="chKız">Kız</label>

                                <RadioButton
                                  style={{ marginLeft: "50px" }}
                                  inputId="chErkek"
                                  name="city"
                                  value="Erkek"
                                  onChange={(e) => {
                                    setStudentData({
                                      ...studentData,
                                      gender: e.value,
                                    });
                                  }}
                                  checked={studentData.gender === "Erkek"}
                                />
                                <label htmlFor="chErkek">Erkek</label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-span-4 lg:col-span-2">
                          <div className="mt-4   ">
                            <Button
                              onClick={() => {
                                studentData.soundUrl ? setSoundPlayStatus("PLAYING") : DownloadSound();
                              }}
                              label={studentData.soundUrl ? " Ses dinle" : " Ses dosyası oluştur"}
                              icon={studentData.soundUrl ? "pi pi-play" : "pi pi-cloud-download"}
                              disabled={soundPlayStatus == "PLAYING"}
                              loading={downloadSoundProgress}
                            />
                            <Sound
                              url={studentData.soundUrl ? studentData.soundUrl : ""}
                              playStatus={soundPlayStatus}
                              playFromPosition={300}
                              onFinishedPlaying={() => setSoundPlayStatus("STOPPED")}
                              // onLoading={this.handleSongLoading}
                              // onPlaying={this.handleSongPlaying}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-4 gap-4"></div>

                    <div className="px-4 py-3 mt-5 bg-gray-50 text-right sm:px-6">
                      <Button
                        ref={btnRef}
                        disabled={btnRef.current?.disabled}
                        loading={dataProgress}
                        onClick={() => btnSaveHandle()}
                        label="Kaydet"
                        // className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium
                        //  rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2
                        //  focus:ring-offset-2 focus:ring-indigo-500"
                      ></Button>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel
              header="İlişkilendirmeler"
              className="tabview-header-icon"
              leftIcon={parentListLoading ? "pi pi-spin pi-spinner" : ""}
              disabled={parentList?.length == 0 || parentListLoading}
            >
              <div className=" bg-white ">
                {parentList && (
                  <DataTable
                    value={parentList}
                    stripedRows
                    emptyMessage="Kayıtlı ilişkilendirme yok"
                    responsiveLayout="scroll"
                    loading={isLoading || studentParentBusy}
                    scrollable
                    size="small"
                    footer={`Toplam İlişkilendirme Sayısı: ${parentList.length}`}
                  >
                    <Column className=" max-w-[60px]" field="id" header="#"></Column>
                    <Column field="parent.fullName" header="Veli Adı Soyadı" body={parentFullNameBodyTemplate}></Column>
                    <Column field="parentType" className="max-w-[150px]" header="Yakınlık Tipi"></Column>
                    <Column field="parent.mobilePhone" className="max-w-[150px]" header="Telefon Numarası"></Column>
                    <Column field="isUsecard" className="min-w-[140px] max-w-[140px]" header="Kart Kullanımı " body={isSUseCardBodyTemplate}></Column>
                    <Column className=" max-w-[120px]" field="isParent" header="Velisi Mi ?" body={isSParentBodyTemplate}></Column>
                    <Column className=" max-w-[120px]" field="sendSms" header="Sms Gönder" body={isSendSmsBodyTemplate}></Column>
                    <Column header="İşlemler" body={(data) => actionBodyTemplate(data)} exportable={false}></Column>
                    {/*
                <Column
                  className=""
                  header="İşlemler"
                  body={(data) => actionBodyTemplate(data)}
                  exportable={false}
                ></Column> */}
                  </DataTable>
                )}
              </div>
            </TabPanel>
            <TabPanel
              header="Ödeme Tablosu"
              disabled={paymentPlanDetail.length == 0 || paymentPlanDetailLoading}
              leftIcon={paymentPlanDetailLoading ? "pi pi-spin pi-spinner" : ""}
            >
              <DataTable
                scrollable
                scrollHeight="600px"
                scrollDirection="vertical"
                size="small"
                value={paymentPlanDetail}
                className="table-auto w-full"
                columnResizeMode="fit"
                stripedRows
                responsiveLayout="stack"
                loading={isLoading}
                emptyMessage="Ekli ödeme planı bulunmamaktadır."
                sortMode="multiple"
                footer={`Toplam Kayıt Sayısı: ${paymentPlanDetail.length}`}
                footerColumnGroup={footerGroup()}
              >
                <Column field="planName" header="Ödeme Planı"></Column>
                <Column field="installmentNumber" header="Taksit sayısı" sortable></Column>
                <Column field="dueDate" header="Vade Tarihi" body={(data) => createDateBody(data)} sortable></Column>
                <Column field="monthName" header="Ay" sortable></Column>

                <Column sortable field="installmentAmount" header="Ödenecek tutar" body={(rowData: any) => `${rowData.installmentAmount.toLocaleString("tr-TR")} ₺`}></Column>
                <Column sortable field="amountPaid" header="Ödenen" body={(rowData: any) => `${rowData.amountPaid.toLocaleString("tr-TR")} ₺`}></Column>
                <Column sortable field="discount" header="Muafiyet/indirim" body={(rowData: any) => `${rowData.discount.toLocaleString("tr-TR")} ₺`}></Column>
                <Column sortable field="remaininAmount" header="Kalan" body={(rowData: any) => `${rowData.remainingAmount.toLocaleString("tr-TR")} ₺`}></Column>

                <Column field="paidDate" header="Ödeme Tarihi" body={(data) => createPaidDateBody(data)} sortable></Column>

                <Column field="receiptNumber" header="Makbuz Numarası"></Column>
                <Column className="min-w-[110px]" header="İşlemler" body={(data) => actionInstallmentBodyTemplate(data)} exportable={false}></Column>
              </DataTable>
            </TabPanel>
            <TabPanel header="Anons Sınıfı Ataması" disabled={studentAnnouncementClassLoading} leftIcon={studentAnnouncementClassLoading ? "pi pi-spin pi-spinner" : ""}>
              <>
                <label className="text-xl ">
                  * Bu özellik öğrenciyi belli saatlerde başka sınıfta anons etmek için kullanılır. Buraya girilen saatlerde öğrenci kendi sınıfında anons EDİLMEZ !
                </label>
                <DataTable
                  scrollable
                  scrollHeight="600px"
                  scrollDirection="vertical"
                  size="small"
                  value={studentAnnouncementClassList}
                  className="table-auto w-full mt-3"
                  columnResizeMode="fit"
                  stripedRows
                  responsiveLayout="stack"
                  loading={isLoading}
                  emptyMessage="Ekli anons planı bulunmamaktadır."
                  sortMode="multiple"
                  footer={`Toplam Kayıt Sayısı: ${studentAnnouncementClassList.length}`}
                  header={
                    <div className="flex justify-between">
                      <div className="flex">
                        <Button
                          label="Ekle"
                          icon="pi pi-plus"
                          className="p-button-rounded p-button-success p-mr-2"
                          onClick={() => setStudentAnnouncementClassDialog(true)}
                        ></Button>
                      </div>
                    </div>
                  }
                >
                  <Column field="startTime" header="Başlangıç" sortable></Column>
                  <Column field="endTime" header="Bitiş" sortable></Column>
                  <Column field="classroom.name" header="Sınıf" sortable></Column>

                  <Column className="min-w-[110px]" header="İşlemler" body={(data) => actionStudentAnnouncementClassBodyTemplate(data)} exportable={false}></Column>
                </DataTable>
              </>
            </TabPanel>

            <TabPanel header="Öğrenci Hareket Listesi" disabled={studentAnnouncementClassLoading} leftIcon={studentAnnouncementClassLoading ? "pi pi-spin pi-spinner" : ""}>
              <>
                <DataTable
                  value={movementListData}
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data)}
                  onRowExpand={onRowExpand}
                  onRowCollapse={onRowCollapse}
                  rowExpansionTemplate={rowExpansionTemplate}
                  dataKey="id"
                  scrollable
                  scrollHeight="600px"
                  scrollDirection="vertical"
                  size="small"
                  className="table-auto w-full mt-3"
                  columnResizeMode="fit"
                  stripedRows
                  responsiveLayout="stack"
                  loading={movementListDataLoading}
                  emptyMessage="Ekli anons planı bulunmamaktadır."
                  sortMode="multiple"
                  footer={`Toplam Kayıt Sayısı: ${movementListData.length}`}
                >
                  <Column expander style={{ width: 30 }} />
                  <Column field="createDate" header="Tarih" body={(data) => moment(data.createDate).format("DD.MM.YYYY HH:mm")} sortable></Column>
                  <Column field="parentFullName" header="Kişi" sortable></Column>
                  <Column field="classroomName" header="Sınıf" sortable></Column>
                  <Column field="movementType" header="İşlem" sortable></Column>
                  <Column field="accessNumber" header="Geçiş No" sortable></Column>
                  <Column field="readerType" header="Okutma Tipi" sortable></Column>
                </DataTable>
              </>
            </TabPanel>
          </TabView>
        </>
      )}

      <Dialog
        visible={installmentDialog}
        style={{ width: "450px" }}
        header="Taksit Detayı"
        modal
        className="p-fluid"
        footer={installmentDialogFooter}
        onHide={hideInstallmentDialog}
      >
        <div className="grid col-span-2 gap-3">
          <div className="field">
            <label className="block text-sm font-medium text-gray-700">İndirim tutarı</label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <InputComp
                type="text"
                value={selectedInstallment?.discount}
                placeholder="İndirim tutarı"
                onChange={(e: any) => {
                  if (selectedInstallment) {
                    setSelectedInstallment({
                      ...selectedInstallment,
                      discount: e.target.value,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="field">
            <label className="block text-sm font-medium text-gray-700">Makbuz numarası</label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <InputComp
                type="text"
                value={selectedInstallment?.receiptNumber}
                placeholder="Makbuz numarası"
                onChange={(e: any) => {
                  if (selectedInstallment) {
                    setSelectedInstallment({
                      ...selectedInstallment,
                      receiptNumber: e.target.value,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={cardNumbertDialog}
        style={{ width: "450px" }}
        header="Kart numarasını girmek ister misiniz ?"
        modal
        className="p-fluid"
        footer={cardNumbertDialogFooter}
        onHide={hideCardNumberDialog}
      >
        <div className="grid col-span-2 gap-3">
          <div className="field">
            <label className="block text-sm font-medium text-gray-700">Kart numarası</label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <InputComp
                type="text"
                value={cardNumber}
                onChange={(e: any) => {
                  setCardNumber(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={studentAnnounemetClassDialog}
        style={{ width: "450px" }}
        header="Anons Sınıfı"
        modal
        className="p-fluid"
        onHide={hideStudentAnnouncementClassDialog}
        footer={StudentAnnouncementClassDialogFooter}
      >
        <div className="grid col-span-2 gap-3">
          <div className="field">
            <div className="grid grid-cols-4 gap-4">
              <div className="col-span-2 lg:col-span-2">
                <div className="field">
                  <label className=" text-sm font-medium text-gray-700">Başlangıç Saati</label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputMask
                      className="p-inputtext-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 block w-full rounded-md sm:text-sm h-10 "
                      id="basic"
                      mask="99:99"
                      value={selectedStudentAnnouncementClass?.startTime.toString()}
                      placeholder="00:00"
                      onChange={(e: any) => {
                        if (selectedStudentAnnouncementClass) {
                          setSelectedStudentAnnouncementClass({
                            ...selectedStudentAnnouncementClass,
                            startTime: e.value,
                          });
                        }
                      }}
                    ></InputMask>
                  </div>
                </div>
              </div>
              <div className="col-span-2 lg:col-span-2">
                <div className="field">
                  <label className=" text-sm font-medium text-gray-700">Bitiş Saati</label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputMask
                      className="p-inputtext-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 block w-full rounded-md sm:text-sm h-10 "
                      id="basic"
                      mask="99:99"
                      value={selectedStudentAnnouncementClass?.endTime.toString()}
                      placeholder="00:00"
                      onChange={(e: any) => {
                        if (selectedStudentAnnouncementClass) {
                          setSelectedStudentAnnouncementClass({
                            ...selectedStudentAnnouncementClass,
                            endTime: e.value,
                          });
                        }
                      }}
                    ></InputMask>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="block text-sm font-medium text-gray-700">Anons Edilecek Sınıf</label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <DropdownComponent
                disabled={classroomDataLoading}
                id="classroomId"
                value={selectedClassroom}
                options={classroomList as any[]}
                onChange={onClassroomChange}
                optionLabel="name"
                dataKey="id"
                filter
                showClear
                filterBy="name"
                placeholder={classroomDataLoading ? "Yükleniyor.." : "Sınıf Seçiniz"}
                className="min-w-full"
                panelStyle={{ marginTop: -30 }}
                valueTemplate={selectedClassroomTemplate}
                itemTemplate={classroomOptionTemplate}
              />
            </div>
          </div>
        </div>
      </Dialog>
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default StudentCard;
