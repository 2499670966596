import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IAppSetting, IResultDto } from "../../Models";
import { AppSettingService, SmsService } from "../../Services";

// props inteface username and password
interface IProps {
  username: string;
  password: string;
  defaultSenderName: string;
  showCredit: boolean;
  setSelectedSenderName: (senderName: string) => void;
}

const CompSmsSenderList = (props: IProps) => {
  const smsService = SmsService();
  const appSettingService = AppSettingService();
  const [senderList, setSenderList] = useState<any[]>([]);
  const [selectedSender, setSelectedSender] = useState("");
  const [senderListLoading, setSenderListLoading] = useState(false);
  const [credit, setCredit] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [sendSmsSelectedSender, setSendSmsSelectedSender] = useState("");

  const [smsApiUsername, setSmsApiUsername] = useState("");
  const [smsApiPassword, setSmsApiPassword] = useState("");

  const [settingData, setSettingData] = useState<IAppSetting[]>([
    {
      id: 0,
      setKey: "",
      setValue: "",
      isActive: false,
    },
  ]);

  useEffect(() => {
    GetSettings();
  }, []);

  const GetSettings = async () => {
    setIsLoading(true);
    const { data } = await appSettingService.GetAllSetting();
    setIsLoading(false);
    if (data) {
      if (data.success) {
        const _data = data.returnData as IAppSetting[];

        if (_data) {
          setSettingData(_data);
          // console.log("=_data => ", _data);

          const username = _data.find(
            (t) => t.setKey == "SmsApiUserName"
          )?.setValue;
          const password = _data.find(
            (t) => t.setKey == "SmsApiPassword"
          )?.setValue;
          const senderName = _data.find(
            (t) => t.setKey == "SmsDefaultSender"
          )?.setValue;
          if (senderName) {
            props.setSelectedSenderName(senderName);
          }

          if (username && password) {
            setSmsApiUsername(username);
            setSmsApiPassword(password);

            await GetSenderList(username, password, senderName);
          }
        }
      } else {
        toast.error("Yüklme işleminde hata oluştu.");
        console.log(data.errorMessage);
      }
    }
  };

  const GetSenderList = async (
    username: string = "",
    password: string = "",
    sender: string = ""
  ) => {
    let _username = "";
    let _password = "";

    if (username && password) {
      _username = username;
      _password = password;
    } else {
      _username = props.username;
      _password = props.password;
    }

    if (_username && _password) {
      setSenderListLoading(true);
      const { data } = await smsService.GetSenders(_username, _password);
      setSenderListLoading(false);
      if (data) {
        const _senderList = data.data.senders;

        setSenderList(_senderList);
        GetCredit(_username, _password);
        if (sender) {
          const _sender = _senderList.find((x: any) => x.name == sender);
          if (_sender) {
            setSelectedSender(_sender);
            props.setSelectedSenderName(_sender);
          }
        }
      }
    } else {
      toast.error("Sms api bilgileri girilmelidir !");
    }
  };

  const GetCredit = async (username: string = "", password: string = "") => {
    if (username && password) {
      setSenderListLoading(true);
      const { data } = await smsService.GetCredit(username, password);
      setSenderListLoading(false);
      if (data) {
        const _credit = data as IResultDto;

        setCredit(_credit.returnData);
      }
    } else {
      toast.error("Sms api bilgileri girilmelidir !");
    }
  };

  const dropdownOptionTemplate = (option: any) => {
    return <span>{option.name}</span>;
  };

  const dropdownValueTemplate = (
    option: { name: string; code: string },
    props: any
  ) => {
    if (option) {
      return (
        <div style={{ marginTop: -4 }}>
          <span>{option.name}</span>
        </div>
      );
    }

    return (
      <div className="flex " style={{ marginTop: -4 }}>
        {props.placeholder}
      </div>
    );
  };

  return (
    <>
      <Dropdown
        disabled={senderListLoading}
        className="min-w-[220px] h-10"
        value={selectedSender}
        options={senderList}
        dataKey="id"
        onChange={(e) => {
  
          setSelectedSender(e.value);
          props.setSelectedSenderName(e.value);
        }}

        optionLabel="name"
        placeholder={
          "Yükleniyor..." ||
          senderListLoading ? "Yükleniyor..." :
          smsApiUsername && smsApiPassword
            ? "Başlık seçiniz"
            : "Tanımlı hesap yok"
        }
        valueTemplate={dropdownValueTemplate}
        itemTemplate={dropdownOptionTemplate}
      />

      <Button
        loading={senderListLoading}
        onClick={() => {
          GetSettings();
        }}
        icon={PrimeIcons.REFRESH}
        style={{ height: 42, width: 42 }}
        className={classNames(
          "p-button-rounded p-button-info ml-4",
          senderListLoading && "p-button-loading p-button-warning"
        )}
      />
      <label className="ml-3">{credit}</label>
    </>
  );
};

export default CompSmsSenderList;
