import React, { useEffect, useRef, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { ISchoolDetail } from "../../Models";
import { MasterDbService } from "../../Services/MasterDbService";
import InputComp from "../../Components/Input/InputComp";
import { AppSettingService } from "../../Services";
import { Dropdown } from "primereact/dropdown";
import ProfileImage from "../../Components/Image/ProfileImage";
import { InputSwitch } from "primereact/inputswitch";
import SkeletonCard from "../../Components/Loading/Skeleton_Card";

const SchoolCard = () => {
  const navigate = useNavigate();
  const settingService = AppSettingService();

  const btnRef: any = useRef<HTMLButtonElement>();
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [schoolData, setSchoolData] = useState<ISchoolDetail>({
    id: 0,

    address: "",
    district: "",
    province: "",
    schoolCode: "",
    schoolMobilePhone: "",
    schoolName: "",
    schoolPhone: "",
    sistemIlgiliPersonel: "",
    sistemIlgiliPersonelTelefon: "",
    mudur: "",
    mudurTelefon: "",
    mudurYardimcisi: "",
    mudurYardimcisiTelefon: "",
    createDate: new Date(),
    distance: "",
    latitude: "",
    longitude: "",
    year: "",
    workingType: "",
    dbName: "",
    cardDesingUrlPersonnelBack: "",
    cardDesingUrlPersonnelFront: "",
    cardDesingUrlStudentBack: "",
    cardDesingUrlStudentFemaleFront: "",
    cardDesingUrlStudentMaleFront: "",
    qRCodeCardDesingBack: false,
    studentPayment: 0,
    personnelPayment: 0,
  });

  const schoolWorkingType = ["Online", "Offline", "Online&Offline"];

  useEffect(() => {
    getSchoolDetail();
  }, []);

  const getSchoolDetail = async () => {
    setIsLoading(true);
    const { data, error } = await settingService.GetSchoolDetail();
    setIsLoading(false);
    if (error != null) {
      console.log(error);
    } else {
      if (data) {
        if (data.success) {
          let _data = data.returnData as ISchoolDetail;
          // console.log("_data=> ", _data);
          setSchoolData(_data);
        } else {
          toast.error(data.errorMessage);
          console.log("error Message => ", data?.errorMessage);
        }
      }
    }
  };

  const btnSaveHandle = async () => {
    btnRef.current.disabled = true;

    if (schoolData.id > 0) {
      // Güncelle
      const { data, error } = await settingService.UpdateSchoolDetail(schoolData);
      if (data) {
        if (data.success) {
          toast.success("Güncelleme başarılı.");
        } else {
          toast.error(data?.errorMessage);
        }
      }
    } else {
      // Yeni kayıt ekle
      // const { data, error } = await masterDbService.Add(schoolData);
      // if (data) {
      //   if (data.success) {
      //     navigate("/admin/schools");
      //   } else {
      //     toast.error(data?.errorMessage);
      //   }
      // }
    }

    btnRef.current.disabled = false;
  };

  const DropdownSelectedTemplate = (option: any, props: any) => {
    if (option) {
      if (option.name) {
        return (
          <div className="flex justify-between " style={{ marginTop: -4 }}>
            <span>{option.name}</span>
          </div>
        );
      } else {
        return (
          <div className="flex justify-between " style={{ marginTop: -4 }}>
            <span>{option}</span>
          </div>
        );
      }
    } else {
      return (
        <div className="flex " style={{ marginTop: -4 }}>
          {props.placeholder}
        </div>
      );
    }
  };
  const DropdownValueTemplate = (option: any) => {
    if (option.name) {
      return <span>{option.name}</span>;
    } else {
      return <span>{option}</span>;
    }
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">Okul bilgileri</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>
      {isLoading ? (
        <SkeletonCard />
      ) : (
        <div className="ml-2">
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">Okul Adı</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.schoolName}
                        placeholder="Okul adı"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            schoolName: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">Kurum kodu</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.schoolCode}
                        placeholder="Kurum Kodu"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            schoolCode: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">Okul Cep Telefon Numarası</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.schoolMobilePhone}
                        placeholder="Okul Cep Tel"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            schoolMobilePhone: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">Okul Telefon</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.schoolPhone}
                        placeholder="Okul Telefon"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            schoolPhone: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <label className="block text-sm font-medium text-gray-700">Adres</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.address}
                        placeholder="Adres"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            address: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">il</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.province}
                        placeholder="il"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            province: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">İlçe</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.district}
                        placeholder="İlçe"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            district: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">Müdür</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.mudur}
                        placeholder="Müdür Adı Soyadı"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            mudur: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">Müdür Telefon</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.mudurTelefon}
                        placeholder="Müdür Telefon"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            mudurTelefon: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">Müdür Yardımcısı</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.mudurYardimcisi}
                        placeholder="Müdür Yardımcısı Adı Soyadı"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            mudurYardimcisi: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">Müdür Yardımcısı Telefon</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.mudurYardimcisiTelefon}
                        placeholder="Müdür Yardımcısı Telefon"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            mudurYardimcisiTelefon: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">İlgili Personel</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.sistemIlgiliPersonel}
                        placeholder="İlgili Personel Adı Soyadı"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            sistemIlgiliPersonel: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">İlgili Personel Telefon</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.sistemIlgiliPersonelTelefon}
                        placeholder="İlgili Personel Telefon"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            sistemIlgiliPersonelTelefon: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">Enlem</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.latitude}
                        placeholder="Enlem"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            latitude: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">Boylam</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.longitude}
                        placeholder="Boylam"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            longitude: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">Mobil Uygulama İçin Mesafe</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.distance}
                        placeholder="Mobil Uygulama İçin Mesafe"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            distance: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">Çalışma Tipi</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <Dropdown
                        className=" w-full h-10 "
                        value={schoolData.workingType}
                        options={schoolWorkingType}
                        onChange={(e) => {
                          setSchoolData({
                            ...schoolData,
                            workingType: e.target.value,
                          });
                        }}
                        placeholder="Çalışma tipi seçiniz"
                        valueTemplate={DropdownSelectedTemplate}
                        itemTemplate={DropdownValueTemplate}
                      />
                    </div>
                  </div>


                  
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">Öğrenci Ücret</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.studentPayment}
                        placeholder="Öğrenci ücreti"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            studentPayment: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">Personel Ücret</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.personnelPayment}
                        placeholder="Personel ücreti"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            personnelPayment: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-12">
                    {/* Resim bölümü */}
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-12 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Erkek Öğrenci Önyüz</label>
                        <div className="col-span-3  border rounded-md">
                          <div className="m-1 flex justify-center">
                            <ProfileImage
                              uploadImageUrl={async (e) => {
                                setSchoolData({
                                  ...schoolData,
                                  cardDesingUrlStudentMaleFront: e,
                                });
                              }}
                              modalOpen={false}
                              folder="school"
                              value={schoolData.cardDesingUrlStudentMaleFront}
                              selectedImage={async (e) => {}}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-12 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Kız Öğrenci Önyüz</label>
                        <div className="col-span-3  border rounded-md">
                          <div className="m-1 flex justify-center">
                            <ProfileImage
                              uploadImageUrl={async (e) => {
                                setSchoolData({
                                  ...schoolData,
                                  cardDesingUrlStudentFemaleFront: e,
                                });
                              }}
                              modalOpen={false}
                              folder="school"
                              value={schoolData.cardDesingUrlStudentFemaleFront}
                              selectedImage={async (e) => {}}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-12 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Öğrenci Arkayüz</label>
                        <div className="col-span-3  border rounded-md">
                          <div className="m-1 flex justify-center">
                            <ProfileImage
                              uploadImageUrl={async (e) => {
                                setSchoolData({
                                  ...schoolData,
                                  cardDesingUrlStudentBack: e,
                                });
                              }}
                              modalOpen={false}
                              folder="school"
                              value={schoolData.cardDesingUrlStudentBack}
                              selectedImage={async (e) => {}}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-12 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Personel Önyüz</label>
                        <div className="col-span-3  border rounded-md">
                          <div className="m-1 flex justify-center">
                            <ProfileImage
                              uploadImageUrl={async (e) => {
                                setSchoolData({
                                  ...schoolData,
                                  cardDesingUrlPersonnelFront: e,
                                });
                              }}
                              modalOpen={false}
                              folder="school"
                              value={schoolData.cardDesingUrlPersonnelBack}
                              selectedImage={async (e) => {}}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-12 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Öğrenci Arkayüz</label>
                        <div className="col-span-3  border rounded-md">
                          <div className="m-1 flex justify-center">
                            <ProfileImage
                              uploadImageUrl={async (e) => {
                                setSchoolData({
                                  ...schoolData,
                                  cardDesingUrlPersonnelBack: e,
                                });
                              }}
                              modalOpen={false}
                              folder="school"
                              value={schoolData.cardDesingUrlPersonnelBack}
                              selectedImage={async (e) => {}}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-12 lg:col-span-2">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">Kart Arkasına QR Kod Bas</label>
                          <div className="flex items-center h-11 ">
                            <InputSwitch
                              onChange={(e) =>
                                setSchoolData({
                                  ...schoolData,
                                  qRCodeCardDesingBack: e.value,
                                })
                              }
                              checked={schoolData.qRCodeCardDesingBack}
                            ></InputSwitch>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <Button
                  loading={btnRef?.current?.disabled}
                  ref={btnRef}
                  onClick={() => btnSaveHandle()}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Kaydet
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default SchoolCard;
