import { ConfirmDialog } from "primereact/confirmdialog";
import { useContext, useEffect, useState } from "react";
import SkeletonList from "../../Components/Loading/Skeleton_List";
import { PaymentPlanService } from "../../Services";
import toast from "react-hot-toast";
import { IBank } from "../../Models";

import { Button } from "primereact/button";
import { AppContext } from "../../Context/Store";
import { useNavigate, useParams } from "react-router-dom";
import InputComp from "../../Components/Input/InputComp";
import { InputMask } from "primereact/inputmask";
import { Calendar } from "primereact/calendar";
import moment from "moment";

const BankCard = () => {
  const { id } = useParams<"id">();
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const paymentPlanService = PaymentPlanService();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [bankDetail, setBankDetail] = useState<IBank>({
    tanim: "",
    bankaAdi: "",
    subeAdi: "",
    subeKodu: "",
    hesapNo: "",
    musteriNo: "",
    iban: "",
    apiUsername: "",
    apiPassword: "",
    createDate: new Date(),
    id: 0,
    updateDate: new Date(),
    aliciAdi: "",
    bankHarBaslangicTarihi: new Date(),
    kacGunlukHareketCekilecek: 7,
  });

  useEffect(() => {
    if (id) {
      FindBankById(id);
    }
  }, [id]);

  const FindBankById = async (id: string) => {
    setIsLoading(true);
    const _id: number = parseInt(id);
    const { data, error } = await paymentPlanService.BankFind(_id);
    setIsLoading(false);
    if (data) {
      if (data.success) {
        console.log("FindBankById => ", data.returnData);

        const bankData = data.returnData as IBank;

        // convert datetime to date
        setBankDetail({
          ...bankData,
          bankHarBaslangicTarihi: new Date(bankData.bankHarBaslangicTarihi),
        });
      } else {
        toast.error(data?.errorMessage);
      }
    }
    if (error) {
      toast.error("Bir hata oluştu");
    }
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div>
        {/* {(UserRole.ADMIN === user.role || UserRole.SUPERADMIN === user.role) && (
          <Button
            disabled={deleteBtnProgress}
            style={{ height: 30, width: 30, marginRight: 5 }}
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger "
            onClick={(e) => DeleteHandle(e, rowData)}
          ></Button>
        )} */}
        <Button
          style={{ height: 30, width: 30, marginRight: 5 }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-warning"
          onClick={() => navigate(`/banks/${rowData.id}`)}
        />
      </div>
    );
  };

  const btnBankSaveHandle = async () => {
    // console.log("bankDetail => ", bankDetail);

    if (bankDetail) {
      if (bankDetail.id > 0) {
        const { data, error } = await paymentPlanService.BankUpdate(bankDetail);
        if (data) {
          if (data.success) {
            toast.success("Banka bilgileri başarıyla güncellendi");
            setIsUpdateData(false);
            navigate("/banks");
          } else {
            toast.error(data.errorMessage);
          }
        }
        if (error) {
          toast.error("Bir hata oluştu");
        }
      } else {
        const { data, error } = await paymentPlanService.BankAdd(bankDetail);
        if (data) {
          if (data.success) {
            toast.success("Banka bilgileri başarıyla güncellendi");
            setIsUpdateData(false);
            navigate("/banks");
          } else {
            toast.error(data.errorMessage);
          }
        }
        if (error) {
          toast.error("Bir hata oluştu");
        }
      }
    }
  };

  const formatDateForDotNetAPI = (date: Date): Date => {
    const turkeyDate = new Date(date);
    turkeyDate.setHours(0, 0, 0, 0);
    const utcDate = new Date(turkeyDate.getTime() - 3 * 60 * 60 * 1000);

    // add day 1
    utcDate.setDate(utcDate.getDate() + 1);
    return utcDate;
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">Kasa Tanım Kartı</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div className="">
            <ConfirmDialog />
            <div className=" ">
              <div className=" bg-white space-y-6 ">
                {/* banka hesap bilgileri */}

                <div className="ml-2 mt-5">
                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div className="grid grid-cols-4 gap-4">
                          {/* *Hesap / Alıcı Adı */}
                          <div className="col-span-2 lg:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">*Kasa Tanımı</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputComp
                                type="text"
                                value={bankDetail?.tanim}
                                placeholder="Alıcı / Hesap Adı"
                                onChange={(e: any) => {
                                  if (bankDetail) {
                                    setBankDetail({ ...bankDetail, tanim: e.target.value });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          {/* *Hesap / Alıcı Adı */}
                          <div className="col-span-2 lg:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">*Hesap / Alıcı Adı</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputComp
                                type="text"
                                value={bankDetail?.aliciAdi}
                                placeholder="Alıcı / Hesap Adı"
                                onChange={(e: any) => {
                                  if (bankDetail) {
                                    setBankDetail({ ...bankDetail, aliciAdi: e.target.value });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          {/* *Banka Adı */}
                          <div className="col-span-2 lg:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">*Banka Adı</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputComp
                                type="text"
                                value={bankDetail?.bankaAdi}
                                placeholder="Banka adı"
                                onChange={(e: any) => {
                                  if (bankDetail) {
                                    setBankDetail({ ...bankDetail, bankaAdi: e.target.value });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          {/* *IBAN Numarası */}
                          <div className="col-span-2 lg:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">*IBAN Numarası</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputMask
                                className="p-inputtext-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 block w-full rounded-md sm:text-sm h-10"
                                value={bankDetail?.iban}
                                onChange={(e: any) => {
                                  if (bankDetail) {
                                    setBankDetail({ ...bankDetail, iban: e.target.value });
                                  }
                                }}
                                validateOnly
                                mask="TR99 9999 9999 9999 9999 9999 99"
                                placeholder="TR99 9999 9999 9999 9999 9999 99"
                              />
                            </div>
                          </div>
                          <div className="col-span-2 lg:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Şube Adı</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputComp
                                type="text"
                                value={bankDetail?.subeAdi}
                                placeholder="Şube adı"
                                onChange={(e: any) => {
                                  if (bankDetail) {
                                    setBankDetail({ ...bankDetail, subeAdi: e.target.value });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-span-2 lg:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Şube Kodu</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputComp
                                type="text"
                                value={bankDetail?.subeKodu}
                                placeholder="Şube Kodu"
                                onChange={(e: any) => {
                                  if (bankDetail) {
                                    setBankDetail({ ...bankDetail, subeKodu: e.target.value });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-span-2 lg:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Hesap Numarası</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputComp
                                type="text"
                                value={bankDetail?.hesapNo}
                                placeholder="Hesap Numarası"
                                onChange={(e: any) => {
                                  if (bankDetail) {
                                    setBankDetail({ ...bankDetail, hesapNo: e.target.value });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-span-2 lg:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Müşteri Numarası</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputComp
                                type="text"
                                value={bankDetail?.musteriNo}
                                placeholder="Müşteri Numarası"
                                onChange={(e: any) => {
                                  if (bankDetail) {
                                    setBankDetail({ ...bankDetail, musteriNo: e.target.value });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-span-2 lg:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Api Kullanıcı Adı</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputComp
                                type="text"
                                value={bankDetail?.apiUsername}
                                placeholder="Api Kullanıcı Adı"
                                onChange={(e: any) => {
                                  if (bankDetail) {
                                    setBankDetail({ ...bankDetail, apiUsername: e.target.value });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-span-2 lg:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Api Kullanıcı Şifre</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputComp
                                type="text"
                                value={bankDetail?.apiPassword}
                                placeholder="Api Kullanıcı Şifre"
                                onChange={(e: any) => {
                                  if (bankDetail) {
                                    setBankDetail({ ...bankDetail, apiPassword: e.target.value });
                                  }
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-span-2 lg:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Hareket Çekim Baş. Tarihi</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Calendar
                                showButtonBar
                                locale="tr"
                                className="p-inputtext-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300  w-full rounded-md sm:text-sm h-10"
                                dateFormat="dd/mm/yy"
                                value={bankDetail?.bankHarBaslangicTarihi}
                                onChange={(e: any) => {
                                  const selectedDate = e.target.value;
                                  const formattedDate = formatDateForDotNetAPI(selectedDate);

                                  console.log("Seçilen tarih:", selectedDate);
                                  console.log("API için formatlanmış tarih:", formattedDate);

                                  if (bankDetail) {
                                    setBankDetail({
                                      ...bankDetail,
                                      bankHarBaslangicTarihi: formattedDate, // UI için orijinal Date objesi
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-span-2 lg:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Son kaç günlük hareket çekilecek</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputComp
                                type="text"
                                value={bankDetail?.kacGunlukHareketCekilecek}
                                placeholder="Son kaç günlük hareket çekilecek"
                                onChange={(e: any) => {
                                  if (bankDetail) {
                                    setBankDetail({ ...bankDetail, kacGunlukHareketCekilecek: e.target.value });
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-between items-center px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <div>
                          <Button
                            className="h-10"
                            //  loading={btnSaveRef.current?.disabled}
                            //  ref={btnSaveRef}
                            onClick={() => btnBankSaveHandle()}
                            label={bankDetail.id > 0 ? "Güncelle" : "Ekle"}
                          ></Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BankCard;
