import React, { useEffect, useState } from "react";
import { IParent, IPersonnel, IStaffMovement } from "../../Models";
import { PersonnelService } from "../../Services";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import Moment from "moment";
import toast from "react-hot-toast";
import SkeletonList from "../../Components/Loading/Skeleton_List";

const PersonnelMovements = () => {
  Moment.locale("tr");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const personnelService = PersonnelService();
  const [personnelList, setPersonnelList] = useState<IStaffMovement[] | null>();

  useEffect(() => {
    StudentList();
  }, []);

  const StudentList = async () => {
    setIsLoading(true);
    const { data, error } = await personnelService.PersonnelMovements();
    setIsLoading(false);
    if (data) {
      if (data.success) {
        console.log(data.returnData as IStaffMovement[]);
        setPersonnelList(data.returnData as IStaffMovement[]);
      } else {
        setPersonnelList(null);
        toast.error(data?.errorMessage);
      }
    }
  };

  const isActiveBody = (data: IParent) => {
    if (data.isActive) {
      return (
        <div className="w-14 flex justify-center items-center">
          <label className="p-badge p-badge-success ">✓</label>
        </div>
      );
    } else {
      return (
        <div className="w-14 flex justify-center items-center">
          <span className="p-badge p-badge-danger">✗</span>
        </div>
      );
    }
  };
  const createDateBody = (data: IStaffMovement) => {
    if (data.movementTime) {
      return <label>{Moment(data.movementTime).format("DD.MM.YYYY HH:mm")}</label>;
    }
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div>
        <Button
          style={{ height: 30, width: 30, marginRight: 5 }}
          icon="pi pi-eye"
          className="p-button-rounded p-button-warning"
          onClick={() => navigate(`/Personnel/${rowData.id}`)}
        />
      </div>
    );
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">Personel Hareket Listesi</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>
      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div className="ml-2">
            <div className="mt-5 md:mt-0 ">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  {personnelList && (
                    <DataTable
                      value={personnelList}
                      stripedRows
                      emptyMessage="Kayıtlı personel yok"
                      loading={isLoading}
                      scrollable
                      scrollHeight="650px"
                      responsiveLayout="scroll"
                      filterDisplay="row"
                      sortMode="multiple"
                      footer={`Toplam Kayıt Sayısı: ${personnelList.length}`}
                    >
                      <Column sortable filter className="h-11 min-w-[230px]" header="Adı Soyadı" field="personnel.fullName"></Column>
                      <Column sortable filter className="h-11 min-w-[220px] max-w-[220px]" header="İşlem Tip" field="movementType"></Column>
                      <Column sortable className="h-11 min-w-[160px]" field="movementTime" body={(data) => createDateBody(data)} header="İşlem Zamanı"></Column>

                      {/* <Column
                    className="h-11"
                    header="İşlemler"
                    body={(data) => actionBodyTemplate(data)}
                    exportable={false}
                  ></Column> */}
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PersonnelMovements;
