import toast from "react-hot-toast";
import AxiosUtility from "../Extended-tools/AxiosUtility";
import { IParent, IResultDto } from "../Models";

const ParentService = () => {
  const AddParent = async (data: IParent) => {
    try {
      const response = await AxiosUtility().post("/parent/add", data);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GetAllParents = async (desc: boolean = false) => {
    try {
      const response = await AxiosUtility().get(`/parent/All?desc=${desc}`);

      if (response.status === 200) {
        const data = response.data as IResultDto;
        // console.log('GetAllParents service response 200 ', data)

        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GetAllParentsBySchoolId = async (schoolId: number) => {
    try {
      const response = await AxiosUtility().get(`/parent/all/${schoolId}`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GetStudentList = async (parentId: number) => {
    try {
      const response = await AxiosUtility().get(`/parent/${parentId}/students`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const AddStudentParent = async (
    studentId: string,
    parentId: string,
    parentType: string
  ) => {
    try {
      const data = {
        parentType,
        studentId,
        parentId,
      };
      const response = await AxiosUtility().post(
        "/parent/AddStudentparent",
        data
      );
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const UpdateParent = async (data: IParent) => {
    try {
      const response = await AxiosUtility().put("/parent/Update", data);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const FindParentById = async (parentId: number) => {
    try {
      const response = await AxiosUtility().get(`/parent/card/${parentId}`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const FindByNameSurname = async (nameSurname: string) => {
    try {
      const response = await AxiosUtility().get(`/parent/find?nameSurname=${nameSurname}`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const FindByMobilePhone = async (mobilePhone: string) => {
    try {
      const response = await AxiosUtility().get(`/parent/find?mobilePhone=${mobilePhone}`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const DeleteStudentParentRelation = async (id: number) => {
    try {
      const response = await AxiosUtility().get(`/parent/${id}`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const GetAllStudentParents = async (desc: boolean) => {
    try {
      const response = await AxiosUtility().get(
        `/parent/AllStudentParent${desc && "?desc=true"}`
      );
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const DeleteParent = async (id: number) => {
    try {
      const response = await AxiosUtility().delete(`/parent/${id}`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const SenkronUserMap = async (parentId: number) => {
    try {
      const response = await AxiosUtility().get(
        `/parent/SenkronUserMap/${parentId}`
      );
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  return {
    AddParent,
    GetAllParentsBySchoolId,
    GetAllParents,
    FindParentById,
    UpdateParent,
    AddStudentParent,
    GetAllStudentParents,
    DeleteStudentParentRelation,
    DeleteParent,
    GetStudentList,
    SenkronUserMap,
    FindByNameSurname,
    FindByMobilePhone,

  };
};

export { ParentService };
