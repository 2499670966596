import React, { useEffect, useRef, useState } from "react";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { FileUpload } from "primereact/fileupload";
import SkeletonList from "../../Components/Loading/Skeleton_List";
import * as XLSX from "xlsx";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Steps } from "primereact/steps";
import { v4 as uuidv4 } from "uuid";
import CryptoJS from "crypto-js";
import { PaymentPlanService } from "../../Services";
import { IBank } from "../../Models";
import toast from "react-hot-toast";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ExcellBulkPayment = () => {
  const navigate = useNavigate();
  const paymentPlanService = PaymentPlanService();
  const btnRef: any = useRef<HTMLButtonElement>();

  const [isLoading, setIsLoading] = useState(false);
  const [excellUploadData, setExcellUploadData] = useState<any>([]);
  const [step2Data, setStep2Data] = useState<any>([]);
  const [step3Data, setStep3Data] = useState<any>([]);
  const [headers, setHeaders] = useState<any>([]);
  const [headersSetp2, setHeadersStep2] = useState<any>([]);
  const [step, setStep] = useState(1);

  const [selectReceiptNo, setSelectReceiptNo] = useState<any>(null);
  const [selectReceiptDate, setSelectReceiptDate] = useState<any>(null);
  const [selectReceiptDescription, setSelectReceiptDescription] = useState<any>(null);
  const [selectAmount, setSelectAmount] = useState<any>(null);

  const [banks, setBanks] = useState<IBank[] | null>([]);
  const [selectedBank, setSelectedBank] = useState<IBank>();

  useEffect(() => {
    if (step == 4) {
      LoadBanksData();
    }
    setStepActiveIndex(step - 1);
  }, [step]);

  const LoadBanksData = async () => {
    const { data, error } = await paymentPlanService.BanksList();

    if (data) {
      if (data.success) {
        setBanks(data.returnData as IBank[]);
      } else {
        setBanks(null);
        toast.error(data?.errorMessage);
      }
    }
    if (error) {
      setBanks(null);
      toast.error("Bir hata oluştu");
    }
  };

  const handleUpload = (e: any) => {
    setIsLoading(true);

    const reader = new FileReader();
    reader.readAsBinaryString(e.files[0]);
    reader.onload = (e) => {
      try {
        // @ts-ignore
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(sheet);

        const headerList = new Set(); // Set kullanarak duplicate (tekrarlayan) header'ları engelleriz
        let idCounter = 1; // ID için sayaç

        const result3 = json.map((row: any) => {
          const formattedRow: any = { id: idCounter++ }; // Her satıra artan ID ekle
          Object.keys(row).forEach((key, index) => {
            const columnName = `sütun${index + 1}`;
            formattedRow[columnName] = row[key];
            headerList.add(columnName);
          });
          return formattedRow;
        });

        setHeaders(Array.from(headerList));

        setExcellUploadData(result3);
        setStep(2);
        console.log("result3 => ", result3);
      } catch (error) {
        console.log("error => ", error);
      } finally {
        setIsLoading(false);
      }
    };
  };

  const handleStepGo2Click = () => {
    const selectedRowData = excellUploadData.find((item: any) => item.id === selectedHeaderRowIndex.id);

    console.log("selectedRowData => ", selectedRowData);

    console.log("selectedProduct =>", selectedHeaderRowIndex);

    const startIndex = excellUploadData.findIndex((item: any) => item.id === selectedHeaderRowIndex.id);
    console.log("startIndex =>", startIndex);
    var afterRowDataExcellUploadData = excellUploadData.slice(startIndex + 1);
    console.log("afterRowDataExcellUploadData =>", afterRowDataExcellUploadData);

    const headerListStep2 = new Set();
    // selectedRowData içindeki id sütunu hariç tüm sütunlar için döngü oluştur
    const updatedAfterRowDataExcellUploadData = afterRowDataExcellUploadData.map((row: any) => {
      const updatedRow: any = {};
      let columnIndex = 1;
      Object.keys(row).forEach((key) => {
        if (key !== "id") {
          const newKey = selectedRowData[`sütun${columnIndex}`] || key;
          updatedRow[newKey] = row[key];
          headerListStep2.add(newKey);
          columnIndex++;
        } else {
          updatedRow[key] = row[key]; // id sütununu koru
        }
      });
      return updatedRow;
    });

    console.log("newHeader => ", Array.from(headerListStep2));
    setHeadersStep2(Array.from(headerListStep2));

    setStep(3);

    console.log("updatedAfterRowDataExcellUploadData =>", updatedAfterRowDataExcellUploadData);
    setStep2Data(updatedAfterRowDataExcellUploadData);
  };

  const selectedRowDeleteHandler = () => {
    console.log("selectedProducts =>", selectedProducts);
    const updatedStep2Data = step2Data.filter((item: any) => !selectedProducts.includes(item));
    setStep2Data(updatedStep2Data);
  };

  const handleStepGo3Click = () => {
    let newData: any = [];

    step2Data.map((item: any) => {
      const newItem = {
        receiptNo: item[selectReceiptNo] || "",
        receiptDate: moment(item[selectReceiptDate], "DD.MM.YYYY").format("YYYY-MM-DD"),
        receiptDate2: item[selectReceiptDate],
        receiptDescription: item[selectReceiptDescription],
        amount: parseFloat(item[selectAmount]),
        amountData: item[selectAmount],
        // Amount: new Intl.NumberFormat("tr-TR", { style: "currency", currency: "TRY" }).format(item[selectAmount]),
      };

      newData.push(newItem);
    });

    const notAnyReceiptNo = newData
      .filter((item: any) => item.amount > 0)
      .map((item: any) => {
        if (!item.receiptNo) {
          item.receiptNo = generateGUID(item.receiptDate, item.receiptDescription, item.amount);
          return item;
        }
        return item;
      });

    console.log("newData=> ", newData);
    console.log("notAnyReceiptNo=> ", notAnyReceiptNo);
    // const _selectReceiptDescription = step2Data.map((item: any) => item[selectReceiptDescription]);
    // console.log("selectReceiptNo =>", selectReceiptDescription);
    // console.log("_selectReceiptDescription =>", _selectReceiptDescription);
    // const updatedStep2Data = step2Data.filter((item: any) => !selectedProducts.includes(item));
    setStep2Data(notAnyReceiptNo);
    setStep(4);
  };

  const handleStepGo4Click = async () => {
    if (!selectedBank) {
      toast.error("Lütfen bir kasa seçiniz");
      return;
    }

    const updatedData = step2Data.map((item: any) => {
      const newItem = {
        receiptNo: item.receiptNo,
        receiptDate: item.receiptDate,
        receiptDescription: item.receiptDescription,
        amount: item.amount,
        bankId: selectedBank,
        processType: "Excel",
      };
      return newItem;
    });

    console.log("updatedData =>", updatedData);

    btnRef.current.disabled = true;

    const { data, error } = await paymentPlanService.BankTransitionAddBulk(updatedData);

    if (data) {
      if (data.success) {
        const newRecord = data.returnData.newRecord;
        const anyRecord = data.returnData.anyRecord;

        const message = `${updatedData.length} adet kayıttan, ${newRecord} adet kayıt eklendi. ${anyRecord} adet kayıt daha önce eklenmiş.`;
        toast.success(message);
        navigate("/bulkPayment");
      } else {
        toast.error(data?.errorMessage);
      }
    }
    if (error) {
      toast.error("Bir hata oluştu");
    }

    setStepsReadOnly(false);

    btnRef.current.disabled = false;
    // setStep2Data(updatedData);
  };

  // Hash oluşturma fonksiyonu
  const generateGUID = (receiptDate: any, receiptDescription: any, amount: any) => {
    const hash = CryptoJS.SHA256(`${receiptDate}${receiptDescription}${amount}`);
    return hash.toString(CryptoJS.enc.Hex);
  };

  const ClearPageData = () => {
    setExcellUploadData([]);
    setStep2Data([]);
    setStep3Data([]);
    setHeaders([]);
    setHeadersStep2([]);
    setStep(1);
    setSelectReceiptNo(null);
    setSelectReceiptDate(null);
    setSelectReceiptDescription(null);
    setSelectAmount(null);
    // @ts-ignore
    setSelectedBank(null);
    setSelectedProducts(null);
    setSelectedHeaderRow(null);
  };

  const [selectedProducts, setSelectedProducts] = useState<any>(null);

  const [selectedHeaderRowIndex, setSelectedHeaderRow] = useState<any>(null);

  const stepsItems = [
    {
      label: "Excel Seç",
    },
    {
      label: "Başlık Satırını Seç",
    },
    {
      label: "Sütun eşleştir",
    },
    {
      label: "Onayla ve aktar",
    },
  ];

  const [stepsReadOnly, setStepsReadOnly] = useState(false);
  const [stepActiveIndex, setStepActiveIndex] = useState(0);
  useEffect(() => {
    console.log("stepActiveIndex => ", stepActiveIndex);
    if (stepActiveIndex == 0) {
      ClearPageData();
    }
    if (stepActiveIndex < 4) {
      setStepsReadOnly(true);
    }
  }, [stepActiveIndex]);

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-xl">Excel'den ödemeleri yükle</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div>
            <ConfirmDialog />
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="bg-white space-y-6">
                <Steps model={stepsItems} readOnly={stepsReadOnly} activeIndex={stepActiveIndex} onSelect={(e) => setStepActiveIndex(e.index)} />

                <div className="flex flex-col justify-center">
                  {step == 1 && (
                    <FileUpload
                      name="demo[]"
                      auto
                      customUpload={true}
                      uploadHandler={handleUpload}
                      maxFileSize={1000000}
                      emptyTemplate={<p className="m-0">Excel dosyasını buraya sürükleyin veya seçin!</p>}
                    />
                  )}

                  {step == 2 && excellUploadData.length > 0 && (
                    <>
                      <DataTable
                        // @ts-ignore
                        selectionMode={null}
                        selection={selectedHeaderRowIndex}
                        onSelectionChange={(e) => {
                          console.log("e =>", e);
                          setSelectedHeaderRow(e.value);
                        }}
                        dataKey="id"
                        scrollHeight="63vh"
                        scrollable
                        value={excellUploadData}
                      >
                        <Column selectionMode="single" style={{ maxWidth: 30 }}></Column>
                        <Column field={"id"} header={"id"} className="max-w-10"></Column>
                        {headers?.map((header: any, index: any) => (
                          <Column key={index} field={`${header}`} header={header}></Column>
                        ))}
                      </DataTable>
                      <Button label="Sonraki adım" className="mt-5" onClick={handleStepGo2Click} />
                    </>
                  )}

                  {step == 3 && step2Data.length > 0 && (
                    <>
                      <div>
                        <label className="text-xl block font-bold  text-red-500">* Sütunları eşleştir</label>

                        <label className="text-xl block text-red-500">* Silinecek boş veya gereksiz satır varsa seçip siliniz !</label>
                        <br />

                        <div className=" m-5">
                          <Dropdown
                            showClear
                            options={headersSetp2}
                            placeholder="Makbuz No Sütun seç"
                            value={selectReceiptNo}
                            onChange={(e) => {
                              console.log("selectReceiptNo =>", e.value);
                              setSelectReceiptNo(e.value);
                            }}
                          />

                          <Dropdown
                            showClear
                            options={headersSetp2}
                            placeholder="Ödeme Tarihi Sütun seç"
                            value={selectReceiptDate}
                            onChange={(e) => {
                              console.log("selectReceiptDate =>", e.value);
                              setSelectReceiptDate(e.value);
                            }}
                          />

                          <Dropdown
                            showClear
                            options={headersSetp2}
                            placeholder="Açıklama Sütun seç"
                            value={selectReceiptDescription}
                            onChange={(e) => {
                              console.log("selectReceiptDescription =>", e.value);
                              setSelectReceiptDescription(e.value);
                            }}
                          />

                          <Dropdown
                            showClear
                            options={headersSetp2}
                            placeholder="Tutar Sütun seç"
                            value={selectAmount}
                            onChange={(e) => {
                              console.log("selectAmount =>", e.value);
                              setSelectAmount(e.value);
                            }}
                          />

                          <Button
                            label="Seçilen satırları Sil"
                            className="mt-5"
                            onClick={() => {
                              selectedRowDeleteHandler();
                            }}
                          />
                        </div>
                      </div>

                      <DataTable
                        showSelectAll
                        rowHover
                        scrollable
                        scrollHeight="63vh"
                        stripedRows
                        size="small"
                        value={step2Data}
                        selection={selectedProducts}
                        onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id"
                      >
                        <Column selectionMode="multiple" className="max-w-10"></Column>
                        <Column field={"id"} header={"id"} className="max-w-10"></Column>
                        {headersSetp2?.map((header: any, index: any) => (
                          <Column key={index} field={`${header}`} header={header}></Column>
                        ))}
                      </DataTable>

                      <Button label="Sonraki adım" className="mt-5" onClick={handleStepGo3Click} />
                    </>
                  )}

                  {step == 4 && step2Data.length > 0 && (
                    <>
                      <label className="text-lg block font-bold m-4 text-red-500">* Son düzenlemeler yapılmıştır. Lütfen kontrol ediniz</label>
                      <DataTable value={step2Data} scrollable stripedRows size="small" scrollHeight="63vh" >
                        <Column field={"receiptDate"} header={"Tarih"} className="max-w-28"></Column>
                        <Column field={"receiptDescription"} header={"Açıklama"} className=" flex flex-1"></Column>
                        <Column field={"amount"} header={"Tutar"} className=" w-24  max-w-24 "></Column>
                        <Column field={"receiptNo"} header={"Makbuz No"} className="w-24 max-w-32 truncate hover:text-clip"></Column>
                      </DataTable>
                      <div className="m-4  p-2 border">
                        <label className="text-xl  font-bold  text-red-500 mb-2">* Hesaba ait kasayı seçiniz : </label>
                        <Dropdown
                          showClear
                          value={selectedBank}
                          onChange={(e) => setSelectedBank(e.value)}
                          options={banks ? banks.map((item) => ({ label: item.tanim, value: item.id })) : []}
                          placeholder="Kasa seç"
                        />
                      </div>
                      <Button loading={btnRef?.current?.disabled} ref={btnRef} label="Ödemeleri Aktar ve Eşleştirme Sayfasına Git" onClick={handleStepGo4Click} className="mt-5" />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ExcellBulkPayment;
