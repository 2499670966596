import React from "react";
import AxiosUtility from "../Extended-tools/AxiosUtility";
import { IResultDto, IStudent } from "../Models";
import IFileImage from "../Models/Abstract/IFileImage";

const FileUploadService = () => {
  const ImageUpload = async (
    image: File,
    folder: string,
    _onUploadProgress: any
  ) => {
    try {
      let data = new FormData();
      data.append("file", image);

      const config = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (progressEvent: any) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          _onUploadProgress(percentCompleted);
        },
      };

      const response = await AxiosUtility().post(
        "/File/AddImage?folder=" + folder,
        data,
        config
      );

      if (response.status === 200) {
        // console.log('AddQuestionImage service response 200 ', response)
        const data = response.data as IResultDto;
        return { data };
      } else {
        console.log("AddQuestionImage service response !200 ", response);
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err?.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const ImageUploadBase64 = async (
    fileImage: IFileImage,
    _onUploadProgress: any
  ) => {
    try {
      const config = {
        // headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (progressEvent: any) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          _onUploadProgress(percentCompleted);
        },
      };

      const response = await AxiosUtility().post(
        `/File/AddImageBase64`,
        fileImage,
        config
      );

      if (response.status === 200) {
        // console.log('AddQuestionImage service response 200 ', response)
        const data = response.data as IResultDto;
        return { data };
      } else {
        console.log("AddQuestionImage service response !200 ", response);
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err?.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  return { ImageUpload, ImageUploadBase64 };
};

export { FileUploadService };
