import React, { useContext, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import Loading from "../Components/Loading/Loading";
import { AppContext } from "../Context/Store";
import StaticVariable from "../Core/StaticVariable";
import CookieCrypto from "../Extended-tools/CookieCrypto";
import { IClient, IUser } from "../Models";
import Layout from "./Layout";
import Login from "./Login/Login";
import routes from "./Routes/routes";

const MainBridge = () => {
  const { user, setUser } = useContext(AppContext);
  const showRoutes = useRoutes(routes);

  const [pageLoading, setPageLoading] = useState(true);


  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.warn("Çalışma Ortamı => ", process.env.NODE_ENV);
    }

    setPageLoading(true);
    const _cookie = CookieCrypto().GetCookie();
    if (_cookie) {
      const _user: IUser = JSON.parse(_cookie);
      if (_user.userMapId > 0) {
        setUser(_user);
      } else {
        CookieCrypto().RemoveCookie();
      }
    } else {
      CookieCrypto().RemoveCookie();
    }
    setPageLoading(false);
  }, []);

  return (
    <div className="w-screen">
      <Toaster position="top-right" toastOptions={{ duration: 3000 }} />
      {pageLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <div>
            <Loading />
          </div>
        </div>
      ) : user.userMapId > 0 ? (
        <>{showRoutes}</>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default MainBridge;
