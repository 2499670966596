import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IClassroom, IParentStudentWiew, IStudentParentWiew_Sms } from "../../Models";
import { SmsService, StudentService } from "../../Services";
import toast from "react-hot-toast";
import { ProgressSpinner } from "primereact/progressspinner";
import classNames from "classnames";
import { InputTextarea } from "primereact/inputtextarea";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import CompSmsSenderList from "../../Components/Sms/SmsSenderList";
import { MultiSelect } from "primereact/multiselect";
import { FilterMatchMode } from "primereact/api";
import SkeletonCard from "../../Components/Loading/Skeleton_Card";
import SkeletonList from "../../Components/Loading/Skeleton_List";
import { Dropdown } from "primereact/dropdown";

const SendSms_Student = () => {
  const studentService = StudentService();
  const smsService = SmsService();

  const btnRef: any = useRef<HTMLButtonElement>();
  const [isLoading, setIsLoading] = useState(false);
  const [btnSaveProgress, setBtnSaveProgress] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [messageContant, setMessageContant] = useState("");
  const [ekstraNumber, setEkstraNumber] = useState("");
  const [selectedSenderNme, setSelectedSenderNme] = useState<any>();

  const [selectedData, setSelectedData] = useState<IStudentParentWiew_Sms[]>([]);
  const [classroomList, setClassroomList] = useState<any[]>();
  const [loadingDataList, setLoadingDataList] = useState(false);
  const [dataList, setDataList] = useState<IStudentParentWiew_Sms[]>();

  const [filters, setFilters] = useState<any>(null);

  const initFilters = () => {
    setFilters({
      classroomName: { value: null, matchMode: FilterMatchMode.IN, lng: "tr" },
      studentFullName: { value: null, matchMode: FilterMatchMode.CONTAINS, lng: "tr" },
      parentFullName: { value: null, matchMode: FilterMatchMode.CONTAINS, lng: "tr" },
      parentMobilePhone: { value: null, matchMode: FilterMatchMode.CONTAINS, lng: "tr" },
      parentType: { value: null, matchMode: FilterMatchMode.CONTAINS, lng: "tr" },
    });
  };

  useEffect(() => {
    GetStudentListWithParent();
    GetClassroomList();
    // Get sms api bilgileri
    // get sender List
  }, []);

  useEffect(() => {
    // console.log("selectedSenderNme effect => ", selectedSenderNme?.name);
    if ((selectedData?.length > 0 || ekstraNumber?.length > 0) && messageContant?.length > 0 && selectedSenderNme && selectedSenderNme.name?.length > 0) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }, [selectedData, selectedSenderNme, messageContant, ekstraNumber]);

  const GetStudentListWithParent = async () => {
    setIsLoading(true);
    const { data } = await studentService.StudentListWithParent();
    setIsLoading(false);
    if (data) {
      // console.log("GetStudentListWithParent data=> ", data);
      if (data.success) {
        setDataList(data.returnData as IStudentParentWiew_Sms[]);
        initFilters();
      } else {
        toast.error(data.errorMessage);
      }
    }
  };
  const GetClassroomList = async () => {
    setIsLoading(true);
    const { data } = await studentService.GetClassroomList();
    setIsLoading(false);
    if (data) {
      // console.log("GetClassroomList data=> ", data);
      if (data.success) {
        const _data = data.returnData as IClassroom[];
        // classroomNames = [{ name: "Amy Elsner" }];
        const _classroomNames = _data
          .map((item) => {
            return item.name;
          })

          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          });

        setClassroomList(_classroomNames);
      } else {
        toast.error(data.errorMessage);
      }
    }
  };

  const representativeBodyTemplate = (rowData: any) => {
    return (
      <>
        <span className="image-text">{rowData.classroomName}</span>
      </>
    );
  };

  const studentBodyTemplate = (rowData: any) => {
    return (
      <>
        <span className="image-text">{rowData.studentFullName}</span>
      </>
    );
  };

  const btnSendSmsHandle = async () => {
    let mobileNumbers = selectedData.map((x) => (x.parentMobilePhone.length > 0 ? x.parentMobilePhone : "")) as string[];

    if (ekstraNumber.length > 0) {
      const _number = ekstraNumber.split("\n");

      mobileNumbers = mobileNumbers.concat(_number);
    }

    confirmDialog({
      message: `${mobileNumbers.length} kişi için sms gönderimini onaylıyor musunuz ?`,
      header: "Onay",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject: () => {
        toast.error("Sms gönderimi iptal edildi");
      },
    });
  };

  const accept = async () => {
    let mobileNumbers = selectedData.map((x) => (x.parentMobilePhone.length > 0 ? x.parentMobilePhone : "")) as string[];

    if (ekstraNumber.length > 0) {
      const _number = ekstraNumber.split("\n");

      mobileNumbers = mobileNumbers.concat(_number);
    }


    console.log('mobileNumbers => ',mobileNumbers);
    console.log('selectedSenderNme => ',selectedSenderNme);
    console.log('messageContant => ',messageContant);

    const { data } = await smsService.SendSms(messageContant.trim(), mobileNumbers as string[], selectedSenderNme?.name);

    if (data) {
      if (data.success) {
        setMessageContant("");
        setEkstraNumber("");
        setSelectedData([]);
        toast.success("Sms gönderimi başarılı");
      } else {
        // console.log('data => ',data);
        toast.error(data.errorMessage);
      }
    }
  };

  // Data grid Column View Type  ==> Başlangıç

  const sendSms_BodyTemplate = (item: IStudentParentWiew_Sms) => {
    return (
      <div className="flex justify-center min-w-[100px] max-w-[100px]">
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle text-green-600 text-xl": item.sendSms,
            "false-icon pi-times-circle text-red-400 text-xl": !item.sendSms,
          })}
        ></i>
      </div>
    );
  };

  const representativeRowFilterTemplate = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={classroomList}
        itemTemplate={representativesItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Sınıf Seçimi"
        className="p-column-filter"
      />
    );
  };

  const representativesItemTemplate = (option: any) => {
    return (
      <div className="p-multiselect-representative-option">
        <span className="image-text">{option}</span>
      </div>
    );
  };

  const statusBodyTemplate = (rowData: any) => {
    return <span className={`customer-badge status-${rowData.classroomName}`}>{rowData.classroomName}--</span>;
  };

  const statusRowFilterTemplate = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        options={classroomList}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Sinif"
        className="p-column-filter"
        showClear
      />
    );
  };

  const statusItemTemplate = (option: any) => {
    console.log("statusItemTemplate => ", option);
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };

  // Data grid Column View Type  ==> Bitiş

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <ConfirmDialog />
      {/* {isLoading && (
        <ProgressSpinner style={{ width: "50px", height: "50px" }} />
      )} */}

      <div className="m-2 mb-5">
        <label className="text-2xl">Öğrenci velisine sms gönder</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>
      <div className="">
        {isLoading ? (
          <>
            <SkeletonCard />
            <SkeletonList />
          </>
        ) : (
          <div className="mt-2">
            <div className=" grid grid-cols-12  grid-gap-4 m-3">
              <div className="col-span-4 m-2 ">
                <label className="block text-xl font-medium text-gray-700 mb-2">Mesaj içeriği</label>
                <InputTextarea
                  value={messageContant}
                  onChange={(e) => {
                    setMessageContant(e.target.value);
                  }}
                  rows={8}
                  cols={30}
                  style={{ height: 185, width: "100%" }}
                />
              </div>
              <div className="col-span-4 m-2 ">
                <label className=" text-xl font-medium text-gray-700 mb-2">Ekstra numara</label>
                <InputTextarea
                  value={ekstraNumber}
                  onChange={(e) => {
                    setEkstraNumber(e.target.value);
                  }}
                  rows={8}
                  cols={30}
                  style={{ height: 185, width: "100%", marginTop: 8 }}
                />
              </div>
              <div className="col-span-4 mt-12 ml-6 ">
                <CompSmsSenderList
                  username=""
                  password=""
                  defaultSenderName=""
                  showCredit={false}
                  setSelectedSenderName={(senderName) => {
                    setSelectedSenderNme(senderName);
                  }}
                />
                <div className="mt-6">
                  <Button
                    ref={btnRef}
                    loading={btnSaveProgress}
                    disabled={btnDisable}
                    className="h-10"
                    onClick={() => {
                      btnSendSmsHandle();
                    }}
                    label="Sms Gönder"
                  ></Button>
                </div>
              </div>
            </div>

            <div className="grid gap-1 ">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className=" bg-white space-y-6 ">
                  {dataList && (
                    <DataTable
                      value={dataList}
                      filters={filters}
                      stripedRows
                      scrollable
                      rowGroupMode="subheader"
                      groupRowsBy="studentFullName"
                      sortMode="single"
                      sortField="studentFullName"
                      sortOrder={1}
                      responsiveLayout="scroll"
                      selectionMode="checkbox"
                      selection={selectedData}
                      scrollHeight="650px"
                      onSelectionChange={(e) => setSelectedData(e.value)}
                      loading={isLoading}
                      filterDisplay="row"
                      footer={`Toplam Öğrenci Veli Sayısı: ${dataList.length} - Seçilen Öğrenci Veli Sayısı: ${selectedData.length}`}
                    >
                      <Column style={{ width: "200px" }} className=" max-w-[40px]" headerClassName="w-4" selectionMode="multiple" headerStyle={{ width: "3em" }}></Column>

                      <Column
                        showFilterMenu={false}
                        showFilterMatchModes={false}
                        showFilterMenuOptions={false}
                        showFilterOperator={false}
                        showClearButton={false}
                        filterMatchMode="contains"
                        filter={true}
                        style={{ width: "200px" }}
                        field="studentFullName"
                        header="Öğrenci"
                        body={studentBodyTemplate}
                      ></Column>

                      <Column
                        showFilterMenu={false}
                        showFilterMatchModes={false}
                        showFilterMenuOptions={false}
                        showFilterOperator={false}
                        showClearButton={false}
                        filterMatchMode="contains"
                        filter
                        style={{ width: "200px" }}
                        field="parentFullName"
                        header="Velisi"
                      ></Column>

                      <Column
                        showFilterMenu={false}
                        showFilterMatchModes={false}
                        showFilterMenuOptions={false}
                        showFilterOperator={false}
                        showClearButton={false}
                        filterMatchMode="contains"
                        filter
                        style={{ width: "200px" }}
                        field="parentType"
                        header="Yakınlığı"
                      ></Column>

                      <Column
                        showFilterMenu={false}
                        showFilterMatchModes={false}
                        showFilterMenuOptions={false}
                        showFilterOperator={false}
                        showClearButton={false}
                        filterMatchMode="contains"
                        filter
                        style={{ width: "200px" }}
                        field="parentMobilePhone"
                        header="Veli Telefon"
                      ></Column>

                      <Column
                        header="Sınıf"
                        filterField="classroomName"
                        showFilterMenu={false}
                        filterMenuStyle={{ width: "14rem" }}
                        style={{ minWidth: "14rem" }}
                        body={representativeBodyTemplate}
                        filter
                        filterElement={representativeRowFilterTemplate}
                      />

                      <Column style={{ width: "200px" }} header="Sms Gönder ?" field="sendSms" body={sendSms_BodyTemplate} />
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default SendSms_Student;
