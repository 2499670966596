import React, { useEffect, useRef, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import InputComp from "../../Components/Input/InputComp";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { IClassroom, IClient } from "../../Models";
import { ClientService, StudentService } from "../../Services";
import { MultiSelect } from "primereact/multiselect";
import { useNavigate, useParams } from "react-router-dom";
import { Password } from "primereact/password";
import "../Styles/PasswordDemo.css";
import toast from "react-hot-toast";
import SkeletonCard from "../../Components/Loading/Skeleton_Card";
const ClientCard = () => {
  let kurumKodu = "070707";

  const studentService = StudentService();
  const clientService = ClientService();
  const { id } = useParams<"id">();
  const navigate = useNavigate();

  const btnRef: any = useRef<HTMLButtonElement>();
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientData, setClientData] = useState<IClient>({
    id: 0,
    clientType: "Sınıf",
    classroomIds: "",
    locationDescription: "",
    displayName: "",
    settingJson: "",
    userName: "",
    password: "",
    isMapped: false,
  });
  const [classroomList, setClassroomList] = useState<IClassroom[] | null>();
  const [classroomDataLoading, setClassroomDataLoading] = useState(false);
  const [selectedClassroom, setSelectedClassroom] = useState<IClassroom[]>();

  useEffect(() => {
    GetClassroomList().then(() => {});
  }, []);

  useEffect(() => {
    if (!id) {
      setClientData({
        id: 0,
        clientType: "Sınıf",
        classroomIds: "",
        locationDescription: "",
        displayName: "",
        settingJson: "",
        userName: "",
        password: "",
        isMapped: false,
      });
      setSelectedClassroom([]);
    }
  }, [id]);

  const findClient_ById = async (userId: number, classroomData: IClassroom[]) => {
    if (userId) {
      setIsLoading(true);
      const { data, error } = await clientService.FindClientById(userId);
      setIsLoading(false);
      if (error != null) {
        console.log(error);
      } else {
        if (data) {
          if (data.success) {
            let _data = data.returnData as IClient;

            if (_data) {
              if (_data.userName) {
                _data.userName = _data.userName.split("@")[0];
              }
              setClientData(_data);
              var ids = _data.classroomIds.split(",");
              if (classroomData) {
                setSelectedClassroom(classroomData.filter((item) => ids.includes(item.id.toString())));
              }
            }
          } else {
            toast.error(data?.errorMessage);
          }
        }
      }
    } else {
      toast.error("Yıldızlı alanları doldurunuz !");
    }
  };

  const GetClassroomList = async () => {
    setClassroomDataLoading(true);
    const { data, error } = await studentService.GetClassroomList();
    setClassroomDataLoading(false);
    if (data) {
      if (data.success) {
        const _data = data.returnData as IClassroom[];
        setClassroomList(_data);
        if (id && Number.parseInt(id) > 0) {
          findClient_ById(Number.parseInt(id), _data);
        }
      } else {
        toast.error(`Sınıf listesi yüklenirken hata oluştu ${data?.errorMessage}`);
      }
    }
  };

  const btnSaveHandle = async () => {
    btnRef.current.disabled = true;

    let _clientData = clientData;
    if (selectedClassroom) {
      _clientData.classroomIds = selectedClassroom?.map((item) => item.id).join(",");
    } else {
      _clientData.classroomIds = "";
    }

    if (clientData.userName) {
      _clientData.userName = clientData.userName + "@" + kurumKodu;
    }

    console.log("_clientData => ", _clientData);

    if (id) {
      // Güncelle
      const { data, error } = await clientService.Update(_clientData);

      if (data) {
        if (data.success) {
          navigate("/clients");
        } else {
          toast.error(data?.errorMessage);
        }
      }
    } else {
      // Yeni kayıt ekle

      const { data, error } = await clientService.Add(_clientData);
      if (data) {
        if (data.success) {
          navigate("/clients");
        } else {
          toast.error(data?.errorMessage);
        }
      }
    }

    btnRef.current.disabled = false;
  };

  const clientOptionTemplate = (option: any) => {
    return <span>{option}</span>;
  };

  const selectedClintTypeTemplate = (option: string, props: any) => {
    if (option) {
      return (
        <div className="flex justify-between " style={{ marginTop: -4 }}>
          <span>{option}</span>
        </div>
      );
    }
    return (
      <div className="flex " style={{ marginTop: -4 }}>
        {props.placeholder}
      </div>
    );
  };

  const onClassroomChange = (e: { value: any }) => {
    if (e.value) {
      setSelectedClassroom(e.value);
    }
  };

  const classroomOptionTemplate = (option: any) => {
    return <span>{option.name}</span>;
  };

  const selectedCountriesTemplate = (option: any) => {
    if (option) {
      return (
        <div className="inline-flex rounded mr-2 bg-[#EEF2FF] ">
          {/* <img
            alt={option.name}
            src="images/flag/flag_placeholder.png"
            onError={(e: any) =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            className={`flag flag-${option.code.toLowerCase()}`}
          /> */}
          <div className="text-[#584ECF] mx-2">{option.name}</div>
        </div>
      );
    }
    return <div className=" -mt-[6px]">Sınıf Seçiniz</div>;
  };
  const panelFooterTemplate = () => {
    const selectedItems = selectedClassroom;
    const length = selectedItems ? selectedItems.length : 0;
    return (
      <div className="py-2 px-3">
        <b>{length}</b> client{length > 1 ? "s" : ""} seçildi
      </div>
    );
  };

  const clientType = ["Sınıf", "Kiosk"];

  const clientTypeOptionTemplate = (option: any) => {
    return <span>{option}</span>;
  };

  const clientTypeTemplate = (option: string, props: any) => {
    if (option) {
      return (
        <div className="flex justify-between " style={{ marginTop: -4 }}>
          <span>{option}</span>
        </div>
      );
    }
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">{id ? "Client Güncelle" : "Yeni Client ekle"}</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      {isLoading ? (
        <SkeletonCard />
      ) : (
        <>
          <div className="ml-2">
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-4 lg:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">Derslik Adı (papatya sınıfı, kırmızı sınıfı vb...) :</label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <InputComp
                          type="text"
                          value={clientData.displayName}
                          placeholder="Görünen Ad"
                          onChange={(e) => {
                            setClientData({
                              ...clientData,
                              displayName: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-span-4 lg:col-span-2">
                      <div className="col-span-3 sm:col-span-2">
                        <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                          Kullanıcı Adı :
                        </label>
                        <div className="mt-1 flex  shadow-sm">
                          <InputComp
                            type="text"
                            disabled
                            value={clientData.userName}
                            placeholder="Kullanıcı Adı"
                            onChange={(e) => {
                              setClientData({
                                ...clientData,
                                userName: e.target.value,
                              });
                            }}
                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full sm:text-sm rounded-none rounded-l-md"
                          />
                          <span className="w-40 inline-flex items-center px-3 rounded-r-md border border-gray-300 bg-gray-50 text-gray-500 text-sm">@{kurumKodu}</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-4 lg:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Anons edilecek sınıfı seçiniz: (<b>{selectedClassroom?.length}</b> seçildi)
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <MultiSelect
                          value={selectedClassroom}
                          options={classroomList as any[]}
                          onChange={onClassroomChange}
                          optionLabel="name"
                          placeholder={classroomDataLoading ? "Yükleniyor.." : "Sınıf Seçiniz"}
                          display="chip"
                          dataKey="id"
                          className="min-w-full"
                          //   selectedItemTemplate={selectedClassroomTemplate}

                          selectedItemTemplate={selectedCountriesTemplate}
                          panelFooterTemplate={panelFooterTemplate}
                          style={{ height: 38 }}
                          itemTemplate={classroomOptionTemplate}
                        />
                      </div>
                    </div>

                    <div className="col-span-4 lg:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">Şifre :</label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <Password
                          className="min-w-full h-[38px]"
                          feedback={false}
                          value={clientData.password}
                          toggleMask
                          onChange={(e) =>
                            setClientData({
                              ...clientData,
                              password: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-span-4 lg:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">Yer Tanımı :</label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <InputComp
                          type="text"
                          value={clientData.locationDescription}
                          placeholder="Sınıf yer tanımı (kat, konum vb..)"
                          onChange={(e) => {
                            setClientData({
                              ...clientData,
                              locationDescription: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-span-4 lg:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">Client Tipi :</label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <Dropdown
                          value={clientData.clientType}
                          options={clientType}
                          onChange={(e) => {
                            setClientData({
                              ...clientData,
                              clientType: e.value,
                            });
                          }}
                          optionLabel="name"
                          placeholder="Okul Durumu Seçiniz"
                          className="min-w-full h-10"
                          valueTemplate={clientTypeTemplate}
                          itemTemplate={clientTypeOptionTemplate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <Button
                    loading={btnRef?.current?.disabled}
                    ref={btnRef}
                    onClick={() => btnSaveHandle()}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Kaydet
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ClientCard;
