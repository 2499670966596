import axios from "axios";
import toast from "react-hot-toast";
import { NavigateFunction, useNavigate } from "react-router-dom";
import StaticVariable from "../Core/StaticVariable";
import CookieCrypto from "./CookieCrypto";

const AxiosUtility = (navigate: any = null) => {
  const isProduction = `${StaticVariable.isPorduct}`;
  // console.log('isProduction=> ',isProduction);

  var url = isProduction === "true" ?`${StaticVariable.apiUrl}` :`${StaticVariable.localUrl}`;

  const instance = axios.create({
    baseURL: url,
  });

  const _cookie = CookieCrypto().GetCookie();
  if (_cookie) {
    var jso = JSON.parse(_cookie);

    instance.defaults.headers.common["Authorization"] = `Bearer ${jso.token}`;
  }

  instance.interceptors.response.use(
    (response) => {
      // console.log("response => ", response);
      return response;
    },
    (error) => {

      if (error.response.status === 401) {
        toast.error("Oturum süresi dolmuştur. Lütfen tekrar giriş yapınız.");
        window.location.href = "/auth/login";
      } else {
        console.log("error => ", JSON.stringify(error));

        if (error.message) {
          toast.error("Hata : " + error.message);
        }

        if (error.response.data && error.response.data.message && error.response.data.title) {
          console.log("error.response.data => ", error.response.data);
          toast.error("Data : " + JSON.stringify(error.response.data.title) + "\r\n" + JSON.stringify(error.response.data.errors));
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default AxiosUtility;
