import React, { useContext, useEffect, useRef, useState } from "react";
import InputComp from "../../Components/Input/InputComp";
import { IParent, UserRole } from "../../Models";
import { ParentService } from "../../Services";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";
import toast from "react-hot-toast";
import { AppContext } from "../../Context/Store";
import moment from "moment";
import { ContextMenu } from "primereact/contextmenu";
import { FilterMatchMode } from "primereact/api";
import SkeletonList from "../../Components/Loading/Skeleton_List";

const ParentList = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const parentService = ParentService();
  const [parentList, setParentList] = useState<IParent[] | null>();
  const [deleteButtonProgress, setDeleteButtonProgress] = useState(false);
  const [menuSenkronProgress, setMenuSenkronProgress] = useState(false);
  const cm: any = useRef(null);
  const [selectedParent, setSelectedParent] = useState<any>(null);
  const [filters1, setFilters1] = useState<any>(null);

  useEffect(() => {
    getParentList();
  }, []);

  const getParentList = async () => {
    setIsLoading(true);
    const { data, error } = await parentService.GetAllParents(true);
    setIsLoading(false);
    if (data) {
      if (data.success) {
        // console.log('data => ', data.returnData);
        setParentList(data.returnData as IParent[]);
        initFilters1();
      } else {
        setParentList(null);
        toast.error(data?.errorMessage);
      }
    }
  };

  const isActiveBody = (data: IParent) => {
    if (data.isActive) {
      return (
        <div className="w-14 flex justify-center items-center">
          <label className="p-badge p-badge-success ">✓</label>
        </div>
      );
    } else {
      return (
        <div className="w-14 flex justify-center items-center">
          <span className="p-badge p-badge-danger">✗</span>
        </div>
      );
    }
  };

  const isUseMobileAppBody = (data: IParent) => {
    if (data.isUseMobileApp) {
      return (
        <div className="w-14 flex justify-center items-center">
          <label className="p-badge p-badge-success ">✓</label>
        </div>
      );
    } else {
      return (
        <div className="w-14 flex justify-center items-center">
          <span className="p-badge p-badge-danger">✗</span>
        </div>
      );
    }
  };

  const acceptDeleteHandle = async (e: any, item: IParent) => {
    const { data, error } = await parentService.DeleteParent(item.id);

    if (data) {
      // console.log("data=> ", data);
      if (data.success) {
        toast.success(`${item.name} ${item.surname} silindi`);
        getParentList();
      } else {
        toast.error(data.errorMessage);
      }
    }
  };

  const DeleteHandle = async (e: any, item: IParent) => {
    confirmPopup({
      target: e.currentTarget,
      message: `${item.name} ${item.surname} kaydın silmek istiyor musunuz ?`,
      icon: "pi pi-times-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => acceptDeleteHandle(e, item),
    });
  };

  const actionBodyTemplate = (rowData: IParent) => {
    return (
      <div className="flex items-center justify-center min-w-[60px]">
        <Button
          style={{ height: 30, width: 30, marginRight: 5 }}
          icon="pi pi-eye"
          className="p-button-rounded p-button-warning "
          onClick={() => navigate(`/parent/${rowData.id}`)}
        />

        {(UserRole.ADMIN === user.role || UserRole.SUPERADMIN === user.role) && (
          <Button
            style={{ height: 30, width: 30, marginRight: 5 }}
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger "
            onClick={(e) => DeleteHandle(e, rowData)}
          ></Button>
        )}
      </div>
    );
  };

  const createDateBody = (data: IParent) => {
    if (data.createdDate) {
      return <label>{moment(data.createdDate).format("DD.MM.YYYY")}</label>;
    }
  };

  const senktronMobile = async (single: boolean = false) => {
    setMenuSenkronProgress(true);
    if (single) {
      const { data } = await parentService.SenkronUserMap(selectedParent.id);
      if (data) {
        if (data.success) {
          toast.success(data.successMessage);
        } else {
          toast.error(data.errorMessage);
        }
      }
    } else {
      if (parentList) {
        if (parentList.length > 0) {
          for (let index = 0; index < parentList.length; index++) {
            const element = parentList[index];
            const { data } = await parentService.SenkronUserMap(element.id);
            if (data) {
              if (data.success) {
                toast.success(`${index + 1}/${parentList.length} ${data.successMessage}`);
              } else {
                toast.error(data.errorMessage);
              }
            }
          }
        }
      }
    }

    setMenuSenkronProgress(false);
  };

  const initFilters1 = () => {
    setFilters1({
      fullName: { value: null, matchMode: FilterMatchMode.CONTAINS ,lng : "tr" },
      "classroom.name": { value: null, matchMode: FilterMatchMode.CONTAINS,lng : "tr"  },
    });
  };

  const menuModel = [
    {
      label: `${selectedParent && selectedParent?.fullName} kişisini mobil için aktar`,
      icon: menuSenkronProgress ? "pi pi-spin pi-spinner" : "pi pi-arrow-up-right",
      command: () => {
        // toast.success("JSON.stringify(selectedProduct)");
        senktronMobile(true);
        // toast.success("Özellik yakında eklenecek");
      },
    },
    {
      label: "Tümünü mobil için aktar",
      icon: menuSenkronProgress ? "pi pi-spin pi-spinner" : "pi pi-angle-double-up",
      command: () => {
        senktronMobile(false);
      },
    },
  ];

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">Veli Listesi</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      <ConfirmPopup />
      <ContextMenu className="w-1/5" model={menuModel} ref={cm} onHide={() => setSelectedParent(null)} />

      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div className="ml-2">
            <div className="mt-5 md:mt-0 ">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  {parentList && (
                    <DataTable
                      onContextMenuSelectionChange={(e) => {
                        setSelectedParent(e.value);
                      }}
                      onContextMenu={(e: any) => {
                        if (e.originalEvent.ctrlKey) {
                          cm.current?.show(e.originalEvent);
                        } else {
                          cm.current?.show(e.originalEvent.defaultPrevented);
                        }
                      }}
                      value={parentList}
                      stripedRows
                      emptyMessage="Kayıtlı veli yok"
                      loading={isLoading}
                      scrollable
                      responsiveLayout="scroll"
                      scrollHeight="650px"
                      filterDisplay="row"
                      scrollDirection="vertical"
                      columnResizeMode="fit"
                      size="small"
                      footer={`Toplam Kayıt Sayısı: ${parentList.length}`}
                      virtualScrollerOptions={{ itemSize: 40 }}
                    >
                      {/* <Column
                    className="min-w-[50px] max-w-[50px]"
                    sortable
                    field="id"
                    header="#"
                  ></Column> */}
                      <Column
                        className="min-w-[250px]"
                        sortable
                        filter
                        filterMatchMode="contains"
                        showFilterMenu={false}
                        showClearButton={false}
                        field="fullName"
                        header="Adı Soyadı"
                      ></Column>

                      <Column
                        filter
                        filterMatchMode="contains"
                        showFilterMenu={false}
                        showClearButton={false}
                        sortable
                        field="mobilePhone"
                        header="Telefon"
                        className="min-w-[220px]"
                      ></Column>
                      <Column field="isActive" body={(data) => isActiveBody(data)} header="Aktiflik"></Column>
                      {/* <Column
                    field="isUseMobileApp"
                    body={(data) => isUseMobileAppBody(data)}
                    header="Mobil Kullanım"
                  ></Column> */}
                      <Column sortable field="createdDate" body={(data) => createDateBody(data)} header="Eklenme Tar."></Column>
                      <Column header="İşlemler" body={(data) => actionBodyTemplate(data)} exportable={false}></Column>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ParentList;
