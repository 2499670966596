import { Skeleton } from "primereact/skeleton";

const SkeletonList = () => {
  return (
    <div className="card">
      <div className="field col-12 md:col-6">
        <div className="custom-skeleton p-4">
          <ul className="m-0 p-0">
            <li className="mb-3">
              <div className="flex">
                <Skeleton
                  shape="circle"
                  size="4rem"
                  className="mr-2"
                ></Skeleton>
                <div style={{ flex: "1" }}>
                  <Skeleton width="100%" className="mb-2"></Skeleton>
                  <Skeleton width="75%"></Skeleton>
                </div>
              </div>
            </li>
            <li className="mb-3">
              <div className="flex">
                <Skeleton
                  shape="circle"
                  size="4rem"
                  className="mr-2"
                ></Skeleton>
                <div style={{ flex: "1" }}>
                  <Skeleton width="100%" className="mb-2"></Skeleton>
                  <Skeleton width="75%"></Skeleton>
                </div>
              </div>
            </li>
            <li className="mb-3">
              <div className="flex">
                <Skeleton
                  shape="circle"
                  size="4rem"
                  className="mr-2"
                ></Skeleton>
                <div style={{ flex: "1" }}>
                  <Skeleton width="100%" className="mb-2"></Skeleton>
                  <Skeleton width="75%"></Skeleton>
                </div>
              </div>
            </li>
            <li>
              <div className="flex">
                <Skeleton
                  shape="circle"
                  size="4rem"
                  className="mr-2"
                ></Skeleton>
                <div style={{ flex: "1" }}>
                  <Skeleton width="100%" className="mb-2"></Skeleton>
                  <Skeleton width="75%"></Skeleton>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SkeletonList;
