import { InputText, InputTextProps } from "primereact/inputtext";
import { classNames } from "primereact/utils";

const InputComp = ({ ...props }: InputTextProps) => {
  const clss = props.className;
  return (
    <InputText
      {...props}
      className={classNames(
        ` p-inputtext-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 block w-full rounded-md sm:text-sm h-10 ${clss}`
      )}
    />
  );
};

export default InputComp;
