import React from "react";
import AxiosUtility from "../Extended-tools/AxiosUtility";
import { IResultDto, ISchoolAdmin } from "../Models";

const MasterDbService = () => {
  const Add = async (data: ISchoolAdmin) => {
    try {
      const response = await AxiosUtility().post("/admin/school/Add", data);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const FindClientById = async (schoolId: number) => {
    try {
      const response = await AxiosUtility().get(`/admin/school/?id=${schoolId}`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const Update = async (data: ISchoolAdmin) => {
    // console.log("running UpdateClassroom");
    try {
      const response = await AxiosUtility().put("/admin/school/Update", data);

      if (response.status === 200) {
        // console.log("UpdateClassroom service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('UpdateClassroom service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GetSchoolList = async () => {
    try {
      const response = await AxiosUtility().get(`/admin/school/all`);

      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const DownloadDatabaseFile = async (schoolId: number, kurumKodu: string, okulAdı: string) => {
    try {
      const response = await AxiosUtility().get(`/admin/school/downloadDbFile/${schoolId}`, {
        responseType: "blob",
      });

      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${kurumKodu}-${okulAdı}.txt`); //or any other extension
        document.body.appendChild(link);
        link.click();

        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const CreateDatabaseOnServer = async (schoolId: number) => {
    try {
      const response = await AxiosUtility().get(`/admin/school/CreateDb/${schoolId}`);

      if (response.status === 200) {
        const data = response.data as IResultDto;
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GetMebSchoolList = async (il: string, ilce: string, okulTip: string) => {
    try {
      const response = await AxiosUtility().get(`/admin/schools/meb?il=${il}&ilce=${ilce}&okulTip=${okulTip}`);

      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GetIlData = async () => {
    try {
      const response = await AxiosUtility().get(`/admin/schools/il`);

      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GetIlceData = async (ilName: string) => {
    try {
      const response = await AxiosUtility().get(`/admin/schools/ilce?il=${ilName}`);

      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };





  return { GetIlData, GetIlceData, GetMebSchoolList, GetSchoolList, Add, Update, FindClientById, DownloadDatabaseFile, CreateDatabaseOnServer };
};

export { MasterDbService };
