import React, { useState, useRef, useEffect, useMemo, memo } from "react";

import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import { Slider } from "primereact/slider";
import { Image } from "primereact/image";

import "react-image-crop/dist/ReactCrop.css";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { FileUploadService } from "../../Services";
import { setEmitFlags } from "typescript";
import IFileImage from "../../Models/Abstract/IFileImage";
import "./style.scss";
import toast from "react-hot-toast";

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

let _selectFile: File;

interface ProfileImageProps {
  value: string | "";
  folder: string | "";
  selectedImage: (url: string) => {};
  uploadImageUrl: (url: string) => {};
  modalOpen: boolean;

  OpenedFormFileUrl?: (url: string) => {};
  lastOpenFormFileUrl?: string | "";
}

const ProfileImage = (prop: ProfileImageProps) => {
  const fileUploadService = FileUploadService();
  const folderName = prop.folder;

  const [imgSrc, setImgSrc] = useState<any>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  // const [selectFile, setSelectFile] = useState<File>();
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState<any>(0.5);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(undefined);
  const [openDialog, setOpenDialog] = useState(false);
  const [image, setImage] = useState<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [oldImgSrc, setOldImgSrc] = useState("");
  const [complateUpload, setComplateUpload] = useState(false);

  let _file: File;

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.onloadend = () => {
        setImgSrc(reader.result?.toString());
        setImage(reader.result);
        if (!prop.modalOpen) {
          if (prop.OpenedFormFileUrl) {
            prop?.OpenedFormFileUrl(reader.result ? reader.result?.toString() : "");
          }

          addImageQuestion();
        } else {
          setOpenDialog(true);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const _onUploadProgressHandle = (progress: any) => {
    setUploadProgress(progress);
  };

  useEffect(() => {
    if (openDialog) {
      setScale(0.8);
    }
  }, [openDialog]);

  const addImageQuestion = async (base64Image: string = "") => {
    if (!_selectFile && image) {
      return;
    }
    if (prop.modalOpen === false) {
      const { data, error } = await fileUploadService.ImageUpload(_selectFile, folderName, _onUploadProgressHandle);
      if (error) {
        console.log("error addImageQuestion=> ", error);
      } else if (data?.success) {
        // console.log("data addImageQuestion=> ", data);
        setUploadSuccess(true);
        prop.uploadImageUrl(data.returnData);
        prop.selectedImage(data.returnData);
      }
    } else {
      var fileImage: IFileImage = {
        fileName: _selectFile.name,
        fileType: _selectFile.type,
        fileSize: _selectFile.size,
        fileBase64: base64Image.split(",")[1],
        fileExtension: _selectFile.name.split(".")[1],
        folder: folderName,
      };

      _file = new File([base64Image], _selectFile.name, {
        type: _selectFile.type,
      });

      const { data, error } = await fileUploadService.ImageUploadBase64(fileImage, _onUploadProgressHandle);

      if (error) {
        console.log("error=> ", error);
      } else if (data?.success) {
        setComplateUpload(true);
        setUploadSuccess(true);

        if (prop.OpenedFormFileUrl) {
          prop?.OpenedFormFileUrl(imgSrc);
        }
        setOldImgSrc(data.returnData);
        prop.uploadImageUrl(data.returnData);
        prop.selectedImage(data.returnData);
      } else {
        prop.modalOpen = false;
      }
    }
  };

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const Clear = () => {
    setImgSrc(null);
    setOpenDialog(false);
    setImage(null);
  };

  useEffect(() => {
    // console.log("render ProfileImage => ", prop.value);
    // console.log("render ProfileImage image => ", oldImgSrc);

    if (prop.value && prop.value.length > 0) {
      if (!image) {
        setImage(prop.value);
        setOldImgSrc(prop.value);
      }
    } else {
      // setImage(require("../../Assets/Img/noimage.jpg"));
      setOldImgSrc("");
      setImage(null);
    }

    if (!prop.lastOpenFormFileUrl && prop.modalOpen && prop.value != oldImgSrc && !complateUpload) {
      setOpenDialog(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop.value]);

  // useEffect(() => {
  //   console.log("render ProfileImage => ", prop.value);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [prop.value]);

  const renderFooter = () => {
    return (
      <div className="-mb-4">
        <Button label="Yeni resim seç" icon="pi pi-times" onClick={() => fileInputRef.current?.click()} className="p-button-text" />
        <Button label="İptal" icon="pi pi-times" onClick={() => setOpenDialog(false)} autoFocus />
        <Button
          label="İşlemi tamamla"
          icon="pi pi-check"
          onClick={() => {
            const base64Image = previewCanvasRef.current?.toDataURL("image/jpeg");
            setImage(base64Image);
            setOpenDialog(false);
            addImageQuestion(base64Image || "");
          }}
          autoFocus
        />
      </div>
    );
  };

  return (
    <div>
      <div className="Crop-Controls">
        <input
          type="file"
          ref={fileInputRef}
          className="form-control"
          style={{ display: "none" }}
          placeholder=""
          name="aoption"
          accept="image/png, image/jpeg, image/jpg"
          onChange={(event) => {
            const file = event.target.files ? event.target.files[0] : "";
            if (file && file.type.substring(0, 5) === "image") {
              _selectFile = file;
              setImgSrc(null);
              onSelectFile(event);

              _file = file;

              event.target.value = "";
            } else {
              toast.error("Lütfen resim seçiniz.");
            }
          }}
        />
      </div>
      {Boolean(imgSrc) && (
        <Dialog visible={openDialog} style={{ width: "60vw", height: "80vh" }} onHide={() => setOpenDialog(false)} footer={() => renderFooter()}>
          <view style={{ display: "flex", flexDirection: "column" }}>
            <view style={{ marginBottom: 10 }}>
              <label onClick={(e) => setRotate((rotate) => rotate - 90)} style={{ fontWeight: "bold", margin: 20, cursor: "pointer" }}>
                Sağa Döndür
              </label>
              <label onClick={(e) => setRotate((rotate) => rotate + 90)} style={{ fontWeight: "bold", cursor: "pointer" }}>
                Sola Döndür
              </label>
            </view>
            <ReactCrop style={{ position: "relative" }} crop={crop} onChange={(crop, percentCrop) => setCrop(percentCrop)} onComplete={(c) => setCompletedCrop(c)} aspect={aspect}>
              <img
                ref={imgRef}
                src={imgSrc}
                style={{
                  transform: `scale(${scale}) rotate(${rotate}deg)    `,
                }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
            <Slider
              style={{
                position: "absolute",
                left: "10%",
                right: "10%",
                bottom: "15%",
                width: "80%",
              }}
              value={scale}
              step={0.05}
              min={0.1}
              max={2}
              onChange={(e) => {
                setScale(e.value);
              }}
            />
          </view>

          <div>
            {Boolean(completedCrop) && (
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: "1px solid black",
                  objectFit: "contain",
                  width: 0,
                  height: 0,
                  marginBottom: 30,
                }}
              />
            )}
          </div>
        </Dialog>
      )}
      {image ? (
        <div className="relative ">
          <img
            style={{
              borderWidth: 1,
              borderRadius: 8,
              borderColor: "#EAEAEA",
            }}
            alt=""
            onError={(e) => {
              // setErrorImage(true);
              // prop.selectedImage("");
            }}
            className="w-44 h-[174px]"
            src={image}
          />

          <button
            style={{
              position: "absolute",
              right: -10,
              top: -10,
            }}
            onClick={() => Clear()}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#424242" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
            </svg>
          </button>
          {uploadSuccess ? (
            <div className="items-center -mt-4">
              <svg className="checkmark" width={12} height={12} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
              </svg>
            </div>
          ) : (
            uploadProgress > 0 && (
              <div className="progress mt-1">
                <ProgressBar mode="indeterminate" style={{ height: "12px" }}></ProgressBar>
              </div>
            )
          )}
        </div>
      ) : null}

      {!image && (
        <div>
          <Button
            className="btn btn-outline-secondary w-30 mt-4"
            onClick={() => {
              if (prop.lastOpenFormFileUrl && prop.lastOpenFormFileUrl.length > 0) {
                setImgSrc(prop.lastOpenFormFileUrl);
                setCrop(undefined); // Makes crop preview update between images.
                if (prop.modalOpen) {
                  setOpenDialog(true);
                }
              } else {
                fileInputRef.current?.click();
              }
            }}
            label="Resim Seç"
          ></Button>
        </div>
      )}
    </div>
  );
};

export default memo(ProfileImage);
