import axios, { Axios } from "axios";
import AxiosUtily from "../Extended-tools/AxiosUtility";
import { IAppSetting } from "../Models";
import IResultDto from "../Models/Abstract/IResultDto";

const SmsService = () => {
  //https://api.okulsmssistemi.com/api/swagger/index.html
  //https://api.okulsmssistemi.com/api
  const local = "https://192.168.1.222:5005/api";
  const client = axios.create({
    baseURL: "https://api.okulsmssistemi.com/api",
  });

  const GetSenders = async (username: string, password: string) => {
    try {
      const response = await client.post("/Sms/SenderList", {
        username,
        password,
      });

      // console.log('login',response);
      if (response.status === 200) {
        // console.log('LoginUser service response 200 ', response)
        const data = response.data;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GetCredit = async (username: string, password: string) => {
    try {
      const response = await client.post("/Sms/credit", {
        username,
        password,
      });

      // console.log('login',response);
      if (response.status === 200) {
        // console.log('LoginUser service response 200 ', response)
        const data = response.data;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  
  const SendSms = async (
    message: string,
    PhoneNumbers: string[],
    sender: string
  ) => {
    try {

        // console.log('PhoneNumbers=> ', PhoneNumbers);


      const response = await AxiosUtily().post("/Sms/SendSms", {
        message,
        PhoneNumbers,
        sender,
      });




      // console.log('login',response);
      if (response.status === 200) {
        // console.log('LoginUser service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  return {
    GetSenders,
    GetCredit,
    SendSms
  };
};

export { SmsService };
