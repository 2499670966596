import { UserRole } from "../../Models";
import { SchoolListMeb_Admin, SchoolList_Admin, UpdateModule } from "../Admin";
import SchoolCard_Admin from "../Admin/School/SchoolCard_Admin";
import ClassroomCard from "../Classroom/ClassroomCard";
import ClassroomList from "../Classroom/ClassroomList";
import ClientCard from "../Client/ClientCard";
import ClientList from "../Client/ClientList";
import HomePage from "../Dashboard/HomePage";
import Layout from "../Layout";
import ParentCard from "../Parent/ParentCard";
import ParentList from "../Parent/ParentList";
import PaymentCard from "../Payment/PaymentCard";
import PaymentEntryForStudent from "../Payment/PaymentEntryForStudent";
import PaymentList from "../Payment/PaymentList";
import PaymentPlanDetailWithStudents from "../Payment/PaymentPlanDetailWithStudents";
import PaymentPlanWithStudents from "../Payment/PaymentPlanWithStudents";
import StudentBulkInstallment from "../Payment/StudentBulkInstallment";
import PersonnelCard from "../Personnel/PersonnelCard";
import PersonnelList from "../Personnel/PersonnelList";
import FastStudentParentInsert from "../Student/FastStudentParentInsert";
import StudentCard from "../Student/StudentCard";
import StudentList from "../Student/StudentList";
import StudentParentMatch from "../Student/StudentParentMatch";
import PrivateRoute from "./PrivateRoute";
import AuthLayout from "../test/AuthLayout/AuthLayout";
import Logintest from "../test/logintest/logintest";
import SendSms_Student from "../Sms/SendSms_Student";
import SettingPage from "../Settings/settingPage";
import PersonnelMovements from "../Personnel/PersonnelMovements";
import SchoolCard from "../Settings/SchoolCard";
import FoodMenu from "../Food/FoodMenu";
import ParentCardNumberList from "../Parent/ParentCardNumberList";
import SchoolPaymentList from "../SchoolCompany/SchoolPaymentList";
import OverduePayment from "../Sms/OverduePayment";
import ExcellBulkPayment from "../Payment/ExcellBulkPayment";
import BankList from "../Settings/BankList";
import BankCard from "../Settings/bankCard";
import BulkPayment from "../Payment/BulkPayment";

const routes = [
  {
    path: "/",
    element: <Layout />,
    auth: true,
    roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER, UserRole.SATIS],
    children: [
      {
        path: "/",
        element: <HomePage />,

        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER, UserRole.SATIS],
        auth: true,
      },
      {
        path: "/students",
        element: <StudentList />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/students/new",
        element: <StudentCard />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/students/:id",
        element: <StudentCard />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/students/parentMatch",
        element: <StudentParentMatch />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/FastStudentParentInsert",
        element: <FastStudentParentInsert />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/classrooms",
        element: <ClassroomList />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/classroom/new",
        element: <ClassroomCard />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/classroom/:id",
        element: <ClassroomCard />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/parents",
        element: <ParentList />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/ParentCardNumberList",
        element: <ParentCardNumberList />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/Parent/new",
        element: <ParentCard />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/Parent/:id",
        element: <ParentCard />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/Clients",
        element: <ClientList />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/Client/new",
        element: <ClientCard />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/Client/:id",
        element: <ClientCard />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/Personnels",
        element: <PersonnelList />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/Movements",
        element: <PersonnelMovements />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/Personnel/new",
        element: <PersonnelCard />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/Personnel/:id",
        element: <PersonnelCard />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },

      {
        path: "/paymentList",
        element: <PaymentList />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/payment/new",
        element: <PaymentCard />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/payment/:id",
        element: <PaymentCard />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/StudentBulkInstallment",
        element: <StudentBulkInstallment />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/PaymentPlanWithStudents",
        element: <PaymentPlanWithStudents />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/PaymentPlanDetailWithStudents",
        element: <PaymentPlanDetailWithStudents />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/PaymentEntryForStudent",
        element: <PaymentEntryForStudent />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.PERSONEL, UserRole.USER],
        auth: true,
      },
      {
        path: "/bulkPayment",
        element: <BulkPayment />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.PERSONEL, UserRole.USER],
        auth: true,
      },
      {
        path: "/ExcellBulkPayment",
        element: <ExcellBulkPayment />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.PERSONEL, UserRole.USER],
        auth: true,
      },

      // SMS İŞLEMLERİ BAŞLANGIÇ
      {
        path: "/Sms/Students",
        element: <SendSms_Student />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/Sms/OverduePayment",
        element: <OverduePayment />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },

      // SMS İŞLEMLERİ BİTİŞ

      // GENEL  İŞLEMLER BAŞLANGIÇ
      {
        path: "/foodMenu",
        element: <FoodMenu />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },

      // SMS İŞLEMLERİ BİTİŞ

      // GENEL AYARLAR BAŞLANGIÇ
      {
        path: "/Settings",
        element: <SettingPage />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/Settings/School",
        element: <SchoolCard />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN],
        auth: true,
      },
      {
        path: "/banks",
        element: <BankList />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/banks/:id",
        element: <BankCard />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },
      {
        path: "/banks/new",
        element: <BankCard />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },

      // GENEL AYARLAR BİTİŞ

      // Şirket Okul  İŞLEMLER BAŞLANGIÇ
      {
        path: "/SchoolPaymenyList",
        element: <SchoolPaymentList />,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN],
        auth: true,
      },
      // ADMİN İŞLEMLERİ BAŞLANGIÇ
      {
        path: "/admin/schools",
        element: <SchoolList_Admin />,
        roles: [UserRole.SUPERADMIN],
        auth: true,
      },
      {
        path: "/admin/school/new",
        element: <SchoolCard_Admin />,
        roles: [UserRole.SUPERADMIN],
        auth: true,
      },
      {
        path: "/admin/school/:id",
        element: <SchoolCard_Admin />,
        roles: [UserRole.SUPERADMIN],
        auth: true,
      },
      {
        path: "/admin/updatemodule",
        element: <UpdateModule />,
        roles: [UserRole.SUPERADMIN],
        auth: true,
      },
      {
        path: "/admin/schools/Meb",
        element: <SchoolListMeb_Admin />,
        roles: [UserRole.SUPERADMIN, UserRole.SATIS],
        auth: true,
      },

      // ADMİN İŞLEMLERİ BİTİŞ
      {
        path: "*",
        element: <div className="w-full h-full text-center text-5xl mt-10">Sayfa bulunamadı</div>,
        roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.MODERATOR, UserRole.USER],
        auth: true,
      },

      // Admin sayfaları
    ],
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: <Logintest />,
      },
    ],
  },
];

const authCheck = (routes: any) => {
  // console.log("route=> ", routes);
  return routes.map((route: any) => {
    if (route?.auth) {
      route.element = <PrivateRoute roles={route?.roles}>{route.element}</PrivateRoute>;
    }

    if (route?.children) {
      route.children = authCheck(route.children);
    }
    return route;
  });
};

export default authCheck(routes);
