import { ConfirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useRef, useState } from "react";
import SkeletonList from "../../Components/Loading/Skeleton_List";
import { PaymentPlanService, StudentService } from "../../Services";
import toast from "react-hot-toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { IStudent } from "../../Models";
import { Button } from "primereact/button";
import { ContextMenu } from "primereact/contextmenu";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import { NULL } from "sass";

const BulkPayment = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProcess, setIsLoadingProcess] = useState(false);
  const paymentPlanService = PaymentPlanService();
  const studentService = StudentService();
  const [bankTransactions, setBankTransactions] = useState([] as any[]);
  const [students, setStudents] = useState([] as IStudent[]);
  const [filteredStudents, setFilteredStudents] = useState<IStudent[]>([]);
  const [autoComplateText, setAutoComplateText] = useState<string>("");

  const [eslestirilmemisKayitSayisi, setEslestirilmemisKayitSayisi] = useState(0);

  const [applyBankTransaction, setApplyBankTransaction] = useState(false);

  useEffect(() => {
    LoadPageData();
  }, []);

  const LoadPageData = async () => {
    await BankTransactionsList();
    await StudentList();
  };

  const StudentList = async () => {
    setIsLoading(true);
    const { data, error } = await studentService.GetAllStudentsOrderName(false);
    setIsLoading(false);

    if (error) {
      console.log(error);
      toast.error("Bir hata oluştu");
      return;
    }

    if (data) {
      if (data.success) {
        setStudents(data.returnData);
      } else {
        toast.error(data.errorMessage);
      }
    }
  };

  const BankTransactionsList = async () => {
    setIsLoading(true);
    const { data, error } = await paymentPlanService.BankTransactionsList();
    setIsLoading(false);

    if (error) {
      console.log(error);
      toast.error("Bir hata oluştu");
      return;
    }

    if (data) {
      if (data.success) {
        setBankTransactions(data.returnData);
        const _eslestirilmemisKayitSayisi = data.returnData.filter((x: any) => x.studentId == null).length;
        // console.log("_eslestirilmemisKayit
        setEslestirilmemisKayitSayisi(_eslestirilmemisKayitSayisi);
      } else {
        toast.error(data.errorMessage);
      }
    }
  };

  const receiptDateBody = (rowData: any) => {
    return <label>{new Date(rowData.receiptDate).toLocaleDateString()}</label>;
  };

  const amountBody = (rowData: any) => {
    return <label>{new Intl.NumberFormat("tr-TR", { style: "currency", currency: "TRY" }).format(rowData.amount)}</label>;
  };

  const cellEditor = (options: any) => {
    if (options.field === "studentFullName") {
      return studentAutoCompleteEditor(options);
    }
  };

  const search = (event: any) => {
    setTimeout(() => {
      let _filteredStudents: IStudent[];

      if (!event.query.trim().length) {
        _filteredStudents = [...students];
      } else {
        _filteredStudents = students.filter((student) => {
          return student.fullName.toLowerCase().includes(event.query.toLowerCase());
        });
      }
      setFilteredStudents(_filteredStudents);
    }, 350);
  };

  const studentAutoCompleteEditor = (options: any) => {
    return (
      <AutoComplete
        className="w-full h-10"
        field="fullName"
        // value={options.value}
        value={autoComplateText}
        suggestions={filteredStudents}
        completeMethod={search}
        onChange={async (e) => {
          setAutoComplateText(e.value);
          if (e.value && (e.value as IStudent).fullName !== undefined) {
            setApplyBankTransaction(true);
            const studentData = e.value as IStudent;

            // console.log("options.rowData, studentData => ", options.rowData, studentData);
            
            if (options.rowData.id > 0 && studentData.id > 0) {
              if (options.rowData.studentId != studentData.id) {
                await ApplyBankTransitionToStudent(options.rowData.id, studentData, false, true);
              } else {
                toast.error("Öğrenci daha önce eşleştirilmiş");
              }
            }
            setApplyBankTransaction(false);
            setAutoComplateText("");
          }
        }}
        onBlur={(e) => {
          if (!applyBankTransaction) {
            setAutoComplateText("");
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setAutoComplateText("");
            e.stopPropagation();
          }
        }}
        dropdown
      />
    );
  };

  const RemoveStudentFromBankTransaction = async (rowData: any) => {
    // console.log("rowData => ", rowData);
    setIsLoadingProcess(true);
    const { data, error } = await paymentPlanService.RemoveStudentFromBankTransaction(rowData.id);
    setIsLoadingProcess(false);
    if (error) {
      toast.error("Bir hata oluştu");
      return;
    }

    if (data) {
      if (data.success) {
        toast.success(data.successMessage);
      } else {
        toast.error(data.errorMessage);
      }
    }

    setBankTransactions((prevState) => {
      const newState = [...prevState];
      const index = newState.findIndex((x) => x.id === rowData.id);
      newState[index].studentFullName = "";
      newState[index].studentId = 0;
      return newState;
    });
  };

  const ApplyBankTransitionToStudent = async (bankTransactionId: number, studentData: any, any: boolean, forceUpdate: boolean = false) => {
    // console.log("studentData => ", studentData);
    // console.log("forceUpdate => ", forceUpdate);

    setIsLoadingProcess(true);
    const { data, error } = await paymentPlanService.ApplyBankTransitionToStudent(bankTransactionId, any ? studentData : studentData.id, forceUpdate);
    setIsLoadingProcess(false);
    if (error) {
      console.log(error);
      toast.error("Bir hata oluştu");
      return;
    }

    if (data) {
      if (data.success) {
        toast.success(data.successMessage);
        // console.log("bankTransactionId => ", bankTransactionId);
        // console.log("studentData => ", studentData);

        setBankTransactions((prevState) => {
          const newState = [...prevState];
          const index = newState.findIndex((x) => x.id === bankTransactionId);
          newState[index].studentFullName = studentData != -1 ? studentData?.fullName : "";
          newState[index].student = studentData;
          newState[index].studentId = studentData != -1 ? studentData.id : studentData;
          // console.log("index => ", newState[index]);
          return newState;
        });
      } else {
        toast.error(data.errorMessage);
      }
    }
  };

  const rowClass = (data: any) => {
    return {
      "bg-red-50": !data.studentId && !data.studentFullName,
      // "bg-green-50": data.studentId,
    };
  };

  const [selectRemoveRowData, setSelectRemoveRowData] = useState<any>();
  const cm = useRef(null);
  const items = [
    {
      label: "Öğrenci detayı",
      icon: "pi pi-user",
      command: () => {
        console.log("Öğrenci detayı");
        if (selectRemoveRowData) {
          navigate(`/students/${selectRemoveRowData.studentId}`);
        }
      },
    },
    {
      label: "Tanımı sil",
      icon: "pi pi-times",
      command: () => {
        RemoveStudentFromBankTransaction(selectRemoveRowData);
      },
    },
  ];

  const cm2 = useRef(null);
  const cmItems2 = [
    {
      label: "Eşleştirildi olarak işaretle",
      icon: "pi pi-bookmark",
      command: async () => {
        await ApplyBankTransitionToStudent(selectRemoveRowData.id, -1, true);
      },
    },
  ];

  const onRightClick = (event: any, rowData: any) => {
    // console.log("selectRemoveRowData => ", rowData);
    setSelectRemoveRowData(rowData);

    if (rowData.studentId) {
      if (cm.current) {
        // @ts-ignore
        cm.current.show(event);
      }
    } else {
      if (cm2.current) {
        // @ts-ignore
        cm2.current.show(event);
      }
    }
  };

  const AutoMatchBankTransactions = async () => {
    setIsLoadingProcess(true);
    const { data, error } = await paymentPlanService.AutoMatchBankTransactions();
    setIsLoadingProcess(false);
    if (error) {
      console.log(error);
      toast.error("Bir hata oluştu");
      return;
    }

    if (data) {
      if (data.success) {
        toast.success(data.successMessage);
        LoadPageData();
      } else {
        toast.error(data.errorMessage);
      }
    }
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">Ödeme Eşleştir</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      {/* açıklama yaz */}
      <div className="m-2 mb-4">
           <label className="text-lg block">* Öğrencinin birden fazla tanımlı ödeme planı var ise, yatırılan tutar ve katları ile karışalaştırma sonucunda işlem yapar</label>

           <label className="text-lg block">
          * Sistem önce öğrenci adına bakar, bulamaz Tc Numarasını arar, bulamazsa 1 öğrencisini olan veli veli adına göre arama yaparak eşleştirme yapmaya çalışır{" "}
        </label>
      </div>

      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div className="">
            <ConfirmDialog />

            <div>
              <Button
                label="Ödemeleri otomatik eşleştir"
                className="h-10 text-white"
                onClick={() => {
                  AutoMatchBankTransactions();
                }}
              />
            </div>
            <div className="flex my-5">
              <Tag
                className="bg-red-100 text-red-500 hover:bg-red-200 hover:text-red-600 cursor-pointer"
                value={"Eşleştirilmemiş Kayıt Sayısı : " + eslestirilmemisKayitSayisi}
                onClick={() => {
                  const _filteredBankTransactions = bankTransactions.filter((x) => !x.studentId);
                  setBankTransactions(_filteredBankTransactions);
                }}
              />

              <Tag
                className="ml-4 bg-green-100 text-green-500 hover:bg-green-200 hover:text-green-600 cursor-pointer"
                value="Tüm kayıtlar"
                onClick={() => {
                  LoadPageData();
                }}
              />
            </div>
            <div className=" ">
              <div className="shadow pb-2 sm:rounded-md sm:overflow-hidden">
                <div className=" bg-white space-y-6 ">
                  <DataTable
                    rowClassName={rowClass}
                    editMode="cell"
                    value={bankTransactions}
                    className="p-datatable-sm "
                    scrollable
                    scrollHeight="63vh"
                    style={{ minWidth: "50rem" }}
                    virtualScrollerOptions={{ itemSize: 50 }}
                    loading={isLoadingProcess}
                  >
                    <Column className="max-w-12" field="id" header="ID" sortable />
                    <Column className="max-w-24" field="receiptDate" header="Tarih" sortable body={receiptDateBody} />
                    <Column className="max-w-24 w-24" field="amount" header="Tutar" sortable body={amountBody} />
                    <Column
                      field="studentFullName"
                      header="Öğrenci"
                      sortable
                      editor={(options) => cellEditor(options)}
                      className="max-w-60 w-60"
                      body={(rowData) => {
                        if (rowData.studentId == -1) {
                          return (
                            <>
                              <span className="text-purple-400" onContextMenu={(event) => onRightClick(event, rowData)}>
                                Elle eşleştirilmiş
                              </span>
                            </>
                          );
                        }
                        if (rowData.studentId < 1 && !rowData.studentFullName) {
                          return (
                            <>
                              <span className="text-blue-300" onContextMenu={(event) => onRightClick(event, rowData)}>
                                Eşleştirilmemiş
                              </span>
                            </>
                          );
                        }
                        return (
                          <>
                            <div className=" flex flex-row items-center tooltipShow" onContextMenu={(event) => onRightClick(event, rowData)}>
                              <div className="card flex md:justify-content-center">
                                {rowData.student?.classroom ? (
                                  <>
                                    <Tooltip target=".custom-target-icon" />

                                    <span
                                      data-pr-tooltip={rowData.student?.classroom?.name ? "Sınıfı : " + rowData.student?.classroom?.name : ""}
                                      data-pr-position="right"
                                      className="custom-target-icon text-overflow-ellipsis text-sm overflow-hidden whitespace-nowrap  "
                                      aria-haspopup="true"
                                    >
                                      {rowData.studentFullName}
                                    </span>
                                  </>
                                ) : (
                                  <span
                                    // className="inline-flex align-items-center justify-content-center border-2 border-primary border-round w-4rem h-4rem"
                                    className="text-overflow-ellipsis text-sm overflow-hidden whitespace-nowrap  "
                                    aria-haspopup="true"
                                  >
                                    {rowData.studentFullName}
                                  </span>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      }}
                    />
                    <Column field="receiptDescription" header="Açıklama" sortable />
                    <Column field="bank.tanim" header="Banka" sortable className="max-w-44 w-44" />
                    <Column field="processType" header="Aktarım" className="max-w-28" sortable />
                  </DataTable>
                </div>
              </div>
            </div>
          </div>

          <ContextMenu model={items} ref={cm} />
          <ContextMenu model={cmItems2} ref={cm2} />
          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BulkPayment;
