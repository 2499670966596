
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import MainBridge from "./Pages/MainBridge";
import TodosProvider from "./Context/Store";
import { addLocale, locale } from "primereact/api";
import StaticVariable from "./Core/StaticVariable";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const isProduction = `${StaticVariable.isPorduct}`;

(window as any).soundManager.setup({debugMode: false});

const StartMode = () => {
  if (isProduction === "true") {
    return (
      <BrowserRouter>
        <MainBridge />
      </BrowserRouter>
    );
  } else {
    console.log("Prod Mode => ", isProduction);
    return (
      // <React.StrictMode>
      <BrowserRouter>
        <MainBridge />
      </BrowserRouter>
      // </React.StrictMode>
    );
  }
};

addLocale("tr", {
  startsWith: "Başlangıç",
  contains: "Barındırır",
  notContains: "İçinde Barındırmaz",
  endsWith: "Bitiş",
  equals: "Eşittir",
  notEquals: "Eşit Değildir",
  noFilter: "Filtresiz",
  lt: "Daha az",
  lte: "Daha az veya Eşit",
  gt: "Daha Fazla",
  gte: "Daha fazla veya Eşit",
  dateIs: "Tarih",
  dateIsNot: "Tarih değildir",
  dateBefore: "Tarihten önce",
  dateAfter: "Tarihten sonra",
  custom: "Özel",
  clear: "Temizle",
  apply: "Uygula",
  matchAll: "Tümüyle eşleşir",
  matchAny: "Herhangi birine eşleşir",
  addRule: "Kural Ekle",
  removeRule: "Kuralı Sil",
  accept: "Tamam",
  reject: "İptal",
  choose: "Seç",
  upload: "Yükle",
  cancel: "Vazgeç",
  dayNames: [
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ],
  dayNamesShort: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
  dayNamesMin: ["Pz", "Pt", "Sa", "Ça", "Pe", "Cu", "Ct"],
  monthNames: [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ],
  monthNamesShort: [
    "Oca",
    "Şub",
    "Mar",
    "Nis",
    "May",
    "Haz",
    "Tem",
    "Ağu",
    "Eyl",
    "Eki",
    "Kas",
    "Ara",
  ],
  today: "Bugün",
  weekHeader: "Hf",
  firstDayOfWeek: 1,
  dateFormat: "dd/mm/yy",
  weak: "Zayıf",
  medium: "Orta",
  strong: "Güçlü",
  passwordPrompt: "Şifre Giriniz",
  emptyFilterMessage: "Kullanılabilir seçenek yok",
  emptyMessage: "Sonuç bulunamadı",
  aria: {
    trueLabel: "Doğru",
    falseLabel: "Yanlış",
    nullLabel: "Seçilmedi",
    pageLabel: "Sayfa",
    firstPageLabel: "İlk Sayfa",
    lastPageLabel: "Son Sayfa",
    nextPageLabel: "Sonraki Sayfa",
    previousPageLabel: "Önceki Sayfa",
  },
});

locale("tr");

root.render(
  <TodosProvider>
    <StartMode />
  </TodosProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
