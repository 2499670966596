import CryptoJS from "crypto-js";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../Context/Store";
import { IClient } from "../Models";
// import jwtDecode, { JwtPayload } from "jwt-decode";

const CookieCrypto = () => {

  let setCookiee = (value: string) => {
    // console.log("setCookiee => ", value);

  };


  const SetCookie = (dataJson: string) => {
    const _cookie = localStorage.getItem("_cookie");
    if (_cookie) {
      localStorage.removeItem("_cookie");
    }
    const secretKey = `${process.env.REACT_APP_CRYPTO_KEY}`;
    var cryptedData = CryptoJS.AES.encrypt(JSON.stringify(dataJson), secretKey).toString();
    setCookiee(cryptedData);
    localStorage.setItem("_cookie", cryptedData);
  };

  const RemoveCookie = () => {
    const _cookie = localStorage.getItem("_cookie");
    if (_cookie) {
      localStorage.removeItem("_cookie");
      setCookiee("");
    }
  };

  const GetCookie = () => {
    const _cookie = localStorage.getItem("_cookie");
    if (_cookie) {
      try {
        const secretKey = `${process.env.REACT_APP_CRYPTO_KEY}`;
        var bytes = CryptoJS.AES.decrypt(_cookie, secretKey);
        const res = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setCookiee(res);
        return res;
      } catch (error) {
        RemoveCookie();
        setCookiee("");
      }
    }
  };

  return {
    SetCookie,
    RemoveCookie,
    GetCookie,
  };
};

export default CookieCrypto;
