import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { classNames } from "primereact/utils";
import { useContext, useEffect, useRef, useState } from "react";
import SkeletonList from "../../Components/Loading/Skeleton_List";
import { IStudentParentWiew_Card, UserRole } from "../../Models";
import { StudentService } from "../../Services";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import toast from "react-hot-toast";
import { AppContext } from "../../Context/Store";
import { ContextMenu } from "primereact/contextmenu";
import { ConfirmPopup } from "primereact/confirmpopup";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import moment from "moment";
import InputComp from "../../Components/Input/InputComp";
import { Dialog } from "primereact/dialog";
import { QRCodeSVG } from "qrcode.react";
import { useReactToPrint } from "react-to-print";
import "./style.css";




interface CardUpdateList {
  cardNumber: "";
  id: 0;
}

const ParentCardNumberList = () => {
  const studentService = StudentService();
  const { user } = useContext(AppContext);
  const [filters, setFilters] = useState<any>(null);
  const componentRef = useRef<any>();

  const [isLoadingRelationList, setIsLoadingRelationList] = useState(false);
  const [cardUpdateProgress, setCardUpdateProgress] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [selectedRow, setSelectedRow] = useState<IStudentParentWiew_Card[]>();
  const [showTopluKartGirisFormu, setShowTopluKartGirisFormu] = useState<boolean>(false);
  const [openPrintQrCodeDialog, setOpenPrintQrCodeDialog] = useState(false);
  const [studentParentList, setStudentParentList] = useState<IStudentParentWiew_Card[] | null>();

  const [menuSenkronProgress, setMenuSenkronProgress] = useState(false);
  const cm: any = useRef(null);
  const [selectedParent, setSelectedParent] = useState<any>(null);

  const [topluKartGirisData, setTopluKartGirisData] = useState<any>({
    cardColumnNumber: 0,
    rowIdColumnNumber: 1,
    cardData: "",
  });

  useEffect(() => {
    setIsLoadingRelationList(true);
    AllStudentParents();
  }, []);

  const AllStudentParents = async () => {
    const { data, error } = await studentService.StudentParentCardUseList();
    setIsLoadingRelationList(false);
    if (data) {
      if (data.success) {
        // console.log("StudentParentCardUseList => ", data.returnData);
        setTotalSize(data.returnData.length);
        setStudentParentList(data.returnData);
        initFilters();
      } else {
        console.log(`Yükleme işleminde hata var ${data?.errorMessage}`);
      }
    }
  };

  // DataTable Body Kolonları

  const dtBaskiyaGonderildiBodyTemplate = (item: IStudentParentWiew_Card) => {
    return (
      <div className="flex justify-center min-w-[50px]">
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle text-green-600 text-xl": item.baskiyaGonderildi,
            "false-icon pi-times-circle text-red-400 text-xl": !item.baskiyaGonderildi,
          })}
        ></i>
      </div>
    );
  };

  const verifiedRowFilterTemplate = (options: any) => {
    return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
  };

  const initFilters = () => {
    setFilters({
      parentFullName: { value: null, matchMode: FilterMatchMode.CONTAINS ,lng : "tr" },
      studentFullName: { value: null, matchMode: FilterMatchMode.CONTAINS ,lng : "tr" },
      baskiyaGonderildi: { value: null, matchMode: FilterMatchMode.EQUALS ,lng : "tr" },
      cardNumber: { value: null, matchMode: FilterMatchMode.EQUALS ,lng : "tr" },
    });
  };

  const textEditor = (options: any) => {
    {
      return <InputText className="max-w-full max-h-[14px]" type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }
  };

  const onCellEditComplete = (e: any) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      default:
        if (newValue == rowData[field]) {
          event.preventDefault();
        } else {
          if (newValue) {
            console.log("newValue => ", newValue);
            if (newValue.trim() == rowData[field]) {
              event.preventDefault();
            }
            rowData[field] = newValue.trim();
          } else {
            rowData[field] = newValue;
          }
          UpdateCardNumber(rowData["id"], rowData[field]);
        }
        break;
    }
  };

  const UpdateCardNumber = async (id: number, cardNumber: string) => {
    if (id) {
      setCardUpdateProgress(true);
      const { data } = await studentService.StudentParentCardUpdateSingle(id, cardNumber);
      setCardUpdateProgress(false);
      if (data) {
        if (data.success) {
          toast.success(data.successMessage);
          AllStudentParents();
        } else {
          toast.error(data.errorMessage);
        }
      }
    }
  };

  const createDateBody = (data: IStudentParentWiew_Card) => {
    if (data.baskiyaGonderimTarihi) {
      return <label>{moment(data.baskiyaGonderimTarihi).format("DD.MM.YYYY")}</label>;
    }
  };

  const menuModel = [
    // {
    //   label: `${selectedParent && selectedParent?.fullName} kişisini mobil için aktar`,
    //   icon: menuSenkronProgress ? "pi pi-spin pi-spinner" : "pi pi-arrow-up-right",
    //   command: () => {
    //     // toast.success("JSON.stringify(selectedProduct)");
    //     senktronMobile(true);
    //     // toast.success("Özellik yakında eklenecek");
    //   },
    // },
    {
      label: `Seçilenleri baskıya gönder - ${selectedRow?.length} adet`,
      icon: menuSenkronProgress ? "pi pi-spin pi-spinner" : "pi pi-angle-double-up",
      command: () => {
        SecilenleriBaskiyaGonder();
      },
    },
    {
      label: `Kart numarası olamayanları baskıya gönder - ${studentParentList?.filter((t) => t.cardNumber.length == 0)?.length} adet`,
      icon: menuSenkronProgress ? "pi pi-spin pi-spinner" : "pi pi-angle-double-up",
      command: () => {
        KartNumarasiOlmayanlariBaskiyaGonder();
      },
    },
    {
      label: `Toplu numara girişi`,
      icon: menuSenkronProgress ? "pi pi-spin pi-spinner" : "pi pi-angle-double-up",
      command: () => {
        setShowTopluKartGirisFormu(true);
      },
    },
    {
      label: `Seçilenlere QR kod oluştur - ${selectedRow?.length} adet`,
      icon: menuSenkronProgress ? "pi pi-spin pi-spinner" : "pi pi-angle-double-up",
      command: () => {
        generateRandomCardNumber();
      },
    },
  ];

  const senktronMobile = async (single: boolean = false) => {};

  const SecilenleriBaskiyaGonder = async () => {
    if (selectedRow) {
      setCardUpdateProgress(true);
      console.log("SecilenleriBaskiyaGonder => ", selectedRow);
      const { data } = await studentService.AddStudentCardReuest(selectedRow);

      setCardUpdateProgress(false);
      if (data) {
        if (data.success) {
          toast.success(data.successMessage);
          AllStudentParents();
        } else {
          toast.error(data.errorMessage);
        }
      }
    } else {
      toast.error("Satır seçimi yapılması gerekiyor");
    }
  };

  const generateRandomCardNumber = async () => {
    if (selectedRow) {
      setCardUpdateProgress(true);

      console.log("generateRandomCardNumber => ", selectedRow);

      const ids = selectedRow.map((t) => t.id);
      console.log("ids => ", ids);

      const { data } = await studentService.GenerateRandomCardNumber(ids);

      setCardUpdateProgress(false);

      if (data) {
        if (data.success) {
          toast.success(data.successMessage);

          setOpenPrintQrCodeDialog(true);
          //AllStudentParents();
        } else {
          toast.error(data.errorMessage);
        }
      }
    } else {
      toast.error("Satır seçimi yapılması gerekiyor");
    }
  };

  const KartNumarasiOlmayanlariBaskiyaGonder = async () => {
    const kartNoBosOlanlar = studentParentList?.filter((t) => t.cardNumber.length == 0);
    if (kartNoBosOlanlar) {
      setCardUpdateProgress(true);

      console.log("KartNumarasiOlmayanlariBaskiyaGonder => ", kartNoBosOlanlar);
      const { data } = await studentService.AddStudentCardReuest(kartNoBosOlanlar);

      setCardUpdateProgress(false);
      if (data) {
        if (data.success) {
          toast.success(data.successMessage);
          AllStudentParents();
        } else {
          toast.error(data.errorMessage);
        }
      }
    } else {
      toast.error("Satır seçimi yapılması gerekiyor");
    }
  };

  const TopluKartNumarasiGirisi = async () => {
    console.log("TopluKartNumarasiGirisi data => ", JSON.stringify(topluKartGirisData));

    if (topluKartGirisData) {
      const { data } = await studentService.TopluKartnumarasiGirisi(topluKartGirisData);

      if (data) {
        if (data.success) {
          toast.success(data.successMessage);
          AllStudentParents();
        } else {
          toast.error(data.errorMessage);
        }
      }
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const printQrCodeDialogPage = () => {
    handlePrint();
  };

  const hideInstallmentDialog = () => {
    setShowTopluKartGirisFormu(false);
  };

  const hidePrintQrCodeDialog = () => {
    setOpenPrintQrCodeDialog(false);
  };
  const installmentDialogFooter = (
    <>
      <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideInstallmentDialog} />
      <Button label="Aktar" icon="pi pi-check" className="p-button-text" onClick={TopluKartNumarasiGirisi} />
    </>
  );
  const printQrCodeDialogFooter = (
    <>
      <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hidePrintQrCodeDialog} />
      <Button label="Yazdır" icon="pi pi-check" className="p-button-text" onClick={printQrCodeDialogPage} />
    </>
  );
  let _printHtml = "";

  return (
    <div className="m-3 p-6 rounded-lg bg-white ">
      <ConfirmPopup />
      <ContextMenu className="w-1/5" model={menuModel} ref={cm} onHide={() => setSelectedParent(null)} />

      <div className="m-2 mb-5">
        <label className="text-2xl">Veli Kart Listesi</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      {isLoadingRelationList ? (
        <SkeletonList />
      ) : (
        <div className="shadow sm:rounded-md overflow-hidden">
          {studentParentList && (
            <div className="h-full">
              <DataTable
                onContextMenuSelectionChange={(e) => {
                  setSelectedParent(e.value);
                }}
                onContextMenu={(e: any) => {
                  try {
                    if (e.originalEvent.ctrlKey) {
                      cm.current?.show(e.originalEvent);
                    } else {
                      cm.current?.show(e.originalEvent.defaultPrevented);
                    }
                  } catch (error) {}
                }}
                value={studentParentList}
                dataKey="id"
                loading={isLoadingRelationList || cardUpdateProgress}
                responsiveLayout="scroll"
                scrollHeight="650px"
                scrollDirection="vertical"
                size="small"
                filterDisplay="row"
                filters={filters}
                footer={`Toplam Kayıt Sayısı: ${totalSize}`}
                emptyMessage="Kayıt bulunamadı"
                stripedRows
                selectionMode="checkbox"
                selection={selectedRow}
                onSelectionChange={(e) => setSelectedRow(e.value)}
                editMode="cell"
                className="editable-cells-table"
              >
                <Column header="#" selectionMode="multiple" headerStyle={{ width: "3em" }}></Column>
                <Column
                  sortable
                  showFilterMenu={false}
                  showFilterMatchModes={false}
                  showFilterMenuOptions={false}
                  showFilterOperator={false}
                  showClearButton={false}
                  className="max-w-[40px]"
                  field="id"
                  header="Id"
                />
                <Column
                  filter
                  sortable
                  showFilterMenu={false}
                  showFilterMatchModes={false}
                  showFilterMenuOptions={false}
                  showFilterOperator={false}
                  showClearButton={false}
                  filterMatchMode="contains"
                  field="studentFullName"
                  header="Öğrenci Adı Soyadı"
                />
                <Column
                  filter
                  showFilterMenu={false}
                  showFilterMatchModes={false}
                  showFilterMenuOptions={false}
                  showFilterOperator={false}
                  showClearButton={false}
                  sortable
                  filterMatchMode="contains"
                  field="parentFullName"
                  header="Veli Adı Soyadı"
                />

                <Column
                  filter
                  sortable
                  showFilterMenu={false}
                  showFilterMatchModes={false}
                  showFilterMenuOptions={false}
                  showFilterOperator={false}
                  showClearButton={false}
                  field="parentType"
                  header="Yakınlık"
                />
                <Column
                  filter
                  sortable
                  showFilterMenu={false}
                  showFilterMatchModes={false}
                  showFilterMenuOptions={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  field="classroomName"
                  header="Sınıf"
                />
                <Column
                  filter
                  sortable
                  showFilterMenu={false}
                  showFilterMatchModes={false}
                  showFilterMenuOptions={false}
                  showFilterOperator={false}
                  showClearButton={false}
                  filterMatchMode="contains"
                  field="cardNumber"
                  header="Kart No"
                  className="max-w-[140px]"
                  editor={(options) => textEditor(options)}
                  onCellEditComplete={onCellEditComplete}
                />
                <Column
                  showFilterMenu={false}
                  showFilterMatchModes={false}
                  showFilterMenuOptions={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  sortable
                  className="text-center"
                  field="baskiyaGonderildi"
                  header="Baskiya Gonderildi"
                  dataType="boolean"
                  body={dtBaskiyaGonderildiBodyTemplate}
                  filter
                  filterElement={verifiedRowFilterTemplate}
                />

                <Column
                  showFilterMenu={false}
                  showFilterMatchModes={false}
                  showFilterMenuOptions={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  sortable
                  className="text-center"
                  field="baskiyaGonderimTarihi"
                  header="Baskiya Gön. Tar."
                  body={createDateBody}
                />

                <Column
                  showFilterMenu={false}
                  showFilterMatchModes={false}
                  showFilterMenuOptions={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  sortable
                  className="text-center"
                  field="password"
                  header="Şifre"
                />

                {/* <Column
                  showFilterMenu={false}
                  showFilterMatchModes={false}
                  showFilterMenuOptions={false}
                  showFilterOperator={false}
                  showClearButton={false}
                  //   filterElement={verifiedRowFilterTemplate}
                  header="Kart Kullanım"
                  field="isUseCard"
                  dataType="boolean"
                  body={isUseCardBodyTemplate}
                /> */}

                {/* <Column body={actionBodyTemplate} exportable={false} header="İşlemler"></Column> */}
              </DataTable>
            </div>
          )}
        </div>
      )}

      <Dialog
        visible={showTopluKartGirisFormu}
        style={{ width: "450px" }}
        header="Taksit Detayı"
        modal
        className="p-fluid"
        footer={installmentDialogFooter}
        onHide={hideInstallmentDialog}
      >
        <div className="grid col-span-2 gap-3">
          <div className="field">
            <label className="block text-sm font-medium text-gray-700">Id Kolon No: </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <InputComp
                type="text"
                value={topluKartGirisData.rowIdColumnNumber}
                onChange={(e: any) => {
                  setTopluKartGirisData({ ...topluKartGirisData, rowIdColumnNumber: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="field">
            <label className="block text-sm font-medium text-gray-700">Kart No Kolon No: </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <InputComp
                type="text"
                value={topluKartGirisData.cardColumnNumber}
                onChange={(e: any) => {
                  setTopluKartGirisData({ ...topluKartGirisData, cardColumnNumber: e.target.value });
                }}
              />
            </div>
          </div>

          <div className="field">
            <label className="block text-sm font-medium text-gray-700">Data:</label>
            <div className="mt-1">
              <textarea
                rows={3}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                placeholder="Data"
                defaultValue={""}
                onChange={(e: any) => {
                  setTopluKartGirisData({ ...topluKartGirisData, cardData: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        id="myModal"
        visible={openPrintQrCodeDialog}
        header="QR Kod Oluşturucu"
        modal
        className="p-fluid"
        footer={printQrCodeDialogFooter}
        onHide={hidePrintQrCodeDialog}
      >
        <div ref={componentRef} className="a4-page">
          {selectedRow?.map((t, index) => {
            return (
              <div key={index} className="itemcard flex flex-col items-center justify-between">
                <div className="flex items-center justify-center flex-1">
                  <label className="text-sm text-center line-clamp-3 flex-wrap ">{t.studentFullName} {t.parentType}: {t.parentFullName}</label>
                </div>
                <div className="flex justify-center m-1">
                  <QRCodeSVG width={150} height={150} value={t.cardNumber} />
                </div>
              </div>
            );
          })}
        </div>
      </Dialog>
    </div>
  );
};

export default ParentCardNumberList;
