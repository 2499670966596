import React, { useContext, useEffect, useRef, useState } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { ParentService, StudentService } from "../../Services";
import {
  IParent,
  IParentStudentWiew,
  IStudent,
  IStudentList,
  IStudentParent,
} from "../../Models";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import toast from "react-hot-toast";
import { InputSwitch } from "primereact/inputswitch";
import { AppContext } from "../../Context/Store";
import classNames from "classnames";
import SkeletonCard from "../../Components/Loading/Skeleton_Card";
import SkeletonList from "../../Components/Loading/Skeleton_List";

// full width - 230px

const StudentParentMatch = () => {
  const studentService = StudentService();
  const parentService = ParentService();

  const btnRef: any = useRef<any>();
  let isProgress = false;

  const [pageProgressLoading, setPageProgressLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);

  // Öğrenci İşlemleri
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRelationList, setIsLoadingRelationList] = useState(false);
  const [loadingStudentList, setLoadingStudentList] = useState<boolean>(false);
  const [studentList, setStudentList] = useState<IStudentList[] | null>();
  const [selectedStudent, setSelectedStudent] = useState<IStudent | null>();
  const [filteredStudent, setFilteredStudent] = useState<any>(null);

  // veli İşlemleri
  const [loadingParentList, setLoadingParentList] = useState<boolean>(false);
  const [parentList, setParentList] = useState<IParent[] | null>();
  const [selectedParent, setSelectedParent] = useState<IStudent | null>();
  const [filteredParent, setFilteredParent] = useState<any>(null);

  // Öğrenci veli işlemleri
  const [selectedParentType, setSelectedParentType] = useState("");
  const [studentParentList, setStudentParentList] = useState<
    IParentStudentWiew[] | null
  >();

  const [insertData, setData] = useState({
    sendSms: false,
    isParent: false,
    isUseMobile: false,
    isUseCard: false,
    studentId: 0,
    parentId: 0,
    parentType: "",
  });

  const yakinlikTipi = [
    "ABİ",
    "ABLA",
    "AİLE ARKADAŞI",
    "AKRABA",
    "AMCA",
    "ANNE",
    "ANNEANNE",
    "BABA",
    "BABAANNE",
    "BAKICI",
    "ÇALIŞAN",
    "DAYI",
    "DEDE",
    "ENİŞTE",
    "HALA",
    "KARDEŞ",
    "KOMŞU",
    "KUZEN",
    "NENE",
    "SERVİSÇİ",
    "ŞOFÖR",
    "TEYZE",
    "YENGE",
    "YÖNETİCİ",
    "DİĞER",
  ];

  useEffect(() => {
    Promise.all([GetStudentList(), GetParentList()]).then((data) => {
      setIsLoading(false);
    });
    AllStudentParents();
  }, []);

  // useEffect(() => {
  //   // if (insertData.isParent) {
  //   //   setData({ ...insertData, sendSms: true });
  //   // }
  // }, [insertData]);

  // Öğrenci İşlemleri
  const GetStudentList = async () => {
    setLoadingStudentList(true);
    const { data, error } = await studentService.GetAllStudents(true);
    setLoadingStudentList(false);
    if (data) {
      if (data.success) {
        const _data = data?.returnData as IStudentList[];
        // console.log('=_data => ',_data);
        _data.forEach(
          (item) => (item.fullName = item.name + " " + item.surname)
        );
        setStudentList(_data);
      } else {
        toast.error(
          `Öğrenci listesi yüklenirken hata oluştu ${data?.errorMessage}`
        );
      }
    }
  };

  const studentItemTemplate = (item: IStudentList) => {
    return (
      <div>
        <div>
          {item.name} {item.surname} - ({item.classroom?.name})
        </div>
      </div>
    );
  };

  const searchStudent = (event: any) => {
    console.log("ara => ", event.query);

    setTimeout(() => {
      let _filtered: any;
      if (!event.query.trim().length) {
        if (studentList) _filtered = [...studentList];
      } else {
        if (studentList)
          _filtered = studentList.filter((item) => {
            return item.fullName
              ?.toLowerCase()
              .includes(event.query.toLowerCase());
          });
      }
      setFilteredStudent(_filtered);
    }, 250);
  };

  const GetParentList = async () => {
    setLoadingParentList(true);
    const { data, error } = await parentService.GetAllParents(true);
    setLoadingParentList(false);
    if (data) {
      if (data.success) {
        const _data = data?.returnData as IParent[];
        _data.forEach(
          (item) => (item.fullName = item.name + " " + item.surname)
        );
        setParentList(_data);
      } else {
        toast.error(
          `Veli listesi yüklenirken hata oluştu ${data?.errorMessage}`
        );
      }
    }
  };
  const parentItemTemplate = (item: IParent) => {
    return (
      <div>
        <div>
          {item.name} {item.surname}
        </div>
      </div>
    );
  };
  const searchParent = (event: any) => {
    setTimeout(() => {
      let _filtered: any;
      if (!event.query.trim().length) {
        if (parentList) _filtered = [...parentList];
      } else {
        if (parentList)
          _filtered = parentList.filter((item) => {
            return item.fullName
              ?.toLowerCase()
              .includes(event.query.toLowerCase());
          });
      }
      setFilteredParent(_filtered);
    }, 250);
  };

  const AllStudentParents = async () => {
    setIsLoadingRelationList(true);
    const { data, error } = await studentService.StudentParentMatchList(true);
    setIsLoadingRelationList(false);
    if (data) {
      if (data.success) {
        // console.log("data=> ", data.returnData);
        setTotalSize(data.returnData.length);
        setStudentParentList(data.returnData);
      } else {
        console.log(`Yükleme işleminde hata var ${data?.errorMessage}`);
      }
    }
  };

  let selectedItemId = 0;

  const btnSaveClick = async () => {
    btnRef.current.disabled = true;

    if (selectedStudent?.id && selectedParent?.id && insertData.parentType) {
      const { data, error } = await studentService.AddStudentParent(
        insertData.studentId,
        insertData.parentId,
        insertData.parentType,
        insertData.sendSms,
        insertData.isParent,
        insertData.isUseMobile,
        insertData.isUseCard
      );
      setPageProgressLoading(false);

      if (data) {
        if (data.success) {
          toast.success("Ekleme işlemi başarılı");

          await AllStudentParents();
        } else {
          toast.error(`${data?.errorMessage}`);
        }
      }
    } else {
      toast.error("Seçim yapmanız gerekiyor");
    }

    btnRef.current.disabled = false;
  };

  const accept = async (e: any) => {
    const { data, error } = await studentService.DeleteStudentParentRelation(
      selectedItemId
    );

    if (data) {
      if (data.success) {
        toast.success("Silme işlemi başarılı");
        await AllStudentParents();
      } else {
        toast.error(`Silme işlemi başarısız ! ${data?.errorMessage}`);
      }
    }
  };

  const confirm2 = (event: any) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Bu kaydı silmek istiyor musunuz ?",
      icon: "pi pi-times-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => accept(event),
    });
  };

  const DeleteCagetoryHandle = async (e: any, item: any) => {
    selectedItemId = item.id;
    confirm2(e);
  };

  const actionBodyTemplate = (item: any) => {
    return (
      <div className="flex">
        <button
          className="flex btn btn-danger ml-4 "
          onClick={(e) => DeleteCagetoryHandle(e, item)}
        >
          {isLoading && (
            <span
              className="spinner-border spinner-border-sm mr-3"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="red"
            className="bi bi-trash"
            viewBox="0 0 16 16"
          >
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
            <path
              fillRule="evenodd"
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
            />
          </svg>
        </button>
      </div>
    );
  };

  const isParentBodyTemplate = (item: IParentStudentWiew) => {
    return (
      <div className="flex justify-center min-w-[50px]">
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle text-green-600 text-xl": item.isParent,
            "false-icon pi-times-circle text-red-400 text-xl": !item.isParent,
          })}
        ></i>
      </div>
    );
  };

  const isSendSmsBodyTemplate = (item: IParentStudentWiew) => {
    return (
      <div className="flex justify-center min-w-[50px]">
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle text-green-600 text-xl": item.sendSms,
            "false-icon pi-times-circle text-red-400 text-xl": !item.sendSms,
          })}
        ></i>
      </div>
    );
  };
  const isUseCardBodyTemplate = (item: IParentStudentWiew) => {
    return (
      <div className="flex justify-center min-w-[50px]">
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle text-green-600 text-xl": item.isUseCard,
            "false-icon pi-times-circle text-red-400 text-xl": !item.isUseCard,
          })}
        ></i>
      </div>
    );
  };

  const ogrenimDurumuOptionTemplate = (option: any) => {
    return <span>{option}</span>;
  };

  const selectedOgrenimDurumuTemplate = (option: string, props: any) => {
    if (option) {
      return (
        <div className="flex justify-between " style={{ marginTop: -4 }}>
          <span>{option}</span>
        </div>
      );
    }

    return (
      <div className="flex " style={{ marginTop: -4 }}>
        {props.placeholder}
      </div>
    );
  };

  return (
    <div className="max-w-[calc((100%-12rem))] overflow-hidden">
      <div className="m-3 p-6 rounded-lg bg-white ">
        <ConfirmPopup />

        {isLoading ? (
          <SkeletonCard />
        ) : (
          <div className="ml-2">
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-3 sm:col-span-3 md:col-span-1 lg:col-span-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Öğrenci Seçimi:
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        {/* <AutoComplete
                    aria-label="Countries"
                  /> */}

                        <AutoComplete
                          className="h-10 w-full"
                          value={selectedStudent}
                          field="fullName"
                          dropdown
                          forceSelection
                          suggestions={filteredStudent}
                          completeMethod={searchStudent}
                          itemTemplate={studentItemTemplate}
                          placeholder={
                            loadingStudentList
                              ? "Yükleniyor.."
                              : "Öğrenci seçiniz"
                          }
                          onChange={(e) => {
                            setData({
                              ...insertData,
                              studentId: e.value.id,
                              isUseMobile: e.value.isUseMobile ? true : false,
                            });
                            setSelectedStudent(e.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-span-3 md:col-span-1 lg:col-span-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Veli:
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <AutoComplete
                          className="h-10 w-full"
                          value={selectedParent}
                          field="fullName"
                          dropdown
                          forceSelection
                          suggestions={filteredParent}
                          completeMethod={searchParent}
                          itemTemplate={parentItemTemplate}
                          placeholder={
                            loadingParentList ? "Yükleniyor.." : "Veli seçiniz"
                          }
                          onChange={(e) => {
                            setData({ ...insertData, parentId: e.value.id });
                            setSelectedParent(e.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-span-3 md:col-span-1 lg:col-span-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Yakınlık Seçimi:
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <Dropdown
                          value={insertData.parentType}
                          options={yakinlikTipi}
                          onChange={(e) => {
                            if (e.value == "ANNE" || e.value == "BABA") {
                              setData({
                                ...insertData,
                                parentType: e.value,
                                sendSms: true,
                                isParent: true,
                              });
                            } else {
                              setData({
                                ...insertData,
                                parentType: e.value,
                                sendSms: false,
                                isParent: false,
                              });
                            }

                            // setSelectedParentType(e.value);
                          }}
                          placeholder="Yakınlık Seçiniz"
                          className="w-full h-10 "
                          valueTemplate={selectedOgrenimDurumuTemplate}
                          itemTemplate={ogrenimDurumuOptionTemplate}
                        />
                      </div>
                    </div>

                    <div className="col-span-3 md:col-span-3 lg:col-span-3 ">
                      <div className="flex justify-end ">
                        <div className="mr-4 pr-4 border-r-2 border-indigo-500">
                          <label className="block text-sm font-medium text-gray-700">
                            Velisi Mi?
                          </label>
                          <div className="flex items-center h-11 ">
                            <InputSwitch
                              onChange={(e) => {
                                setData({ ...insertData, isParent: e.value });
                              }}
                              checked={insertData.isParent}
                            ></InputSwitch>
                          </div>
                        </div>
                        <div className="mr-4 pr-4 border-r-2 border-indigo-500">
                          <label className="block text-sm font-medium text-gray-700">
                            Sms Gönder
                          </label>
                          <div className="flex items-center h-11 ">
                            <InputSwitch
                              onChange={(e) =>
                                setData({ ...insertData, sendSms: e.value })
                              }
                              checked={insertData.sendSms}
                            ></InputSwitch>
                          </div>
                        </div>
                        <div className="mr-4 pr-4 border-r-2 border-indigo-500">
                          <label className="block text-sm font-medium text-gray-700">
                            Mobil Kullanım
                          </label>
                          <div className="flex items-center h-11 ">
                            <InputSwitch
                              disabled
                              // onChange={(e) => {
                              //   setData({ ...insertData, isUseMobile: e.value });
                              // }}
                              checked={selectedStudent?.isUseMobile}
                            ></InputSwitch>
                          </div>
                        </div>
                        <div className="mr-4 pr-4 border-r-2 border-indigo-500">
                          <label className="block text-sm font-medium text-gray-700">
                            Kart Kullanım
                          </label>
                          <div className="flex items-center h-11 ">
                            <InputSwitch
                              onChange={(e) =>
                                setData({ ...insertData, isUseCard: e.value })
                              }
                              checked={insertData.isUseCard}
                            ></InputSwitch>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <Button
                    ref={btnRef}
                    loading={btnRef?.current?.disabled}
                    disabled={btnRef?.current?.disabled}
                    onClick={() => {
                      btnSaveClick();
                    }}
                    label="Kaydet"
                    className="inline-flex h-10  justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="m-3 p-6 rounded-lg bg-white ">
        {isLoadingRelationList ? (
          <SkeletonList />
        ) : (
          <div className="ml-2 ">
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="  bg-white  ">
                  {studentParentList && (
                    <DataTable
                      value={studentParentList}
                      dataKey="id"
                      loading={isLoading}
                      responsiveLayout="scroll"
                      scrollable
                      scrollHeight="650px"
                      scrollDirection="vertical"
                      columnResizeMode="fit"
                      size="small"
                      filterDisplay="row"
                      footer={`Toplam Kayıt Sayısı: ${totalSize}`}
                      emptyMessage="Kayıt bulunamadı"
                    >
                      <Column
                        filter
                        filterMatchMode="contains"
                        sortable
                        field="studentFullName"
                        header="Öğrenci Adı Soyadı"
                        style={{ minWidth: "16rem" }}
                      />
                      <Column
                        filter
                        showFilterMenu={false}
                        showFilterMatchModes={false}
                        showFilterMenuOptions={false}
                        showFilterOperator={false}
                        showAddButton={false}
                        sortable
                        field="parentFullName"
                        header="Veli Adı Soyadı"
                        style={{ minWidth: "16rem" }}
                      />

                      <Column
                        filter
                        sortable
                        field="parentType"
                        header="Yakınlık"
                        style={{ minWidth: "13rem" }}
                      />
                      <Column
                        filter
                        sortable
                        field="classroomName"
                        header="Sınıf"
                        style={{ minWidth: "14rem" }}
                      />
                      <Column
                        header="Velisi ?"
                        field="isParent"
                        body={isParentBodyTemplate}
                      />
                      <Column
                        style={{ minWidth: "14rem" }}
                        header="Sms Gönder ?"
                        field="sendSms"
                        body={isSendSmsBodyTemplate}
                      />
                      <Column
                        header="Kart Kullanım"
                        field="isUseCard"
                        body={isUseCardBodyTemplate}
                      />

                      <Column
                        body={actionBodyTemplate}
                        exportable={false}
                        header="İşlemler"
                      ></Column>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentParentMatch;
