import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, SubMenu, MenuItem } from "react-pro-sidebar";

import { Link, Outlet, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import "../Components/Sidebar/custom.scss";
import { useContext, useEffect, useRef, useState } from "react";
import * as prime from "primereact/menu";
import { Avatar } from "primereact/avatar";
import SettingsIco from "../Icon/SettingsIco";
import LogoutIco from "../Icon/LogoutIco";
import IClientModelDto from "../Models/Abstract/IClientModelDto";
import { HubConnection, HubConnectionBuilder, HubConnectionState, JsonHubProtocol, LogLevel } from "@microsoft/signalr";
import { AppContext } from "../Context/Store";
import { IOnlineClient, UserRole } from "../Models";
import StaticVariable from "../Core/StaticVariable";
import toast from "react-hot-toast";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import CookieCrypto from "../Extended-tools/CookieCrypto";

function App() {
  const { user, setUser, setOnlineClientList, sidebarHideStatus, setSidebarHideStatus } = useContext(AppContext);
  const navigate = useNavigate();
  const [connection, setConnection] = useState<HubConnection>();

  // console.log("user => ", user);

  // const _role = UserRole[user.role as keyof typeof UserRole];

  useEffect(() => {
    serverConnectionStart();
  }, [connection]);

  const serverConnectionStart = async () => {
    if (connection && connection.state !== HubConnectionState.Connected) {
      await connection.start();
      console.log("== connected ==");
    }
  };

  useEffect(() => {
    const _clientModel: IClientModelDto = {
      username: `${user.userMapId}@${user.dbName}`,
      id: 0,
      type: "WebPanel",
      browserInfo: "",
      schoolCode: user.dbName,
      schoolFullName: user.schoolFullName,
      displayName: user.fullName,
      version: StaticVariable.version,
    };

    const isProduction = `${StaticVariable.isPorduct}`;
    // var url = isProduction === "true" ? "https://socket.prookul.com" : "http://192.168.1.222:5238";
    var url = isProduction === "true" ? StaticVariable.socketUrl : StaticVariable.socketLocalUrl;
    // console.log('url => ',url)
    const _url = `${url}/prosocket?client=${JSON.stringify(_clientModel)}`;

    const newConnection = new HubConnectionBuilder()
      .withUrl(_url, {
        // accessTokenFactory: () => (Client ? Client.token : ""),
      })
      .withHubProtocol(new JsonHubProtocol())
      .withAutomaticReconnect()
      .configureLogging(LogLevel.None)
      .build();

    newConnection.onclose((error) => {
      console.log(newConnection.state);
      // dispatch(changeConnectionState(newConnection.state));
      // dispatch(updateHubConnection(newConnection));
    });

    newConnection.onreconnecting((error) => {
      console.log(newConnection.state);
      // dispatch(changeConnectionState(newConnection.state));
      // dispatch(updateHubConnection(newConnection));
    });

    newConnection.onreconnected((connectionId) => {
      console.log("Yeniden bağlantı kuruldu : Id ", connectionId);
      // dispatch(changeConnectionState(newConnection.state));
      // dispatch(updateHubConnection(newConnection));
    });

    if (user.role == UserRole.ADMIN || user.role == UserRole.SUPERADMIN) {
      newConnection.on("receiveOnlineListPanel", (onlineList: string) => {
        console.log("online List => ", JSON.parse(onlineList).length, " -- ", JSON.parse(onlineList));
        const _data = JSON.parse(onlineList) as IOnlineClient[];
        setOnlineClientList(_data);
      });

      newConnection.on("receiveOnlineListPanelTotalConnectionId", (onlineList: string) => {
        console.log("Total Connection Ids => ", JSON.parse(onlineList));
      });
    }

    newConnection.on("receiveAnnouncement", (announcement: string) => {
      toast.success(announcement);
    });

    setConnection(newConnection);
  }, []);

  const profileMenu: any = useRef(null);
  const menuAdminProfile = [
    {
      items: [
        {
          label: "Ayarlar",
          icon: (
            <div className="pr-1">
              <SettingsIco />
            </div>
          ),
          command: () => {
            navigate(`/Personnel/${user.staffId}`);
          },
        },
        {
          label: "İlk oturuma dön",
          icon: (
            <div className="pr-1">
              <LogoutIco />
            </div>
          ),
          command: () => {
            if (user.firstLoginInfo) {
              setUser(user.firstLoginInfo);
              toast.success(`İlk oturuma geçildi. Hoş geldin : ${user.firstLoginInfo.fullName}`);
              navigate(`/`, { replace: true });
            }
          },
        },
      ],
    },
  ];
  const menuItemsProfile = [
    {
      items: [
        {
          label: "Ayarlar",
          icon: (
            <div className="pr-1">
              <SettingsIco />
            </div>
          ),
          command: () => {
            navigate(`/Personnel/${user.staffId}`);
          },
        },
        {
          label: "Çıkış",
          icon: (
            <div className="pr-1">
              <LogoutIco />
            </div>
          ),
          command: () => {
            CookieCrypto().RemoveCookie();
            setUser({
              userMapId: 0,
              dbName: "",
              fullName: "",
              staffId: 0,
              token: "",
              userId: 0,
              username: "",
              imageUrl: "",
              schoolFullName: "",
              role: UserRole.NEVER,
              firstLoginInfo: null,
            });
          },
        },
      ],
    },
  ];

  return (
    <div className="flex h-screen min-h-screen">
      {sidebarHideStatus && (
        <div className="absolute left-1 top-1">
          <Button
            className="p-button-rounded p-button-text"
            aria-label="Submit"
            onClick={(e) => {
              setSidebarHideStatus();
            }}
          >
            <i className="pi pi-bars" style={{ fontSize: 20 }}></i>
          </Button>
        </div>
      )}
      <div
        // className={classNames(
        //   "hidden h-fit sm:visible md:block lg:block w-72",
        //   { "md:visible": sidebarHideStatus },
        //   { "lg:visible": sidebarHideStatus }
        // )}

        className={classNames(
          " h-fit sm:block md:block lg:block w-72",
          `${!sidebarHideStatus ? "sm:visible" : "sm:hidden"}`,
          `${!sidebarHideStatus ? "md:visible" : "md:hidden"}`,
          `${!sidebarHideStatus ? "lg:visible" : "lg:hidden"}`
        )}
      >
        {/* <div className={`${sidebarHideStatus && "md:hidden"}`} style={{ width: 288 }}> */}
        <ProSidebar className="w-72" hidden={sidebarHideStatus}>
          <SidebarHeader className="h-14 flex justify-center items-center ">
            <label className="text-2xl ">ProOkul Web</label>
          </SidebarHeader>
          <SidebarContent className="border-1 shadow">
            <Menu iconShape="square">
              <MenuItem>
                Anasayfa
                <Link to="/" />
              </MenuItem>
              <SubMenu
                onPointerLeaveCapture={() => {
                  console.log("onPointerLeaveCapture");
                }}
                onPointerEnterCapture={() => {
                  console.log("onPointerEnterCapture");
                }}
                title="Öğrenci İşlemleri"
                placeholder="Öğrenci İşlemleri"
              >
                <MenuItem>
                  Öğrenci Kartı
                  <Link to="/students/new" />
                </MenuItem>
                <MenuItem>
                  Öğrenci Listesi
                  <Link to="/students" />
                </MenuItem>

                <MenuItem>
                  Öğrenci Veli Eşleştir
                  <Link to="/students/parentMatch" />
                </MenuItem>

                <MenuItem>
                  Hızlı Ekleme
                  <Link to="/FastStudentParentInsert" />
                </MenuItem>
              </SubMenu>
              <SubMenu
                onPointerLeaveCapture={() => {
                  console.log("onPointerLeaveCapture");
                }}
                onPointerEnterCapture={() => {
                  console.log("onPointerEnterCapture");
                }}
                title="Veli İşlemleri"
                placeholder="Veli İşlemleri"
              >
                <MenuItem>
                  Veli Kartı
                  <Link to="/parent/new" />
                </MenuItem>
                <MenuItem>
                  Veli Listesi
                  <Link to="/parents" />
                </MenuItem>
                <MenuItem>
                  Veli Kart Tanımlama
                  <Link to="/ParentCardNumberList" />
                </MenuItem>
              </SubMenu>

              <SubMenu
                onPointerLeaveCapture={() => {
                  console.log("onPointerLeaveCapture");
                }}
                onPointerEnterCapture={() => {
                  console.log("onPointerEnterCapture");
                }}
                title="Personel İşlemleri"
                placeholder="Personel İşlemleri"
              >
                <MenuItem>
                  Personel Kartı
                  <Link to="/Personnel/new" />
                </MenuItem>
                <MenuItem>
                  Personel Listesi
                  <Link to="/Personnels" />
                </MenuItem>
                <MenuItem>
                  Personel Hareketleri
                  <Link to="/Movements" />
                </MenuItem>
              </SubMenu>

              <SubMenu
                onPointerLeaveCapture={() => {
                  console.log("onPointerLeaveCapture");
                }}
                onPointerEnterCapture={() => {
                  console.log("onPointerEnterCapture");
                }}
                title="Derslik İşlemleri"
                placeholder="Derslik İşlemleri"
              >
                <MenuItem>
                  Sınıf / Şube Ekle
                  <Link to="/classroom/new" />
                </MenuItem>

                <MenuItem>
                  Sınıf / Şube Listesi
                  <Link to="/classrooms" />
                </MenuItem>
                <MenuItem>
                  Derslik Kartı
                  <Link to="/Client/new" />
                </MenuItem>
                <MenuItem>
                  Derslik Listesi
                  <Link to="/clients" />
                </MenuItem>
              </SubMenu>

              <SubMenu
                onPointerLeaveCapture={() => {
                  console.log("onPointerLeaveCapture");
                }}
                onPointerEnterCapture={() => {
                  console.log("onPointerEnterCapture");
                }}
                title="Muhasebe İşlemleri"
                placeholder={"Muhasebe İşlemleri"}
              >
                <MenuItem>
                  Ödeme Planı Kartı
                  <Link to="/payment/new" />
                </MenuItem>
                <MenuItem>
                  Ödeme Planı Listesi
                  <Link to="/paymentList" />
                </MenuItem>
                <MenuItem>
                  Toplu Ödeme Planı Tanımla
                  <Link to="/StudentBulkInstallment" />
                </MenuItem>
                <MenuItem>
                  Plan tanımlı öğrenciler
                  <Link to="/PaymentPlanWithStudents" />
                </MenuItem>
                <MenuItem>
                  Plan tanımlı öğrenciler detay
                  <Link to="/PaymentPlanDetailWithStudents" />
                </MenuItem>
                <MenuItem>
                  Ödeme girişi
                  <Link to="/PaymentEntryForStudent" />
                </MenuItem>
                <MenuItem>
                  Hızlı ödeme eşleştirme
                  <Link to="/bulkPayment" />
                </MenuItem>
                <MenuItem>
                  Excel ile toplu ödeme girişi
                  <Link to="/ExcellBulkPayment" />
                </MenuItem>
              </SubMenu>

              <SubMenu
                onPointerLeaveCapture={() => {
                  console.log("onPointerLeaveCapture");
                }}
                onPointerEnterCapture={() => {
                  console.log("onPointerEnterCapture");
                }}
                title="Sms İşlemleri"
                placeholder="Sms İşlemleri"
              >
                <MenuItem>
                  Öğrenciye Sms Gönder
                  <Link to="/sms/students" />
                </MenuItem>
                <MenuItem>
                  Ödemesi Gecikenlere Gönder
                  <Link to="/sms/overduePayment" />
                </MenuItem>
              </SubMenu>
              <SubMenu
                onPointerLeaveCapture={() => {
                  console.log("onPointerLeaveCapture");
                }}
                onPointerEnterCapture={() => {
                  console.log("onPointerEnterCapture");
                }}
                title="Genel İşlemler"
                placeholder="Genel İşlemler"
              >
                <MenuItem>
                  Yemek Menüsü
                  <Link to="/foodMenu" />
                </MenuItem>
                <MenuItem>
                  Kasa Bilgileri
                  <Link to="/banks" />
                </MenuItem>
              </SubMenu>

              <SubMenu
                onPointerLeaveCapture={() => {
                  console.log("onPointerLeaveCapture");
                }}
                onPointerEnterCapture={() => {
                  console.log("onPointerEnterCapture");
                }}
                title="Ayarlar"
                placeholder="Ayarlar"
              >
                <MenuItem>
                  Genel Ayarlar
                  <Link to="/settings" />
                </MenuItem>
                <MenuItem>
                  Okul Bilgileri
                  <Link to="/settings/school" />
                </MenuItem>
              </SubMenu>
              <SubMenu
                onPointerLeaveCapture={() => {
                  console.log("onPointerLeaveCapture");
                }}
                onPointerEnterCapture={() => {
                  console.log("onPointerEnterCapture");
                }}
                title="Firma-Okul İşlemleri"
                placeholder="Firma-Okul İşlemleri"
              >
                <MenuItem>
                  Firmaya Ödenecek
                  <Link to="/SchoolPaymenyList" />
                </MenuItem>
              </SubMenu>
            </Menu>

            {/* ADMİN İŞLEMLERİ */}
            {user.role == UserRole.SUPERADMIN && (
              <Menu iconShape="square">
                <MenuItem>admin işlemleri</MenuItem>
                <SubMenu
                  onPointerLeaveCapture={() => {
                    console.log("onPointerLeaveCapture");
                  }}
                  onPointerEnterCapture={() => {
                    console.log("onPointerEnterCapture");
                  }}
                  title="Okul işlemleri"
                  placeholder="Okul işlemleri"
                >
                  <MenuItem>
                    Okul Kartı
                    <Link to="/admin/school/new" />
                  </MenuItem>
                  <MenuItem>
                    Okul Listesi
                    <Link to="/admin/schools" />
                  </MenuItem>
                  <MenuItem>
                    Okul Listesi Meb
                    <Link to="/admin/schools/Meb" />
                  </MenuItem>
                </SubMenu>

                <SubMenu
                  onPointerLeaveCapture={() => {
                    console.log("onPointerLeaveCapture");
                  }}
                  onPointerEnterCapture={() => {
                    console.log("onPointerEnterCapture");
                  }}
                  title="Sistem işlemleri"
                  placeholder="Sistem işlemleri"
                >
                  <MenuItem>
                    Güncelleme İşlemleri
                    <Link to="/admin/updatemodule" />
                  </MenuItem>
                </SubMenu>
              </Menu>
            )}
            {user.role == UserRole.SATIS && (
              <Menu iconShape="square">
                <SubMenu
                  onPointerLeaveCapture={() => {
                    console.log("onPointerLeaveCapture");
                  }}
                  onPointerEnterCapture={() => {
                    console.log("onPointerEnterCapture");
                  }}
                  title="Satış işlemleri"
                  placeholder="Satış işlemleri"
                >
                  <MenuItem>
                    Okul Listesi Meb
                    <Link to="/admin/schools/Meb" />
                  </MenuItem>
                </SubMenu>
              </Menu>
            )}
          </SidebarContent>
          <SidebarFooter className="flex justify-center items-center h-11">
            <label>
              {StaticVariable.year} - V: {StaticVariable.version}
            </label>
          </SidebarFooter>
        </ProSidebar>
      </div>
      <div className="w-full h-full" style={{ backgroundColor: "#EFF3F8" }}>
        <div className={classNames("flex row-auto justify-between bg-white")}>
          {!sidebarHideStatus && (
            <Button
              className="p-button-rounded p-button-text"
              aria-label="Submit"
              onClick={(e) => {
                setSidebarHideStatus();
              }}
            >
              <i className="pi pi-bars" style={{ fontSize: 20 }}></i>
            </Button>
          )}
          <div className={classNames("flex flex-1 justify-end items-center h-14", `${sidebarHideStatus && "absolute left-14 top-1"}`)}>
            <label className="mr-4">
              Merhaba, {user.fullName} - {user.schoolFullName}
            </label>
            {user.firstLoginInfo ? (
              <prime.Menu model={menuAdminProfile} popup ref={profileMenu} id="popup_menu" />
            ) : (
              <>
                <prime.Menu model={menuItemsProfile} popup ref={profileMenu} id="popup_menu" />
              </>
            )}

            <div className="card mr-5 flex">
              <Avatar
                image={user.imageUrl ? user.imageUrl : "https://yotek-web.s3.amazonaws.com/noimage.jpg"}
                className="mr-2 w-10 h-10 items-center justify-center"
                shape="circle"
                onClick={(event) => profileMenu.current.toggle(event)}
                aria-controls="popup_menu"
                aria-haspopup
              />
            </div>
          </div>
        </div>

        <div className="">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default App;
function useRoute() {
  throw new Error("Function not implemented.");
}
function useHistory() {
  throw new Error("Function not implemented.");
}
