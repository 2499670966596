import React, { useContext, useEffect, useRef, useState } from "react";
import { ParentService, StudentService } from "../../Services";
import { useNavigate, useParams } from "react-router-dom";
import { IParent, IStudentParent, UserRole } from "../../Models";
import InputComp from "../../Components/Input/InputComp";
import { ProgressSpinner } from "primereact/progressspinner";
import ProfileImage from "../../Components/Image/ProfileImage";
import { InputMask } from "primereact/inputmask";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { Password } from "primereact/password";
import toast from "react-hot-toast";
import { TabPanel, TabView } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { classNames } from "primereact/utils";
import SkeletonComp from "../../Components/Loading/Skeleton_Card";
import SkeletonCard from "../../Components/Loading/Skeleton_Card";
import userEvent from "@testing-library/user-event";
import { AppContext } from "../../Context/Store";

const StudentCard = () => {
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const { AddParent, UpdateParent, FindParentById, GetStudentList } = ParentService();
  const studentService = StudentService();
  const params = useParams();
  const btnRef: any = useRef<HTMLButtonElement>();

  const [saveBtnTooltipText, setSaveBtnTooltipText] = useState("Click to proceed");

  const [studentList, setStudentList] = useState<IStudentParent[]>();
  const [studentListLoading, setStudentListLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [dataProgress, setDataProgress] = useState(false);
  const [parentData, setParentData] = useState<IParent>({
    name: "",
    surname: "",
    id: 0,
    mobilePhone: "",
    isActive: true,
    isUseMobileApp: true,
    password: "",
    imageUrl: "",
  });
  const [isUpdateData, setIsUpdateData] = useState(false);

  let parentId: number = params.id ? Number.parseInt(params.id) : 0;

  useEffect(() => {
    if (params.id && Number.parseInt(params.id) > 0) {
      parentId = Number.parseInt(params.id);
      GetParentById(Number.parseInt(params.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const GetParentById = async (parentId: number) => {
    setIsLoading(true);
    const { data, error } = await FindParentById(parentId);
    setIsLoading(false);

    if (error) {
      console.log("error => ", error);
    } else {
      const resData = data?.returnData as IParent;
      // console.log("FindParentById data => ", resData);

      GetStudentListForParent(resData.id);

      setParentData(resData);
      setIsUpdateData(true);
    }
  };

  const GetStudentListForParent = async (parentId: number) => {
    setStudentListLoading(true);
    const { data, error } = await GetStudentList(parentId);
    setStudentListLoading(false);
    if (error) {
      toast.error("Öğrenci listesi yüklenirken hata oluştu err");
    } else {
      if (data) {
        // console.log("GetStudentListForParent data=> ", data);
        if (data.success) {
          const _data = data?.returnData as IStudentParent[];
          // console.log("_data => ", _data);
          setStudentList(_data);
        } else {
          toast.error(`Öğrenci listesi yüklenirken hata oluştu ${data.errorMessage || ""}`);
        }
      }
    }
  };

  const btnSaveHandle = async () => {
    // console.log('parentData => ', parentData)
    btnRef.current.disabled = true;
    setDataProgress(true);

    if (parentData) {
      if (!parentData.mobilePhone && !parentData.name && !parentData.surname) {
        toast.error("Adı, soyadı ve telefon numarası boş olamaz");
        setDataProgress(false);
        return;
      }

      const { data, error } = isUpdateData ? await UpdateParent(parentData) : await AddParent(parentData);
      setDataProgress(false);
      btnRef.current.disabled = false;
      if (error) {
        toast.error("Veli eklenemedi !");
      } else {
        if (data) {
          if (data.success) {
            return navigate("/parents");
          } else {
            toast.error(`${data?.errorMessage}`);
          }
        }
      }
    } else {
      toast.error("Bilgileri eksiksiz girmeniz gerekmektedir");
    }
  };

  const accept = async (e: any, id: any) => {
    const { data, error } = await studentService.DeleteStudentParentRelation(id);

    if (data) {
      if (data.success) {
        toast.success("Silme işlemi başarılı");
        await GetStudentListForParent(parentId);
      } else {
        toast.error(`Silme işlemi başarısız ! ${data?.errorMessage}`);
      }
    }
  };

  const DeleteCagetoryHandle = async (e: any, item: any) => {
    confirm2(e, item.id);
  };
  const confirm2 = (event: any, id: any) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Bu kaydı silmek istiyor musunuz ?",
      icon: "pi pi-times-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => accept(event, id),
    });
  };

  const actionBodyTemplate = (rowData: IStudentParent) => {
    return (
      <div>
        <Button
          style={{ height: 30, width: 30, marginRight: 8 }}
          icon="pi pi-eye"
          className="p-button-rounded p-button-warning"
          onClick={() => navigate(`/students/${rowData.studentId}`)}
        />

        <Button
          // disabled={deleteBtnProgress}
          onClick={(e) => DeleteCagetoryHandle(e, rowData)}
          style={{ height: 30, width: 30, marginRight: 5 }}
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger "
        ></Button>
      </div>
    );
  };

  const isSendSmsBodyTemplate = (item: IStudentParent) => {
    return (
      <div className="flex justify-center min-w-[50px]">
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle text-green-600 text-xl": item.sendSms,
            "false-icon pi-times-circle text-red-400 text-xl": !item.sendSms,
          })}
        ></i>
      </div>
    );
  };

  const isSParentBodyTemplate = (item: IStudentParent) => {
    return (
      <div className="flex justify-center min-w-[50px]">
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle text-green-600 text-xl": item.isParent,
            "false-icon pi-times-circle text-red-400 text-xl": !item.isParent,
          })}
        ></i>
      </div>
    );
  };

  const isSUseCardBodyTemplate = (item: IStudentParent) => {
    return (
      <div className="flex justify-center min-w-[50px]">
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle text-green-600 text-xl": item.isUseCard,
            "false-icon pi-times-circle text-red-400 text-xl": !item.isUseCard,
          })}
        ></i>
      </div>
    );
  };

  const studentFullNameBodyTemplate = (item: IStudentParent) => {
    return (
      <div className="flex justify-center min-w-[50px]">
        {item.student.imageUrl.length > 0 && (
          <Tooltip target={classNames(".custom-tooltip-btn" + item.id)} autoHide>
            <img alt="logo" src={item.student.imageUrl} data-pr-tooltip="PrimeReact-Logo" className="w-60 h-60" />
          </Tooltip>
        )}

        <label className={classNames(item.student.imageUrl ? "custom-tooltip-btn" + item.id : "")}>{item.student.fullName}</label>
      </div>
    );
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <ConfirmPopup />
      {isLoading ? (
        <SkeletonCard />
      ) : (
        <>
          <div className="m-2 mb-5">
            <label className="text-2xl">{isUpdateData ? "Veli Güncelle" : "Yeni Veli ekle"}</label>
            <hr className="my-auto flex-grow-1 mt-2" />
          </div>

          <TabView>
            <TabPanel header="Genel Bilgiler">
              <div className="">
                <div className="mt-5 md:mt-0 ">
                  <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className=" bg-white p-3">
                      {/* Burası ilk satır başlangıç */}
                      <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-4 lg:col-span-4">
                          <label className="col-sm-2col-form-label">Resim </label>
                          <label className="text-gray-200"> _{parentData.id}</label>
                          <div className="w-44 h-44 border-2 rounded-md flex justify-center ">
                            <ProfileImage
                              uploadImageUrl={async (e) => {
                                console.log("parentProfileImage => ", e);
                                setParentData({ ...parentData, imageUrl: e });
                              }}
                              modalOpen={true}
                              folder="Veli"
                              value={parentData.imageUrl}
                              selectedImage={async (e) => {
                                // setParentData({ ...parentData, imageUrl: e });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-span-4 lg:col-span-2">
                          <label className="block text-sm font-medium text-gray-700">Adı</label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <InputComp
                              type="text"
                              value={parentData.name}
                              placeholder="Veli adı"
                              onChange={(e: any) => {
                                setParentData({
                                  ...parentData,
                                  name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-span-4 lg:col-span-2">
                          <label className="block text-sm font-medium text-gray-700">Soyadı</label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <InputComp
                              type="text"
                              value={parentData.surname}
                              placeholder="Veli Soyadı"
                              onChange={(e: any) => {
                                setParentData({
                                  ...parentData,
                                  surname: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-span-4 lg:col-span-2">
                          <label className="block text-sm font-medium text-gray-700">Telefon</label>
                          <div className="mt-1 rounded-md shadow-sm">
                            <InputComp
                              tooltip="Başında 0 olmadan giriniz (xxx)xxxxxxx"
                              tooltipOptions={{
                                position: "top",
                                autoHide: true,
                              }}
                              type="text"
                              value={parentData.mobilePhone}
                              placeholder="Telefon"
                              onChange={(e: any) => {
                                if (e.target.value.length > 0) {
                                  if (e.target.value.toString().startsWith("0")) {
                                    return;
                                  } else if (e.target.value.length > 10) {
                                    return;
                                  }
                                }
                                setParentData({
                                  ...parentData,
                                  mobilePhone: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-span-2 lg:col-span-1">
                          <label className="block text-sm font-medium text-gray-700">Kişi Aktifliği</label>
                          <div className="flex items-center h-11 ">
                            <InputSwitch
                              inputId="smsServiceApiActive"
                              onChange={(e) =>
                                setParentData({
                                  ...parentData,
                                  isActive: e.value,
                                })
                              }
                              checked={parentData.isActive}
                            ></InputSwitch>
                            <label htmlFor="userActive" className="ml-3">
                              {parentData.isActive ? "Kişi aktif" : "Kişi pasif"}
                            </label>
                          </div>
                        </div>
                       
                        <div className="col-span-4 lg:col-span-2">
                          <label className="block text-sm font-medium text-gray-700">Şifre (Şifre değiştirmek için doldurulur ! )</label>
                          <div className="mt-1 rounded-md shadow-sm">
                            <InputComp
                              tooltip="Şifreniz"
                              tooltipOptions={{
                                position: "top",
                                autoHide: true,
                              }}
                              type="text"
                              value={parentData.password}
                              placeholder="Sadece güncelleme yapmak istiyorsanız doldurunuz"
                              onChange={(e: any) => {
                            
                                setParentData({
                                  ...parentData,
                                  password: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <Button
                        ref={btnRef}
                        disabled={btnRef.current?.disabled}
                        loading={dataProgress}
                        onClick={() => btnSaveHandle()}
                        label="Kaydet"

                        // className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      ></Button>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel
              header="İlişkilendirmeler"
              className="tabview-header-icon"
              leftIcon={studentListLoading ? "pi pi-spin pi-spinner" : ""}
              disabled={studentList?.length == 0 || studentListLoading}
            >
              <div className=" bg-white ">
                {studentList && (
                  <DataTable
                    value={studentList}
                    stripedRows
                    emptyMessage="İlişkilendirilmiş öğrenci yok"
                    responsiveLayout="scroll"
                    loading={isLoading}
                    scrollable
                    size="small"
                    footer={`Toplam Öğrenci Sayısı: ${studentList.length}`}
                  >
                    <Column className=" max-w-[60px]" field="id" header="#"></Column>
                    <Column field="student.fullName" header="Öğrenci Adı Soyadı" body={studentFullNameBodyTemplate}></Column>
                    <Column field="student.classroom.name" header="Sınıfı"></Column>
                    <Column field="parentType" header="Yakınlık Tipi"></Column>
                    <Column field="isUsecard" className=" max-w-[120px]" header="Kart Kullanımı " body={isSUseCardBodyTemplate}></Column>
                    <Column className=" max-w-[120px]" field="isParent" header="Velisi Mi ?" body={isSParentBodyTemplate}></Column>
                    <Column className=" max-w-[120px]" field="sendSms" header="Sms Gönder" body={isSendSmsBodyTemplate}></Column>
                    <Column header="İşlemler" body={(data) => actionBodyTemplate(data)} exportable={false}></Column>

                    {/* <Column
                  className=" max-w-[120px]"
                  field="isParent"
                  header="Velisi Mi ?"
                  body={isSParentBodyTemplate}
                ></Column>
                <Column
                  className=" max-w-[120px]"
                  field="sendSms"
                  header="Sms Gönder"
                  body={isSendSmsBodyTemplate}
                ></Column> */}
                    {/*           
                <Column
                  className=""
                  header="İşlemler"
                  body={(data) => actionBodyTemplate(data)}
                  exportable={false}
                ></Column> */}
                  </DataTable>
                )}
              </div>
            </TabPanel>
          </TabView>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
      {/* <div className="p-fluid grid formgrid">
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="name">Adı :</label>
            <InputText
              value={studentData.name}
              onChange={(e) =>
                setStudentData({ ...studentData, name: e.target.value })
              }
              id="name"
              type="text"
            />
          </div>
          <div className="p-field p-col-6">
            <label htmlFor="surname">Soyadı :</label>
            <InputText
              value={studentData.surname}
              onChange={(e) =>
                setStudentData({ ...studentData, surname: e.target.value })
              }
              id="surname"
              type="text"
            />
          </div>
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="name">Kumanda No :</label>
            <InputText
              className="w-full h-11 bg-red-400"
              value={studentData.joystickSerialNumber}
              onChange={(e) =>
                setStudentData({
                  ...studentData,
                  joystickSerialNumber: e.target.value,
                })
              }
              id="name"
              type="text"
            />
          </div>

          <div className="p-field  w-36 mt-6">
            <Button className="w-36" label="Submit" aria-label="Submit" />
          </div>
        </div> */}
    </div>
  );
};

export default StudentCard;
