import { useEffect, useState } from "react";
import { IPaymentPlan, IPaymentPlanDetailWithStudentsView, IPaymentPlanWithStudentsView } from "../../Models";
import { PaymentPlanService } from "../../Services";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import toast from "react-hot-toast";
import moment from "moment";
import "moment/locale/tr"; // without this line it didn't work
import { FilterMatchMode } from "primereact/api";
import SkeletonList from "../../Components/Loading/Skeleton_List";
moment.locale("tr");

const PaymentPlanDetailWithStudents = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IPaymentPlanDetailWithStudentsView[] | null>();

  const paymentPlanService = PaymentPlanService();

  const [filters2, setFilters2] = useState({
    studentFullName: { value: null, matchMode: FilterMatchMode.CONTAINS, lng : "tr" },
  });

  useEffect(() => {
    GetList();
  }, []);

  const GetList = async () => {
    setIsLoading(true);
    const { data, error } = await paymentPlanService.PaymentPlanDetailWithStudents();
    setIsLoading(false);
    if (data) {
      if (data.success) {
        // console.log("data.returnData", data.returnData);

        setData(data.returnData as IPaymentPlanDetailWithStudentsView[]);
      } else {
        toast.error(data?.errorMessage);
      }
    } else {
      toast.error(error);
    }
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div>
        <Button
          style={{ height: 30, width: 30, marginRight: 10 }}
          icon="pi pi-eye"
          className="p-button-rounded p-button-warning"
          onClick={() => {
            console.log("rowData => ", rowData);
          }}
        />
      </div>
    );
  };

  const createDateBody = (data: IPaymentPlanDetailWithStudentsView) => {
    if (data.dueDate) {
      return <label>{moment(data.dueDate).format("DD.MM.YYYY")}</label>;
    }
  };

  const createPaidDateBody = (data: IPaymentPlanDetailWithStudentsView) => {
    if (data.paymentDate != null) {
      console.log("data.dueDate=> ", data.paymentDate);
      return <label>{moment(data.paymentDate).format("DD.MM.YYYY")}</label>;
    }
  };

  //https://primereact.org/datatable/#export
//https://stackblitz.com/run?file=src%2FApp.js
//   const exportExcel = () => {
//     import('xlsx').then((xlsx) => {
//         const worksheet = xlsx.utils.json_to_sheet(products);
//         const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
//         const excelBuffer = xlsx.write(workbook, {
//             bookType: 'xlsx',
//             type: 'array'
//         });

//         saveAsExcelFile(excelBuffer, 'products');
//     });
// };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">Ödeme Planı Tanımlı Öğrenciler Detay</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div className="">
            <ConfirmDialog />
            <div className=" ">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className=" bg-white space-y-6 ">
                  {data && (
                    <DataTable
                      scrollable
                      scrollHeight="600px"
                      scrollDirection="vertical"
                      size="small"
                      value={data}
                      className="table-auto w-full"
                      columnResizeMode="fit"
                      stripedRows
                      responsiveLayout="stack"
                      loading={isLoading}
                      emptyMessage="Ekli ödeme planı bulunmamaktadır."
                      filterDisplay="row"
                      filters={filters2}
                      footer={`Toplam Kayıt Sayısı: ${data.length}`}
                      filterDelay={300}
                     virtualScrollerOptions={{ itemSize: 40 }}
   
                    >
                      <Column sortable filter field="studentFullName" className=" min-w-[300px]" header="Öğrenci adı soyadı"></Column>
                      <Column sortable field="classroomName" header="Sınıfı" className=" min-w-[200px]"></Column>
                      <Column sortable field="planName" header="Ödeme Planı" className=" min-w-[200px]"></Column>
                      <Column sortable field="installmentNumber" header="Taksit sayısı"></Column>
                      <Column sortable field="dueDate" header="Vade Tarihi" body={(data) => createDateBody(data)}></Column>
                      <Column sortable field="monthName" header="Ay"></Column>

                      <Column sortable field="installmentAmount" header="Ödenecek tutar" body={(rowData: any) => `${rowData.installmentAmount.toLocaleString("tr-TR")} ₺`}></Column>
                      <Column sortable field="amountPaid" header="Ödenen" body={(rowData: any) => `${rowData.amountPaid.toLocaleString("tr-TR")} ₺`}></Column>
                      <Column sortable field="discount" header="Muafiyet/indirim" body={(rowData: any) => `${rowData.discount.toLocaleString("tr-TR")} ₺`}></Column>
                      <Column sortable field="remaininAmount" header="Kalan" body={(rowData: any) => `${rowData.remainingAmount.toLocaleString("tr-TR")} ₺`}></Column>

                      <Column sortable field="paidDate" header="Ödeme Tarihi" body={(data) => createPaidDateBody(data)}></Column>
                      {/* <Column
                    className="h-11 min-w-[110px]"
                    header="İşlemler"
                    body={(data) => actionBodyTemplate(data)}
                    exportable={false}
                  ></Column> */}
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentPlanDetailWithStudents;
