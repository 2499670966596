import React from "react";

const LogoutIco = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.854 7.79395C13.577 10.5089 6 19.8699 6 30.9999C6 44.2549 16.745 54.9999 30 54.9999C43.255 54.9999 54 44.2549 54 30.9999C54 19.8689 46.422 10.5079 36.144 7.79395"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M30 22V1"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M12.8535 39.1968C15.9145 45.5868 22.4415 49.9998 29.9995 49.9998C37.5565 49.9998 44.0835 45.5878 47.1445 39.1988"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M8.4834 50.4438C11.0744 53.3088 14.2294 55.6538 17.7724 57.3038"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M3.67676 43.1851C4.43876 44.8281 5.34876 46.3871 6.39176 47.8451"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M51 9H49V11H51V9Z" fill="black" />
      <path d="M54 13H52V15H54V13Z" fill="black" />
      <path d="M47 6H45V8H47V6Z" fill="black" />
    </svg>
  );
};

export default LogoutIco;
