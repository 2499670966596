import { Button } from "primereact/button";
import { useContext } from "react";
import { Navigate, useLocation, useNavigate, useRoutes } from "react-router-dom";
import { AppContext } from "../../Context/Store";
import CookieCrypto from "../../Extended-tools/CookieCrypto";
import { UserRole } from "../../Models";

export default function PrivateRoute({ roles, children }: { children: any; roles: UserRole[] }) {
  const { user, setUser } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  // const _role = UserRole[user.role.toUpperCase() as keyof typeof UserRole];

  // console.log('UserRole => ',UserRole.USER)
  // console.log("user.role => ", user.role);

  // const _role = user.role;
  // console.log("roles= ", roles);
  // console.log("_role= ", _role.toString());

  if (!user) {
    return (
      <Navigate
        to="/auth/login"
        replace={true}
        state={{
          return_url: location.pathname,
        }}
      />
    );
  } else if (roles?.includes(user.role)) {
    return children;
  }
  return (
    <div className="m-10">
      <Button
        className="block mb-10"
        onClick={() => {
          console.log("location.pathname=> ", location.pathname);
          if (location.pathname == "/") {
            CookieCrypto().RemoveCookie();
            setUser({
              userMapId: 0,
              dbName: "",
              fullName: "",
              staffId: 0,
              token: "",
              userId: 0,
              username: "",
              imageUrl: "",
              schoolFullName: "",
              role: UserRole.NEVER,
              firstLoginInfo: null,
            });
          } else {
            navigate(-1);
            // <Navigate to={`${location.pathname}`} />;
          }
        }}
        label="Geri dön"
      ></Button>

      <label className="text-2xl">Bu işlem için yetkiniz yok !</label>
    </div>
  );
}
