import moment from "moment";
import AxiosUtily from "../Extended-tools/AxiosUtility";
import { IAppSetting, IFoodMenu, ISchoolDetail } from "../Models";
import IResultDto from "../Models/Abstract/IResultDto";

const FoodMenuService = () => {
  const GetAllFoodMenu = async () => {
    try {
      const response = await AxiosUtily().get(`/foodMenu/All`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log('LoginUser service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const AddFoodMenu = async (data: IFoodMenu) => {
    try {


      const _startDate = moment(data.startDate,"DD-MM-YYYY").add(1, 'days');
      const _endDate = moment(data.endDate,"DD-MM-YYYY").add(1, 'days');

      const _data ={...data, startDate: _startDate, endDate:_endDate};

      

      const response = await AxiosUtily().post(`/foodMenu/add`, _data);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log('LoginUser service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const DeleteFoodMenu = async (id: number) => {
    try {
      const response = await AxiosUtily().delete(`/foodMenu/${id}`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log('LoginUser service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  return {
    GetAllFoodMenu,
    AddFoodMenu,
    DeleteFoodMenu,
  };
};

export { FoodMenuService };
