import { AxiosError } from "axios";
import moment from "moment";
import toast from "react-hot-toast";
import AxiosUtily from "../Extended-tools/AxiosUtility";
import { IPaymentPlan, IPaymentPlanDetailWithStudentsView, ISendSmsOverDueByStudent } from "../Models";
import IResultDto from "../Models/Abstract/IResultDto";

const PaymentPlanService = () => {
  const AddPaymentPlan = async (data: IPaymentPlan[]) => {
    try {
      // const _data = data.map((item) => {
      //   item.dueDate = moment(item.dueDate, "DD-MM-YYYY").add(1, "days") as Date | any;

      //   return item;
      // });

      const response = await AxiosUtily().post(`/payment/add`, data);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log('AddPaymentPlan service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const PaymentPlanListAll = async () => {
    try {
      const response = await AxiosUtily().get(`/Payment/all`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log('AddPaymentPlan service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GetPaymentDetailListForStudent = async (studentId: number) => {
    try {
      const response = await AxiosUtily().get(`/Payment/PaymentPlanDetailWithStudents/${studentId}`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log('AddPaymentPlan service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const PaymentPlanListAllWithDetail = async () => {
    try {
      const response = await AxiosUtily().get(`/payment/all/details`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log('AddPaymentPlan service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const Delete = async (planName: string) => {
    try {
      const response = await AxiosUtily().delete(`/payment/delete/${planName}`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log('AddPaymentPlan service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const InstallmentUpdate = async (installmentRow: IPaymentPlanDetailWithStudentsView) => {
    try {
      const response = await AxiosUtily().post(`/payment/UpdateInstallment`, installmentRow);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      // console.log("exception => ", exception.response?.data);
      return { error };
    }
  };
  const InstallmentDelete = async (rowId: number) => {
    try {
      const response = await AxiosUtily().delete(`/payment/deleteInstallment/${rowId}`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log('AddPaymentPlan service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const FindPaymentPlan = async (planName: string) => {
    try {
      const response = await AxiosUtily().get(`/payment/find/${planName}`);
      console.log("response", response);
      if (response.status === 200) {
        // console.log('AddPaymentPlan service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const AddPaymentPlanForStudents = async (studentIds: number[], paymentPlanname: string) => {
    try {
      console.log("studentIds => ", studentIds);
      console.log("paymentPlanId => ", paymentPlanname);

      const model = {
        studentIds,
        paymentPlanname,
      };

      const response = await AxiosUtily().post(`/payment/AddPaymentPlanForStudent`, model);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log('AddPaymentPlan service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      // console.log("exception => ", exception.response?.data);
      return { error };
    }
  };
  const AddPaymentPlanForStudent = async (studentId: number, paymentPlanname: string) => {
    try {
      console.log("studentId => ", studentId);
      console.log("paymentPlanId => ", paymentPlanname);

      const model = {
        studentId,
        paymentPlanname,
      };

      const response = await AxiosUtily().post(`/payment/AddPaymentPlanForStudent`, model);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      // console.log("exception => ", exception.response?.data);
      return { error };
    }
  };
  const PaymentPlanDetailWithStudents = async () => {
    try {
      const response = await AxiosUtily().get(`/Payment/PaymentPlanDetailWithStudents`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      // console.log("exception => ", exception.response?.data);
      return { error };
    }
  };
  const PaymentPlanWithStudents = async () => {
    try {
      const response = await AxiosUtily().get(`/payment/PaymentPlanWithStudents`);

      // console.log('login',response);
      if (response.status === 200) {
        //  console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      // console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const PaymentPlanWithStudentsOverdue = async () => {
    try {
      const response = await AxiosUtily().get(`/payment/PaymentPlanWithStudentsOverdue?active=1`);

      // console.log('login',response);
      if (response.status === 200) {
        //  console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      // console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const PaymentEntryForStudentList = async () => {
    try {
      const response = await AxiosUtily().get(`/payment/PaymentPlanWithStudentsOverdue`);

      // console.log('login',response);
      if (response.status === 200) {
        //   console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      // console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const SendSmsOverdueByStudentId = async (smsData: ISendSmsOverDueByStudent) => {
    try {
      const response = await AxiosUtily().post(`/payment/SendSmsOverdueByStudentId`, smsData);

      // console.log('login',response);
      if (response.status === 200) {
        //   console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      // console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const PaymentEntryForStudentListByPlanName = async (planName: string) => {
    try {
      const response = await AxiosUtily().get(`/payment/PaymentEntryForStudentListByPlanName/${planName}`);

      // console.log('login',response);
      if (response.status === 200) {
        //   console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      // console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const AddPaymentPaid = async (rowId: number, PaidAmount: number) => {
    try {
      const model = {
        rowId,
        PaidAmount,
      };

      const response = await AxiosUtily().post(`/payment/AddPaymentPaid`, model);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      // console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const AddPaidDate = async (rowId: number, paidDate: Date | null) => {
    try {
      const model = {
        rowId,
        paidDate,
      };

      const response = await AxiosUtily().post(`/payment/AddPaymentPaidDate`, model);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      // console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const UpdateBankDetail = async (model: any) => {
    try {
      const response = await AxiosUtily().post(`/payment/UpdateBankDetail`, model);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const BanksList = async () => {
    try {
      const response = await AxiosUtily().get(`/payment/Banks`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const BankFind = async (bankId: number) => {
    try {
      const response = await AxiosUtily().get(`/payment/Banks/${bankId}`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const BankUpdate = async (model: any) => {
    try {
      console.log("model => ", model);
      console.log("model2 => ", model.bankHarBaslangicTarihi.getDate());

      const response = await AxiosUtily().put(`/payment/Banks`, model);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const BankAdd = async (model: any) => {
    try {
      const response = await AxiosUtily().post(`/payment/Banks`, model);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const DeleteBank = async (bankId: number) => {
    try {
      const response = await AxiosUtily().delete(`/payment/Banks/${bankId}`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const BankTransitionAddBulk = async (model: any) => {
    try {
      const response = await AxiosUtily().post(`/payment/BankTransactions/bulk`, model);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const BankTransactionsList = async () => {
    try {
      const response = await AxiosUtily().get(`/payment/BankTransactions`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const ApplyBankTransitionToStudent = async (transactionId: number, studentId: number, forceUpdate: boolean = false) => {
    try {
      const model = {
        transactionId,
        studentId,
        forceUpdate,
      };

      const response = await AxiosUtily().post(`/payment/BankTransactions/updateStudent`, model);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const RemoveStudentFromBankTransaction = async (transactionId: number) => {
    try {
      const response = await AxiosUtily().delete(`/payment/BankTransactions/updateStudent/${transactionId}`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const ProcessedFromBankTransaction = async (transactionId: number) => {
    try {
      const response = await AxiosUtily().post(`/payment/BankTransactions/Processed/${transactionId}`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  const AutoMatchBankTransactions = async () => {
    try {
      const response = await AxiosUtily().get(`/payment/BankTransactions/AutoMatch`);

      // console.log('login',response);
      if (response.status === 200) {
        // console.log("AddPaymentPlanForStudent service response 200 ", response);
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (error: any) {
      const exception: AxiosError = error;

      console.log("exception => ", exception.response?.data);
      return { error };
    }
  };

  return {
    AddPaymentPlan,
    PaymentPlanListAll,
    PaymentPlanListAllWithDetail,
    Delete,
    FindPaymentPlan,
    AddPaymentPlanForStudents,
    AddPaymentPlanForStudent,
    PaymentPlanWithStudents,
    PaymentPlanDetailWithStudents,
    PaymentEntryForStudentList,
    AddPaymentPaid,
    AddPaidDate,
    PaymentEntryForStudentListByPlanName,
    GetPaymentDetailListForStudent,
    InstallmentDelete,
    InstallmentUpdate,
    PaymentPlanWithStudentsOverdue,
    SendSmsOverdueByStudentId,
    UpdateBankDetail,
    BanksList,
    BankFind,
    BankUpdate,
    BankAdd,
    DeleteBank,
    BankTransitionAddBulk,
    BankTransactionsList,
    ApplyBankTransitionToStudent,
    RemoveStudentFromBankTransaction,
    ProcessedFromBankTransaction,
    AutoMatchBankTransactions,
  };
};

export { PaymentPlanService };
