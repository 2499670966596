import { ConfirmDialog } from "primereact/confirmdialog";
import React, { useContext, useEffect, useState } from "react";
import SkeletonList from "../../Components/Loading/Skeleton_List";
import { PaymentPlanService } from "../../Services";
import toast from "react-hot-toast";
import { IBank, UserRole } from "../../Models";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { AppContext } from "../../Context/Store";
import { useNavigate } from "react-router-dom";

const BankList = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const paymentPlanService = PaymentPlanService();
  const [isLoading, setIsLoading] = useState(false);
  const [banks, setBanks] = useState<IBank[] | null>([]);
  const [deleteBtnProgress, setDeleteBtnProgress] = useState(false);

  useEffect(() => {
    LoadPageData();
  }, []);

  const LoadPageData = async () => {
    setIsLoading(true);
    const { data, error } = await paymentPlanService.BanksList();
    setIsLoading(false);
    if (data) {
      if (data.success) {
        setBanks(data.returnData as IBank[]);
      } else {
        setBanks(null);
        toast.error(data?.errorMessage);
      }
    }
    if (error) {
      setBanks(null);
      toast.error("Bir hata oluştu");
    }
  };

  const DeleteHandle = async (e: any, rowData: any) => {
    setDeleteBtnProgress(true);
    console.log(rowData);

    const { data, error } = await paymentPlanService.DeleteBank(rowData.id);
    setDeleteBtnProgress(false);
    if (data) {
      if (data.success) {
        toast.success(data.successMessage);
        LoadPageData();
      } else {
        toast.error(data.errorMessage);
      }
    }
    if (error) {
      toast.error("Bir hata oluştu");
    }
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div>
        <Button
          disabled={deleteBtnProgress}
          style={{ height: 30, width: 30, marginRight: 5 }}
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger "
          onClick={(e) => DeleteHandle(e, rowData)}
        ></Button>

        <Button
          style={{ height: 30, width: 30, marginRight: 5 }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-warning"
          onClick={() => navigate(`/banks/${rowData.id}`)}
        />
      </div>
    );
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">Kasa Listesi</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      <div className="flex justify-start mb-3">
        <Button icon="pi pi-plus" label="Yeni Kasa Ekle" onClick={() => navigate("/banks/new")} />
      </div>

      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div className="">
            <ConfirmDialog />
            <div className=" ">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className=" bg-white space-y-6 ">
                  {banks && (
                    <DataTable value={banks} scrollable scrollHeight="63vh" stripedRows size="small" emptyMessage="Henüz kayıt yok">
                      <Column header="İşlemler" body={(data) => actionBodyTemplate(data)} className="max-w-[100px]" exportable={false}></Column>

                      <Column field={"id"} header={"Id"} className="max-w-24"></Column>
                      <Column field={"tanim"} header={"Tanım"}></Column>
                      <Column field={"aliciAdi"} header={"Hesap / Alıcı Adı"}></Column>
                      <Column field={"iban"} header={"İban"}></Column>
                      <Column field={"hesapNo"} header={"Hesap No"}></Column>
                      <Column field={"subeKodu"} header={"Şube Kodu"}></Column>
                      <Column field={"subeAdi"} header={"Şube Adı"}></Column>
                      <Column field={"bankaAdi"} header={"Banka"}></Column>
                      <Column header={"Api Entegrasyon"} body={(rowData: IBank) => <label>{rowData.apiUsername ? "Var" : "Yok"}</label>}></Column>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BankList;
