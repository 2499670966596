import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ProfileImage from "../../Components/Image/ProfileImage";
import InputComp from "../../Components/Input/InputComp";
import SkeletonList from "../../Components/Loading/Skeleton_List";
import { IFoodMenu, IPaymentPlan } from "../../Models";
import { FoodMenuService } from "../../Services/FoodMenuService";

const FoodMenu = () => {
  const navigate = useNavigate();
  const foodMenuService = FoodMenuService();

  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [btnNewRowRefLoading, setBtnNewRowRefLoading] = useState(false);
  const btnSaveRef: any = useRef<any>();
  const btnNewRowRef: any = useRef<any>({});
  const [btnNewRowDisabled, setBtnNewRowDisabled] = useState(false);

  const [foodMenuData, setFoodMenuData] = useState<IFoodMenu[]>([
    {
      id: 0,
      startDate: new Date(),
      endDate: new Date(),
      imageUrl: "",
      index: 0,
    },
  ]);

  let emptyPlan: IFoodMenu = {
    id: 0,
    startDate: new Date(),
    endDate: new Date(),
    imageUrl: "",
    index: 0,
  };

  useEffect(() => {
    const emptyItem = foodMenuData.find((item) => item.id === 0);
    if (emptyItem) {
      setBtnNewRowDisabled(true);
    } else {
      setBtnNewRowDisabled(false);
    }
  }, [foodMenuData]);

  useEffect(() => {
    GetFoodMenuList();
  }, []);

  const GetFoodMenuList = async () => {
    setIsLoading(true);
    const { data } = await foodMenuService.GetAllFoodMenu();
    setIsLoading(false);
    if (data) {
      if (data.success) {
        const _data = data.returnData as IFoodMenu[];

        _data.forEach((item, index) => {
          item.startDate = moment(item.startDate).toDate();
          item.endDate = moment(item.endDate).toDate();
          item.index = index;
        });

        setFoodMenuData(_data);
      } else {
        toast.error(data.errorMessage);
      }
    }
  };

  const confirm2 = (data: any) => {
    confirmDialog({
      message: `Silmek istediğinize emin misiniz ?`,
      header: "Silme işlemi",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => {
        DeleteFoodMenu(data);
      },
      reject,
    });
  };

  const reject = (text: string = "") => {
    toast.error("Silme işlemi iptal edildi");
  };

  const DeleteFoodMenu = async (id: number) => {
    const { data } = await foodMenuService.DeleteFoodMenu(id);
    if (data) {
      if (data.success) {
        toast.success(data.successMessage);
        await GetFoodMenuList();
      } else {
        toast.error(data.errorMessage);
      }
    }
  };

  const addRow = () => {
    if (foodMenuData.length == 0) {
      setFoodMenuData([...foodMenuData, emptyPlan]);
    } else {
      const lastStartDate = foodMenuData[foodMenuData.length - 1].startDate;
      const lastEndDate = foodMenuData[foodMenuData.length - 1].endDate;

      const newStartDate = new Date(lastStartDate);
      newStartDate.setMonth(newStartDate.getMonth() + 1);

      const newEndDate = new Date(lastStartDate);
      newEndDate.setMonth(lastEndDate.getMonth() + 1);

      let lastIndex = foodMenuData[foodMenuData.length - 1].index;
      // if (lastIndex) {
      //   lastIndex = foodMenuData[foodMenuData.length - 1].id;
      // }
      emptyPlan.index = lastIndex + 1;
      emptyPlan.startDate = newStartDate;
      emptyPlan.endDate = newEndDate;

      setFoodMenuData([...foodMenuData, emptyPlan]);
    }
  };

  const BtnSaveHandle = async () => {
    const _item = foodMenuData.find((item) => item.id === 0);
    if (_item) {
      const { data } = await foodMenuService.AddFoodMenu(_item);
      if (data) {
        if (data.success) {
          toast.success("Ekleme işlemi başarılı");
          GetFoodMenuList();
        } else {
          toast.error(data.errorMessage);
        }
      }
    }
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <ConfirmDialog />

      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div className="m-2 mb-5">
            <label className="text-2xl">Yemek Menüsü</label>
            <hr className="my-auto flex-grow-1 mt-2" />
          </div>
          <div className="ml-2">
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <div className="grid grid-cols-3 gap-4">
                    {foodMenuData ? (
                      <>
                        <div className="col-span-1 lg:col-span-1">
                          <label className="block text-sm font-medium text-gray-700">
                            Menü Başlangıç Tarihi
                          </label>
                        </div>
                        <div className="col-span-1 lg:col-span-1">
                          <label className="block text-sm font-medium text-gray-700">
                            Menü Bitiş tarihi
                          </label>
                        </div>
                        <div className="col-span-1 lg:col-span-1">
                          <label className="block text-sm font-medium text-gray-700">
                            Menü Resmi
                          </label>
                        </div>
                        {foodMenuData.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="grid grid-cols-3 gap-4 col-span-3"
                            >
                              <div className="col-span-1 lg:col-span-1">
                                <div className="mt-1  rounded-md block">
                                  <Calendar
                                    showButtonBar
                                    locale="tr"
                                    className="mb-2 p-inputtext-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300  w-full rounded-md sm:text-sm h-10"
                                    dateFormat="dd/mm/yy"
                                    value={item.startDate}
                                    onChange={(e) => {
                                      const data = foodMenuData.map((item) => {
                                        if (item.index === index) {
                                          return {
                                            ...item,
                                            startDate: e.target.value as Date,
                                          };
                                        }
                                        return item;
                                      });
                                      setFoodMenuData(data);
                                    }}
                                  ></Calendar>
                                  <label className="ml-1">
                                    {moment(item.startDate).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </label>
                                </div>
                              </div>
                              <div className="col-span-1 lg:col-span-1">
                                <div className="mt-1 rounded-md">
                                  <Calendar
                                    showButtonBar
                                    locale="tr"
                                    className="mb-2  p-inputtext-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300  w-full rounded-md sm:text-sm h-10"
                                    dateFormat="dd/mm/yy"
                                    value={item.endDate}
                                    onChange={(e) => {
                                      const data = foodMenuData.map((item) => {
                                        if (item.index === index) {
                                          return {
                                            ...item,
                                            endDate: e.target.value as Date,
                                          };
                                        }
                                        return item;
                                      });
                                      setFoodMenuData(data);
                                    }}
                                  ></Calendar>
                                  <label className="ml-1">
                                    {moment(item.endDate).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </label>
                                </div>
                              </div>
                              <div className="col-span-1 lg:col-span-1">
                                <div className="mt-1 flex rounded-md shadow-sm">
                                  <div className="w-44 h-44 border-2 rounded-md flex justify-center">
                                    <ProfileImage
                                    
                                      uploadImageUrl={async (e) => {}}
                                      modalOpen={false}
                                      folder="FoodMenu"
                                      value={item.imageUrl}
                                      selectedImage={async (e) => {
                                        console.log("e=> ", index);

                                        const data = foodMenuData.map(
                                          (item) => {
                                            if (item.index === index) {
                                              return {
                                                ...item,
                                                imageUrl: e,
                                              };
                                            }
                                            return item;
                                          }
                                        );
                                        console.log("e data => ", data);
                                        setFoodMenuData(data);
                                      }}
                                    />
                                  </div>
                                  <Button
                                    className="ml-6 h-10"
                                    label="Sil"
                                    onClick={() => confirm2(item.id)}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : null}

                    <div className="col-span-3 lg:col-span-3">
                      <Button
                        className="h-10"
                        loading={btnNewRowRefLoading}
                        disabled={btnNewRowDisabled}
                        ref={btnNewRowRef}
                        onClick={() => addRow()}
                        label="Menü İçin Satır Ekle"
                      ></Button>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <div>
                    <Button
                      className="h-10"
                      loading={btnSaveRef.current?.disabled}
                      onClick={() => BtnSaveHandle()}
                      label="Kaydet"
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FoodMenu;
