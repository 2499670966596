
const SettingsIco = () => {
  return (
<svg width="22" height="22" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30 38C34.4183 38 38 34.4183 38 30C38 25.5817 34.4183 22 30 22C25.5817 22 22 25.5817 22 30C22 34.4183 25.5817 38 30 38Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M37.3301 20.498C40.1711 22.693 42.0001 26.133 42.0001 30C42.0001 30.624 41.9521 31.236 41.8611 31.835" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M41.2454 34.1973C41.0324 34.7673 40.7774 35.3163 40.4844 35.8413" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M20 33H18V35H20V33Z" fill="black"/>
<path d="M19 29H17V31H19V29Z" fill="black"/>
<path d="M22 37H20V39H22V37Z" fill="black"/>
<path d="M20 25H18V27H20V25Z" fill="black"/>
<path d="M22 21H20V23H22V21Z" fill="black"/>
<path d="M26.604 3L24.188 9.188L19.393 11.173L13.309 8.507L8.507 13.309L11.173 19.393L9.188 24.188L3 26.604V33.396L9.188 35.813L11.173 40.607L8.507 46.691L13.309 51.493L19.393 48.827L24.188 50.813L26.604 57H33.396L35.813 50.813L40.607 48.827L46.691 51.493L51.493 46.691L48.827 40.607L50.813 35.813L57 33.396V26.604L50.813 24.188L48.827 19.393L51.493 13.309L46.691 8.507L40.607 11.173L35.813 9.188L33.396 3H26.604Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
</svg>

  );
};

export default SettingsIco;
