import { Button } from "primereact/button";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/Store";
import { IPersonnel, IUser, UserRole } from "../../Models";
import { Loginservice, PersonnelService } from "../../Services";
import toast from "react-hot-toast";
import { Dialog } from "primereact/dialog";
import InputComp from "../../Components/Input/InputComp";
import { Password } from "primereact/password";
let denemeSayisi = 0;

const Login = () => {
  const navigate = useNavigate();
  const { user, setUser, updateImageUrl } = useContext(AppContext);
  const loginService = Loginservice();
  const personnelService = PersonnelService();
  const btnRef: any = useRef<HTMLButtonElement>();
  const btnUsernameRef: any = useRef<HTMLButtonElement>();
  const btnPasswordRef: any = useRef<HTMLInputElement>();
  const btnRefSmsVerifyOk: any = useRef<HTMLInputElement>();
  const btnRefSmsVerifyValue: any = useRef<HTMLInputElement>();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [pageLoading, setPageLoading] = useState(false);

  const [loginWithSmsCode, setLoginWithSmsCode] = useState(false);
  const [smsCode, setSmsCode] = useState("");

  const GetDetail = async (personnelId: number) => {
    setPageLoading(true);
    const { data, error } = await personnelService.FindPersonnelById(personnelId);

    setPageLoading(false);

    if (error) {
      console.log("Error LoginButtonHandle => ", error);
    } else {
      if (data) {
        if (data.success) {
          return data.returnData as IPersonnel;
          // navigate("/");
        } else {
          toast.error(data.errorMessage);
        }
      }
    }
  };

  useEffect(() => {
    btnUsernameRef.current?.focus();
  }, []);

  let location = useLocation();
  React.useEffect(() => {
    if (location.pathname === "/auth/login") {
      navigate("/", { replace: true });
    }
  }, [location]);

  useEffect(() => {
    // sms codu 4 basamaklı ise otomatik olarak giriş butonuna tıkla
    if (smsCode.toString().length == 4) {
      // btnRefSmsVerifyOk.current?.click();
    }
  }, [smsCode]);

  const btnLogin = async () => {
    if (userName.length < 3) {
      toast.error("Kullanıcı adı boş olamaz !");
      return;
    }

    if (loginWithSmsCode && smsCode.length == 0) {
      toast.error("SMS kodu boş olamaz !");
      return;
    }

    setPageLoading(true);
    const { data, error } = await loginService.LoginUser(userName, password, 0, smsCode);
    setPageLoading(false);

    if (error) {
      console.log("Error LoginButtonHandle => ", error);
    } else {
      if (data) {
        if (data.success) {
          let _user = data.returnData as IUser;
          // console.log("logged user => ", _user);
          if (_user) {
            // const _role =
            //   UserRole[_user.role.toUpperCase() as keyof typeof UserRole];

            // user.role = _role;

            setUser(_user);
            const personnelData = await GetDetail(_user.staffId);
            _user.imageUrl = personnelData ? personnelData.imageUrl : "";
            setUser(_user);
            toast.success(`Hoş geldin : ${_user.fullName}`);
          }

          // navigate("/");
        } else {
          if (data.errorMessage == "Sms Kodu İle Giriş Yapınız") {
            if (denemeSayisi < 3) {
              denemeSayisi = denemeSayisi + 1;
              setLoginWithSmsCode(true);
            } else {
              toast.error("Çok fazla deneme oldu daha sonra tekrar deneyiniz! ");
            }
          } else {
            toast.error(data.errorMessage);
            console.log(`${data?.errorMessage}`);
          }
        }
      }
    }
  };

  const renderFooter = (name: string) => {
    return (
      <div>
        <Button
          onClick={() => {
            setLoginWithSmsCode(false);
          }}
          label="İptal"
          icon="pi pi-times"
          className="p-button-text"
        />
        <Button ref={btnRefSmsVerifyOk} label="Giriş" icon="pi pi-check" onClick={() => btnLogin()} autoFocus />
      </div>
    );
  };

  return (
    <section className="h-screen">
      <div className="px-6 h-full text-gray-800">
        <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap lg:h-full   g-6 ">
          <Dialog
         
            header="Doğrulama kodunu giriniz !"
            visible={loginWithSmsCode}
          
            footer={renderFooter("displayBasic")}
            onHide={() => setLoginWithSmsCode(false)}
            closable={false}
          >
            <div className="">
              <div className="mt-1 flex rounded-md shadow-sm">
                <Password
                ref={btnRefSmsVerifyValue}
                  className={`p-inputtext-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 block w-full rounded-md sm:text-sm h-10`}
                  value={smsCode}
                  onChange={(e) => {
                    setSmsCode(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      btnRefSmsVerifyOk.current?.focus();
                    }
                  }}
                  feedback={false}
                  placeholder="doğrulama kodu"
                />
              </div>
            </div>
          </Dialog>
          <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0">
            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp" className="w-full" alt="Sample image" />
          </div>
          <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
            <div className="mb-6">
              <input
                ref={btnUsernameRef}
                onChange={(e) => setUserName(e.target.value)}
                type="text"
                className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="Kullanıcı adı"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    btnPasswordRef.current?.focus();
                  }
                }}
              />
            </div>

            <div className="mb-6">
              <input
                ref={btnPasswordRef}
                onChange={(e) => setPassword(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    btnRef.current.click();
                  }
                }}
                type="password"
                className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="Şifre"
              />
            </div>

            <div className="text-start lg:text-left">
              <Button
                ref={btnRef}
                loading={pageLoading}
                onClick={btnLogin}
                label="Giriş yap"
                type="button"
                className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              ></Button>
              <br></br>
              <br></br>
              <p className="text-sm font-semibold mt-2 pt-1 mb-0">
                Yardıma mı ihtiyacın var ?<br></br>
                <a
                  className="text-red-600 hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out"
                  target={"_blank"}
                  href={`https://wa.me/905545319424?text=Merhaba ProOkul yönetim paneli üzerinde hesap oluşturmak istiyorum`} rel="noreferrer"
                >
                  {"       WhatsApp iletişimi için tıklayın"}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
