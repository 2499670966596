import React from "react";

const Loading = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100px"
      height="100px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <g transform="translate(79,50)">
        <g transform="rotate(0)">
          <circle cx="0" cy="0" r="4" fill="#ff727d" fillOpacity="1">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-1.559454191033138s"
              values="0.91 0.91;1 1"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
              values="1;0"
              begin="-1.559454191033138s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(72.21528885045036,68.64084068090963)">
        <g transform="rotate(40)">
          <circle
            cx="0"
            cy="0"
            r="4"
            fill="#ff727d"
            fillOpacity="0.8888888888888888"
          >
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-1.3645224171539958s"
              values="0.91 0.91;1 1"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
              values="1;0"
              begin="-1.3645224171539958s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(55.03579715234098,78.55942483735403)">
        <g transform="rotate(80)">
          <circle
            cx="0"
            cy="0"
            r="4"
            fill="#ff727d"
            fillOpacity="0.7777777777777778"
          >
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-1.1695906432748535s"
              values="0.91 0.91;1 1"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
              values="1;0"
              begin="-1.1695906432748535s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(35.50000000000001,75.11473670974873)">
        <g transform="rotate(119.99999999999999)">
          <circle
            cx="0"
            cy="0"
            r="4"
            fill="#ff727d"
            fillOpacity="0.6666666666666666"
          >
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.9746588693957113s"
              values="0.91 0.91;1 1"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.9746588693957113s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(22.74891399720866,59.918584156444396)">
        <g transform="rotate(160)">
          <circle
            cx="0"
            cy="0"
            r="4"
            fill="#ff727d"
            fillOpacity="0.5555555555555556"
          >
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.779727095516569s"
              values="0.91 0.91;1 1"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.779727095516569s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(22.748913997208657,40.08141584355561)">
        <g transform="rotate(200)">
          <circle
            cx="0"
            cy="0"
            r="4"
            fill="#ff727d"
            fillOpacity="0.4444444444444444"
          >
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.5847953216374268s"
              values="0.91 0.91;1 1"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.5847953216374268s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(35.499999999999986,24.885263290251284)">
        <g transform="rotate(239.99999999999997)">
          <circle
            cx="0"
            cy="0"
            r="4"
            fill="#ff727d"
            fillOpacity="0.3333333333333333"
          >
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.3898635477582845s"
              values="0.91 0.91;1 1"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.3898635477582845s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(55.03579715234097,21.440575162645963)">
        <g transform="rotate(280)">
          <circle
            cx="0"
            cy="0"
            r="4"
            fill="#ff727d"
            fillOpacity="0.2222222222222222"
          >
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.19493177387914226s"
              values="0.91 0.91;1 1"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.19493177387914226s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(72.21528885045035,31.359159319090352)">
        <g transform="rotate(320)">
          <circle
            cx="0"
            cy="0"
            r="4"
            fill="#ff727d"
            fillOpacity="0.1111111111111111"
          >
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="0s"
              values="0.91 0.91;1 1"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="1.7543859649122806s"
              repeatCount="indefinite"
              values="1;0"
              begin="0s"
            ></animate>
          </circle>
        </g>
      </g>
    </svg>
  );
};

export default Loading;
