import React, { useEffect, useState } from "react";
import InputComp from "../../Components/Input/InputComp";
import { IClassroom, IParent, IStudent } from "../../Models";
import { ParentService, StudentService } from "../../Services";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import toast, { Toaster } from "react-hot-toast";
import ProfileImage from "../../Components/Image/ProfileImage";
import SkeletonCard from "../../Components/Loading/Skeleton_Card";
import { AutoComplete } from "primereact/autocomplete";

let lastOpenFormFileUrl = "";

const FastStudentParentInsert = () => {
  const navigate = useNavigate();

  const studentService = StudentService();
  const parentService = ParentService();
  const btnSaveRef: any = React.createRef<HTMLButtonElement>();

  const [isLoading, setIsLoading] = useState(false);

  const [classroomList, setClassroomList] = useState<any>();
  const [tcNumarasiError, setTcNumarasiError] = useState("");
  const [veli1TelefonError, setVeli1TelefonError] = useState("");
  const [veli2TelefonError, setVeli2TelefonError] = useState("");
  const [veli3TelefonError, setVeli3TelefonError] = useState("");
  const [veli4TelefonError, setVeli4TelefonError] = useState("");

  const [studentNameSurname, setStudentNameSurname] = useState("");
  const [selectedClassroom, setSelectedClassroom] = useState<any>();
  const [query, setQuery] = useState("");
  const [loadStudentNameFind, setLoadStudentNameFind] = useState(false);
  const [anyStudent, setAnyStudent] = useState(false);

  const [anyVeli1, setAnyVeli1] = useState<IParent | null>();
  const [anyVeli2, setAnyVeli2] = useState<IParent | null>();
  const [anyVeli3, setAnyVeli3] = useState<IParent | null>();
  const [anyVeli4, setAnyVeli4] = useState<IParent | null>();

  const [queryVeli, setQueryVeli] = useState({ query: "", type: "", parentIndex: 0 });

  const [insertData, setData] = useState({
    studentNameSurname: "",
    selectedClassroomId: "",
    selectedGender: "",
    tcNumber: "",
    studentImageUrl: "",
    studentFormUrl: "",
    isUseMobile: true,
    parent1NameSurname: "",
    parent1Type: "",
    parent1MobilePhone: "",
    parent1WantsCard: false,
    parent1ImageUrl: "",
    parent1IsParent: false,
    parent2NameSurname: "",
    parent2Type: "",
    parent2MobilePhone: "",
    parent2WantsCard: false,
    parent2ImageUrl: "",
    parent2IsParent: false,
    parent3NameSurname: "",
    parent3Type: "",
    parent3MobilePhone: "",
    parent3WantsCard: false,
    parent3ImageUrl: "",
    parent3IsParent: false,
    parent4NameSurname: "",
    parent4Type: "",
    parent4MobilePhone: "",
    parent4WantsCard: false,
    parent4ImageUrl: "",
    parent4IsParent: false,
  });

  const genderType = ["Kız", "Erkek"];

  const yakinlikTipi = [
    "ABİ",
    "ABLA",
    "AİLE ARKADAŞI",
    "AKRABA",
    "AMCA",
    "ANNE",
    "ANNEANNE",
    "BABA",
    "BABAANNE",
    "BAKICI",
    "ÇALIŞAN",
    "DAYI",
    "DEDE",
    "ENİŞTE",
    "HALA",
    "KARDEŞ",
    "KOMŞU",
    "KUZEN",
    "NENE",
    "SERVİSÇİ",
    "ŞOFÖR",
    "TEYZE",
    "YENGE",
    "YÖNETİCİ",
    "DİĞER",
  ];

  useEffect(() => {
    ClassroomList();
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => FindStudentName(query), 1000);
    return () => clearTimeout(timeOutId);
  }, [query]);

  useEffect(() => {
    const timeOutId = setTimeout(() => FindParent(queryVeli.query, queryVeli.type, queryVeli.parentIndex), 1000);
    return () => clearTimeout(timeOutId);
  }, [queryVeli]);

  const FindStudentName = async (studentNameSurname: string) => {
    if (studentNameSurname.trim().length < 5) {
      return;
    }
    setLoadStudentNameFind(true);

    const { data, error } = await studentService.FindStudentByNameSurname(studentNameSurname);
    setLoadStudentNameFind(false);

    if (data) {
      if (data.success) {
        setAnyStudent(false);
      } else {
        setAnyStudent(true);
        toast.error(data.errorMessage, {
          duration: 4000,
          position: "top-center",
        });

        // toast.error(data?.errorMessage);
      }
    }

    if (error) {
      toast.error(error, {
        duration: 4000,
        position: "top-center",
      });
    }
  };

  const FindParent = async (query: string, type: string, parentIndex: number) => {
    if (type == "nameSurname") {
      if (query.trim().length < 5) {
        return;
      }

      setAnyVeli1(null);
      setAnyVeli2(null);
      setAnyVeli3(null);
      setAnyVeli4(null);

      const { data, error } = await parentService.FindByNameSurname(query);

      if (data) {
        if (!data.success) {
          // console.log("FindVeli1 _data => ", data);

          toast.error(data.errorMessage, {
            duration: 4000,
            position: "top-center",
          });
          if (parentIndex == 1) {
            setAnyVeli1(data.returnData as IParent);
          } else if (parentIndex == 2) {
            setAnyVeli2(data.returnData as IParent);
          } else if (parentIndex == 3) {
            setAnyVeli3(data.returnData as IParent);
          } else if (parentIndex == 4) {
            setAnyVeli4(data.returnData as IParent);
          }
        }
      }
      if (error) {
        toast.error(error, {
          duration: 4000,
          position: "top-center",
        });
      }
    } else {
      if (query.trim().length != 10) {
        return;
      }

      setAnyVeli1(null);
      setAnyVeli2(null);
      setAnyVeli3(null);
      setAnyVeli4(null);

      const { data, error } = await parentService.FindByMobilePhone(query);

      if (data) {
        if (!data.success) {
          // console.log("FindVeli1 _data => ", data);
          toast.error(data.errorMessage, {
            duration: 4000,
            position: "top-center",
          });

          if (parentIndex == 1) {
            setAnyVeli1(data.returnData as IParent);
          } else if (parentIndex == 2) {
            setAnyVeli2(data.returnData as IParent);
          } else if (parentIndex == 3) {
            setAnyVeli3(data.returnData as IParent);
          } else if (parentIndex == 4) {
            setAnyVeli4(data.returnData as IParent);
          }
        }
      }
      if (error) {
        toast.error(error, {
          duration: 4000,
          position: "top-center",
        });
      }
    }
  };

  const ClassroomList = async () => {
    setIsLoading(true);
    const { data, error } = await studentService.GetClassroomList();
    setIsLoading(false);
    if (data) {
      if (data.success) {
        const _data = data.returnData as IClassroom[];

        let listObj: any[] = [];

        for (let i = 0; i < _data.length; i++) {
          const obj = {
            name: _data[i].name,
            code: _data[i].id,
          };
          listObj.push(obj);
        }

        setClassroomList(listObj);
      } else {
        toast.error(data?.errorMessage);
      }
    }
  };

  const Temizle = () => {
    setData({
      studentNameSurname: "",
      selectedClassroomId: selectedClassroom.code,
      selectedGender: "",
      tcNumber: "",
      studentImageUrl: "",
      studentFormUrl: "",
      isUseMobile: true,
      parent1NameSurname: "",
      parent1Type: "",
      parent1MobilePhone: "",
      parent1WantsCard: false,
      parent1ImageUrl: "",
      parent2NameSurname: "",
      parent2Type: "",
      parent2MobilePhone: "",
      parent2WantsCard: false,
      parent2ImageUrl: "",
      parent3NameSurname: "",
      parent3Type: "",
      parent3MobilePhone: "",
      parent3WantsCard: false,
      parent3ImageUrl: "",
      parent4NameSurname: "",
      parent4Type: "",
      parent4MobilePhone: "",
      parent4WantsCard: false,
      parent4ImageUrl: "",

      parent1IsParent: false,
      parent2IsParent: false,
      parent3IsParent: false,
      parent4IsParent: false,
    });

    lastOpenFormFileUrl = "";
  };

  const DropdownSelectedClassroomTemplate = (option: any, props: any) => {
    if (option) {
      if (option.name) {
        return (
          <div className="flex justify-between " style={{ marginTop: -7 }}>
            <span>{option.name}</span>
          </div>
        );
      } else {
        return (
          <div className="flex justify-between " style={{ marginTop: -7 }}>
            <span>{option}</span>
          </div>
        );
      }
    } else {
      return (
        <div className="flex " style={{ marginTop: -6 }}>
          {props.placeholder}
        </div>
      );
    }
  };
  const ClassroomTemplate = (option: any) => {
    if (option.name) {
      return <span>{option.name}</span>;
    } else {
      return <span>{option}</span>;
    }
  };

  const parentTypeDropDownOptionTemplate = (option: any) => {
    return <span>{option}</span>;
  };

  const parentTypeDropDownTemplate = (option: string, props: any) => {
    if (option) {
      return (
        <div className="flex justify-between " style={{ marginTop: -6 }}>
          <span>{option}</span>
        </div>
      );
    }

    return (
      <div className="flex " style={{ marginTop: -4 }}>
        {props.placeholder}
      </div>
    );
  };

  const DataControl = (): boolean => {
    if (insertData.studentNameSurname.trim().length == 0 || !insertData.studentNameSurname.trim().includes(" ")) {
      toast.error("Öğrenci ismi boş olamaz ve ad soyad olarak giriş yapılmalı");
      return false;
    }

    if (insertData.selectedClassroomId == "") {
      toast.error("Sınıf seçimi yapılmalıdır!");
      return false;
    }

    if (
      insertData.parent1NameSurname.trim().length == 0 &&
      insertData.parent2NameSurname.trim().length == 0 &&
      insertData.parent3NameSurname.trim().length == 0 &&
      insertData.parent4NameSurname.trim().length == 0
    ) {
      toast.error("En az 1 veli girişi yapılmalı");
      return false;
    }
    
    // Veli 1 kişi kontrolü
    if (insertData.tcNumber != "" && insertData.tcNumber.length != 11) {
      toast.error("Tc Numarası 11 karakter olmalı !");
      return false;
    }

    // Veli 1 kişi kontrolü
    if (insertData.parent1NameSurname.trim().length > 0 && !insertData.parent1NameSurname.trim().includes(" ")) {
      toast.error("Veli 1, ad ve soyad olarak giriş yapılmalı");
      return false;
    }
    //veli 1 ismi var ise diğer şartları kontrol et !
    if (insertData.parent1NameSurname.length > 0) {
      //veli 1 ismi var ise diğer şartları kontrol et !
      if (insertData.parent1Type == "") {
        toast.error("Veli 1 yakınlık seçimi yapılmalı !");
        return false;
      }

      if (insertData.parent1MobilePhone != "" && insertData.parent1MobilePhone.length != 10) {
        toast.error("Veli 1 telefon numarası 10 karakter olmalı !");
        return false;
      }
    }

    // Veli 2 kişi kontrolü
    if (insertData.parent2NameSurname.trim().length > 0 && !insertData.parent2NameSurname.trim().includes(" ")) {
      toast.error("Veli 2, ad ve soyad olarak giriş yapılmalı");
      return false;
    }
    //veli 2 ismi var ise diğer şartları kontrol et !
    if (insertData.parent2NameSurname.length > 0) {
      //veli 2 ismi var ise diğer şartları kontrol et !
      if (insertData.parent2Type == "") {
        toast.error("Veli 2 yakınlık seçimi yapılmalı !");
        return false;
      }

      if (insertData.parent2MobilePhone != "" && insertData.parent2MobilePhone.length != 10) {
        toast.error("Veli 2 telefon numarası 10 karakter olmalı !");
        return false;
      }
    }

    return true;
  };

  const btnSaveHandle = async () => {
    // console.log("Data => ", JSON.stringify(insertData));
    btnSaveRef.current.disabled = true;
    if (!DataControl()) {
      btnSaveRef.current.disabled = false;
      return;
    }
    const { data, error } = await studentService.AddFastStudentWithParent(insertData);
    btnSaveRef.current.disabled = false;
    if (!error) {
      if (data) {
        if (data.success) {
          toast.success("Ekleme işlemi başarılı.");
          Temizle();
        } else {
          toast.error(data?.errorMessage ? data.errorMessage : "Hata oluştu");
        }
      }
    } else {
      toast.error("Hata oluştu" + error);
    }
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white ">
      <div className="m-2 mb-5">
        <label className="text-2xl">Veri Girişi</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      {isLoading ? (
        <SkeletonCard />
      ) : (
        <>
          <div className="">
            <div className="-m-5">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-2 py-5 bg-white space-y-6">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-8 ">
                      <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 lg:col-span-12">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">Mobil Kullanımı</label>
                            <div className="flex items-center h-11 ">
                              <InputSwitch
                                className="min-w-[50px]"
                                inputId="smsServiceApiActive"
                                onChange={(e) =>
                                  setData({
                                    ...insertData,
                                    isUseMobile: e.value,
                                  })
                                }
                                checked={insertData.isUseMobile}
                              ></InputSwitch>
                              {insertData.isUseMobile && (
                                <label className="text-red-500 ml-4 ">Öğrenci mobil kullanımı için ücretlendirilecek. Mobil uygulama kullanmayacak ise lütfen pasif yapınız.</label>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* sınıf */}
                        <div className="col-span-6 ">
                          <label className="block text-sm font-medium text-gray-700">Sınıf :</label>
                          <div className="mt-1 flex rounded-md">
                            <Dropdown
                              className="h-9 w-full"
                              value={selectedClassroom}
                              options={classroomList}
                              onChange={(e) => {
                                setData({
                                  ...insertData,
                                  selectedClassroomId: e.target.value.code,
                                });
                                setSelectedClassroom(e.target.value);
                              }}
                              optionLabel="name"
                              placeholder="Sınıf seçiniz"
                              valueTemplate={DropdownSelectedClassroomTemplate}
                              itemTemplate={ClassroomTemplate}
                            />
                          </div>
                        </div>
                        {/* çocuk cisnyeti */}
                        <div className="col-span-6 ">
                          <label className="block text-sm font-medium text-gray-700">Cinstiyet :</label>
                          <div className="mt-1 flex rounded-md">
                            <Dropdown
                              className="h-9 w-full"
                              value={insertData.selectedGender}
                              options={genderType}
                              onChange={(e) => {
                                setData({
                                  ...insertData,
                                  selectedGender: e.target.value,
                                });
                              }}
                              placeholder="Cinsiyet seçiniz"
                              valueTemplate={DropdownSelectedClassroomTemplate}
                              itemTemplate={ClassroomTemplate}
                            />
                          </div>
                        </div>
                        {/* çocuk ismi ve soyismiselectedClassroomId */}
                        <div className="col-span-6 pt-2">
                          <label className="block text-sm font-medium text-gray-700">Öğrenci Adı Soyadı :</label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <InputComp
                              // anyStudent true ise border kırmızı olsun
                              className={anyStudent ? "border-red-500 border-2 w-full" : "  w-full"}
                              disabled={loadStudentNameFind}
                              autoComplete="off"
                              type="text"
                              name="name"
                              placeholder="Adı Soyadı"
                              value={insertData.studentNameSurname}
                              onChange={(e) => {
                                setData({
                                  ...insertData,
                                  studentNameSurname: e.target.value,
                                });
                              }}
                              onBlur={(e) => setQuery(e.target.value)}
                            />
                          </div>
                          {anyStudent ? <label className="block text-lg bold text-red-500">Aynı isimde öğrenci mevcut</label> : null}
                        </div>
                        {/* çocuk Tc */}
                        <div className="col-span-6 pt-2">
                          <label className="block text-sm font-medium text-gray-700">Tc Numarası:</label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <InputComp
                              autoComplete="off"
                              type="text"
                              name="name"
                              placeholder="(boş bırakabilirsiniz)"
                              className="w-full"
                              value={insertData.tcNumber}
                              onChange={(e) => {
                                const _data = e.target.value as string;

                                if (_data.length > 11) {
                                  setTcNumarasiError("11 karakterden fazla giriş yapılamaz.");
                                  return;
                                }

                                setTcNumarasiError("");
                                setData({
                                  ...insertData,
                                  tcNumber: e.target.value,
                                });
                              }}
                            />
                            {tcNumarasiError && <label className="text-red-500 ml-4 ">{tcNumarasiError}</label>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-4  border flex justify-around">
                      <div className="">
                        <label className="block text-lg font-medium text-red-400">Öğrenci formu :</label>
                        <ProfileImage
                          uploadImageUrl={async (e) => {
                            setData({
                              ...insertData,
                              studentFormUrl: e,
                            });
                          }}
                          OpenedFormFileUrl={async (e) => {
                            if (e.length > 0) {
                              lastOpenFormFileUrl = e;
                            }
                          }}
                          modalOpen={false}
                          folder="Form"
                          value={insertData.studentFormUrl}
                          selectedImage={async (e) => {}}
                        />
                      </div>
                      <div className="">
                        <label className="block text-sm font-medium text-gray-700">Öğrenci resmi :</label>
                        <ProfileImage
                          lastOpenFormFileUrl={lastOpenFormFileUrl}
                          OpenedFormFileUrl={async (e) => {
                            if (e.length > 0) {
                              lastOpenFormFileUrl = e;
                            }
                          }}
                          uploadImageUrl={async (e) => {
                            setData({
                              ...insertData,
                              studentImageUrl: e,
                            });
                          }}
                          modalOpen={true}
                          folder="Ogrenci"
                          value={insertData.studentImageUrl}
                          selectedImage={async (e) => {}}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-12 grid-flow-col gap-4">
                    {/* veli 1 bilgileri */}
                    <div className="col-span-6 gap-4 row-span-1">
                      <div className="grid grid-cols-12 grid-flow-col gap-4">
                        <div className="col-span-8 ">
                          {/* veli 1 adı soyadı */}
                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">Veli 1 Adı Soyadı :</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputComp
                                autoComplete="off"
                                type="text"
                                name="name"
                                placeholder="Adı Soyadı"
                                className="w-full"
                                value={insertData.parent1NameSurname}
                                onChange={(e) => {
                                  setData((prevState) => ({
                                    ...prevState,
                                    parent1NameSurname: e.target.value,
                                  }));
                                }}
                                onBlur={(e) => {
                                  setQueryVeli({
                                    query: e.target.value,
                                    type: "nameSurname",
                                    parentIndex: 1,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* veli 1 yakınlık seçimi */}
                          <div className="">
                            <label className="block text-sm font-medium text-gray-700 pt-2">Veli 1 Yakınlık Seçimi:</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Dropdown
                                value={insertData.parent1Type}
                                options={yakinlikTipi}
                                onChange={(e) => {
                                  if (e.value == "BABA" || e.value == "ANNE") {
                                    setData({
                                      ...insertData,
                                      parent1IsParent: true,
                                      parent1Type: e.value,
                                    });
                                  } else {
                                    setData({
                                      ...insertData,
                                      parent1IsParent: false,
                                      parent1Type: e.value,
                                    });
                                  }
                                }}
                                placeholder="Yakınlık Seçiniz"
                                className="w-full h-10 "
                                valueTemplate={parentTypeDropDownTemplate}
                                itemTemplate={parentTypeDropDownOptionTemplate}
                              />
                            </div>
                          </div>
                          {/* veli 1 cep telefon */}
                          <div className=" grid grid-cols-12  ">
                            <div className="col-span-5 mt-2  ">
                              <label className="block text-sm font-medium text-gray-700">Veli 1 cep telefon:</label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <InputComp
                                  autoComplete="off"
                                  type="text"
                                  name="name"
                                  placeholder="sıfır olmadan"
                                  className="w-full"
                                  value={insertData.parent1MobilePhone}
                                  onChange={(e) => {
                                    const _data = e.target.value as string;

                                    if (_data.length > 10) {
                                      setVeli1TelefonError("10 karakterden fazla giriş yapılamaz.");
                                      return;
                                    }
                                    setVeli1TelefonError("");

                                    setData((prevState) => ({
                                      ...prevState,
                                      parent1MobilePhone: e.target.value,
                                    }));

                                    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                                    if (!regex.test(e.target.value)) {
                                      if (e.target.value.trim().length != 0) {
                                        setVeli1TelefonError("Geçersiz telefon numarası");
                                      }
                                      return;
                                    }
                                  }}
                                  onBlur={(e) => {
                                    setQueryVeli({
                                      query: e.target.value,
                                      type: "mobilePhone",
                                      parentIndex: 1,
                                    });
                                  }}
                                />
                              </div>
                              {veli1TelefonError && <label className="text-red-500 ml-4 ">{veli1TelefonError}</label>}
                            </div>
                            {/* veli 1 Kart istiyor */}
                            <div className="col-span-3 mt-2 ml-2 ">
                              <label className="block text-sm font-medium text-gray-700">Velisi mi ?</label>
                              <div className="flex items-center h-11 ">
                                <InputSwitch
                                  inputId="smsServiceApiActive"
                                  checked={insertData.parent1IsParent}
                                  onChange={(e) => {
                                    setData({
                                      ...insertData,
                                      parent1IsParent: e.value,
                                    });
                                  }}
                                ></InputSwitch>
                              </div>
                            </div>
                            <div className="col-span-4 mt-2 ml-2 ">
                              <label className="block text-sm font-medium text-gray-700">Kart istiyor</label>
                              <div className="flex items-center h-11">
                                <InputSwitch
                                  inputId="smsServiceApiActive"
                                  checked={insertData.parent1WantsCard}
                                  onChange={(e) => {
                                    setData({
                                      ...insertData,
                                      parent1WantsCard: e.value,
                                    });
                                  }}
                                ></InputSwitch>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* veli 1 profil resmi */}
                        <div className="col-span-4  border rounded-md">
                          <div className="mt-4 flex justify-center">
                            <ProfileImage
                              lastOpenFormFileUrl={lastOpenFormFileUrl}
                              OpenedFormFileUrl={async (e) => {
                                if (e.length > 0) {
                                  lastOpenFormFileUrl = e;
                                }
                              }}
                              uploadImageUrl={async (e) => {
                                setData({
                                  ...insertData,
                                  parent1ImageUrl: e,
                                });
                              }}
                              modalOpen={true}
                              folder="Veli"
                              value={insertData.parent1ImageUrl}
                              selectedImage={async (e) => {}}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Veli 2 bilgileri */}
                    <div className="col-span-6 gap-4 row-span-1">
                      <div className="grid grid-cols-12 grid-flow-col gap-4">
                        <div className="col-span-8 ">
                          {/* veli 2 adı soyadı */}
                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">Veli 2 Adı Soyadı :</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputComp
                                autoComplete="off"
                                type="text"
                                name="name"
                                placeholder="Adı Soyadı"
                                className="w-full"
                                value={insertData.parent2NameSurname}
                                onChange={(e) => {
                                  setData((prevState) => ({
                                    ...prevState,
                                    parent2NameSurname: e.target.value,
                                  }));
                                }}
                                onBlur={(e) => {
                                  setQueryVeli({
                                    query: e.target.value,
                                    type: "nameSurname",
                                    parentIndex: 2,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* veli 2 yakınlık seçimi */}
                          <div className="">
                            <label className="block text-sm font-medium text-gray-700 pt-2">Veli 2 Yakınlık Seçimi:</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Dropdown
                                value={insertData.parent2Type}
                                options={yakinlikTipi}
                                onChange={(e) => {
                                  if (e.value == "BABA" || e.value == "ANNE") {
                                    setData({
                                      ...insertData,
                                      parent2IsParent: true,
                                      parent2Type: e.value,
                                    });
                                  } else {
                                    setData({
                                      ...insertData,
                                      parent2IsParent: false,
                                      parent2Type: e.value,
                                    });
                                  }
                                }}
                                placeholder="Yakınlık Seçiniz"
                                className="w-full h-10 "
                                valueTemplate={parentTypeDropDownTemplate}
                                itemTemplate={parentTypeDropDownOptionTemplate}
                              />
                            </div>
                          </div>
                          {/* veli 2 cep telefon */}
                          <div className=" grid grid-cols-12  ">
                            <div className="col-span-5 mt-2  ">
                              <label className="block text-sm font-medium text-gray-700">Veli 2 cep telefon:</label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <InputComp
                                  autoComplete="off"
                                  type="text"
                                  placeholder="sıfır olmadan"
                                  className="w-full"
                                  value={insertData.parent2MobilePhone}
                                  onChange={(e) => {
                                    const _data = e.target.value as string;

                                    if (_data.length > 10) {
                                      setVeli2TelefonError("10 karakterden fazla giriş yapılamaz.");
                                      return;
                                    }
                                    setVeli2TelefonError("");

                                    setData((prevState) => ({
                                      ...prevState,
                                      parent2MobilePhone: e.target.value,
                                    }));

                                    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                                    if (!regex.test(e.target.value)) {
                                      if (e.target.value.trim().length != 0) {
                                        setVeli2TelefonError("Geçersiz telefon numarası");
                                      }
                                      return;
                                    }
                                  }}
                                  onBlur={(e) => {
                                    if (e.target.value.length > 0) {
                                      setQueryVeli({
                                        query: e.target.value,
                                        type: "mobilePhone",
                                        parentIndex: 2,
                                      });
                                    }
                                  }}
                                />
                              </div>
                              {veli2TelefonError && <label className="text-red-500 ml-4 ">{veli2TelefonError}</label>}
                            </div>
                            {/* veli 2 Kart istior  */}
                            <div className="col-span-3 mt-2 ml-2 ">
                              <label className="block text-sm font-medium text-gray-700">Velisi mi ?</label>
                              <div className="flex items-center h-11 ">
                                <InputSwitch
                                  inputId="smsServiceApiActive"
                                  checked={insertData.parent2IsParent}
                                  onChange={(e) => {
                                    setData({
                                      ...insertData,
                                      parent2IsParent: e.value,
                                    });
                                  }}
                                ></InputSwitch>
                                {/* <label htmlFor="userActive" className="ml-3">
                              {insertData.parent2IsParent ? "Evet" : "Hayır"}
                            </label> */}
                              </div>
                            </div>
                            <div className="col-span-4 mt-2 ml-2 ">
                              <label className="block text-sm font-medium text-gray-700">Kart istiyor</label>
                              <div className="flex items-center h-11 ">
                                <InputSwitch
                                  inputId="smsServiceApiActive"
                                  checked={insertData.parent2WantsCard}
                                  onChange={(e) => {
                                    setData({
                                      ...insertData,
                                      parent2WantsCard: e.value,
                                    });
                                  }}
                                ></InputSwitch>
                                {/* <label htmlFor="userActive" className="ml-3">
                              {insertData.parent2WantsCard ? "Evet" : "Hayır"}
                            </label> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* veli 2 profil resmi */}
                        <div className="col-span-4  border rounded-md">
                          <div className="mt-4 flex justify-center">
                            <ProfileImage
                              lastOpenFormFileUrl={lastOpenFormFileUrl}
                              OpenedFormFileUrl={async (e) => {
                                if (e.length > 0) {
                                  lastOpenFormFileUrl = e;
                                }
                              }}
                              uploadImageUrl={async (e) => {
                                setData({
                                  ...insertData,
                                  parent2ImageUrl: e,
                                });
                              }}
                              modalOpen={true}
                              folder="Veli"
                              value={insertData.parent2ImageUrl}
                              selectedImage={async (e) => {}}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-auto flex-grow-1 mt-2" />
                  <div className="grid grid-cols-12 grid-flow-col gap-4">
                    {/* veli 3 bilgileri */}
                    <div className="col-span-6 gap-4 row-span-1">
                      <div className="grid grid-cols-12 grid-flow-col gap-4">
                        <div className="col-span-8 ">
                          {/* veli 3 adı soyadı */}
                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">Veli 3 Adı Soyadı :</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputComp
                                autoComplete="off"
                                type="text"
                                name="name"
                                placeholder="Adı Soyadı"
                                className="w-full"
                                value={insertData.parent3NameSurname}
                                onChange={(e) => {
                                  setData((prevState) => ({
                                    ...prevState,
                                    parent3NameSurname: e.target.value,
                                  }));
                                }}
                                onBlur={(e) => {
                                  setQueryVeli({
                                    query: e.target.value,
                                    type: "nameSurname",
                                    parentIndex: 3,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* veli 3 yakınlık seçimi */}
                          <div className="">
                            <label className="block text-sm font-medium text-gray-700 pt-2">Veli 3 Yakınlık Seçimi:</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Dropdown
                                value={insertData.parent3Type}
                                options={yakinlikTipi}
                                onChange={(e) => {
                                  if (e.value == "BABA" || e.value == "ANNE") {
                                    setData({
                                      ...insertData,
                                      parent3IsParent: true,
                                      parent3Type: e.value,
                                    });
                                  } else {
                                    setData({
                                      ...insertData,
                                      parent3IsParent: false,
                                      parent3Type: e.value,
                                    });
                                  }
                                }}
                                placeholder="Yakınlık Seçiniz"
                                className="w-full h-10 "
                                valueTemplate={parentTypeDropDownTemplate}
                                itemTemplate={parentTypeDropDownOptionTemplate}
                              />
                            </div>
                          </div>
                          {/* veli 3 cep telefon */}
                          <div className=" grid grid-cols-12  ">
                            <div className="col-span-5 mt-2  ">
                              <label className="block text-sm font-medium text-gray-700">Veli 3 cep telefon:</label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <InputComp
                                  autoComplete="off"
                                  type="text"
                                  name="name"
                                  placeholder="sıfır olmadan"
                                  className="w-full"
                                  value={insertData.parent3MobilePhone}
                                  onChange={(e) => {
                                    const _data = e.target.value as string;

                                    if (_data.length > 10) {
                                      setVeli3TelefonError("10 karakterden fazla giriş yapılamaz.");
                                      return;
                                    }
                                    setVeli3TelefonError("");

                                    setData((prevState) => ({
                                      ...prevState,
                                      parent3MobilePhone: e.target.value,
                                    }));

                                    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                                    if (!regex.test(e.target.value)) {
                                      if (e.target.value.trim().length != 0) {
                                        setVeli3TelefonError("Geçersiz telefon numarası");
                                      }
                                      return;
                                    }
                                  }}
                                  onBlur={(e) => {
                                    setQueryVeli({
                                      query: e.target.value,
                                      type: "mobilePhone",
                                      parentIndex: 3,
                                    });
                                  }}
                                />
                              </div>
                              {veli3TelefonError && <label className="text-red-500 ml-4 ">{veli3TelefonError}</label>}
                            </div>
                            <div className="col-span-3 mt-2 ml-2 ">
                              <label className="block text-sm font-medium text-gray-700">Velisi mi ?</label>
                              <div className="flex items-center h-11 ">
                                <InputSwitch
                                  inputId="smsServiceApiActive"
                                  checked={insertData.parent3IsParent}
                                  onChange={(e) => {
                                    setData({
                                      ...insertData,
                                      parent3IsParent: e.value,
                                    });
                                  }}
                                ></InputSwitch>
                              </div>
                            </div>
                            {/* veli 3 kart istiyor */}
                            <div className="col-span-4 mt-2 ml-2 ">
                              <label className="block text-sm font-medium text-gray-700">Kart istiyor</label>
                              <div className="flex items-center h-11 ">
                                <InputSwitch
                                  inputId="smsServiceApiActive"
                                  checked={insertData.parent3WantsCard}
                                  onChange={(e) => {
                                    setData({
                                      ...insertData,
                                      parent3WantsCard: e.value,
                                    });
                                  }}
                                ></InputSwitch>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* veli 3 profil resmi */}
                        <div className="col-span-4  border rounded-md">
                          <div className="mt-4 flex justify-center">
                            <ProfileImage
                              lastOpenFormFileUrl={lastOpenFormFileUrl}
                              OpenedFormFileUrl={async (e) => {
                                if (e.length > 0) {
                                  lastOpenFormFileUrl = e;
                                }
                              }}
                              uploadImageUrl={async (e) => {
                                setData({
                                  ...insertData,
                                  parent3ImageUrl: e,
                                });
                              }}
                              modalOpen={true}
                              folder="Veli"
                              value={insertData.parent3ImageUrl}
                              selectedImage={async (e) => {}}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Veli 4 bilgileri */}
                    <div className="col-span-6 gap-4 row-span-1">
                      <div className="grid grid-cols-12 grid-flow-col gap-4">
                        <div className="col-span-8 ">
                          <div className="mt-2">
                            {/* veli 4 adı soyadı */}
                            <label className="block text-sm font-medium text-gray-700">Veli 4 Adı Soyadı :</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputComp
                                autoComplete="off"
                                type="text"
                                name="name"
                                placeholder="Adı Soyadı"
                                className="w-full"
                                value={insertData.parent4NameSurname}
                                onChange={(e) => {
                                  setData((prevState) => ({
                                    ...prevState,
                                    parent4NameSurname: e.target.value,
                                  }));
                                }}
                                onBlur={(e) => {
                                  setQueryVeli({
                                    query: e.target.value,
                                    type: "nameSurname",
                                    parentIndex: 4,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* veli 4 yakınlık seçimi */}
                          <div className="">
                            <label className="block text-sm font-medium text-gray-700 pt-2">Veli 4 Yakınlık Seçimi:</label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Dropdown
                                value={insertData.parent4Type}
                                options={yakinlikTipi}
                                onChange={(e) => {
                                  if (e.value == "BABA" || e.value == "ANNE") {
                                    setData({
                                      ...insertData,
                                      parent4IsParent: true,
                                      parent4Type: e.value,
                                    });
                                  } else {
                                    setData({
                                      ...insertData,
                                      parent4IsParent: false,
                                      parent4Type: e.value,
                                    });
                                  }
                                }}
                                placeholder="Yakınlık Seçiniz"
                                className="w-full h-10 "
                                valueTemplate={parentTypeDropDownTemplate}
                                itemTemplate={parentTypeDropDownOptionTemplate}
                              />
                            </div>
                          </div>
                          {/* veli 4 cep telefon */}
                          <div className=" grid grid-cols-12  ">
                            <div className="col-span-5 mt-2  ">
                              <label className="block text-sm font-medium text-gray-700">Veli 4 cep telefon:</label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <InputComp
                                  autoComplete="off"
                                  type="text"
                                  name="name"
                                  placeholder="sıfır olmadan"
                                  className="w-full"
                                  value={insertData.parent4MobilePhone}
                                  onChange={(e) => {
                                    const _data = e.target.value as string;

                                    if (_data.length > 10) {
                                      setVeli4TelefonError("10 karakterden fazla giriş yapılamaz.");
                                      return;
                                    }
                                    setVeli4TelefonError("");

                                    setData((prevState) => ({
                                      ...prevState,
                                      parent4MobilePhone: e.target.value,
                                    }));

                                    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                                    if (!regex.test(e.target.value)) {
                                      if (e.target.value.trim().length != 0) {
                                        setVeli4TelefonError("Geçersiz telefon numarası");
                                      }
                                      return;
                                    }
                                  }}
                                  onBlur={(e) => {
                                    setQueryVeli({
                                      query: e.target.value,
                                      type: "mobilePhone",
                                      parentIndex: 4,
                                    });
                                  }}
                                />
                              </div>
                              {veli4TelefonError && <label className="text-red-500 ml-4 ">{veli4TelefonError}</label>}
                            </div>
                            <div className="col-span-3 mt-2 ml-2 ">
                              <label className="block text-sm font-medium text-gray-700">Velisi mi ?</label>
                              <div className="flex items-center h-11 ">
                                <InputSwitch
                                  inputId="smsServiceApiActive"
                                  checked={insertData.parent4IsParent}
                                  onChange={(e) => {
                                    setData({
                                      ...insertData,
                                      parent4IsParent: e.value,
                                    });
                                  }}
                                ></InputSwitch>
                              </div>
                            </div>
                            {/* veli 4 Kart istiyor telefon */}
                            <div className="col-span-4 mt-2 ml-2 ">
                              <label className="block text-sm font-medium text-gray-700">Kart istiyor</label>
                              <div className="flex items-center h-11 ">
                                <InputSwitch
                                  inputId="smsServiceApiActive"
                                  checked={insertData.parent4WantsCard}
                                  onChange={(e) => {
                                    setData({
                                      ...insertData,
                                      parent4WantsCard: e.value,
                                    });
                                  }}
                                ></InputSwitch>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* veli 4 profil resmi */}
                        <div className="col-span-4  border rounded-md">
                          <div className="mt-4 flex justify-center">
                            <ProfileImage
                              lastOpenFormFileUrl={lastOpenFormFileUrl}
                              OpenedFormFileUrl={async (e) => {
                                if (e.length > 0) {
                                  lastOpenFormFileUrl = e;
                                }
                              }}
                              uploadImageUrl={async (e) => {
                                setData({
                                  ...insertData,
                                  parent4ImageUrl: e,
                                });
                              }}
                              modalOpen={true}
                              folder="Veli"
                              value={insertData.parent4ImageUrl}
                              selectedImage={async (e) => {}}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-center sm:px-6">
                <Button
                  ref={btnSaveRef}
                  loading={btnSaveRef.current?.disabled}
                  disabled={btnSaveRef.current?.disabled}
                  className="inline-flex justify-center w-40 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={btnSaveHandle}
                >
                  Kaydet
                </Button>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FastStudentParentInsert;
