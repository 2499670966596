import React, { useContext, useEffect, useState } from "react";
import InputComp from "../../Components/Input/InputComp";
import { IClient, ICompanyStudentPayment, IStudent, UserRole } from "../../Models";
import { ClientService, StudentService } from "../../Services";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import SkeletonList from "../../Components/Loading/Skeleton_List";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { AppContext } from "../../Context/Store";
import toast from "react-hot-toast";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";

const SchoolPaymentList = () => {
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const studentService = StudentService();
  const [paymentStudentList, setpaymentStudentList] = useState<ICompanyStudentPayment[] | null>();

  useEffect(() => {
    StudentList();
  }, []);

  const StudentList = async () => {
    setIsLoading(true);
    const { data, error } = await studentService.CompanyStudentPayment();
    setIsLoading(false);
    if (data) {
      console.log("paymentStudentList Data => ", paymentStudentList);
      if (data.success) {
        setpaymentStudentList(data.returnData as ICompanyStudentPayment[]);
      } else {
        setpaymentStudentList(null);
      }
    }
  };

  const updateAmount = async (dataStudent: ICompanyStudentPayment) => {
    // console.log("data => ", dataStudent);

    const { data, error } = await studentService.StudentPaymentAddForCompany(dataStudent);
    if (data) {
      if (data.success) {
        toast.success(data.successMessage);
      } else {
        toast.error(data.errorMessage);
      }
    }
  };

  const formatCurrency = (value: any) => {
    return value.toLocaleString("tr-Tr", { style: "currency", currency: "TRY" });
  };

  const getTotalAmountToPaid = () => {
    if (paymentStudentList) {
      var total = paymentStudentList?.reduce(function (res, item) {
        return res + item.amountToPaid;
      }, 0);
      return formatCurrency(total);
    }
    return 0;
  };
  const getTotalDiscount = () => {
    if (paymentStudentList) {
      var total = paymentStudentList?.reduce(function (res, item) {
        return res + item.discount;
      }, 0);
      return formatCurrency(total);
    }
    return 0;
  };
  const getTotalPaidAmount = () => {
    if (paymentStudentList) {
      var total = paymentStudentList?.reduce(function (res, item) {
        return res + item.paidAmount;
      }, 0);
      return formatCurrency(total);
    }
    return 0;
  };

  const getTotalRemainingPayment = () => {
    if (paymentStudentList) {
      var total = paymentStudentList?.reduce(function (res, item) {
        return res + item.remainingPayment;
      }, 0);
      return formatCurrency(total);
    }
    return 0;
  };

  let footer = (
    <ColumnGroup>
      <Row>
        <Column footer={"Ödenecek: " + getTotalAmountToPaid()} footerStyle={{ textAlign: "left" }} />
        <Column footer={"İndirim: " + getTotalDiscount()} footerStyle={{ textAlign: "left" }} />
        <Column footer={"Ödenen: " + getTotalPaidAmount()} footerStyle={{ textAlign: "left" }} />
        <Column footer={"Kalan: " + getTotalRemainingPayment()} footerStyle={{ textAlign: "left" }} />
      </Row>
    </ColumnGroup>
  );

  let footer2 = () => {
    return (
      <ColumnGroup>
        <Row>
          <Column footer="Toplam:" footerStyle={{ textAlign: "right" }} />
          <Column footer={"lastYearTotal"} />
          <Column footer={"thisYearTotal"} />
        </Row>
      </ColumnGroup>
    );
  };
  const priceBodyTemplate = (rowData: any) => {
    const _value = new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
    })
      .format(rowData ? rowData : 0)
      .toString();

    return <label className={classNames("tooltip")}>{_value}</label>;
  };

  const paidAmountBodyTemplate = (rowData: any) => {
    const _value = new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
    })
      .format(rowData ? rowData : 0)
      .toString();
    return (
      <view className="w-20 " style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
        <label className={classNames("tooltip")}>{_value}</label>
        {user.role == UserRole.SUPERADMIN ? <i className="pi pi-pencil" style={{ fontSize: "10px", justifyContent: "end", alignItems: "end" }}></i> : null}
      </view>
    );
  };

  const priceRemaininPayment = (amountToPaid: any) => {
    const _value = new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
    })
      .format(amountToPaid ? amountToPaid : 0)
      .toString();

    return <>{<label className={classNames(amountToPaid != 0 ? "text-red-400" : "")}>{_value}</label>}</>;
  };

  const statusBody = (data: IStudent) => {
    if (data.status === "Devam Ediyor") {
      return <label className="p-badge p-badge-warning">{data.status}</label>;
    } else {
      return <span className="p-badge p-badge-danger">{data.status}</span>;
    }
  };

  const onRowEditComplete1 = (e: any) => {
    if (paymentStudentList) {
      let { rowData, newValue, field, originalEvent: event } = e;
      let item = paymentStudentList.find((item) => item.studentId === (rowData as ICompanyStudentPayment).studentId);

      // console.log(' e => ',e);
      // console.log("rowData=> ", rowData, " newValue=> ", newValue, " field=> ", field);
      // console.log("newValue=> ", newValue);
      // console.log("field=> ", field);
      if (newValue && newValue.length > 0) {
        if (field === "paidAmount") {
          if (item) {
            const remainingPayment = item.amountToPaid - item.discount - Number.parseInt(newValue);
            if (remainingPayment > -1) {
              rowData[field] = newValue;

              item.paidAmount = Number.parseInt(newValue);
              item.remainingPayment = remainingPayment;
              rowData.remainingPayment = remainingPayment;
              updateAmount(item);
            } else {
              toast.error("Kalan tutar eksi bakiye olamaz");
            }
          }
        }
      }
      if (newValue && newValue.length > 0) {
        if (field === "discount") {
          if (item) {
            const remainingPayment = item.amountToPaid - Number.parseInt(newValue) - item.paidAmount;
            if (remainingPayment > -1) {
              rowData[field] = newValue;
              item.discount = Number.parseInt(newValue);
              item.remainingPayment = item.amountToPaid - item.discount - item.paidAmount;
              rowData.remainingPayment = item.amountToPaid - item.discount - item.paidAmount;
              updateAmount(item);
            } else {
              toast.error("Kalan tutar eksi bakiye olamaz");
            }
          }
        }
      }
      if (newValue && newValue.length > 0) {
        if (field === "adminNote") {
          rowData[field] = newValue;
          if (item) {
            item.adminNote = newValue;
            updateAmount(item);
          }
        }
      } else event.preventDefault();
    }
  };
  const priceEditor = (options: any) => {
    if (user.role == UserRole.SUPERADMIN) {
      return <InputText className=" h-4 max-w-[100px]  w-28" type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    } else {
      const _value = new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
      })
        .format(options.value ? options.value : 0)
        .toString();
      return (
        <view className="w-20 " style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
          <label className={classNames("tooltip")}>{_value}</label>
        </view>
      );
    }
  };
  const textEditor = (options: any) => {
    if (user.role == UserRole.SUPERADMIN) {
      return <InputText className=" h-4 max-w-[100px]  w-28" type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    } else {
      return (
        <view className="w-20 " style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
          <label className={classNames("tooltip")}>{options.value}</label>
        </view>
      );
    }
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">Öğrenci Ücret Listesi</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div className="ml-2">
            <div className="mt-5 md:mt-0 ">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  {paymentStudentList && paymentStudentList.length > 0 && (
                    <DataTable
                      value={paymentStudentList}
                      stripedRows
                      emptyMessage="Kayıtlı öğrenci yok"
                      responsiveLayout="scroll"
                      loading={isLoading}
                      scrollable
                      size="small"
                      footer={`Toplam Öğrenci Sayısı: ${paymentStudentList.length}`}
                      editMode="cell"
                      dataKey="id"
                      footerColumnGroup={footer}
                    >
                      <Column field="studentFullName" header="Öğrenci"></Column>
                      <Column field="classroomName" header="Sınıf"></Column>
                      <Column sortable header="Ödenecek" body={(data: ICompanyStudentPayment) => priceBodyTemplate(data.amountToPaid)} exportable={false}></Column>

                      <Column
                        editor={(options) => priceEditor(options)}
                        header="İndirim"
                        field="discount"
                        onCellEditComplete={onRowEditComplete1}
                        body={(data: ICompanyStudentPayment) => priceBodyTemplate(data.discount)}
                        exportable={false}
                      ></Column>
                      <Column
                        header="Ödenen"
                        editor={(options) => priceEditor(options)}
                        field="paidAmount"
                        onCellEditComplete={onRowEditComplete1}
                        body={(data: ICompanyStudentPayment) => paidAmountBodyTemplate(data.paidAmount)}
                        exportable={false}
                      ></Column>
                      <Column header="Kalan" body={(data: ICompanyStudentPayment) => priceRemaininPayment(data.remainingPayment)} exportable={false}></Column>
                      <Column editor={(options) => textEditor(options)} field="adminNote" onCellEditComplete={onRowEditComplete1} header="Not"></Column>

                      {/* <Column header="İşlemler" body={(data) => actionBodyTemplate(data)} exportable={false}></Column> */}
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SchoolPaymentList;
