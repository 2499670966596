import { Skeleton } from "primereact/skeleton";

const SkeletonCard = () => {
  return (
    <div className="card">
      <div className="field col-12 md:col-6 md:pr-6 pr-0">
        <h5>Card</h5>
        <div className="custom-skeleton p-4">
          <div className="flex mb-3">
            <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
            <div>
              <Skeleton width="10rem" className="mb-2"></Skeleton>
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height=".5rem"></Skeleton>
            </div>
          </div>
          <Skeleton width="100%" height="150px"></Skeleton>
          <div className="flex justify-content-between mt-3">
            <Skeleton width="4rem" height="2rem"></Skeleton>
            <Skeleton width="4rem" height="2rem"></Skeleton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCard;
