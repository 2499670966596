export enum EnPersonnelType {
  MUDUR='Müdür',
  MUDUR_YAR ='Müdür yardımcısı',
  OGRETMEN = 'Öğretmen', 
  MEMUR='Memur',
  PERSONEL = 'Personel',
  ADMIN = 'Admin',
  SATIS = 'Satış',
}


