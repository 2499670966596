import AxiosUtility from "../Extended-tools/AxiosUtility";
import { IParent, IPersonnel, IResultDto } from "../Models";

const PersonnelService = () => {
  const AddPersonnel = async (data: IPersonnel) => {
    try {
      const response = await AxiosUtility().post("/personnel/add", data);
      if (response.status === 200) {
        //  console.log('AddPersonnel service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const UpdatePersonnel = async (data: IPersonnel) => {
    try {
      const response = await AxiosUtility().put("/personnel/Update", data);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const FindPersonnelById = async (personnelId: number) => {
    try {
      const response = await AxiosUtility().get(`/personnel/${personnelId}`);
      if (response.status === 200) {
        // console.log('AddCategory service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const GetAllPersonnels = async () => {
    try {
      const response = await AxiosUtility().get(`/personnel/All`);

      if (response.status === 200) {
        const data = response.data as IResultDto;
        // console.log('GetAllParents service response 200 ', data)

        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  const PersonnelMovements = async () => {
    try {
      const response = await AxiosUtility().get(`/personnel/Movements`);

      if (response.status === 200) {
        const data = response.data as IResultDto;
        // console.log('GetAllParents service response 200 ', data)

        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  return {
    AddPersonnel,
    UpdatePersonnel,
    FindPersonnelById,
    GetAllPersonnels,
    PersonnelMovements,
    // GetAllParentsBySchoolId,
    // GetAllParents,
    // FindParentById,
    // UpdateParent,
    // AddStudentParent,
    // GetAllStudentParents,
    // DeleteStudentParentRelation
  };
};

export { PersonnelService };
