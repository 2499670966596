import React, { useContext, useEffect, useState, useCallback } from "react";
import SkeletonList from "../../../Components/Loading/Skeleton_List";
import { MasterDbService } from "../../../Services/MasterDbService";
import toast from "react-hot-toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { GoogleMap, InfoWindow, Marker, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { AppContext } from "../../../Context/Store";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

const SchoolListMeb_Admin = () => {
  const { user } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const masterDbService = MasterDbService();
  const [mebSchools, setMebSchools] = useState([]);
  const [infoWindowOpen, setInfoWindowOpen] = useState<number | null>(null);
  const [center, setCenter] = useState<any>({ lat: 0, lng: 0 }); // Default center in Antalya

  const [ilData, setIlData] = useState([]);
  const [ilceData, setIlceData] = useState([]);
  const [selectedIl, setSelectedIl] = useState("-1");
  const [selectedIlce, setSelectedIlce] = useState("");
  const [okulTip, setokulTip] = useState("anaokulu");

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBDF78O-PoyTbhMaT0MH-oYLiL90yaHY3U", // Replace with your Google Maps API key
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
    });

    LoadPageData();
  }, []);

  const LoadPageData = async () => {
    GetIlData();
  };

  const GetIlData = async () => {
    const { data, error } = await masterDbService.GetIlData();

    if (error) {
      toast.error("İl listesi yüklenirken hata oluştu");
      return;
    }

    if (data && data.success) {
      // console.log("il listesi => ", data.returnData);
      setIlData(data.returnData);
    }
  };

  const GetIlceData = async (ilName: string) => {
    const { data, error } = await masterDbService.GetIlceData(ilName);

    if (error) {
      toast.error("İlçe listesi yüklenirken hata oluştu");
      return;
    }

    if (data && data.success) {
      setIlceData(data.returnData);
    }
  };

  const MebSchoolList = async () => {
    if (selectedIl == "-1" || selectedIl == "") {
      toast.error("Lütfen il seçiniz");
      return;
    }
    setIsLoading(true);
    const { data, error } = await masterDbService.GetMebSchoolList(selectedIl, selectedIlce, okulTip);

    if (error) {
      toast.error("Okul listesi yüklenirken hata oluştu");
      setIsLoading(false);
      return;
    }

    if (data && data.success) {
      // console.log("okul listesi => ", data.returnData);
      setMebSchools(data.returnData);
    }

    setIsLoading(false);
  };

  const [selectedMarker, setSelectedMarker] = useState<any>(null);

  const handleMarkerClick = (marker: any) => {
    // console.log("handleMarkerClick => ", marker);
    setSelectedMarker(marker);
  };

  const handleCloseClick = () => {
    setSelectedMarker(null);
  };

  const handleDirectionsClick = (marker: any) => {
    const { enlem, boylam } = marker;
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${enlem},${boylam}`, "_blank");
  };

  const handleOpenWebClick = (marker: any) => {
    const { url } = marker;
    window.open(url, "_blank");
  };

  const handleNoteClick = () => {
    alert("Not yazma fonksiyonu burada çalışacak.");
  };

  const handleVisited = () => {
    alert("Not yazma fonksiyonu burada çalışacak.");
  };

  const mapContainerStyle = {
    height: "400px",
    width: "100%",
  };

  return (
    <div className="m-3 mt-6 p-6 rounded-lg bg-white">
      <div>
        <label className="text-lg ">* Okul Listesi</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div className="md:mt-0">
            <div className=" sm:rounded-md sm:overflow-hidden">
              <div className="bg-white">
                {/* ///////// */}
                <div className="grid grid-cols-12 gap-4 mt-3">
                  <div className="col-span-6  lg:col-span-3 md:col-span-3  sm:col-span-4">
                    <label className="block text-sm font-medium text-gray-700">İl Seçimi</label>
                    <div className="mt-1 flex rounded-md ">
                      <Dropdown
                        className="flex-1"
                        value={selectedIl}
                        options={ilData}
                        onChange={(e) => {
                          setSelectedIl(e.value);
                          GetIlceData(e.value);
                        }}
                        placeholder="İl seçiniz"
                      />
                    </div>
                  </div>

                  <div className="col-span-6  lg:col-span-3 md:col-span-3  sm:col-span-4">
                    <label className="block text-sm font-medium text-gray-700">İlçe Seçimi</label>
                    <div className="mt-1 flex rounded-md">
                      <Dropdown className="flex-1" value={selectedIlce} options={ilceData} onChange={(e) => setSelectedIlce(e.value)} placeholder="İlçe seçiniz" />
                    </div>
                  </div>

                  <div className="col-span-6  lg:col-span-3 md:col-span-3 sm:col-span-4  ">
                    <label className="block text-sm font-medium text-gray-700">Okul Tipi</label>
                    <div className="mt-1 flex rounded-md ">
                      <Dropdown
                        className="flex-1"
                        value={okulTip}
                        options={[
                          { label: "Anaokulu", value: "anaokulu" },
                          { label: "İlkokul", value: "ilkokul" },
                          { label: "Ortaokul", value: "ortaokul" },
                          { label: "Lise", value: "lise" },
                        ]}
                        onChange={(e) => setokulTip(e.value)}
                        placeholder="Okul tipi seçiniz"
                      />
                    </div>
                  </div>

                  <div className="col-span-6  lg:col-span-3 md:col-span-3 sm:col-span-4  flex items-end">
                    <Button label="Filtrele" onClick={() => MebSchoolList()} />
                  </div>
                </div>

                {center && isLoaded ? (
                  <div>
                    {mebSchools && mebSchools.length > 0 && (
                      <div className="mt-4">
                        <label className="text-lg mt-4 font-bold">
                          {mebSchools.length} Okuldan, {mebSchools.filter((t: any) => t.enlem && t.boylam && t.enlem != 0 && t.boylam != 0).length} konumu olan okul listelenmiştir
                        </label>
                      </div>
                    )}
                    <div className="mt-4 mb-4 ">
                      <GoogleMap
                        options={{
                          gestureHandling: "greedy",
                          streetViewControl: false,
                          styles: [
                            {
                              featureType: "poi",
                              elementType: "labels",
                              stylers: [{ visibility: "off" }],
                            },
                            {
                              featureType: "transit",
                              elementType: "labels",
                              stylers: [{ visibility: "off" }],
                            },
                            {
                              featureType: "poi.medical",
                              elementType: "labels",
                              stylers: [{ visibility: "on" }],
                            },
                          ],
                        }}
                        mapContainerStyle={mapContainerStyle}
                        center={center}
                        zoom={14}
                      >
                        {center && (
                          <MarkerF
                            position={center}
                            label={{ text: "* BURADAYIM * ", className: "text-red-400 font-bold" }}
                            icon={{
                              url: "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
                              scaledSize: new window.google.maps.Size(40, 40),
                              labelOrigin: new window.google.maps.Point(25, -10),
                            }}
                          />
                        )}

                        {mebSchools &&
                          mebSchools
                            .filter((t: any) => t.enlem && t.boylam && t.enlem != 0 && t.boylam != 0)
                            .map((marker: any) => {
                              return (
                                <MarkerF
                                  key={marker.id}
                                  position={{
                                    lat: parseFloat(marker.enlem),
                                    lng: parseFloat(marker.boylam),
                                  }}
                                  label={{ text: `${marker.adi} - ${marker.ogrenciSayisi}`, className: "font-bold text-black" }}
                                  onClick={() => handleMarkerClick(marker)}
                                  icon={{
                                    url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                                    scaledSize: new window.google.maps.Size(40, 40),
                                    labelOrigin: new window.google.maps.Point(25, -10),
                                  }}
                                />
                              );
                            })}

                        {selectedMarker && (
                          <InfoWindow
                            position={{
                              lat: parseFloat(selectedMarker.enlem),
                              lng: parseFloat(selectedMarker.boylam),
                            }}
                            onCloseClick={handleCloseClick}
                          >
                            <div className="p-2 bg-white shadow-md rounded-lg border border-gray-200">
                              <h2 className="text-lg font-semibold text-gray-800">{selectedMarker.adi}</h2>
                              <label className=" text-lg ">Öğrenci Sayısı : {selectedMarker.ogrenciSayisi}</label>
                              {/* <div className=" mt-4">
                                <label className=" text-sm ">Son ziyaret Tarihi : 22.06.2024</label>
                              </div> */}
                              <div className=" mt-4  flex flex-row justify-between items-center w-full gap-2">
                                <Button onClick={() => handleDirectionsClick(selectedMarker)}>Yol Tarifi Al</Button>
                                {/* <Button onClick={handleNoteClick}>Not Yaz</Button>
                                <Button onClick={handleVisited}>Gidildi İşaretle</Button> */}
                                <Button onClick={() => handleOpenWebClick(selectedMarker)}>Web Sitesine Git</Button>
                                {/* <Button onClick={handleNoteClick}>Not Yaz</Button>
                                <Button onClick={handleVisited}>Gidildi İşaretle</Button> */}
                              </div>
                            </div>
                          </InfoWindow>
                        )}
                      </GoogleMap>
                    </div>
                  </div>
                ) : (
                  <label>Koordinat yok</label>
                )}

                <DataTable
                  scrollable
                  scrollDirection="vertical"
                  rowHover
                  scrollHeight="63vh"
                  stripedRows
                  size="small"
                  filterDisplay="row"
                  footer={`Toplam: ${mebSchools.length} Okul - ${mebSchools.filter((t: any) => t.enlem && t.boylam && t.enlem != 0 && t.boylam != 0).length} Adresi olan okul`}
                  loading={isLoading}
                  value={mebSchools}
                  className="p-datatable-sm"
                >
                  <Column field="id" header="Id" className="w-24" />
                  <Column filter field="il" header="İl" className="w-24" />
                  <Column filter field="ilce" header="İlçe" className="w-24" />
                  <Column filter field="adi" header="Okul Adı" className="w-24" />
                  <Column field="ogrenciSayisi" header="Öğrenci Sayısı" className="w-24" />
                  <Column field="sinifSayisi" header="Sınıf Sayısı" className="w-24" />
                  <Column field="ogretmenSayisi" header="Öğretmen Sayısı" className="w-24" />
                  <Column field="telefon" header="Telefon" className="w-24" />
                  <Column
                    header="Location"
                    className="w-24"
                    body={(rowData) => {
                      if (!rowData.enlem || !rowData.boylam || rowData.enlem == 0 || rowData.boylam == 0) {
                        return "-";
                      }
                      return (
                        <a href={`https://www.google.com/maps/search/?api=1&query=${rowData.enlem},${rowData.boylam}`} target="_blank" rel="noreferrer">
                          <i
                            className="pi pi-map"
                            style={{
                              fontSize: "1.5rem",
                              color: "blue",
                              padding: "0.5rem",
                              borderRadius: "50%",
                            }}
                          />
                        </a>
                      );
                    }}
                  />
                  <Column
                    field="url"
                    header="URL"
                    body={(rowData) => {
                      if (!rowData.url) {
                        return "-";
                      }
                      return (
                        <a href={rowData.url} target="_blank" rel="noreferrer">
                          <i
                            style={{
                              fontSize: "1.5rem",
                              color: "blue",
                              padding: "0.5rem",
                              borderRadius: "50%",
                            }}
                            className="pi pi-link"
                          />
                        </a>
                      );
                    }}
                  />
                </DataTable>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SchoolListMeb_Admin;
