import React, { useContext, useEffect, useRef, useState } from "react";
import InputComp from "../../Components/Input/InputComp";
import { IClassroom, IStudent, UserRole } from "../../Models";
import { StudentService } from "../../Services";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import toast from "react-hot-toast";
import moment from "moment";
import { AppContext } from "../../Context/Store";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { ContextMenu } from "primereact/contextmenu";
import { FilterMatchMode, locale } from "primereact/api";
import SkeletonList from "../../Components/Loading/Skeleton_List";

locale("tr");

const StudentList = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const studentService = StudentService();
  const [studentList, setStudentList] = useState<IStudent[] | null>();
  const [deleteBtnProgress, setDeleteBtnProgress] = useState(false);
  const [filters, setFilters] = useState<any>(null);
  const [downloadEOkulImageFile, setDownloadEOkulImageFile] = useState(false);
  const [classroomList, setClassroomList] = useState<IClassroom[] | null>(null);

  const [menuProgressCreateSoundsWithAllEmpty, setMenuProgressCreateSoundsWithAllEmpty] = useState(false);

  const cm: any = useRef(null);
  const [selectedStudent, setSelectedStudent] = useState<IStudent | null>();

  useEffect(() => {
    StudentList();
    ClassroomList();
  }, []);

  const StudentList = async () => {
    setIsLoading(true);

    const { data, error } = await studentService.GetAllStudents(true);
    setIsLoading(false);
    if (data) {
      if (data.success) {
        // console.log("StudentList => ", data.returnData);
        setStudentList(data.returnData as IStudent[]);
        initFilters();
      } else {
        setStudentList(null);
        toast.error(data?.errorMessage);
      }
    }
  };

  const ClassroomList = async () => {
    const { data, error } = await studentService.GetClassroomList();
    if (data) {
      if (data.success) {
        // console.log("ClassroomList => ", data.returnData);
        setClassroomList(data.returnData as IClassroom[]);
      } else {
        setClassroomList([]);
        toast.error(data?.errorMessage);
      }
    }
  };

  const btnSaveHandle = async () => {};

  const acceptDeleteHandle = async (e: any, item: IStudent) => {
    setDeleteBtnProgress(true);
    const { data, error } = await studentService.DeleteStudent(item.id);
    setDeleteBtnProgress(false);
    if (data) {
      // console.log("data=> ", data);
      if (data.success) {
        toast.success(`${item.name} ${item.surname} silindi`);
        StudentList();
      } else {
        toast.error(data.errorMessage);
      }
    }
  };

  const DeleteHandle = async (e: any, item: IStudent) => {
    confirmPopup({
      target: e.currentTarget,
      message: `${item.name} ${item.surname} kaydın silmek istiyor musunuz ?`,
      icon: "pi pi-times-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => acceptDeleteHandle(e, item),
    });
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div>
        {(UserRole.ADMIN === user.role || UserRole.SUPERADMIN === user.role) && (
          <Button
            disabled={deleteBtnProgress}
            style={{ height: 30, width: 30, marginRight: 5 }}
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger "
            onClick={(e) => DeleteHandle(e, rowData)}
          ></Button>
        )}
        <Button
          style={{ height: 30, width: 30, marginRight: 5 }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-warning"
          onClick={() => navigate(`/students/${rowData.id}`)}
        />
      </div>
    );
  };

  const statusBody = (data: IStudent) => {
    if (data.status === "Devam Ediyor") {
      return <label className="p-badge p-badge-success">{data.status}</label>;
    } else {
      return <span className="p-badge p-badge-danger">{data.status}</span>;
    }
  };

  const createDateBody = (data: IStudent) => {
    if (data.createdDate) {
      return <label>{moment(data.createdDate).format("DD.MM.YYYY")}</label>;
    }
  };
  const imageUrlBody = (data: IStudent) => {
    // const anyImage = data.imageUrl ? (data.imageUrl.includes("noimage") ? "Yok": 'var') : "Yok";
    // return <label>{data.imageUrl}</label>;

    const anyImage = data.imageUrl ? (data.imageUrl.includes("noimage") ? "Yok" : "var") : "Yok";

    if (anyImage === "var") {
      return (
        <div className="w-14 flex justify-center items-center">
          <label className="p-badge p-badge-success ">✓</label>
        </div>
      );
    } else {
      return (
        <div className="w-14 flex justify-center items-center">
          <span className="p-badge p-badge-danger">✗</span>
        </div>
      );
    }
  };

  const isUseMobileAppBody = (data: IStudent) => {
    if (data.isUseMobile) {
      return (
        <div className="w-14 flex justify-center items-center">
          <label className="p-badge p-badge-success ">✓</label>
        </div>
      );
    } else {
      return (
        <div className="w-14 flex justify-center items-center">
          <span className="p-badge p-badge-danger">✗</span>
        </div>
      );
    }
  };

  const initFilters = () => {
    setFilters({
      fullName: { value: null, matchMode: FilterMatchMode.CONTAINS, lng: "tr" },
      tcNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH, lng: "tr" },
      "classroom.name": { value: null, matchMode: FilterMatchMode.CONTAINS, lng: "tr" },
    });
  };

  const ChangeStudentClassroom = async (student: IStudent, classroomId: number) => {
    console.log("classroomId=> ", classroomId);
    const { data, error } = await studentService.ChangeStudentClassroom(student.id, classroomId);
    if (data) {
      if (data.success) {
        toast.success(data.successMessage);
        // studentListChangeState
        const newStudentList = studentList?.map((x) => {
          if (x.id === student.id) {
            x.classroomId = classroomId;
            x.classroom = classroomList?.find((x) => x.id === classroomId);
          }
          return x;
        });
        setStudentList(newStudentList);
      } else {
        toast.error(data.errorMessage);
      }
    }
  };

  const menuModel = [
    {
      label: "Sınıf Değiştir",
      icon: "pi pi-sync",
      items: classroomList?.map((x) => {
        // console.log("selectedStudent => ", selectedStudent);
        if (x.id === selectedStudent?.classroomId) {
          return {
            label: x.name,
            icon: "pi pi-angle-double-right",
            disabled: true,
          };
        }
        return {
          label: x.name,
          icon: "pi pi-angle-double-right",
          command: () => {
            if (selectedStudent) {
              ChangeStudentClassroom(selectedStudent, x.id);
            }
          },
        };
      }),
    },
    {
      label: `${selectedStudent && selectedStudent?.fullName} için E-Okul resmi indir`,
      icon: menuProgressCreateSoundsWithAllEmpty ? "pi pi-spin pi-spinner" : "pi pi-angle-double-up",
      command: () => {
        const ids: number[] = [selectedStudent ? selectedStudent.id : 0];
        DownloadEOkulImage(ids);
      },
    },
    {
      label: `${selectedStudent && selectedStudent?.fullName} için ses dosyası oluştur`,
      icon: "pi pi-arrow-up-right",
      command: () => {
        // toast.success("JSON.stringify(selectedProduct)");
        if (selectedStudent) {
          CreateSoundsWithAllEmpty(selectedStudent);
        }
      },
    },
    {
      label: "Ses dosyası olmayan öğrencilerin ses dosyasını oluştur",
      icon: menuProgressCreateSoundsWithAllEmpty ? "pi pi-spin pi-spinner" : "pi pi-angle-double-up",
      command: () => {
        CreateSoundsWithAllEmpty(null);
      },
    },
    {
      label: "Tüm öğrenciler için E-Okul resmi oluştur ve indir",
      icon: menuProgressCreateSoundsWithAllEmpty ? "pi pi-spin pi-spinner" : "pi pi-angle-double-up",
      command: () => {
        DownloadEOkulImage(studentList?.map((x) => x.id) as number[]);
      },
    },
  ];
  const DownloadEOkulImage = async (studentIds: number[]) => {
    toast.success("Bu işlem öğrenci sayınıza bağlı olarak uzun sürebilir. Lütfen bekleyiniz.");

    setDownloadEOkulImageFile(true);
    const { data } = await studentService.DownloadEOkulImage(studentIds);
    setDownloadEOkulImageFile(false);

    console.log("data => ", data);
  };

  const CreateSoundsWithAllEmpty = async (studentId: IStudent | null) => {
    setMenuProgressCreateSoundsWithAllEmpty(true);
    let studentListEmptySoundUrl: IStudent[] | undefined = [];

    if (studentId && studentId.id > 0) {
      studentListEmptySoundUrl?.push(studentId);
    } else {
      studentListEmptySoundUrl = studentList?.filter((student) => !student.soundUrl);
    }

    if (studentListEmptySoundUrl) {
      for (let index = 0; index < studentListEmptySoundUrl.length; index++) {
        const element = studentListEmptySoundUrl[index];
        const { data } = await studentService.CreateSoundById(element.id);
        if (data) {
          if (data.success) {
            toast.success(`${index + 1}/${studentListEmptySoundUrl.length} ${data.successMessage}`);
          } else {
            toast.error(data.errorMessage);
          }
        }
      }
    }
    setMenuProgressCreateSoundsWithAllEmpty(false);
  };

  const customFunction = (value: any, filter: any) => {
    console.log("value => ", value);
    console.log("filter => ", filter);
    return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0;
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">Öğrenci Listesi</label>
        <hr className="my-auto flex-grow-1 mt-2 mb-2" />
        <label className="text-lg mt-2 text-red-400">*Hızlı işlemler ve menü için, aşağıdaki listeye üzerine mouse sağ tuşu ile basınız</label>
      </div>

      <ConfirmPopup />
      <ContextMenu className="w-1/5" model={menuModel} ref={cm} onHide={() => setSelectedStudent(null)} />

      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div className="ml-2">
            <div className="mt-5 md:mt-0 ">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  {studentList && (
                    <DataTable
                      value={studentList}
                      filters={filters}
                      stripedRows
                      emptyMessage="Kayıtlı öğrenci yok"
                      responsiveLayout="scroll"
                      loading={isLoading || menuProgressCreateSoundsWithAllEmpty || downloadEOkulImageFile}
                      scrollable
                      scrollHeight="650px"
                      size="small"
                      footer={`Toplam Kayıt Sayısı: ${studentList.length}`}
                      filterDisplay="row"
                      scrollDirection="vertical"
                      columnResizeMode="fit"
                      onContextMenuSelectionChange={(e) => {
                        console.log("e.value => ", e.value);
                        setSelectedStudent(e.value);
                      }}
                      onContextMenu={(e: any) => {
                        cm.current?.show(e.originalEvent);
                      }}
                    >
                      <Column header="İşlemler" body={(data) => actionBodyTemplate(data)} className="max-w-[100px]" exportable={false}></Column>
                      <Column filter filterMatchMode="contains" showFilterMenu={false} showClearButton={false} sortable field="fullName" header="Adı Soyadı"></Column>
                      <Column filter filterMatchMode="contains" showFilterMenu={false} showClearButton={false} sortable field="classroom.name" header="Sınıf"></Column>
                      <Column filter showFilterMenu={false} showClearButton={false} sortable field="tcNumber" header="TC" className="max-w-[150px]"></Column>

                      <Column sortable field="createdDate" body={(data) => createDateBody(data)} header="Eklenme Tar."></Column>

                      <Column sortable field="imageUrl" body={(data) => imageUrlBody(data)} header="Resim"></Column>
                      <Column sortable field="status" body={(data) => statusBody(data)} header="Durumu"></Column>
                      <Column sortable field="isUseMobile" body={(data) => isUseMobileAppBody(data)} header="Mobil Kullanım"></Column>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StudentList;
