import React, { memo, useEffect, useRef, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import InputComp from "../../Components/Input/InputComp";
import { Button } from "primereact/button";
import { PaymentPlanService } from "../../Services";
import { IBank, IPaymentPlan, IPaymentPlanBankNumber } from "../../Models";
import { useNavigate, useParams } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import toast from "react-hot-toast";
import moment from "moment";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import DropdownComponent from "../../Components/Dropdown/DropdownComponent";

const PaymentCard = () => {
  const { id } = useParams<"id">();
  const paymentPlanService = PaymentPlanService();

  const navigate = useNavigate();

  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [btnNewRowRefLoading, setBtnNewRowRefLoading] = useState(false);
  const btnSaveRef: any = useRef<HTMLButtonElement>();
  const btnNewRowRef: any = useRef<HTMLButtonElement>();
  const [bankList, setBankList] = useState<IBank[]>([]);

  const [paymentPlanData, setPaymentPlanData] = useState<IPaymentPlan[]>([
    {
      id: 0,
      planName: "",
      installmentNumber: 1,
      dueDate: new Date(),
      installmentAmount: 0,
      index: 0,
      bankAccountName: "",
      bankBranch: "",
      bankName: "",
      ibanNumber: "",
      bankId: 0,
    },
  ]);

  const [bankDetail, setBankDetail] = useState<IPaymentPlanBankNumber | null>({
    ibanNumber: "",
    bankAccountName: "",
    bankName: "",
    bankBranch: "",
    bankId: 0,
  });

  let emptyPlan: IPaymentPlan = {
    id: 0,
    planName: "",
    installmentNumber: 1,
    dueDate: new Date(),
    installmentAmount: 0,
    index: 0,
    bankAccountName: "",
    bankBranch: "",
    bankName: "",
    ibanNumber: "",
    bankId: 0,
  };
  useEffect(() => {
    if (id) {
      FindPaymentPlan(id);
    } else {
      getBankList();
    }
  }, [id]);

  const getBankList = async () => {
    const { data, error } = await paymentPlanService.BanksList();

    if (data) {
      if (data.success) {
        setBankList(data.returnData as IBank[]);
        return data.returnData as IBank[];
      } else {
        toast.error(data.errorMessage);
      }
    }
    if (error) {
      toast.error(error);
    }
  };

  const FindPaymentPlan = async (planName: string) => {
    setIsLoading(true);
    setIsUpdateData(true);
    const _bankList = await getBankList();

    const { data, error } = await paymentPlanService.FindPaymentPlan(planName);
    setIsLoading(false);
    if (data) {
      if (data.success) {
        const resData = data?.returnData as IPaymentPlan[];

        resData.forEach((item, index) => {
          item.dueDate = moment(item.dueDate).toDate();
        });
        console.log("FindPaymentPlan resData => ", resData);
        setPaymentPlanData(resData);
        if (resData.length > 0) {
          setBankDetail({
            ibanNumber: resData[0].ibanNumber,
            bankAccountName: resData[0].bankAccountName,
            bankName: resData[0].bankName,
            bankBranch: resData[0].bankBranch,
          });
        }
        if (_bankList) {
          const bank = _bankList.find((item) => item.id === resData[0].bankId);
          setSelectedBank(bank || null);
        }

        btnNewRowRef.current.disabled = true;
      } else {
        toast.error(data?.errorMessage);
      }
    }
  };

  const dueDateTemplate = (rowData: IPaymentPlan) => {
    return <React.Fragment>{rowData.dueDate.toLocaleDateString()}</React.Fragment>;
  };

  const footerTemplate = (data: any) => {
    console.log("data => ", data);
    return (
      <React.Fragment>
        <Button
          className="h-10"
          loading={btnSaveRef.current?.disabled}
          ref={btnSaveRef}
          style={{ marginLeft: "10px" }}
          onClick={() => btnSaveHandle(true)}
          label="Satır Ekle"
        ></Button>
      </React.Fragment>
    );
  };

  const formatDateForDotNetAPI = (date: Date): Date => {
    const turkeyDate = new Date(date);
    turkeyDate.setHours(0, 0, 0, 0);
    const utcDate = new Date(turkeyDate.getTime() - 3 * 60 * 60 * 1000);
    utcDate.setDate(utcDate.getDate() + 1);
    return utcDate;
  };

  const btnSaveHandle = async (stayOnPage: boolean = false) => {
    console.log("btnSaveHandle => ", paymentPlanData);

    btnSaveRef.current.disabled = true;

    if (paymentPlanData.length < 1) {
      toast.error("Ödeme planı eklemelisiniz.");
      btnSaveRef.current.disabled = false;
      return;
    } else if (paymentPlanData[0].planName.length < 1) {
      toast.error("Ödeme planı ismi girmelisiniz.");
      btnSaveRef.current.disabled = false;
      return;
    } else if (paymentPlanData[0].installmentAmount < 1) {
      toast.error("aksit tutarı girmelisiniz.");
      btnSaveRef.current.disabled = false;
      return;
    } else if (paymentPlanData[0].dueDate.toLocaleDateString() === "Invalid Date") {
      toast.error("Ödeme planı tarihi girmelisiniz.");
      btnSaveRef.current.disabled = false;
      return;
    }

    if (paymentPlanData[0].id > 0) {
      toast.error("Güncelleme işlemi yapılamaz !");
      btnSaveRef.current.disabled = false;
      return;
    }

    const { data, error } = await paymentPlanService.AddPaymentPlan(paymentPlanData);

    if (error) {
      toast.error(error);
    } else {
      if (data) {
        if (data.success) {
          toast.success(data.successMessage);
          navigate("/paymentList");
        } else {
          toast.error(data.errorMessage);
        }
      }
    }
    btnSaveRef.current.disabled = false;
  };
  const btnBankSaveHandle = async () => {
    // console.log("bankDetail => ", bankDetail);
    if (isUpdateData) {
      //
      const updateData = {
        ...bankDetail,
        planName: paymentPlanData[0].planName,
        bankId: paymentPlanData[0].bankId,
      };

      // public string PlanName { get; set; } // aidat
      // public string BankAccountName { get; set; }
      // public string BankName { get; set; }
      // public string IbanNumber { get; set; }
      // public string BankBranch { get; set; }
      const { data, error } = await paymentPlanService.UpdateBankDetail(updateData);

      if (error) {
        toast.error(error);
      } else {
        if (data) {
          if (data.success) {
            toast.success(data.successMessage);
          } else {
            toast.error(data.errorMessage);
          }
        }
      }
    }
  };

  const addRow = () => {
    if (paymentPlanData.length == 0) {
      emptyPlan.installmentNumber = 1;
    } else {
      const lastNumber = paymentPlanData[paymentPlanData.length - 1].installmentNumber;
      const lastDueDate = paymentPlanData[paymentPlanData.length - 1].dueDate;
      // add one month to last due date
      const newDueDate = new Date(lastDueDate);
      newDueDate.setMonth(newDueDate.getMonth() + 1);

      const lastIndex = paymentPlanData[paymentPlanData.length - 1].index;

      emptyPlan.index = lastIndex + 1;
      emptyPlan.dueDate = newDueDate;
      emptyPlan.installmentNumber = Number.parseInt(lastNumber.toString()) + 1;

      const lastPlanName = paymentPlanData[paymentPlanData.length - 1].planName;
      emptyPlan.planName = lastPlanName;

      const lastInstallmentAmount = paymentPlanData[paymentPlanData.length - 1].installmentAmount;
      emptyPlan.installmentAmount = lastInstallmentAmount;
    }

    setPaymentPlanData([...paymentPlanData, emptyPlan]);
  };

  const DropdownValueTemplate = (option: any) => {
    if (option.tanim) {
      return (
        <span>
          {option.tanim} - {option.bankaAdi}
        </span>
      );
    } else {
      return <span>{option}</span>;
    }
  };

  const DropdownSelectedTemplate = (option: any, props: any) => {
    if (option) {
      if (option.tanim) {
        return (
          <div className="flex justify-between " style={{ marginTop: -4 }}>
            <span>{option.tanim}</span>
          </div>
        );
      } else {
        return (
          <div className="flex justify-between " style={{ marginTop: -4 }}>
            <span>{option}</span>
          </div>
        );
      }
    } else {
      return (
        <div className="flex " style={{ marginTop: -4 }}>
          {props.placeholder}
        </div>
      );
    }
  };

  const [selectedBank, setSelectedBank] = useState<IBank | null>(null);

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      {isLoading && <ProgressSpinner style={{ width: "50px", height: "50px" }} />}

      <div className="m-2 mb-5">
        <label className="text-2xl">{isUpdateData ? "Ödeme planı Güncelle" : "Ödeme Planı ekle"}</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>
      <div className="ml-2">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-3 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">Ödeme Planı Adı</label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      disabled={isUpdateData}
                      value={paymentPlanData[0].planName}
                      placeholder="Ödeme planı adı"
                      onChange={(e: any) => {
                        e.target.value = e.target.value.replace(/[^a-zA-Z0-9- ]/g, "");

                        let newPlanList = paymentPlanData.map((item) => {
                          return { ...item, planName: e.target.value };
                        });
                        setPaymentPlanData(newPlanList);
                      }}
                    />
                  </div>
                </div>
                {paymentPlanData ? (
                  <>
                    <div className="col-span-1 lg:col-span-1">
                      <label className="block text-sm font-medium text-gray-700">Kaçıncı taksit olduğu</label>
                    </div>
                    <div className="col-span-1 lg:col-span-1">
                      <label className="block text-sm font-medium text-gray-700">Taksit tutarı</label>
                    </div>
                    <div className="col-span-1 lg:col-span-1">
                      <label className="block text-sm font-medium text-gray-700">Son ödeme tarihi</label>
                    </div>
                    {paymentPlanData.map((item, index) => {
                      return (
                        <div key={index} className="grid grid-cols-3 gap-4 col-span-3">
                          <div className="col-span-1 lg:col-span-1">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputComp
                                type="text"
                                value={item.installmentNumber}
                                placeholder="kaçıncı taksit olduğu"
                                onChange={(e: any) => {
                                  const data = paymentPlanData.map((item) => {
                                    if (item.index === index) {
                                      return {
                                        ...item,
                                        installmentNumber: e.target.value,
                                      };
                                    }
                                    return item;
                                  });
                                  setPaymentPlanData(data);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-span-1 lg:col-span-1">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <InputComp
                                type="text"
                                value={item.installmentAmount}
                                placeholder="Taksit tutarı"
                                onChange={(e: any) => {
                                  // update  installmentAmount

                                  const data = paymentPlanData.map((item) => {
                                    if (item.index === index) {
                                      return {
                                        ...item,
                                        installmentAmount: e.target.value,
                                      };
                                    }
                                    return item;
                                  });
                                  setPaymentPlanData(data);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-span-1 lg:col-span-1">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Calendar
                                showButtonBar
                                locale="tr"
                                className="p-inputtext-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300  w-full rounded-md sm:text-sm h-10"
                                dateFormat="dd/mm/yy"
                                value={item.dueDate}
                                onChange={(e) => {
                                  console.log("change calendar", e.target.value);
                                  const data = paymentPlanData.map((item) => {
                                    if (item.index === index) {
                                      return {
                                        ...item,
                                        dueDate: formatDateForDotNetAPI(e.target.value as Date),
                                      };
                                    }
                                    return item;
                                  });
                                  setPaymentPlanData(data);
                                }}
                              ></Calendar>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : null}

                <div className="col-span-3 lg:col-span-3">
                  <Button
                    className="h-10"
                    loading={btnNewRowRefLoading}
                    disabled={btnNewRowRef.current?.disabled}
                    ref={btnNewRowRef}
                    onClick={() => addRow()}
                    label="Satır Ekle"
                  ></Button>
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center px-4 py-3 bg-gray-50 text-right sm:px-6">
              <div>
                <Button disabled={isUpdateData} className="h-10" loading={btnSaveRef.current?.disabled} ref={btnSaveRef} onClick={() => btnSaveHandle()} label="Kaydet"></Button>
                <Button
                  disabled={isUpdateData}
                  className="h-10"
                  loading={btnSaveRef.current?.disabled}
                  ref={btnSaveRef}
                  style={{ marginLeft: "10px" }}
                  onClick={() => btnSaveHandle(true)}
                  label="Kaydet ve Sayfada Kal"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* banka hesap bilgileri */}
      <div className="ml-2 mt-5">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div className="col-span-3 lg:col-span-3">
                <label className="block text-sm font-medium text-gray-700">Kasa bilgisi</label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  {/* <DropdownComponent
                    id="id"
                    value={selectedBank}
                    options={bankList}
                    onChange={(e) => {
                      console.log("bankId => ", e.target.value);
                      setSelectedBank(e.target.value);
                    }}
                    optionLabel="name"
                    dataKey="id"
                    filter
                    showClear
                    filterBy="name"
              
                    valueTemplate={DropdownSelectedTemplate}
                    //   itemTemplate={classroomOptionTemplate}
                  /> */}
                  <Dropdown
                    id="id"
                    optionLabel="name"
                    dataKey="id"
                    className=" w-full h-10 "
                    value={selectedBank}
                    options={bankList}
                    onChange={(e) => {
                      console.log("bankId => ", e.target.value);

                      if (e.target.value) {
                        setSelectedBank(e.target.value);
                        const data = paymentPlanData.map((item) => {
                          return {
                            ...item,
                            bankId: e.target.value.id,
                            bankAccountName: e.target.value.bankAccountName,
                            bankName: e.target.value.bankaAdi,
                            ibanNumber: e.target.value.iban,
                            bankBranch: e.target.value.subeAdi,
                          };
                        });

                        setBankDetail(data[0]);

                        setPaymentPlanData(data);
                      }
                    }}
                    placeholder="Kasa varsa seçiniz"
                    valueTemplate={DropdownSelectedTemplate}
                    itemTemplate={DropdownValueTemplate}
                  />
                </div>
              </div>

              <div className="grid grid-cols-4 gap-4">
                <div className="col-span-2 lg:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">*Hesap / Alıcı Adı</label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={bankDetail?.bankAccountName}
                      placeholder="Alıcı / Hesap Adı"
                      onChange={(e: any) => {
                        setBankDetail({ ...bankDetail, bankAccountName: e.target.value });

                        // update all paymentPlanData bankAccountName
                        let newPlanList = paymentPlanData.map((item) => {
                          return { ...item, bankAccountName: e.target.value };
                        });
                        setPaymentPlanData(newPlanList);
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-2 lg:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">*Banka Adı</label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={bankDetail?.bankName}
                      placeholder="Banka adı"
                      onChange={(e: any) => {
                        setBankDetail({ ...bankDetail, bankName: e.target.value });

                        // update all paymentPlanData bankName
                        let newPlanList = paymentPlanData.map((item) => {
                          return { ...item, bankName: e.target.value };
                        });
                        setPaymentPlanData(newPlanList);
                      }}
                    />
                  </div>
                </div>

                <div className="col-span-2 lg:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">*IBAN Numarası</label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputMask
                      className="p-inputtext-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 block w-full rounded-md sm:text-sm h-10"
                      value={bankDetail?.ibanNumber}
                      onChange={(e: any) => {
                        setBankDetail({ ...bankDetail, ibanNumber: e.target.value });

                        // update all paymentPlanData ibanNumber
                        let newPlanList = paymentPlanData.map((item) => {
                          return { ...item, ibanNumber: e.target.value };
                        });
                        setPaymentPlanData(newPlanList);
                      }}
                      validateOnly
                      mask="TR99 9999 9999 9999 9999 9999 99"
                      placeholder="TR99 9999 9999 9999 9999 9999 99"
                    />
                  </div>
                </div>

                <div className="col-span-2 lg:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">Şube Adı</label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={bankDetail?.bankBranch}
                      placeholder="Şube adı"
                      onChange={(e: any) => {
                        setBankDetail({ ...bankDetail, bankBranch: e.target.value });

                        // update all paymentPlanData bankBranch
                        let newPlanList = paymentPlanData.map((item) => {
                          return { ...item, bankBranch: e.target.value };
                        });
                        setPaymentPlanData(newPlanList);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {isUpdateData && (
              <div className="flex justify-between items-center px-4 py-3 bg-gray-50 text-right sm:px-6">
                <div>
                  <Button className="h-10" loading={btnSaveRef.current?.disabled} ref={btnSaveRef} onClick={() => btnBankSaveHandle()} label="Kaydet"></Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
