import moment from "moment";
import { ProgressSpinner } from "primereact/progressspinner";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../Context/Store";
import { SchoolService } from "../../Services/SchoolService";
import { Chart } from "primereact/chart";
import toast from "react-hot-toast";
import { UserRole } from "../../Models";
import OnlineList from "../../Components/SignalrOnline/OnlineList";

const HomePage = () => {
  const schoolService = SchoolService();


  const [isLoading, setIsLoading] = useState(false);
  const [schoolData, setSchoolData] = useState<any[]>([]);

  const [chartData, setChartData] = useState<any>();

  useEffect(() => {
    GetReport();
  }, []);

  useEffect(() => {
    if (schoolData) {
      // console.log('schoolData', schoolData);

      setChartData({
        labels: ["Aktif Öğrenci Sayısı ", "Ayrılan Öğrenci Sayısı", "Dondurulan Öğrenci Sayısı", "Veli Sayısı"],
        datasets: [
          {
            data: [
              schoolData.find((t) => t.reportName == "StudentActiveCount-Devam Ediyor")?.reportValue,
              schoolData.find((t) => t.reportName == "StudentActiveCount-Ayrıldı")?.reportValue,
              schoolData.find((t) => t.reportName == "StudentActiveCount-Dondurdu")?.reportValue,
              schoolData.find((t) => t.reportName == "ParentCount")?.reportValue,
            ],
            backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726", "#DC29B1"],
            hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D", "#DA58BB"],
          },
        ],
      });
    }
  }, [schoolData]);

  const GetReport = async () => {
    setIsLoading(true);
    const { data, error } = await schoolService.GetReport();
    setIsLoading(false);
    if (data) {
      if (data.success) {
        setSchoolData(data.returnData as any[]);
      } else {
        toast.error(data?.errorMessage);
      }
    }
  };

  const Card = ({ text, data }: { text: string; data: any }) => {
    return (
      <div className="m-2">
        {text} {data}
      </div>
    );
  };

  const [lightOptions] = useState({
    plugins: {
      legend: {
        labels: {
          color: "#495057",
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        bodyFont: {
          size: 16,
        },
      },
    },
  });
  return (
    <>
      <div className="m-4 bg-white p-4 rounded-lg">
        {isLoading ? (
          <ProgressSpinner />
        ) : (
          <div className="block">
            <div className="max-w-5xl flex justify-center sm:justify-center md:justify-start  ">
              <Chart type="pie" className="text-3xl" data={chartData} options={lightOptions} style={{ position: "relative", width: "40%" }} />
            </div>

            <div className="">
              <div>
                <Card text="Öğrenci Veli İlişkilendirme Sayısı : " data={schoolData.find((t) => t.reportName == "StudentParentRelationCount")?.reportValue} />
              </div>
              <div>
                <Card text="Personel Sayısı : " data={schoolData.find((t) => t.reportName == "PersonnelCount")?.reportValue} />
              </div>
              {/* <div>
                <Card
                  text="Sms Krediniz : "
                  data={
                    schoolData.find((t) => t.reportName == "SmsCredit")
                      ?.reportValue
                  }
                />
              </div> */}
            </div>
          </div>
        )}
      </div>

      <OnlineList />

    </>
  );
};

export default HomePage;
