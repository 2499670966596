class Variables {
  public year: string = `2024-2025`;
  public version: string = `1.5.8`;
  public isPorduct: boolean = true;

  public mapsApiKey :string ="AIzaSyBDF78O-PoyTbhMaT0MH-oYLiL90yaHY3U";

  public localUrl: string = "https://192.168.1.12:7021/api";
  public socketLocalUrl: string = "https://192.168.1.12:5238";

  public apiUrl: string = "https://webapi.prookul.com.tr/api";
   public socketUrl: string = "https://socket.prookul.com.tr";
 // public socketUrl: string = "https://socket.yotekbilisim.com";

}

export default new Variables();
