import React, { useContext, useEffect, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { IPersonnel, ISchoolAdmin, IUser } from "../../../Models";
import { MasterDbService } from "../../../Services/MasterDbService";
import { FilterMatchMode } from "primereact/api";
import SkeletonList from "../../../Components/Loading/Skeleton_List";
import { AppContext } from "../../../Context/Store";
import { Loginservice, PersonnelService } from "../../../Services";
import toast from "react-hot-toast";
import "./school.css";
import StaticVariable from "../../../Core/StaticVariable";
const SchoolList_Admin = () => {
  const navigate = useNavigate();
  const loginService = Loginservice();
  const personnelService = PersonnelService();
  const { user, setUser } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const masterDbService = MasterDbService();
  const [schoolList, setSchoolList] = useState<ISchoolAdmin[] | null>();
  const [filters, setFilters] = useState<any>(null);

  useEffect(() => {
    GetSchoolList();
  }, []);

  const GetSchoolList = async () => {
    setIsLoading(true);
    const { data, error } = await masterDbService.GetSchoolList();
    setIsLoading(false);
    if (data && data.success) {
      let _data = data.returnData as ISchoolAdmin[];

      for (let i = 0; i < _data.length; i++) {
        _data[i].ililce = _data[i].il + " " + _data[i].ilce;
      }

      setSchoolList(_data);
      // console.log("schooList => ", _data);
      initFilters();
    } else {
      setSchoolList(null);
    }
  };

  const initFilters = () => {
    setFilters({
      okulAdi: { value: null, matchMode: FilterMatchMode.CONTAINS ,lng : "tr" },
      ililce: { value: null, matchMode: FilterMatchMode.CONTAINS ,lng : "tr" },
      yil: { value: null, matchMode: FilterMatchMode.CONTAINS,lng : "tr"  },
      kurumKodu: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
  };

  const btnSaveHandle = async () => {};

  const CreateDatabaseFile = async (schoolId: number, kurumKodu: string, okulAdı: string) => {
    setIsLoading(true);
    await masterDbService.DownloadDatabaseFile(schoolId, kurumKodu, okulAdı);
    setIsLoading(false);
  };

  const CreateDatabaseOnServer = async (schoolId: number) => {
    setIsLoading(true);
    const { data, error } = await masterDbService.CreateDatabaseOnServer(schoolId);
    setIsLoading(false);
    console.log("data => ", data);
    console.log("error => ", error);
    if (data && data.success) {
      toast.success("Veritabanı oluşturuldu.");
      GetSchoolList();
    } else {
      toast.error("Veritabanı oluşturulamadı.");
    }
  };

  const LoginWithSchool = async (school: ISchoolAdmin) => {
    if (user.dbName == school.kurumKodu) {
      toast.error("Zaten bu okula giriş yaptınız.");
      return;
    }

    setIsLoading(true);
    const { data, error } = await loginService.LoginWebSecond(school.kurumKodu);
    setIsLoading(false);

    if (error) {
      console.log("Error LoginButtonHandle => ", error);
    } else {
      if (data) {
        if (data.success) {
          // console.log("LoginWithSchool data => ", data.returnData);
          let _user = data.returnData as IUser;
          // console.log("logged user => ", _user);
          if (_user) {
            // const _role =
            //   UserRole[_user.role.toUpperCase() as keyof typeof UserRole];

            // user.role = _role;
            _user.firstLoginInfo = user;

            setUser(_user);
            const personnelData = await GetDetail(_user.staffId);
            _user.imageUrl = personnelData ? personnelData.imageUrl : "";
            setUser(_user);
            toast.success(`Hoş geldin : ${_user.fullName} ${_user.schoolFullName}`);
          }

          navigate("/", { replace: true });
        } else {
          toast.error(data.errorMessage);
          console.log(`${data?.errorMessage}`);
        }
      }
    }
  };

  const GetDetail = async (personnelId: number) => {
    setIsLoading(true);
    const { data, error } = await personnelService.FindPersonnelById(personnelId);

    setIsLoading(false);

    if (error) {
      console.log("Error LoginButtonHandle => ", error);
    } else {
      if (data) {
        if (data.success) {
          return data.returnData as IPersonnel;
          // navigate("/");
        } else {
          toast.error(data.errorMessage);
        }
      }
    }
  };

  const idBodyTemplate = (rowData: ISchoolAdmin) => {
    return (
      <div className="flex flex-1 flex-row items-center justify-between">
        <label>{rowData.id}&nbsp;&nbsp;</label>
        <Button
          style={{ height: 32, width: 32 }}
          icon="pi pi-sign-in"
          className="mr-4 p-button-rounded p-button-success"
          onClick={(e) => {
            LoginWithSchool(rowData);
          }}
        />

      </div>
    );
  };

  const actionBodyTemplate = (rowData: ISchoolAdmin) => {
    return (
      <div>
        <Button
          style={{ height: 30, width: 30, marginRight: 5 }}
          icon="pi pi-eye"
          className="p-button-rounded p-button-warning"
          onClick={() => navigate(`/admin/school/${rowData.id}`)}
        />
        <Button
          style={{ height: 30, width: 30, marginRight: 5 }}
          icon="pi pi-database"
          className="p-button-rounded p-button-info"
          onClick={(e) => {
            CreateDatabaseOnServer(rowData.id);
          }}
        />
        <Button
          style={{ height: 30, width: 30 }}
          icon="pi pi-cloud-download"
          className="p-button-rounded p-button-info"
          onClick={(e) => {
            CreateDatabaseFile(rowData.id, rowData.kurumKodu, rowData.okulAdi);
          }}
        />
      </div>
    );
  };

  const ilIlceTemplate = (rowData: ISchoolAdmin) => {
    return (
      <div>
        <label>
          {rowData.il}-{rowData.ilce}
        </label>
      </div>
    );
  };
  const yilTemplate = (rowData: ISchoolAdmin) => {
    return (
      <div>{StaticVariable.year == rowData.yil ? <label className="text-blue-600 font-bold"> {rowData.yil}</label> : <label className="text-red-300"> {rowData.yil}</label>}</div>
    );
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="">
        <label className="text-lg">* Okul Listesi</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div className="">
            <div className=" md:mt-0 ">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className=" bg-white ">
                  {schoolList && (
                    <DataTable
                      filters={filters}
                      value={schoolList}
                      stripedRows
                      emptyMessage="Kayıtlı okul yok"
                      responsiveLayout="scroll"
                      loading={isLoading}
                      scrollHeight="calc(100vh - 150px)"
                      scrollable
                      filterDisplay="row"
                      size="small"
                      footer={`Toplam Okul Sayısı: ${schoolList.length}`}
                    >
                      <Column sortable className=" max-w-[90px]" header="#" body={(data) => idBodyTemplate(data)} exportable={false}></Column>
                      <Column sortable filter className="min-w-[200px]" field="okulAdi" header="Okul Adı"></Column>
                      <Column sortable filter className="min-w-[150px]" header="İl - İlçe" filterField="ililce" body={(data) => ilIlceTemplate(data)}></Column>
                      <Column filter showFilterMenu={false} className=" max-w-[130px] min-w-[130px]" field="kurumKodu" header="Kurum Kodu"></Column>
                      <Column
                        sortable
                        filter
                        showFilterMenu={false}
                        showClearButton={false}
                        className="min-w-[50px] max-w-[100px]"
                        field="yil"
                        body={(data) => yilTemplate(data)}
                        header="Dönem"
                      ></Column>
                    

                      <Column className="min-w-[150px] max-w-[170px] " header="İşlemler" body={(data) => actionBodyTemplate(data)} exportable={false}></Column>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SchoolList_Admin;
