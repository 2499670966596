import React, { useEffect, useRef, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import InputComp from "../../../Components/Input/InputComp";
import { Button } from "primereact/button";
import { ISchoolAdmin } from "../../../Models";
import { useNavigate, useParams } from "react-router-dom";
import { MasterDbService } from "../../../Services/MasterDbService";
import toast from "react-hot-toast";
import { Dropdown } from "primereact/dropdown";

const SchoolCard_Admin = () => {
  const { id } = useParams<"id">();
  const navigate = useNavigate();
  const masterDbService = MasterDbService();

  const btnRef: any = useRef<HTMLButtonElement>();
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [schoolData, setSchoolData] = useState<ISchoolAdmin>({
    id: 0,
    kurumKodu: "",
    dbName: "",
    yil: "",
    okulAdi: "",
    il: "",
    ilce: "",
    ililce: "",
    adres: "",
    telefon: "",
    cepTel: "",
    sistemIlgiliPersonel: "",
    sistemIlgiliPersonelTelefon: "",
    mudur: "",
    mudurTelefon: "",
    mudurYardimcisi: "",
    mudurYardimcisiTelefon: "",
    createDate: new Date(),
    latitude: "",
    longitude: "",
    distance: "0.100",
    workingType: "Online",
    personnelPayment: 0,
    studentPayment: 0,
  });
  const schoolWorkingType = ["Online", "Offline", "Online&Offline"];

  useEffect(() => {
    if (id) {
      find_ById(Number(id));
    }
  }, [id]);

  const find_ById = async (schoolId: number) => {
    if (schoolId) {
      setIsLoading(true);
      const { data, error } = await masterDbService.FindClientById(schoolId);
      setIsLoading(false);
      if (error != null) {
        console.log(error);
      } else {
        if (data) {
          if (data.success) {
            let _data = data.returnData as ISchoolAdmin;
            // console.log("_data=> ", _data);
            setSchoolData(_data);
          } else {
            toast.error(data.errorMessage);
            console.log("error Message => ", data?.errorMessage);
          }
        }
      }
    } else {
      toast.error("Yıldızlı alanları doldurunuz !");
    }
  };

  const btnSaveHandle = async () => {
    btnRef.current.disabled = true;

    if (id) {
      // Güncelle
      const { data, error } = await masterDbService.Update(schoolData);
      if (data) {
        if (data.success) {
          navigate("/admin/schools");
        } else {
          toast.error(data?.errorMessage);
        }
      }
    } else {
      // Yeni kayıt ekle

      const { data, error } = await masterDbService.Add(schoolData);
      if (data) {
        if (data.success) {
          navigate("/admin/schools");
        } else {
          toast.error(data?.errorMessage);
        }
      }
    }

    btnRef.current.disabled = false;
  };

  const DropdownSelectedTemplate = (option: any, props: any) => {
    if (option) {
      if (option.name) {
        return (
          <div className="flex justify-between " style={{ marginTop: -4 }}>
            <span>{option.name}</span>
          </div>
        );
      } else {
        return (
          <div className="flex justify-between " style={{ marginTop: -4 }}>
            <span>{option}</span>
          </div>
        );
      }
    } else {
      return (
        <div className="flex " style={{ marginTop: -4 }}>
          {props.placeholder}
        </div>
      );
    }
  };
  const DropdownValueTemplate = (option: any) => {
    if (option.name) {
      return <span>{option.name}</span>;
    } else {
      return <span>{option}</span>;
    }
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      {isLoading && (
        <ProgressSpinner style={{ width: "50px", height: "50px" }} />
      )}

      <div className="m-2 mb-5">
        <label className="text-2xl">
          {isUpdateData ? "Okul Güncelle" : "Yeni Okul ekle"}
        </label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>
      <div className="ml-2">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Okul Adı
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={schoolData.okulAdi}
                      placeholder="Okul adı"
                      onChange={(e: any) => {
                        setSchoolData({
                          ...schoolData,
                          okulAdi: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-3 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Kurum kodu
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={schoolData.kurumKodu}
                      placeholder="Kurum Kodu"
                      onChange={(e: any) => {
                        setSchoolData({
                          ...schoolData,
                          kurumKodu: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Okul Cep Telefon Numarası
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={schoolData.cepTel}
                      placeholder="Okul Cep Tel"
                      onChange={(e: any) => {
                        setSchoolData({
                          ...schoolData,
                          cepTel: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-3 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Okul Telefon
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={schoolData.telefon}
                      placeholder="Okul Telefon"
                      onChange={(e: any) => {
                        setSchoolData({
                          ...schoolData,
                          telefon: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col-span-12 lg:col-span-6">
                  <label className="block text-sm font-medium text-gray-700">
                    Adres
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={schoolData.adres}
                      placeholder="Adres"
                      onChange={(e: any) => {
                        setSchoolData({
                          ...schoolData,
                          adres: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    il
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={schoolData.il}
                      placeholder="il"
                      onChange={(e: any) => {
                        setSchoolData({ ...schoolData, il: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    İlçe
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={schoolData.ilce}
                      placeholder="İlçe"
                      onChange={(e: any) => {
                        setSchoolData({
                          ...schoolData,
                          ilce: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Müdür
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={schoolData.mudur}
                      placeholder="Müdür Adı Soyadı"
                      onChange={(e: any) => {
                        setSchoolData({
                          ...schoolData,
                          mudur: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Müdür Telefon
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={schoolData.mudurTelefon}
                      placeholder="Müdür Telefon"
                      onChange={(e: any) => {
                        setSchoolData({
                          ...schoolData,
                          mudurTelefon: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Müdür Yardımcısı
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={schoolData.mudurYardimcisi}
                      placeholder="Müdür Yardımcısı Adı Soyadı"
                      onChange={(e: any) => {
                        setSchoolData({
                          ...schoolData,
                          mudurYardimcisi: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Müdür Yardımcısı Telefon
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={schoolData.mudurYardimcisiTelefon}
                      placeholder="Müdür Yardımcısı Telefon"
                      onChange={(e: any) => {
                        setSchoolData({
                          ...schoolData,
                          mudurYardimcisiTelefon: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    İlgili Personel
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={schoolData.sistemIlgiliPersonel}
                      placeholder="İlgili Personel Adı Soyadı"
                      onChange={(e: any) => {
                        setSchoolData({
                          ...schoolData,
                          sistemIlgiliPersonel: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    İlgili Personel Telefon
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={schoolData.sistemIlgiliPersonelTelefon}
                      placeholder="İlgili Personel Telefon"
                      onChange={(e: any) => {
                        setSchoolData({
                          ...schoolData,
                          sistemIlgiliPersonelTelefon: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col-span-12 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Enlem
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={schoolData.latitude}
                      placeholder="Enlem"
                      onChange={(e: any) => {
                        setSchoolData({
                          ...schoolData,
                          latitude: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Boylam
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={schoolData.longitude}
                      placeholder="Boylam"
                      onChange={(e: any) => {
                        setSchoolData({
                          ...schoolData,
                          longitude: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Mobil Uygulama İçin Mesafe
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <InputComp
                      type="text"
                      value={schoolData.distance}
                      placeholder="Mobil Uygulama İçin Mesafe"
                      onChange={(e: any) => {
                        setSchoolData({
                          ...schoolData,
                          distance: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Çalışma Tipi
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <Dropdown
                      className=" w-full h-10 "
                      value={schoolData.workingType}
                      options={schoolWorkingType}
                      onChange={(e) => {
                        setSchoolData({
                          ...schoolData,
                          workingType: e.target.value,
                        });
                      }}
                      placeholder="Çalışma tipi seçiniz"
                      valueTemplate={DropdownSelectedTemplate}
                      itemTemplate={DropdownValueTemplate}
                    />
                  </div>
                </div>

                      
                <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">Öğrenci Ücret</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.studentPayment}
                        placeholder="Öğrenci ücreti"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            studentPayment: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">Personel Ücret</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <InputComp
                        type="text"
                        value={schoolData.personnelPayment}
                        placeholder="Personel ücreti"
                        onChange={(e: any) => {
                          setSchoolData({
                            ...schoolData,
                            personnelPayment: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <Button
                loading={btnRef?.current?.disabled}
                ref={btnRef}
                onClick={() => btnSaveHandle()}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Kaydet
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default SchoolCard_Admin;
