import moment from "moment";
import React, { useContext, useEffect } from "react";
import { AppContext } from "../../Context/Store";
import { IOnlineClientDetail, UserRole } from "../../Models";
import { SchoolService } from "../../Services/SchoolService";

const OnlineList = () => {
  const { onlineList, user } = useContext(AppContext);
  const schoolService = SchoolService();

  useEffect(() => {
    // if (user.role == UserRole.SUPERADMIN) {
    //   // tüm okul listesini çek
    //   GetClientList("All");
    // } else if (user.role == UserRole.ADMIN) {
    //   // sadece kendi okulun listesini çek
    //   GetClientList(user.dbName);
    // }

  }, []);


  const GetClientList =async (schoolCode:string) => {
    
    const { data, error } = await  schoolService.GetClientList(schoolCode);
  }

  const classroomDesktopCount = (data: IOnlineClientDetail[] | null) => {
    if (data) {
      return data.filter((t) => t.clientType == "classroomDesktop").length;
    }
  };

  const DesktopKioskCount = (data: IOnlineClientDetail[] | null) => {
    if (data) {
      return data.filter((t) => t.clientType == "DesktopKiosk").length;
    }
  };
  const WebPanelCount = (data: IOnlineClientDetail[] | null) => {
    if (data) {
      return data.filter((t) => t.clientType == "WebPanel").length;
    }
  };

  return (
    <>
      {user.role == UserRole.SUPERADMIN && (
        <div className="m-4 bg-white p-4 rounded-lg">
          <label>Online Listesi {onlineList && onlineList?.length}</label>
          <div className="overflow-y-auto max-h-[500px]">
            {onlineList &&
              onlineList.length > 0 &&
              onlineList
                .sort((a, b) => ("" + a.schoolFullName).localeCompare(b.schoolFullName))
                .map((t, i) => (
                  <div key={i} className="m-2">
                    {!t.schoolCode ? "OkulKoduYok !" : t.schoolFullName} - {t.schoolCode} - {t?.locationName}
                    <div>
                      -- Sınıf :{classroomDesktopCount(t?.detail)} - Kiosk : {DesktopKioskCount(t?.detail)} - WebPanel : {WebPanelCount(t?.detail)}
                    </div>
                    {t.detail && (
                      <>
                        {t.detail
                          .sort((a, b) => ("" + a.clientType).localeCompare(b.clientType))
                         // .sort((a, b) => Number(a.username.split("@")[0]) - Number(b.username.split("@")[0]))
                          .map((t, i) => (
                            <div key={i}>
                              <li>
                                {t &&
                                  t.clientType +
                                    " - " +
                                    t.username +
                                    " - N:" +
                                    t.displayName +
                                    " - V:" +
                                    t.version +
                                    " - Connected : " +
                                    moment(t.connectedDate).format("DD.MM.YYYY HH:mm") +
                                    "-ReCon : " +
                                    moment(t.reConnectedDate).format("DD.MM.YYYY HH:mm") +
                                    " - Id S: " 
                                    }
                              </li>
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                ))}
          </div>
        </div>
      )}
    </>
  );
};

export default OnlineList;
