import AxiosUtily from "../Extended-tools/AxiosUtility";
import IResultDto from "../Models/Abstract/IResultDto";

const Loginservice = () => {
  const LoginUser = async (userName: string, password: string, userMapId: number, smsVerifyCode: string) => {
    try {
      const response = await AxiosUtily().post(`/Auth/login`, {
        userName: userName,
        password: password,
        userMapId: userMapId,
        smsVerifyCode: smsVerifyCode,
      });
      // console.log('login',response);
      if (response.status === 200) {
        // console.log('LoginUser service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const LoginWebSecond = async (schoolCode: string) => {
    try {
      const response = await AxiosUtily().post(
        `/Auth/LoginWebSecond`,
        '"' + schoolCode + '"',
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log('login',response);
      if (response.status === 200) {
        // console.log('LoginUser service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };
  const GetUserDetail = async () => {
    try {
      const response = await AxiosUtily().get(`/Auth/login`);
      // console.log('login',response);
      if (response.status === 200) {
        // console.log('LoginUser service response 200 ', response)
        const data = response.data as IResultDto;
        // console.log('data=> ', data)
        return { data };
      } else {
        // console.log('AddCategory service response !200 ', response)
        const error = response;
        return { error };
      }
    } catch (err: any) {
      const error = err.response ? err.response : err;
      // console.log(error);
      return { error };
    }
  };

  return {
    LoginUser,
    LoginWebSecond,
  };
};

export { Loginservice };
