import React, { useContext, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../Context/Store";
import StaticVariable from "../../../Core/StaticVariable";
import CookieCrypto from "../../../Extended-tools/CookieCrypto";
import { UserRole } from "../../../Models";

const Logintest = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(AppContext);


  console.log('git')

  useEffect(() => {
    CookieCrypto().RemoveCookie();
    setUser({
      userMapId: 0,
      dbName: "",
      fullName: "",
      staffId: 0,
      token: "",
      userId: 0,
      username: "",
      imageUrl: "",
      schoolFullName: "",
      role: UserRole.NEVER,
      firstLoginInfo: null,
    });
 
    navigate("/", { replace: true });
  }, []);

  return <div>logintest</div>;
};

export default Logintest;
