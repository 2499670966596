import React, { useEffect, useRef, useState } from "react";
import InputComp from "../../Components/Input/InputComp";
import { IPaymentPlan, IStudent } from "../../Models";
import { PaymentPlanService, StudentService } from "../../Services";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import toast from "react-hot-toast";
import SkeletonList from "../../Components/Loading/Skeleton_List";

const PaymentList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentPlanList, setPaymentPlanList] = useState<IPaymentPlan[] | null>();
  const paymentPlanService = PaymentPlanService();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    GetList();
  }, []);

  const GetList = async () => {
    setIsLoading(true);
    const { data, error } = await paymentPlanService.PaymentPlanListAll();
    setIsLoading(false);
    if (data) {
      if (data.success) {
        console.log('GetList => ', data.returnData)
        setPaymentPlanList(data.returnData as IPaymentPlan[]);
      } else {
        toast.error(data?.errorMessage);
      }
    } else {
      toast.error(error);
    }
  };

  const btnDeleteHandle = async (planName: string) => {
    const { data, error } = await paymentPlanService.Delete(planName);
    setIsLoading(false);
    if (error) {
      console.log("Error DeleteSchoolHandle => ", error);
    } else {
      if (data?.success) {
        toast.success("Silme işlemi başarılı");
        GetList();
      } else {
        reject(data?.errorMessage);
      }
    }
  };

  const confirm2 = (data: any) => {
    confirmDialog({
      message: `${data.planName} ödeme planını silmek istediğinize emin misiniz ? Hareketi varsa silinemez !`,
      header: "Silme işlemi",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => {
        btnDeleteHandle(data.planName);
      },
      reject,
    });
  };

  const reject = (text: string = "") => {
    console.log("text =>", text);
    toast.error(text.length > 0 ? text : "Silme işlemi iptal edildi");
  };

  const actionBodyTemplate = (rowData: IPaymentPlan) => {
    return (
      <div>
        <Button
          style={{ height: 30, width: 30, marginRight: 10 }}
          icon="pi pi-eye"
          className="p-button-rounded p-button-warning"
          onClick={() => {
            navigate(`/payment/${rowData.planName}`);
          }}
        />
        <Button style={{ height: 30, width: 30 }} icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirm2(rowData)} />
      </div>
    );
  };

  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">Ödeme Planı Listesi</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div className="">
            <ConfirmDialog />
            <div className=" ">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className=" bg-white space-y-6 ">
                  {paymentPlanList && (
                    <DataTable value={paymentPlanList} lazy stripedRows scrollable responsiveLayout="scroll" loading={isLoading} emptyMessage="Ekli ödeme planı bulunmamaktadır.">
                      <Column field="planName" className="h-11" header="Ödeme Planı Adı"></Column>
                      <Column field="installment" className="h-11" header="Taksit Sayısı"></Column>
                      <Column
                        field="totaInstallmentAmount"
                        className="h-11"
                        header="Toplam Ödenecek"
                        body={(rowData: any) => `${rowData.totaInstallmentAmount.toLocaleString("tr-TR")} ₺`}
                      ></Column>
                      <Column className="h-11" header="İşlemler" body={(data) => actionBodyTemplate(data)} exportable={false}></Column>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentList;
