import React, { useEffect, useState } from "react";
import InputComp from "../../Components/Input/InputComp";
import { IClassroom, IClient, IStudent } from "../../Models";
import { ClientService, StudentService } from "../../Services";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import SkeletonList from "../../Components/Loading/Skeleton_List";
import { Tooltip } from "primereact/tooltip";
import { classNames } from "primereact/utils";
import toast from "react-hot-toast";
import ConsoleTime from "../../Components/ConsoleTime/consoleTime";

const ClientList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const clientService = ClientService();
  const [clientList, setClientList] = useState<IClient[] | null>();

  const [classroomList, setClassroomList] = useState<IClassroom[] | null>();

  const classroomService = StudentService();

  const ClassroomList = async () => {
    setIsLoading(true);
 
    ConsoleTime("ClassroomList load start")
    const { data, error } = await classroomService.GetClassroomList();
    ConsoleTime("ClassroomList load end")

    setIsLoading(false);
    if (data) {
      if (data.success) {
        setClassroomList(data.returnData as IClassroom[]);
      } else {
        setClassroomList(null);
        toast.error(data?.errorMessage);
      }
    }
  };

  useEffect(() => {
    clientListLoad();
  }, []);



  const clientListLoad = async () => {
    await ClassroomList();
    setIsLoading(true);

    ConsoleTime("clientListLoad load start")
    const { data, error } = await clientService.GetList();
    ConsoleTime("clientListLoad load end")
    

    setIsLoading(false);
    if (data) {
      if (data.success) {
        setClientList(data.returnData as IClient[]);
      } else {
        setClientList(null);
      }
    }
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div>
        <Button
          style={{ height: 30, width: 30, marginRight: 5 }}
          icon="pi pi-eye"
          className="p-button-rounded p-button-warning"
          onClick={() => navigate(`/client/${rowData.id}`)}
        />
        {/* <Button
          style={{ height: 40, width: 40 }}
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning h-4"
          onClick={(e) => {
            // setSelectedUser(rowData);
            // setDeleteProductDialog(true);
          }}
        /> */}
      </div>
    );
  };

  const classroomNames = (item: IClient) => {
    // console.log("item => ", item);
    return (
      <div className="flex justify-center min-w-[50px]">
        <Tooltip target={classNames(".custom-tooltip-btn" + item.id)} autoHide>
          <ul className="flex flex-col p-0 m-0">
            {item.classroomIds?.split(",").map((item) => {
              const classroom = classroomList?.find((x) => x.id == Number.parseInt(item));
              if (classroom) {
                return (
                  <li key={classroom.id} className="flex lex-row">
                  <label className="mr-1">({classroom.id}) - </label>
                    <label className="mr-1">{classroom.name}</label>
                  </li>
                );
              }
            })}
          </ul>
        </Tooltip>
        <label className={classNames(item.classroomIds ? "custom-tooltip-btn" + item.id : "")}>{item.classroomIds}</label>
      </div>
    );
  };

  const statusBody = (data: IStudent) => {
    if (data.status === "Devam Ediyor") {
      return <label className="p-badge p-badge-warning">{data.status}</label>;
    } else {
      return <span className="p-badge p-badge-danger">{data.status}</span>;
    }
  };
  return (
    <div className="m-3 p-6 rounded-lg bg-white">
      <div className="m-2 mb-5">
        <label className="text-2xl">Client Listesi</label>
        <hr className="my-auto flex-grow-1 mt-2" />
      </div>

      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          <div className="ml-2">
            <div className="mt-5 md:mt-0 ">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  {clientList && (
                    <DataTable
                      value={clientList}
                      stripedRows
                      emptyMessage="Kayıtlı client yok"
                      responsiveLayout="scroll"
                      loading={isLoading}
                      scrollable
                      size="small"
                      footer={`Toplam Derslik Sayısı: ${clientList.length}`}
                    >
                      <Column field="userName" header="Derslik Id"></Column>
                      <Column field="displayName" header="Görünen isim"></Column>
                      <Column body={(data) => classroomNames(data)} header="Sınıf Idleri"></Column>
                      <Column field="locationDescription" header="Sınıf yer tanımı"></Column>
                      <Column header="İşlemler" body={(data) => actionBodyTemplate(data)} exportable={false}></Column>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ClientList;
